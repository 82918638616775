import React, { useState, useMemo, forwardRef, useImperativeHandle, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { AutoCompleteMarcasPayload } from 'interfaces';
import AutoCompleteService from 'services/autocomplete/AutoCompleteService';
import { UseFormRegister } from 'react-hook-form';
import { TextFieldHookForms } from '../text-field-hook-forms';
import style from './style';

type AutocompleteCategoriasProps = {
  // eslint-disable-next-line
  register?: UseFormRegister<any>;
  getMarcaSelected?: (marca: AutoCompleteMarcasPayload | null) => void;
  disabled?: boolean;
  marca?: AutoCompleteMarcasPayload | null;
  onChange?: () => void;
};

const AutocompleteMarcas = forwardRef(function AutocompleteMarcas(props: AutocompleteCategoriasProps, externalRef) {
  const classes = style();
  const {
    marca = null,
    register = () => ({
      ref: null
    }),
    getMarcaSelected = null,
    disabled = false,
    onChange = null
  } = props;

  const [campoMarca, setCamposMarca] = useState(false);
  const [marcaOptions, setMarcaOptions] = useState<AutoCompleteMarcasPayload[]>([]);
  const [campoMarcaValue, setMarcaValue] = useState<AutoCompleteMarcasPayload | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  /**
   * Permite que essas funcoes sejam acessiveis de fora do componente pelo atributo ref e o hook userRef
   */
  useImperativeHandle(externalRef, () => ({
    clearAutoComplete: () => {
      setMarcaValue(null);
      setCamposMarca(false);
      setInputValue('');
      getMarcaSelected?.(null);
    }
  }));

  const handleBuscaMarca = async (event: React.ChangeEvent<unknown>, value: string) => {
    if (event && event.type === 'change') {
      setInputValue(value);
      AutoCompleteService.marcas(value)?.then(({ data: { data } }) => {
        setMarcaOptions(data);
        setCamposMarca(true);
      });
    }
  };

  const { ref, ...restProps } = useMemo(() => {
    if (campoMarcaValue?.codmarca) {
      return register?.('codmarca', {
        setValueAs() {
          return campoMarcaValue?.codmarca;
        }
      });
    }

    return {
      ref: null
    };
  }, [campoMarcaValue?.codmarca, register]);

  useEffect(() => {
    if (marca) {
      setMarcaValue(marca);
      setInputValue(marca?.nome ?? '');
    }
  }, [marca]);

  return (
    <Autocomplete
      open={campoMarca}
      onInputChange={handleBuscaMarca}
      value={campoMarcaValue}
      onChange={(e, newValue: AutoCompleteMarcasPayload | null) => {
        setMarcaValue(newValue);
        onChange?.();
        getMarcaSelected?.(newValue);
        setInputValue(newValue?.nome ?? '');
      }}
      isOptionEqualToValue={(option, value) => option.nome === value.nome}
      disabled={disabled}
      getOptionLabel={option => option.nome ?? ''}
      onClose={() => {
        setCamposMarca(false);
      }}
      options={marcaOptions}
      autoComplete
      noOptionsText="No options"
      renderInput={params => (
        <TextFieldHookForms
          {...params}
          label="Marca"
          className={classes.textInput}
          variant="outlined"
          size="small"
          {...restProps}
          ref={ref}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              endAdornment: params.InputProps.endAdornment,
              value: inputValue
            }
          }}
        />
      )}
    />
  );
});

export default AutocompleteMarcas;
