import { useState, useCallback } from 'react';
import MidiasInternas from 'services/midias-internas/MidiasInternasService';

type FileUpload = {
  id: string;
  file: File;
  progress: number;
};

type IImage = {
  id: string;
  url: string;
  data: File;
};

export const useUploadImagesQueue = (codeProduct: string) => {
  const [finishedQueue, setFinishedQueue] = useState(false);
  const [images, setImages] = useState<IImage[]>([]);

  const initUpload = () => {
    const newFiles: FileUpload[] = images.map(({ id, data: file }) => ({
      id,
      file,
      progress: 0
    }));
    processQueue(newFiles);
  };

  const uploadFile = useCallback(
    async (fileUpload: FileUpload, queue: FileUpload[], callback: (files: FileUpload[]) => void) => {
      const formData = new FormData();

      formData.append('arquivo', fileUpload.file);
      formData.append('uso', 'FOTOGRAFIA');
      await MidiasInternas.upload(codeProduct, formData);

      setImages(state => state.filter(f => f.id !== fileUpload.id));
      callback(queue.filter(f => f.id !== fileUpload.id));
    },
    [codeProduct]
  );

  const processQueue = useCallback(
    async (queue: FileUpload[]) => {
      if (queue.length > 0) {
        await uploadFile(queue[0], queue, processQueue);
      } else {
        setFinishedQueue(true);
      }
    },
    [uploadFile]
  );

  const resetFinishedQueue = useCallback(() => {
    setFinishedQueue(false);
  }, [setFinishedQueue]);

  return {
    initUpload,
    resetFinishedQueue,
    finishedQueue,
    images,
    setImages
  };
};
