import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller } from 'react-hook-form';
import { setSeconds, format } from 'date-fns';
import { ElementoFaixa } from 'interfaces';

import { toHexaDecimal } from './toHexaDecimal';

type Props = {
  methods: any; // eslint-disable-line
  isLoading?: boolean;
  sendFormData: (data: ElementoFaixa) => void;
};

export function FormFaixa(props: Props) {
  const { methods, isLoading = false, sendFormData } = props;

  const navigate = useNavigate();

  const { register, handleSubmit, control, getValues } = methods;

  /**
   * Controle para selecionar qual dos checkbox "pagina" ser selecionado de acordo com resposta da API
   */
  const handleCheckboxPaginas = (pagina: string) => {
    if (getValues('paginas')) {
      return getValues('paginas').includes(pagina);
    }

    return false;
  };

  function onSubmit({ ...rest }) {
    const data = { ...rest };

    data.data_entrada = format(setSeconds(new Date(data.data_entrada), 0), "yyyy-MM-dd'T'HH:mm:ss");
    data.data_saida = format(setSeconds(new Date(data.data_saida), 59), "yyyy-MM-dd'T'HH:mm:ss");

    const payload = {
      agendado_de: data.data_entrada,
      agendado_ate: data.data_saida,
      paginas: data.paginas,
      configuracoes: {
        cronometro1: {
          data_inicio: data.data_entrada,
          data_termino: data.data_saida
        },
        bg_color: toHexaDecimal(data.bgcolor),
        link: data.link,
        linha1_fonte: {
          cor: toHexaDecimal(data.cor)
        },
        fixo_desktop: true,
        fixo_mobile: true,
        fixo_app: true,
        linha1_texto_desktop: data.texto,
        linha1_texto_mobile: data.texto,
        linha1_texto_app: data.texto,
        isCronometro: !!data.contador,
        label: data.label_faixa
      },
      status: data.status ? 'ativo' : 'inativo'
    };

    sendFormData(payload);
  }

  return isLoading ? (
    <div>Carregando...</div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <Controller
            name="status"
            control={control}
            render={({ field }) => {
              return (
                <FormGroup>
                  <FormControlLabel
                    label={field.value ? 'Ativo' : 'Inativo'}
                    control={<Switch checked={field.value} onChange={field.onChange} color="primary" />}
                  />
                </FormGroup>
              );
            }}
          />
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        <Grid size={12}>
          <TextField {...register('texto')} fullWidth label="Texto" />
        </Grid>
        <Grid size={6}>
          <TextField {...register('cor')} fullWidth label="Cor do texto" />
        </Grid>
        <Grid size={6}>
          <TextField {...register('bgcolor')} fullWidth label="Cor de fundo" />
        </Grid>
      </Grid>
      <Box my={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Página</FormLabel>
          <FormGroup row>
            <FormControlLabel
              {...register('paginas')}
              control={<Checkbox defaultChecked={handleCheckboxPaginas('home')} />}
              label="Home"
              value="home"
            />
            <FormControlLabel
              {...register('paginas')}
              control={<Checkbox defaultChecked={handleCheckboxPaginas('vitrine')} />}
              label="Vitrine"
              value="vitrine"
            />
            <FormControlLabel
              {...register('paginas')}
              control={<Checkbox defaultChecked={handleCheckboxPaginas('produto')} />}
              label="Produto"
              value="produto"
            />
            <FormControlLabel
              {...register('paginas')}
              control={<Checkbox defaultChecked={handleCheckboxPaginas('checkout')} />}
              label="Checkout"
              value="checkout"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        <Grid size={12}>
          <TextField {...register('link')} fullWidth label="Link" />
        </Grid>
        <Grid size={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="data_entrada"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  label="Data de entrada"
                  ampm={false}
                  ref={field.ref}
                  format="dd/MM/yyyy HH:mm"
                  onChange={field.onChange}
                  value={field.value}
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true
                    }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid size={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="data_saida"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  label="Data de saída"
                  ampm={false}
                  ref={field.ref}
                  format="dd/MM/yyyy HH:mm"
                  onChange={field.onChange}
                  value={field.value}
                  timeSteps={{ minutes: 1 }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      fullWidth: true
                    }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box my={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Contador</FormLabel>
          <Controller
            name="contador"
            control={control}
            render={({ field }) => (
              <FormGroup>
                <FormControlLabel
                  label={field.value ? 'Ativo' : 'Inativo'}
                  control={<Switch checked={field.value} onChange={field.onChange} color="primary" />}
                />
              </FormGroup>
            )}
          />
        </FormControl>
      </Box>
      <FormControl>
        <FormLabel>Label Faixa:</FormLabel>
        <Controller
          name="label_faixa"
          control={control}
          render={({ field }) => (
            <RadioGroup row value={field.value} onChange={field.onChange}>
              <FormControlLabel value="comeca" control={<Radio />} label="Começa em:" />
              <FormControlLabel value="termina" control={<Radio />} label="Termina em:" />
            </RadioGroup>
          )}
        />
      </FormControl>
      <Box mt={4}>
        <Button
          variant="contained"
          onClick={() => navigate('/painel-marketing/gerenciar-faixas')}
          color="secondary"
          sx={{ mr: 1 }}
        >
          Voltar
        </Button>
        <Button type="submit" variant="contained">
          Salvar
        </Button>
      </Box>
    </form>
  );
}
