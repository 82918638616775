import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import alertValidationMessage from 'helpers/alert-validation-message';
import { QueroVenderBatch, QueroVenderCaptadoraVendedora } from 'interfaces';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';

interface ModalAssociarComissionadaProps {
  batch: QueroVenderBatch;
  onCancel: () => void;
  afterMatchSeller(): void;
}

/**
 * Regras das validações
 */
const schema = Yup.object().shape({
  vendedora: Yup.string().required('Associar Vendedora é obrigatório'),
  comissionada: Yup.string(),
  trocarvendedora: Yup.boolean().optional()
});

function ModalAssociarComissionada(props: ModalAssociarComissionadaProps) {
  const { batch, afterMatchSeller, onCancel } = props;

  /**
   * Resgata dados do form e dispara mensagem de validação
   */
  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm<QueroVenderCaptadoraVendedora>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Controla a ação de cancelar
   */

  const handleCancelModal = () => {
    onCancel();
  };

  const handleSalvarDados = (data: QueroVenderCaptadoraVendedora) => {
    QueroVenderService.match_seller(batch.codquerovender, data)
      .then(() => {
        afterMatchSeller();
      })
      .catch(err => {
        if (err.response.status === 406) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            text: `
          ${err.response.data.error.message}
          Deseja vincular a vendedora a este cadastro?
        `
          }).then(result => {
            if (result.isConfirmed) {
              const newData = data;

              newData.trocarvendedora = true;

              QueroVenderService.match_seller(batch.codquerovender, newData).then(() => {
                afterMatchSeller();
              });
            }
          });
        }
      });
  };

  return (
    <Dialog open>
      <form onSubmit={handleSubmit(handleSalvarDados)}>
        <DialogTitle>Associar Captadora e Vendedora</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <TextFieldHookForms
                {...register('vendedora')}
                label="Associar Vendedora*"
                variant="outlined"
                aria-readonly={!!batch?.vendedora}
                defaultValue={batch?.vendedora?.codigo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldHookForms
                {...register('comissionada')}
                label="Associar Captadora"
                variant="outlined"
                aria-readonly={!!batch?.comissionada}
                defaultValue={batch?.comissionada?.codigo}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleCancelModal}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              Enviar
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default ModalAssociarComissionada;
