import { useCallback, useEffect, useState } from 'react';
import GeracaoCarrinhoService from 'services/geracao-carrinho/GeracaoCarrinhoService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Carrinho } from 'interfaces';

export default function useFetchCarrinho(codcarrinho: string) {
  const [carrinho, setCarrinho] = useState<Carrinho>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showScreen, setShowScreen] = useState(false);

  const navigate = useNavigate();

  const fetchCarrinho = useCallback(() => {
    GeracaoCarrinhoService.fetch_carrinho(codcarrinho)
      .then(result => {
        setCarrinho(result.data);
        setShowScreen(true);
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error?.response?.data?.error?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
        }).then(result => {
          if (result.isConfirmed) {
            navigate(`/ferramentas/gerar-carrinho`);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [codcarrinho, navigate]);

  useEffect(() => {
    setLoading(true);
    fetchCarrinho();
  }, [codcarrinho, fetchCarrinho]);

  return {
    carrinho,
    loading,
    showScreen,
    setCarrinho
  };
}
