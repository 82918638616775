import api from '../api';

export type FetchParams = { filtro: string; codcategoria?: number; codfiltrovariacaopai?: string; codmarca?: number };

const fetch_filtro_variacoes = ({ filtro, codcategoria, codfiltrovariacaopai, codmarca }: FetchParams) => {
  const params = [];

  if (codcategoria) {
    params.push(`codcategoria=${codcategoria}`);
  }

  if (codmarca) {
    params.push(`codmarca=${codmarca}`);
  }

  if (codfiltrovariacaopai) {
    params.push(`codfiltrovariacaopai=${codfiltrovariacaopai}`);
  }

  return api.get(`filtros/${filtro}/variacoes?${params.join('&')}`);
};

const fetch_filtros = ({ codcategoria }: { codcategoria: number }) => {
  return api.get(`filtros?codcategoria=${codcategoria}`);
};

export default {
  fetch_filtro_variacoes,
  fetch_filtros
};
