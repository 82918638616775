import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import VendedoraDevolucaoService, { ListParams } from 'services/devolucao/VendedoraDevolucaoService';
import ItensEmDevolucaoService from 'services/devolucao/ItensEmDevolucaoService';
import { Produto, QueroVenderItemVendedora, ApiListMeta } from 'interfaces';
import { EmptyResults } from 'components/empty-results';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';

export type ProdutoSelected = Pick<
  Produto,
  'custo_devolucao' | 'nome' | 'codigo' | 'codproduto' | 'imagens' | 'vendedora' | 'repasse'
>;

type FetchDevolucoesData = {
  fetchProdutosDevolucaoManual: ({
    codloja,
    query,
    page
  }: {
    codloja?: string;
    query?: string;
    page?: number;
  }) => Promise<any>; //eslint-disable-line
  vendedora: QueroVenderItemVendedora;
  produtos: ProdutoSelected[] | [];
  showEmptyResults: boolean;
  meta: ApiListMeta | null;
  loading: boolean;
  querySearch: string;
};

type FetchDevolucoes = {
  codigo: string;
};

const FetchDevolucoesContext = createContext<FetchDevolucoesData>({} as FetchDevolucoesData);

export function FetchDevolucoesProvider({ children, ...props }: { children: React.ReactNode } & FetchDevolucoes) {
  const [produtos, setProdutos] = useState<ProdutoSelected[] | []>([]);
  const [vendedora, setVendedora] = useState<QueroVenderItemVendedora>({} as QueroVenderItemVendedora);
  const { loading, setLoading } = useLoading();
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [querySearch, setQuerySearch] = useState<string>('');
  const [showEmptyResults, setShowEmptyResults] = useState<boolean>(false);

  const { codigo } = props;

  /**
   * fetchDevolucoes
   * @description request de devoluções de acordo com o código alpha numérico da loja
   */

  const fetchDevolucoes = () => {
    setLoading(true);
    ItensEmDevolucaoService.list(`codloja=${codigo}`)
      .then(async response => {
        setVendedora(response.data.data.vendedora);

        fetchProdutosDevolucaoManual({ codloja: response.data.data.vendedora.codloja })
          .then(result => {
            if (result.data.data.length === 0) {
              setShowEmptyResults(true);
            } else {
              setProdutos(result.data.data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(e => {
        if (e.response.status === 500) {
          Swal.fire('Oops', e.response.data.message, 'error');
        }
      });
  };

  /**
   * fetchProdutosDevolucaoManual
   * @description Busca produtos para iniciar devolução
   */

  const fetchProdutosDevolucaoManual = useCallback(
    async ({ codloja, query, page = 1 }: { codloja?: string; query?: string; page?: number }) => {
      const data: ListParams = {
        codloja: codloja || vendedora.codloja,
        params: {
          q: typeof query === 'string' ? query : querySearch,
          perpage: 6,
          page
        }
      };

      return VendedoraDevolucaoService.list(data)
        .then(response => {
          if (response.data.data.length > 0) {
            setMeta(response.data.meta);
            setProdutos(response.data.data);
            setQuerySearch(typeof query === 'string' ? query : querySearch);
          }

          return response;
        })
        .catch(e => e);
    },
    [querySearch, vendedora]
  );

  useEffect(() => {
    fetchDevolucoes();
  }, []); // eslint-disable-line

  const values = useMemo(
    () => ({ fetchProdutosDevolucaoManual, vendedora, produtos, showEmptyResults, meta, loading, querySearch }),
    [fetchProdutosDevolucaoManual, vendedora, produtos, showEmptyResults, meta, loading, querySearch]
  );

  return (
    <FetchDevolucoesContext.Provider value={values}>
      {!loading && produtos.length > 0 && children}

      {showEmptyResults && <EmptyResults text="Nenhum produto foi encontrado" />}
    </FetchDevolucoesContext.Provider>
  );
}

export function useFetchDevolucoes() {
  const context = React.useContext(FetchDevolucoesContext);

  if (!context) {
    throw new Error('useFetchDevolucoes deve usado dentro de um FetchDevolucoesProvider');
  }

  return context;
}
