import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();
const ModeracaoProdutoStyles = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  root: {
    marginBottom: '20px',
    marginTop: '20px'
  },
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginTop: 23
    },
    '& .MuiAvatar-root': {
      width: 90,
      height: 90
    }
  },
  actions: {
    textAlign: 'right',
    backgroundColor: '#eee',
    justifyContent: 'flex-end'
  },
  btnsLeft: {
    textAlign: 'left'
  },
  checkBox: {
    marginLeft: 0,
    '& .MuiFormControlLabel-root': {
      marginLeft: 0
    },
    '& .MuiCheckbox-root, & .MuiRadio-root': {
      padding: 2
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12
    }
  },
  etapasTitle: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
      '&::placeholder': {
        fontSize: 13
      }
    },

    '& .MuiInputLabel-root': {
      fontSize: 13
    }
  }
}));

export default ModeracaoProdutoStyles;
