import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MidiasInternasService from 'services/midias-internas/MidiasInternasService';
import { ModeracaoEtapa, Produto, MidiasInternas } from 'interfaces';
import CircularProgress from '@mui/material/CircularProgress';
import { GaleriaMidiasInternas } from 'components/galeria-midias-internas';

type Props = {
  dados?: ModeracaoEtapa['dados'];
  produto: Produto | null;
};

export function EtapaFotografia(props: Props) {
  const { dados, produto } = props;
  const [midias_internas, setMidiasInternas] = useState<MidiasInternas[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const createEtapaId = (value: string) => `App\\Services\\Produto\\Etapas\\Models\\Dado\\${value}`;

  const fotos = dados?.filter(dado => dado.id === createEtapaId('Fotos'))[0];

  useEffect(() => {
    if (produto) {
      MidiasInternasService.list(`${produto?.codproduto}`, 'uso=FOTOGRAFIA_P2P')
        .then(response => {
          setMidiasInternas(response.data.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [produto]);

  return fotos?.exibir ? (
    <>
      {midias_internas.length > 0 && (
        <Box my={1} style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
          <GaleriaMidiasInternas data={midias_internas} />
        </Box>
      )}
      {!isLoading && midias_internas.length === 0 && <Box my={1}>-</Box>}
      {isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" p={4}>
          <CircularProgress />
        </Box>
      )}
    </>
  ) : null;
}
