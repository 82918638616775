import React from 'react';
import { useAuth } from 'context/auth';
import { RouteProps, Navigate } from 'react-router-dom';

export function withRequireAuth<T extends RouteProps>(
  BaseComponent: React.ComponentType<T>,
  { isPrivate = false } = {}
) {
  return function WithRequireAuth(props: T) {
    const { user } = useAuth();

    return isPrivate === !!user ? <BaseComponent {...props} /> : <Navigate to={isPrivate ? '/' : '/home'} />;
  };
}
