import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import TransferenciasServices from 'services/ferramentas/TransferenciasServices';

export function CancelarTransferencia({
  open = false,
  handleClose,
  codtransferencia,
  handleSuccess
}: {
  open: boolean;
  handleClose: () => void;
  codtransferencia: number;
  handleSuccess: () => void;
}) {
  const modalOpen = useMemo(() => {
    return open;
  }, [open]);

  const [motivo, setMotivo] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleMotivo = (event: ChangeEvent<HTMLInputElement>) => setMotivo(event.target.value);

  const handleEnviarMotivo = () => {
    Swal.fire({
      title: 'Tem certeza que deseja cancelar esta transferência?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      setDisabled(true);
      if (result.isConfirmed) {
        TransferenciasServices.cancelar(codtransferencia, motivo).then(() => {
          handleSuccess();
          setDisabled(false);
        });
      }
    });
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Cancelar transferência</DialogTitle>

      <DialogContent>
        <TextField
          multiline
          rows={4}
          fullWidth
          onChange={handleMotivo}
          variant="outlined"
          placeholder="Motivo do cancelamento"
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleEnviarMotivo} disabled={disabled}>
          Enviar
        </Button>
        <Button variant="contained" onClick={handleClose} disabled={disabled}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
