import React, { useEffect } from 'react';
import TemplateBase from 'components/template-base/template';
import { useForm } from 'react-hook-form';
import ElementosService from 'services/elementos/ElementosService';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';
import { useParams, useNavigate } from 'react-router-dom';
import { ElementoFaixa } from 'interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormFaixa } from './form-faixa';

const schema = Yup.object().shape({
  bgcolor: Yup.string().matches(
    /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    'Insira um valor valido para o campo Cor de Fundo'
  ),
  cor: Yup.string().matches(/^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Insira um valor valido para o campo Cor do Texto'),
  paginas: Yup.array().of(Yup.string()).min(1, 'Selecione ao menos 1 página'),
  label_faixa: Yup.string().required('Selecione um valor para Label Faixa'),
  texto: Yup.string().required('Selecione um valor para Texto'),
  link: Yup.string().matches(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    'Insira um valor valido para o campo Link'
  )
});

function CadastrarFaixa() {
  document.title = 'Painel Marketing | Cadastrar Faixa';

  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const defaultValues = {
    texto: '',
    status: 'ativo',
    cor: '',
    bgcolor: '',
    link: '',
    data_entrada: new Date(),
    data_saida: new Date(),
    paginas: [],
    contador: '',
    label_faixa: ''
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        html: Object.values(methods.formState.errors).reduce((acc, error) => `${acc}${error.message} <br />`, '')
      });
    }
  }, [methods.formState.errors]);

  /**
   * Recebe os dados do formulário
   */
  function sendFormData(payload: ElementoFaixa) {
    try {
      setLoading(true);
      ElementosService.criar(id, payload)
        .then(() => {
          Swal.fire('Faixa Aualizada com sucesso', '', 'success').then(() => {
            navigate('/painel-marketing/gerenciar-faixas');
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <TemplateBase>
      <h1>Cadastrar Faixa</h1>
      <FormFaixa sendFormData={(data: ElementoFaixa) => sendFormData(data)} methods={methods} />
    </TemplateBase>
  );
}

export default CadastrarFaixa;
