import React, { useRef, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Pluralize from 'components/utils/pluralize';
import { Barcode } from 'components/icons';
import { indigo, grey } from '@mui/material/colors';
import Loading from 'components/loading';
import PostadorStyle from './style';
import Pedido from './pedido';

function ListaPortador() {
  document.title = 'Expedição - Lista Portador';

  const classes = PostadorStyle();

  const codBarras = useRef<HTMLImageElement>(null);

  const [loadingFull] = useState(false);

  // eslint-disable-next-line
  const handleClickAway = (e: any) => {
    if (!e.target?.dataset?.clickaway) {
      codBarras?.current?.focus();
    }
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      //
    }
  };

  const handleOnExcluirSuccess = () => {
    // console.log('fetch screen');
  };

  return (
    <TemplateBase>
      {/* <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <h3>Lista portador</h3>

              <TextField
                name="nomeremessa"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Barcode />
                    </InputAdornment>
                  )
                }}
                // onChange={handleBuscaNome}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      <Box pb={5}>
        <Box display="inline-flex" justifyContent="space-between" width="100%" alignItems="center">
          <Box fontSize={24} fontWeight={700}>
            Maurício - 3232211
          </Box>
          <Box fontSize={20} fontWeight={400} color={grey[500]}>
            2 &nbsp;
            <Pluralize quantidade={2} singular="item" plural="itens" />
          </Box>
        </Box>

        <div>
          <Box fontSize={14} fontWeight={700} color={indigo[500]} my={3}>
            07 de julho 2022
          </Box>

          <Pedido onExcluirSuccess={handleOnExcluirSuccess} />
        </div>
        <div>
          <Box fontSize={14} fontWeight={700} color={indigo[500]} my={3}>
            07 de julho 2022
          </Box>

          <Pedido onExcluirSuccess={handleOnExcluirSuccess} />
          <Pedido onExcluirSuccess={handleOnExcluirSuccess} />
          <Pedido onExcluirSuccess={handleOnExcluirSuccess} />
        </div>
      </Box>
      <div className={classes.campoCodBarras}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            name="codbarra"
            size="small"
            id="field-cod-barras"
            variant="outlined"
            autoFocus
            onKeyPress={handleInputKeyPress}
            inputRef={codBarras}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Barcode />
                </InputAdornment>
              )
            }}
          />
        </ClickAwayListener>
      </div>
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default ListaPortador;
