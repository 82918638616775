import { Box, IconButton, Paper } from '@mui/material';
import { styles } from './styles';

type Props = { url: string };

export function Image({ url }: Props) {
  const classes = styles();

  return (
    <Box>
      <Paper key={url} className={classes.paper} elevation={1}>
        <IconButton type="button" onClick={() => window.open(url)} className={classes.buttonImage}>
          <div
            style={{
              width: 120,
              height: 120,
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
              backgroundRepeat: 'no-repeat'
            }}
          />
        </IconButton>
      </Paper>
    </Box>
  );
}
