export const templateHeaderOptions = [
  {
    label: 'Imagem e Texto',
    value: 'box-imagem-texto'
  },
  {
    label: 'Imagem',
    value: 'so-imagem'
  }
];

export const orderDefault = [
  {
    label: 'Ordenação padrão',
    value: 'default'
  },
  {
    label: 'Novidades',
    value: 'novidades'
  },
  {
    label: 'Preço Crescente',
    value: 'preco'
  },
  {
    label: 'Preço Decrescente',
    value: 'preco_desc'
  },
  {
    label: 'Descontos Recentes',
    value: 'descontos_recentes'
  },
  {
    label: 'Favoritos',
    value: 'favoritos'
  },
  {
    label: 'Relevância',
    value: 'relevancia'
  }
];
