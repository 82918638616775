import React, { useCallback, useRef, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel, RadioGroup } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { ApiListMeta } from 'interfaces';
import PagamentosVendedorasService from 'services/ferramentas/PagamentosVendedoras';
import PagamentoItem from './pagamento-item';
import ModalPago from './modal-pago';
import ModalTentarNovamente from './modal-tentar-novamente';

import PagamentosVendedorasStyle from './style';

export type PagamentosVendedorasTypes = {
  codpedidoproduto: number;
  codpedido: number;
  nome: string;
  pago: string;
  pago_captadora: string;
  datapagamento: Date | string;
  datapagamento_captadora: Date | string;
  valorrepasse: number;
  valorcomissionada: number;
  saque_erro_ultimo_vendedora: string | null;
  saque_erro_ultimo_captadora: string | null;
};

export type StatusStringMap = {
  [key: string]: string;
};

function PagamentosVendedoras() {
  document.title = 'Ferramentas | Pagamentos Vendedoras';

  const classes = PagamentosVendedorasStyle();

  const [loading, setLoading] = useState(false);
  const [pagamentos, setPagamentos] = useState<PagamentosVendedorasTypes[]>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [queryFilter, setQueryFilter] = useState('codpedido');
  const [filterInput, setFilterInput] = useState('');
  const [modalPago, setModalPago] = useState(false);
  const [codPedidoProduto, setCodPedidoProduto] = useState(0);
  const [modalTentar, setModalTentar] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const listaDeStatus = useRef<StatusStringMap>({});
  const inputFilter = useRef<HTMLInputElement>(null);

  const fetchPagamentos = useCallback(
    (input = '', page = 1) => {
      setLoading(true);

      PagamentosVendedorasService.listar(page, queryFilter, input)
        .then(response => {
          setPagamentos(response?.data.data);
          setMeta(response?.data.meta);
          setShowPagination(response?.data.meta.last_page > 1);
          listaDeStatus.current = response?.data.meta.labels.pago;
        })
        .catch(() => {
        inputFilter.current!.value = ''; //eslint-disable-line
          setFilterInput('');
        })
        .finally(() => {
          setLoading(false);
        });
  }, [queryFilter]); //eslint-disable-line


  // useEffect(() => {
  //   fetchPagamentos();
  // }, []);//eslint-disable-line

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    fetchPagamentos(filterInput, value);
    setCurrentPage(value);
  };

  const handleRadioButtonFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryFilter(event.target.value);
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(event.target.value);
  };

  const handleSearchPagamentos = () => {
    fetchPagamentos(filterInput, 1);
  };

  const handleLimparBusca = () => {
    setFilterInput('');
    setPagamentos([]);
  };

  return (
    <TemplateBase>
      <Paper elevation={1} className={classes.paper}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item>
                <h3>Filtrar por:</h3>
                <FormControl variant="standard">
                  <RadioGroup row defaultValue="codpedido" onChange={handleRadioButtonFilter}>
                    <FormControlLabel value="codpedido" control={<Radio color="primary" />} label="Código do pedido" />
                    <FormControlLabel
                      value="codproduto"
                      control={<Radio color="primary" />}
                      label="Código do produto"
                    />
                    <FormControlLabel
                      value="produto_codigo"
                      control={<Radio color="primary" />}
                      label="Código alfanumerico do produto"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={classes.wrapInputBusca} spacing={2}>
              <Grid item>
                <TextField
                  inputRef={inputFilter}
                  name="filtro"
                  label="Código"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={filterInput}
                  onChange={handleFilter}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={filterInput.length < 1}
                  onClick={handleSearchPagamentos}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleLimparBusca}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      {loading && <Box>Carregando...</Box>}

      {!loading && pagamentos.length > 0 && (
        <>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Pedido</TableCell>
                <TableCell>Informações</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pagamentos.map(item => (
                <PagamentoItem
                  key={item.codpedidoproduto}
                  pagamento={item}
                  listaStatus={listaDeStatus.current}
                  onOpenModalPago={setModalPago}
                  onOpenModalTentarNovamente={setModalTentar}
                  setCodPedidoProduto={setCodPedidoProduto}
                />
              ))}
            </TableBody>
          </Table>

          {showPagination && (
            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination color="primary" count={meta?.last_page} page={currentPage} onChange={handlePage} />
            </Box>
          )}
        </>
      )}

      {!loading && !pagamentos && (
        <Box display="flex" justifyContent="center" p={10}>
          Não há pagamentos localizados
        </Box>
      )}

      <ModalPago
        openModal={modalPago}
        setModalPago={setModalPago}
        fetchPagamentos={fetchPagamentos}
        codPedidoProduto={codPedidoProduto}
      />
      <ModalTentarNovamente
        openModal={modalTentar}
        setModalTentar={setModalTentar}
        fetchPagamentos={fetchPagamentos}
        codPedidoProduto={codPedidoProduto}
      />
    </TemplateBase>
  );
}

export default PagamentosVendedoras;
