import React from 'react';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { CheckingProvider } from '../components/checking/CheckingProvider';

function Checking() {
  const filterPedidos = new Criteria();
  const filterLocalizarBip = new Criteria();

  filterPedidos.add('codstatuspedido', '16');
  filterPedidos.add('perpage', '20');
  filterPedidos.add('page', '1');

  filterLocalizarBip.add('codstatuspedido[]', '16');

  return (
    <CheckingProvider
      title="Expedição | Checking"
      path="checking"
      subtitle="Checking"
      filterLocalizarBip={filterLocalizarBip}
      filterPedidos={filterPedidos}
      detailUrl="/expedicao/"
    >
      <CheckingProvider.CheckingLayout />
    </CheckingProvider>
  );
}

export default Checking;
