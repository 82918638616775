import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import TemplateBase from 'components/template-base/template';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TransferenciasServices from 'services/ferramentas/TransferenciasServices';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import formatPrice from 'helpers/format-price';
import IconButton from '@mui/material/IconButton';
import Loading from 'components/loading';
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ApiListMeta, Usuario } from 'interfaces';
import Tooltip from '@mui/material/Tooltip';
import { Cancel } from '@mui/icons-material';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { RemoverTransferencia } from '../remover';
import { CancelarTransferencia } from '../cancelar';

type Filtros = {
  codpedido: string;
  codtransferencia: string;
  contacredito: string;
  contadebito: string;
  moiptoken: string;
};

type Transferencias = {
  acao: string;
  codtransferencia: number;
  datadebito: string;
  status: string;
  valor: number;
  mensagem: string;
  motivo?: string;
  usuario?: Usuario;
  contadebito: string;
  contacredito: string;
};

function Listar() {
  document.title = 'Ferramentas | Listar Transferências';
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [transferencias, setTransferencias] = useState<Transferencias[]>([]);
  const [storedQuery, setStoredQuery] = useState<string>('');
  const [modalRemoverTransferencia, setModalRemoverTransferencia] = useState({
    open: false,
    codtransferencia: 0
  });

  const [modalCancelarTransferencia, setModalCancelarTransferencia] = useState({
    open: false,
    codtransferencia: 0
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      codpedido: '',
      codtransferencia: '',
      contacredito: '',
      contadebito: '',
      moiptoken: ''
    }
  });

  const onSubmit = (data: Filtros) => {
    const query = Object.entries(data).reduce((prev, [key, value], index) => {
      let search = prev;

      if (value || value.length > 0) {
        search += `${index > 0 ? '&' : ''}${key}=${value}`;
      }

      return search;
    }, '');

    fetchTransferencias(query, page);
  };

  const fetchTransferencias = (query: string, currentpage: number) => {
    setLoading(true);
    TransferenciasServices.listar({
      query,
      page: currentpage
    })
      .then(response => {
        setTransferencias(response.data.data);
        setStoredQuery(query);
        setMeta(response?.data.meta);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModalRemoverTransferencia = (codtransferencia: number) => {
    setModalRemoverTransferencia({
      open: true,
      codtransferencia
    });
  };

  const openModalCancelarTransferencia = (codtransferencia: number) => {
    setModalCancelarTransferencia({
      open: true,
      codtransferencia
    });
  };

  const handleClearAttempts = (codtransferencia: number) => {
    // eslint-disable-next-line
    if (confirm("Tem certeza que deseja limpar as tentativas desta transferência?")) {
      setLoading(true);
      TransferenciasServices.limparTentativas(codtransferencia)
        .then(() => {
          fetchTransferencias(storedQuery, page);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const closeModalCancelarTransferencia = () =>
    setModalCancelarTransferencia({
      open: false,
      codtransferencia: 0
    });

  const closeModalRemoverTransferencia = () =>
    setModalRemoverTransferencia({
      open: false,
      codtransferencia: 0
    });

  const handleSuccess = () => {
    fetchTransferencias(storedQuery, page);

    setModalRemoverTransferencia({
      open: false,
      codtransferencia: 0
    });
  };

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    fetchTransferencias(storedQuery, value);
    setPage(value);
  };

  return (
    <TemplateBase>
      <RemoverTransferencia
        codtransferencia={modalRemoverTransferencia.codtransferencia}
        open={modalRemoverTransferencia.open}
        handleClose={closeModalRemoverTransferencia}
        handleSuccess={handleSuccess}
      />
      <CancelarTransferencia
        open={modalCancelarTransferencia.open}
        handleClose={closeModalCancelarTransferencia}
        codtransferencia={modalCancelarTransferencia.codtransferencia}
        handleSuccess={handleSuccess}
      />
      <Paper elevation={1}>
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Filtros</h2>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <TextFieldHookForms
                  variant="outlined"
                  label="codtransferencia"
                  fullWidth
                  size="small"
                  {...register('codtransferencia')}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextFieldHookForms
                  variant="outlined"
                  label="Conta débito"
                  fullWidth
                  size="small"
                  {...register('contadebito')}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextFieldHookForms
                  variant="outlined"
                  label="Conta crédito"
                  fullWidth
                  size="small"
                  {...register('contacredito')}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextFieldHookForms
                  variant="outlined"
                  label="codpedido"
                  fullWidth
                  size="small"
                  {...register('codpedido')}
                />
              </Grid>
              <Grid item xs={6} sm={4} />
            </Grid>

            <Box mt={1} display="flex-inline">
              <Box mr={1} component="span">
                <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
                  Buscar
                </Button>
              </Box>
              {/* <Box mr={1} component="span">
                <Button type="button" variant="contained" disabled={!isDirty} onClick={clearForm}>
                  Limpar
                </Button>
              </Box> */}
            </Box>
          </form>
        </Box>
      </Paper>
      {transferencias.length > 0 && (
        <>
          <Box mt={2}>
            <Paper square>
              <Box>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Ação</TableCell>
                      <TableCell>Contas</TableCell>
                      <TableCell>Data débito</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Mensagem</TableCell>
                      <TableCell>Motivo</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transferencias.map(item => {
                      return (
                        <TableRow>
                          <TableCell>{item.codtransferencia}</TableCell>
                          <TableCell>{item.acao}</TableCell>
                          <TableCell>
                            <Tooltip title={item.contadebito}>
                              <code>{`${item.contadebito.substring(0, 3)}…${item.contadebito.slice(-6)}`}</code>
                            </Tooltip>
                            <span> → </span>
                            <Tooltip title={item.contacredito}>
                              <code>{`${item.contacredito.substring(0, 3)}…${item.contacredito.slice(-6)}`}</code>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{format(new Date(item.datadebito), 'dd/MM/yyyy')}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{formatPrice(item.valor)}</TableCell>
                          <TableCell>{item.mensagem}</TableCell>
                          <TableCell>
                            {item.motivo ? item.motivo : ''}
                            <br />
                            {item.usuario ? `Criado por: ${item.usuario.nome}.` : ''}
                          </TableCell>

                          <TableCell>
                            <IconButton onClick={() => handleClearAttempts(item.codtransferencia)} size="large">
                              <RefreshIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => openModalRemoverTransferencia(item.codtransferencia)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => openModalCancelarTransferencia(item.codtransferencia)}
                              size="large"
                            >
                              <Cancel />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Box>
          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
      <Loading open={loading} />
    </TemplateBase>
  );
}

export default Listar;
