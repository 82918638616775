import React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import avatarProdutoStyles from './styles';

export type AvatarProdutoProps = {
  imagem: string | undefined;
  text: string;
  color: string;
  titulo?: string;
};

function AvatarProduto(props: AvatarProdutoProps) {
  const styles = avatarProdutoStyles();
  const { imagem, text, titulo = '', color } = props;

  return (
    <Box mb={1}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Avatar className={styles.avatar} style={{ borderColor: color }} src={imagem} />
        </Grid>
        <Grid item>
          {titulo && <div>{titulo}</div>}
          <div>{text}</div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AvatarProduto;
