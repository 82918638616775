import React, { useCallback, useEffect, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import WarningIcon from '@mui/icons-material/Warning';
import Pagination from '@mui/material/Pagination';
import PickingService from 'services/picking';
import { PedidoProduto, ApiListMeta } from 'interfaces';

import { DialogActions } from '@mui/material';
import ProdutosNaoEncontradosStyle from './styles';

function ProdutosNaoEncontrados() {
  document.title = 'Expedição | Produtos não encontrados';

  const [produtos, setProdutos] = useState<PedidoProduto[]>([]);
  const [isModalOpen, setIsModalOpens] = useState<boolean>(false);
  const [observacao, setObservacao] = useState<string>('');
  const [codPedidoProduto, setCodPedidoProduto] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = ProdutosNaoEncontradosStyle();
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);

  const handleMarcarResolvido = (codpedidoproduto: number) => {
    setIsModalOpens(true);
    setCodPedidoProduto(codpedidoproduto);
    setObservacao('');
  };

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const fechaModal = () => {
    setIsModalOpens(false);
    setCodPedidoProduto(null);
  };

  const handleObservacao = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObservacao(event.target.value);
  };

  const handleResolverProduto = () => {
    setLoading(true);

    PickingService.marcarResolvido(codPedidoProduto as number, {
      observacao
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Produto marcado como resolvido.'
        }).then(() => {
          fechaModal();
          fetchProdutosNaoLocalizados();
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProdutosNaoLocalizados = useCallback(() => {
    const perPage = 60;

    const queryMeta = `page=${page}&per_page=${perPage}`;

    setLoading(true);
    PickingService.produtosNaoLocalizados(queryMeta)
      .then(response => {
        setProdutos(response.data.data);
        setMeta(response.data.meta);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  useEffect(() => {
    fetchProdutosNaoLocalizados();
  }, [fetchProdutosNaoLocalizados]);

  return (
    <TemplateBase>
      <h1>Produtos não encontrados</h1>
      {produtos && produtos.length > 0 ? (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Produto</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Código do pedido</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {produtos.map(item => (
                  <TableRow key={item.codpedidoproduto}>
                    <TableCell>
                      <Box display="inline-flex" alignItems="center">
                        <Avatar src={item?.imagens?.[0]?.thumbnail} className={classes.avatar} />
                        <Box ml={2}>{item?.produto}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>{item?.codpedidoproduto}</TableCell>
                    <TableCell>{item?.pedido_codigo}</TableCell>

                    <TableCell>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => handleMarcarResolvido(item?.codpedidoproduto)}
                      >
                        Marcar como Resolvido
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <Box display="flex" justifyContent="center" mt={3}>
            {meta?.last_page && meta?.last_page > 1 && (
              <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhum resultado encontrado
          </Box>
        </Box>
      )}

      <Dialog open={isModalOpen} fullWidth maxWidth="sm">
        <DialogTitle>Observações</DialogTitle>
        <DialogContent>
          <TextField multiline rows={3} variant="outlined" fullWidth onChange={handleObservacao} value={observacao} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={fechaModal}>
            Cancelar
          </Button>
          <Button color="primary" variant="contained" onClick={handleResolverProduto}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Loading open={loading} />
    </TemplateBase>
  );
}

export default ProdutosNaoEncontrados;
