import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const ProdutosNaoEncontradosStyle = makeStyles(() => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}));

export default ProdutosNaoEncontradosStyle;
