import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GreenSwitch } from 'components/custom-switch';
import { useFormContext } from 'react-hook-form';

export function ValorPreAvaliacao() {
  const { register } = useFormContext();

  const [valorPreAvaliacao, setValorPreAvaliacao] = useState<boolean>(false);

  return (
    <Box my={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <GreenSwitch
              {...register('sugestao_valor_aceita')}
              checked={valorPreAvaliacao}
              onChange={() => setValorPreAvaliacao(!valorPreAvaliacao)}
              color="secondary"
            />
          }
          label="Valor igual ou superior ao aprovado na pré-avaliação"
        />
      </Grid>
    </Box>
  );
}
