import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AssociarQuiosqueService from 'services/associar-quiosque/AssociarQuiosqueService';
import Swal from 'sweetalert2';

function AssociarQuiosque() {
  document.title = 'Ferramentas';

  const [codcliente, setCodCliente] = useState<string>('');

  const handleCodClienteInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodCliente(event.target.value);
  };

  const handleAssociarCodCliente = () => {
    AssociarQuiosqueService.associar(codcliente).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Cliente associado com sucesso!',
        confirmButtonText: `Ok`
      }).then(() => {
        setCodCliente('');
      });
    });
  };

  return (
    <TemplateBase>
      <h1>Associar cliente ao quiosque</h1>

      <Paper elevation={1} style={{ padding: 15 }}>
        <Box py={2}>
          <TextField
            label="Codigo do cliente (ex: 100040)"
            autoFocus
            variant="outlined"
            fullWidth
            value={codcliente}
            onChange={handleCodClienteInput}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handleAssociarCodCliente}>
          Associar
        </Button>
      </Paper>
    </TemplateBase>
  );
}

export default AssociarQuiosque;
