import React, { ReactNode, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface PropsItem {
  name: string;
  link?: string;
  icon?: JSX.Element;
  submenu?: boolean;
  activePage: string;
  pathBase?: string;
  children?: ReactNode;
}

function MenuItem(props: PropsItem) {
  const { name, link = '', icon = '', submenu = false, activePage, pathBase = '', children = '' } = props;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (pathBase) {
      const reg = new RegExp(`/${pathBase}`, 'g');

      if (activePage.match(reg)) {
        toggleSubMenu();
      }
    }
  }, [activePage, pathBase]);

  const toggleSubMenu = () => {
    setOpenSubMenu(prev => !prev);
  };

  return (
    <>
      {link ? (
        <ListItemButton component={Link} to={link} selected={activePage === link}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={name} />
        </ListItemButton>
      ) : (
        <ListItemButton onClick={toggleSubMenu}>
          <ListItemIcon>{icon && <ListItemIcon>{icon}</ListItemIcon>}</ListItemIcon>
          <ListItemText primary={name} />
          {openSubMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      {submenu && (
        <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default MenuItem;
