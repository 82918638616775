import React, { useMemo } from 'react';
import VipIcon from 'components/vip-icon';
import QuiosqueIcon from 'components/quiosque-icon';
import { QueroVenderBatch } from 'interfaces';
import Box from '@mui/material/Box';

type Props = {
  cliente: QueroVenderBatch['cliente'] | undefined;
};

export function ClientClusters(props: Props) {
  const { cliente } = props;

  /* checa se cliente pertence ao cluster vip */

  const isVip = useMemo(() => {
    return !!cliente?.prioridade;
  }, [cliente]);

  /* checa se cliente pertence ao cluster de quiosque */
  const isQuiosque = useMemo(() => {
    return !!cliente?.clusters.some(item => item.identificador === 'quiosque');
  }, [cliente]);

  return cliente ? (
    <Box display="inline-flex" alignItems="center">
      <VipIcon isVip={isVip} />
      <QuiosqueIcon isQuiosque={isQuiosque} />
    </Box>
  ) : null;
}
