import React, { useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CalculadoraValores from 'components/calculadora-de-valores';
import { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import { QueroVenderItem } from 'interfaces';
import withStyles from '@mui/styles/withStyles';
import { blue } from '@mui/material/colors';
import { BotaoEnviarDevolucao } from './botao-enviar-devolucao';
import { BotaoValorAceito } from './botao-valor-aceito';
import { BotaoAlterareAceitar } from './botao-alterar-e-aceitar';
import { BotaoHistoricoValor } from './botao-historico-valor';
import { BotaoEditarPropostaInicial } from './botao-editar-proposta-inicial';
import { FormCalculosMotivos } from './form-calculos-motivos';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  item: QueroVenderItem | undefined;
  codloja: number | undefined;
  doRefresh: () => void;
};

const CustomButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[500]
    }
  }
}))(Button);

export function PropostaEnviada(props: Props) {
  const { item, codloja, doRefresh } = props;

  const styles = avaliacaoFisicaNegociacaoStyle();

  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const defaultValuesNegociacao = useMemo(
    () => ({
      preco: 0,
      precoaprazo: 0,
      precoavista: 0,
      precocomissao: 0,
      precocomissionada: 0,
      status: 0,
      observacoes: '',
      revisado: false
    }),
    []
  );

  const methods = useForm<AprovacaoFisica>({ defaultValues: defaultValuesNegociacao });
  const methods2 = useForm<AprovacaoFisica>({ defaultValues: defaultValuesNegociacao });

  const handleIsCalculating = (value: boolean) => {
    setIsCalculating(value);
  };

  const [negociacao, setNegociacao] = useState(item?.negociacao_atual);
  const [editValoresNegociacao, setEditValoresNegociacao] = useState<boolean>(false);
  const [isEditingNegociacao, setIsEditingNegociacao] = useState<boolean>(false);

  const editNegociacao = useMemo(() => item?.sugestoes.length === 1, [item]);

  const handleEditcaoValor = () => {
    setNegociacao(undefined);
    setEditValoresNegociacao(true);
    setIsEditingNegociacao(true);
  };

  const handleCancelarEdicaoValor = () => {
    setNegociacao(item?.negociacao_atual);
    setEditValoresNegociacao(false);
    setIsEditingNegociacao(false);
  };

  return (
    <Box p={2}>
      <Paper elevation={2}>
        <Box p={2}>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Typography variant="overline" color="textSecondary">
              Negociação:
            </Typography>
            <BotaoHistoricoValor item={item} />
          </Box>

          <FormProvider {...methods}>
            {/* {TODO: register should not be required} */}
            <CalculadoraValores
              negociacaoAtual={negociacao}
              categoria={item?.atributos.categoria}
              captadora
              disabled={!editValoresNegociacao}
              codloja={codloja}
              comissionamentoDiferenciado={false}
              register={methods.register}
              codquerovenderitem={item?.codquerovenderitem}
              showPrecoLoja
              itemValorLoja={item?.produto?.precificacao?.loja.toString()}
              xs={2}
              isCalculating={handleIsCalculating}
            />
            <TextField
              multiline
              rows={3}
              label="Observações na primeira avaliação"
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              className={styles.textInput}
              defaultValue={item?.negociacao_atual?.observacoes}
            />
            <Box display="inline-flex" justifyContent="space-between" width="100%">
              <div>
                {editNegociacao && (
                  <>
                    {!isEditingNegociacao && (
                      <CustomButton variant="contained" color="primary" onClick={handleEditcaoValor}>
                        Editar Proposta
                      </CustomButton>
                    )}
                    {isEditingNegociacao && (
                      <Box display="inline-flex" justifyContent="flex-start">
                        <BotaoEditarPropostaInicial
                          codquerovenderitem={item?.codquerovenderitem}
                          doRefresh={doRefresh}
                          disabled={isCalculating}
                        />

                        <Button variant="contained" disabled={isCalculating} onClick={handleCancelarEdicaoValor}>
                          Cancelar
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </div>
              <div>
                <BotaoEnviarDevolucao
                  observacoes={item?.negociacao_atual?.observacoes}
                  codquerovenderitem={item?.codquerovenderitem}
                  doRefresh={doRefresh}
                  disabled={isCalculating}
                />
                <BotaoValorAceito
                  disabled={isCalculating}
                  codquerovenderitem={item?.codquerovenderitem}
                  doRefresh={doRefresh}
                />
              </div>
            </Box>
          </FormProvider>
        </Box>

        <Box p={2}>
          <Box mb={3}>
            <Typography variant="overline" color="textSecondary" className={styles.titleStrong}>
              Sugerir novo valor:
            </Typography>
          </Box>
          <FormProvider {...methods2}>
            <FormCalculosMotivos
              defaultValuesNegociacao={defaultValuesNegociacao}
              codloja={codloja}
              handleIsCalculating={handleIsCalculating}
              codquerovenderitem={item?.codquerovenderitem}
            />

            <BotaoAlterareAceitar
              disabled={isCalculating}
              codquerovenderitem={item?.codquerovenderitem}
              doRefresh={doRefresh}
            />
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
}
