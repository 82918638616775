import makeStyles from '@mui/styles/makeStyles';

const AutocompleteModelosStyle = makeStyles(() => ({
  textInput: {
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
      '&::placeholder': {
        fontSize: 13
      }
    },

    '& .MuiInputLabel-root': {
      fontSize: 13
    }
  }
}));

export default AutocompleteModelosStyle;
