import React from 'react';
import { useStatusInterface } from 'pages/avaliacao/hooks/status-interface';
import { QueroVenderItem } from 'interfaces';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { format } from 'date-fns';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

export function ItemRow(props: QueroVenderItem) {
  const { codquerovenderitem, produto, data_avaliacao, negociacao_atual } = props;

  const { Icon, statusText } = useStatusInterface(negociacao_atual?.status as number);

  return (
    <TableRow key={codquerovenderitem}>
      <TableCell width="45%">
        <List dense>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar src={produto?.imagens?.[0]?.thumbnail} alt="" />
            </ListItemAvatar>
            <ListItemText primary={produto?.nome} />
          </ListItem>
        </List>
      </TableCell>
      <TableCell>{data_avaliacao ? format(new Date(data_avaliacao as string), 'dd/MM/yyyy HH:mm:ss') : ''}</TableCell>

      <TableCell>
        <Box display="inline-flex" justifyContent="center">
          <Icon /> &nbsp; {statusText}
        </Box>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}
