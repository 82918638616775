import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import { PickingFiltro } from 'interfaces/picking';
import FilterSelectPickingStyle from './styles';

type Props = {
  onSelectChange: (value: string) => void;
  onSelectClose: () => void;
  getFiltrosHook: () => PickingFiltro[];
};

export function FilterSelectPicking(props: Props) {
  const { onSelectChange, onSelectClose, getFiltrosHook } = props;
  const styles = FilterSelectPickingStyle();
  const filtros = getFiltrosHook();
  const [selectValue, setSelectValue] = useState<number>(0);

  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    setSelectValue(e.target.value as number);
    onSelectChange(e.target.value as string);
    // barCodeFocus();
  };

  const handleSelectClose = () => {
    onSelectClose();
    // barCodeFocus();
  };

  return (
    <FormControl variant="standard" className={styles.filtroSelectForm}>
      <Select
        variant="standard"
        value={selectValue}
        id="select-filtros"
        onChange={handleSelectChange}
        onClose={handleSelectClose}
      >
        <MenuItem value="0" key={0}>
          Selecione um filtro
        </MenuItem>
        {filtros &&
          filtros.map((filtro: PickingFiltro) => (
            <MenuItem value={filtro.id} key={filtro.id}>
              {filtro.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
