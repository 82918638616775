import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PercentSheet(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.66699 11.1666C7.90033 11.1666 8.09755 11.0833 8.25866 10.9166C8.41977 10.75 8.50033 10.5555 8.50033 10.3333C8.50033 10.1 8.41977 9.90276 8.25866 9.74165C8.09755 9.58053 7.90033 9.49998 7.66699 9.49998C7.44477 9.49998 7.25033 9.58053 7.08366 9.74165C6.91699 9.90276 6.83366 10.1 6.83366 10.3333C6.83366 10.5555 6.91699 10.75 7.08366 10.9166C7.25033 11.0833 7.44477 11.1666 7.66699 11.1666ZM4.30033 11.0666L8.40033 6.96665L7.70033 6.26665L3.60033 10.3666L4.30033 11.0666ZM4.33366 7.83331C4.56699 7.83331 4.76421 7.74998 4.92533 7.58331C5.08644 7.41665 5.16699 7.2222 5.16699 6.99998C5.16699 6.76665 5.08644 6.56942 4.92533 6.40831C4.76421 6.2472 4.56699 6.16665 4.33366 6.16665C4.11144 6.16665 3.91699 6.2472 3.75033 6.40831C3.58366 6.56942 3.50033 6.76665 3.50033 6.99998C3.50033 7.2222 3.58366 7.41665 3.75033 7.58331C3.91699 7.74998 4.11144 7.83331 4.33366 7.83331ZM1.66699 13.6666C1.40033 13.6666 1.16699 13.5666 0.966992 13.3666C0.766992 13.1666 0.666992 12.9333 0.666992 12.6666V1.33331C0.666992 1.06665 0.766992 0.833313 0.966992 0.633313C1.16699 0.433313 1.40033 0.333313 1.66699 0.333313H7.68366L11.3337 3.98331V12.6666C11.3337 12.9333 11.2337 13.1666 11.0337 13.3666C10.8337 13.5666 10.6003 13.6666 10.3337 13.6666H1.66699ZM7.18366 4.43331H10.3337L7.18366 1.33331V4.43331Z" />
    </SvgIcon>
  );
}
