import { Sacola } from 'interfaces';
import api from '../api';

export interface SacolaSplit {
  sacola_tipo_id: number;
  extra: number;
  itens?: number[];
  descricao: string;
}

export interface SplitData {
  codquerovender?: string;
  vendedora_codigo?: string;
  captadora_codigo?: string;
  sacolas?: SacolaSplit[];
}

const get_info = (id: string) => {
  return api.get(`quero-vender/${id}/split`);
};

const get_tipos = () => {
  return api.get<Sacola[]>(`sacolas/tipos`);
};

const split = (data: SplitData) => {
  return api.post(`quero-vender/split`, data);
};

export default { get_info, get_tipos, split };
