import React, { useState, useMemo } from 'react';
import TemplateBase from 'components/template-base/template';
import { format } from 'date-fns';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { BadgeVendedorVip } from 'components/custom-badge/badge-vendedor-vip';
import { Barcode } from 'components/icons';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PackingService from 'services/packing';
import CheckingService from 'services/checking';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import { Pedido } from 'interfaces';
import { BadgePortadorRetirada } from 'components/custom-badge/badge-portador-retirada';
import { usePackingProvider } from '../PackingProvider';
import PackingStyle from './style';

export function PackingLayout() {
  const { filter, title } = usePackingProvider();

  document.title = title;

  const classes = PackingStyle();

  const [codigoPedidoRastreio, setCodigoPedidoRastreio] = useState<string>('');
  const [pedido, setPedido] = useState<Pedido | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [linkPrint, setLinkPrint] = useState<string>('');

  const produtosLocalizados = useMemo(() => {
    return pedido?.produtos.filter(produto => produto?.localizado === 'LOCALIZADO');
  }, [pedido]);

  // Controla a ação ENTER no campo Localizar pedido
  const handleLocalizarPedidoEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setLoading(true);
      filter.add('codigo', codigoPedidoRastreio);

      PackingService.buscar_bip(filter.getQuery())
        .then(response => {
          setPedido(response.data);
          CheckingService.imprimir_declaracao(response.data.codigo).then(({ data }) => {
            setLinkPrint(data.pdf);
          });
        })
        .finally(() => {
          setLoading(false);
          setCodigoPedidoRastreio('');
        });
    }
  };

  const handleLocalizarPedido = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodigoPedidoRastreio(e.target.value);
  };

  // Controla ação ENTER no campo Finalizar pedido
  const handleFinalizarPedido = () => {
    setLoading(true);
    Swal.fire({
      title: 'Tem certeza que deseja finalizar o pedido?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        PackingService.finalizar(pedido?.codigo as string)
          .then(() => {
            Swal.fire('Pedido embalado!', '', 'success');
          })
          .finally(() => {
            setLoading(false);
            setPedido(null);
            setCodigoPedidoRastreio('');
          });
      }
    });
  };

  const isVip = useMemo(() => {
    return pedido?.cliente?.prioridade === 1 && pedido?.devolucao;
  }, [pedido]);

  return (
    <TemplateBase>
      <h1>Packing</h1>
      <Paper elevation={1} className={classes.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <h3>Localizar pedido</h3>
                <TextField
                  variant="outlined"
                  onKeyPress={handleLocalizarPedidoEnter}
                  onChange={handleLocalizarPedido}
                  value={codigoPedidoRastreio}
                  size="small"
                  fullWidth
                  autoFocus
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Barcode />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {pedido && (
        <Card className={classes.productContainer}>
          <CardContent>
            <Box display="inline-flex" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="inline-flex" alignItems="center">
                <div className={classes.pedido}>
                  Código de rastreio #{pedido?.entrega?.informacoes?.rastreamento}&nbsp;
                </div>
                {isVip && <BadgeVendedorVip />}
                {pedido.data_agendamento_retirada && (
                  <BadgePortadorRetirada
                    nospace
                    date={format(new Date(pedido.data_agendamento_retirada), 'dd/MM/yyyy')}
                  />
                )}
              </Box>
              {linkPrint && (
                <div>
                  <Button
                    component="a"
                    href={linkPrint}
                    target="_blank"
                    endIcon={<PrintIcon />}
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    reemprimir declaração de conteúdo
                  </Button>
                </div>
              )}
            </Box>
            <Box mt={2} fontSize={16}>
              <strong>Sugestão de embalagem:</strong> Caixa
            </Box>

            {pedido.cliente.embalagem_observacoes && (
              <Box my={2}>
                <MuiAlert variant="filled" severity="warning">
                  <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                    {pedido.cliente.embalagem_observacoes}
                  </Typography>
                </MuiAlert>
              </Box>
            )}

            <Box my={4}>
              <strong>Itens do pedido</strong>
            </Box>

            <Grid container spacing={2}>
              {produtosLocalizados?.map(produto => {
                return (
                  <Grid item xs={6} key={produto?.codproduto}>
                    <Box display="inline-flex" alignItems="center">
                      <div>
                        <Avatar className={classes.productPhoto} src={produto?.imagens?.[0]?.medium} />
                      </div>
                      <Box ml={2}>
                        <span className={classes.titleProduct}>{produto?.codigo}</span> <br />
                        <span>{produto?.nome}</span> <br />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Box display="inline-flex" mt={5} alignItems="center">
              <Button color="secondary" variant="contained" onClick={handleFinalizarPedido}>
                Finalizar pedido
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
      <Loading open={loading} />
    </TemplateBase>
  );
}
