import React, { createContext, useCallback, useMemo, useState } from 'react';

import { QueroVenderBatch, QueroVenderRemessaData, QueroVenderEntradaProduto } from 'interfaces';
import QueroVenderRemessaService from 'services/quero-vender/QueroVenderRemessaService';
import useDebounce from 'hooks/debounce';
import { format } from 'date-fns';

interface EntradaProdutosContextData {
  openModalEntradaRemessa: boolean;
  setOpenModalEntradaRemessa: React.Dispatch<React.SetStateAction<boolean>>;
  dataRemessa: QueroVenderRemessaData;
  setdataRemessa: React.Dispatch<React.SetStateAction<QueroVenderRemessaData>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  batches: Array<QueroVenderBatch>;
  setBatch: React.Dispatch<React.SetStateAction<Array<QueroVenderBatch>>>;
  localizarRemessa(dataRemessaBatch: QueroVenderEntradaProduto): void;
  buscaNome: string;
  setBuscaNome: React.Dispatch<React.SetStateAction<string>>;
  nomeCliente: string;
  formaEntrada: string;
  setFormaEntrada: React.Dispatch<React.SetStateAction<string>>;
  dataAgendamento: Date | null;
  setDataAgendamento: React.Dispatch<React.SetStateAction<Date | null>>;
  dataAgentamentoFormatted: string;
  debouncedDataAgendamento: string;
  debouncedFormaEntrada: string;
}

const EntradaProdutosContext = createContext<EntradaProdutosContextData>({} as EntradaProdutosContextData);

const initialStateRemessa = {
  codquerovender: 0,
  count: {
    total: 0,
    aceitos: 0
  },
  cliente: {
    nome: ''
  },
  volume: {
    remessa_codigo: 0
  }
};

function EntradaProdutosProvider({ children }: { children: React.ReactElement }) {
  const [openModalEntradaRemessa, setOpenModalEntradaRemessa] = useState(false);
  const [dataRemessa, setdataRemessa] = useState<QueroVenderRemessaData>(initialStateRemessa);
  const [loading, setLoading] = useState(false);
  const [batches, setBatch] = useState<Array<QueroVenderBatch>>([]);
  const [buscaNome, setBuscaNome] = useState('');
  const [formaEntrada, setFormaEntrada] = useState('');
  const [dataAgendamento, setDataAgendamento] = useState<Date | null>(null);

  const nomeCliente = useDebounce(buscaNome, 500);
  let dataAgentamentoFormatted = '';

  if (dataAgendamento) {
    dataAgentamentoFormatted = format(dataAgendamento, 'yyyy-MM-dd');
  }

  const debouncedDataAgendamento = useDebounce(dataAgentamentoFormatted, 500);
  const debouncedFormaEntrada = useDebounce(formaEntrada, 500);

  const localizarRemessa = useCallback((dataRemessaBatch: QueroVenderEntradaProduto) => {
    setLoading(true);
    QueroVenderRemessaService.localizar(dataRemessaBatch.codremessa).then(response => {
      setLoading(false);

      if (response) {
        const { data } = response;

        setdataRemessa(data);
        setOpenModalEntradaRemessa(true);
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      openModalEntradaRemessa,
      setOpenModalEntradaRemessa,
      dataRemessa,
      setdataRemessa,
      loading,
      setLoading,
      batches,
      setBatch,
      localizarRemessa,
      buscaNome,
      setBuscaNome,
      nomeCliente,
      formaEntrada,
      setFormaEntrada,
      dataAgendamento,
      dataAgentamentoFormatted,
      setDataAgendamento,
      debouncedDataAgendamento,
      debouncedFormaEntrada
    }),
    [
      openModalEntradaRemessa,
      dataRemessa,
      loading,
      batches,
      localizarRemessa,
      buscaNome,
      nomeCliente,
      formaEntrada,
      dataAgendamento,
      dataAgentamentoFormatted,
      debouncedDataAgendamento,
      debouncedFormaEntrada
    ]
  );

  return <EntradaProdutosContext.Provider value={value}>{children}</EntradaProdutosContext.Provider>;
}

function useEntradaProdutos() {
  const context = React.useContext(EntradaProdutosContext);

  if (!context) {
    throw new Error('useEntradaProdutos deve usado dentro de um EntradaProdutosProvider');
  }

  return context;
}

export { EntradaProdutosProvider, useEntradaProdutos };
