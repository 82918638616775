import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import QueroVenderItemService, { ReceiveData } from 'services/quero-vender/QueroVenderItemService';
import QueroVenderConverterEmProdutoService from 'services/quero-vender/QueroVenderConverterEmProdutoService';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import { FormQueroConverterData } from '../functions';
import { useValidateSchema } from './useValidateSchema';
import { useMapperPayload } from './useMapperPayload';
import styles from '../styles';

type Props = {
  isExpanded?: boolean;
  doRefresh?(): void;
  handleCardExpand?(value: boolean): void;
  openModalRejeitarItem(): void;
};

export function RodapeProdutoBatch(props: Props) {
  const { isExpanded = false, doRefresh = null, handleCardExpand = null, openModalRejeitarItem } = props;
  const { item, codQueroVender, isRequesting } = useFormAvaliacao();
  const { handleSubmit } = useFormContext<FormQueroConverterData>();
  const { setLoading } = useLoading();
  const { validate } = useValidateSchema();
  const { mapper } = useMapperPayload();

  const classes = styles();

  const handleImprimirEtiqueta = (etiqueta_produto: string) => {
    window.open(etiqueta_produto);
  };

  const handleMarcarNaoRecebido = () => {
    const dataReceive: ReceiveData = { recebido: 0 };

    if (item) {
      QueroVenderItemService.receive(item?.codquerovenderitem, dataReceive).then(() => {
        doRefresh?.();
        handleCardExpand?.(false);
      });
    }
  };

  /**
   * Salvar Produto
   */

  const handleSalvarProduto = async (data: FormQueroConverterData) => {
    setLoading(true);

    try {
      await validate(data);
      const payload = mapper(data);

      if (item?.codquerovenderitem) {
        await QueroVenderConverterEmProdutoService.convert_produto_batch(item?.codquerovenderitem, payload).then(
          response => {
            Swal.fire('O produto foi criado', '', 'success').then(() => {
            handleImprimirEtiqueta(response?.data?.links?.etiqueta_produto); // eslint-disable-line
              setTimeout(() => {
                if (handleCardExpand) handleCardExpand(false);
              }, 600);
              if (doRefresh) doRefresh();
            });
          }
        );
      }
    } catch (error) {
      if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Adicionar produto
   */

  const handleAdicionarProduto = async (data: FormQueroConverterData) => {
    setLoading(true);

    try {
      await validate(data);
      const payload = mapper(data);

      if (codQueroVender) {
        payload.codquerovender = codQueroVender;
      }
      await QueroVenderConverterEmProdutoService.add(payload).then(response => {
        Swal.fire('O produto foi criado', '', 'success').then(() => {
          handleImprimirEtiqueta(response?.data?.links?.etiqueta_produto); // eslint-disable-line
          if (doRefresh) doRefresh();
          // setSubmittedData(true);
        });
      });
    } catch (error) {
      if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {item?.recebido || item?.recebido === null ? (
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.btnsLeft}>
            {item && (
              <span>
                {(item.recebido || item.recebido === null) && (
                  <>
                    <Button size="small" disabled={isRequesting} color="secondary" onClick={handleMarcarNaoRecebido}>
                      Não recebido
                    </Button>
                    <Button size="small" disabled={isRequesting} onClick={openModalRejeitarItem}>
                      Rejeitar
                    </Button>
                  </>
                )}
              </span>
            )}
          </Grid>
          <Grid item xs={6}>
            {!item && (
              <Button
                size="small"
                disabled={isRequesting}
                className={classes.btnSalvar}
                onClick={handleSubmit(handleAdicionarProduto)}
              >
                Salvar
              </Button>
            )}
            {item && (
              <Button
                size="small"
                disabled={isRequesting}
                className={classes.btnSalvar}
                onClick={handleSubmit(handleSalvarProduto)}
              >
                Salvar produto
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}

      {isExpanded && (
        <Button
          size="small"
          disabled={isRequesting}
          className={classes.btnSalvar}
          onClick={handleSubmit(handleAdicionarProduto)}
        >
          Salvar produto
        </Button>
      )}
    </>
  );
}
