import React, { createContext, useContext, Children, useState, useEffect, useMemo } from 'react';

type AccordionCollapseData = {
  activeCollapse: number | null;
  setActiveCollapse: React.Dispatch<React.SetStateAction<number | null>>;
  count: number;
};

const AccordionCollapseContext = createContext<AccordionCollapseData>({} as AccordionCollapseData);

type Props = {
  children: React.ReactNode;
  initialCollapse: number | null;
};

function AccordionCollapseProvider({ children, initialCollapse = null }: Props) {
  const count = Children.count(children);

  const [activeCollapse, setActiveCollapse] = useState<number | null>(initialCollapse);

  useEffect(() => {
    setActiveCollapse(initialCollapse);
  }, [initialCollapse]);

  const value = useMemo(
    () => ({ activeCollapse, setActiveCollapse, count }),
    [activeCollapse, setActiveCollapse, count]
  );

  return <AccordionCollapseContext.Provider value={value}>{children}</AccordionCollapseContext.Provider>;
}

function useAccordionCollapse() {
  const context = useContext(AccordionCollapseContext);

  if (!context) {
    throw new Error('useAccordionCollapse deve ser utilizado dentro de um AccordionCollapseProvider');
  }

  return context;
}

export { AccordionCollapseProvider, useAccordionCollapse };
