import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

const quisoqueIconStyles = makeStyles(() => ({
  isQuiosque: {
    color: '#B88058'
  },
  isNotQuiosque: {
    color: grey[500]
  }
}));

export default quisoqueIconStyles;
