import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

interface ComponentProps {
  imagem: string;
}

const theme = createTheme();

export default makeStyles(() =>
  createStyles({
    root: (props: ComponentProps) => ({
      margin: '0.5rem',

      '& img': {
        width: theme.spacing(16),
        height: theme.spacing(16),
        backgroundImage: `url(${props.imagem})`,
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        cursor: 'pointer'
      }
    }),
    button: {
      width: '100%',
      height: '100%',
      display: 'block',
      fontSize: 0,
      padding: 0,
      border: 0
    }
  })
);
