import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import { PickingLayoutContext } from '../picking-layout';

import TabPanelPickingStyles from './styles';

// referente as abas de fila de picking e produtos pulados
interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

type Props = {
  children: React.ReactElement;
  tabValue: number;
  loading: boolean;
};

export function TabPanelPicking(props: Props) {
  const { tab } = useContext(PickingLayoutContext);
  const { children, tabValue, loading } = props;

  const styles = TabPanelPickingStyles();

  return (
    <TabPanel value={tab} index={tabValue}>
      <div className={styles.wrapper}>
        {loading && <div>Carregando...</div>}
        {!loading && children.props.children && <div>{children}</div>}
        {!loading && !children.props.children && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
            <WarningIcon fontSize="large" color="disabled" />
            <Box component="span" ml={2}>
              Nenhum produto encontrado para picking.
            </Box>
          </Box>
        )}
      </div>
    </TabPanel>
  );
}
