import React, { useState } from 'react';
import { Paper, Card, CardContent, Grid, Button, Box, IconButton } from '@mui/material';
import TemplateBase from 'components/template-base/template';
import MidiasInternasService from 'services/midias-internas/MidiasInternasService';
import { MidiasInternas } from 'interfaces';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';

import UploadImagensStyle from './style';

function UploadImagens() {
  document.title = 'Fotografias';
  const style = UploadImagensStyle();
  const [loadingThumbs, setLoadingThumbs] = useState<{ id: string; error: boolean }[]>([]);
  const [imagesList, setImagesList] = useState<MidiasInternas[]>([]);
  const [storeCodeProduto, setCodProduto] = useState<string | null>(null);

  const { setLoading } = useLoading();
  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const { register, handleSubmit } = useForm<{ codproduto: string }>({});

  /**
   * Função asincrona para load de imagem
   *
   */

  function imageOnloadAsync(src: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = document.createElement('img');

      img.src = src;
      img.id = Date.now().toString();

      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });
  }

  async function makeUpload(image: HTMLImageElement) {
    const data = new FormData();
    const { id, src } = image;

    const dataURL = src;

    const blobBin = atob(dataURL.split(',')[1]);
    const array = [];

    for (let i = 0; i < blobBin.length; i += 1) {
      array.push(blobBin.charCodeAt(i));
    }

    const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

    if (storeCodeProduto?.match(/[a-zA-Z]/g) && storeCodeProduto.match(/[0-9]/g)) {
      data.append('codigo', storeCodeProduto as string | Blob);
    } else {
      data.append('codproduto', storeCodeProduto as string | Blob);
    }

    data.append('arquivo', file);

    setLoadingThumbs(oldItem => [...oldItem, { id, error: false }]);

    try {
      await MidiasInternasService.upload(storeCodeProduto as string, data).then(response => {
        setImagesList(oldItem => [...oldItem, { ...response.data }]);
      });

      setLoadingThumbs(oldItem => {
        const temp = oldItem.filter(item => item.id !== id);

        return [...temp];
      });
    } catch {
      setLoadingThumbs(oldItem => {
        const temp = oldItem;

        const index = temp.findIndex(item => item.id === id);
        temp[index].error = true;

        return [...temp];
      });
    }
  }

  const handleDeleteThumb = (id: string) => {
    setLoadingThumbs(oldItem => {
      let temp = oldItem;

      temp = temp.filter(item => item.id !== id);

      return [...temp];
    });
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => loopThroughFiles(e.target.files);

  const loopThroughFiles = (files: FileList | null) => {
    if (!files) return;

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      if (file.type.match('image')) {
        const picReaderImage = new FileReader();

        picReaderImage.addEventListener('load', event => {
          if (event?.target && typeof event?.target.result === 'string') {
            imageOnloadAsync(event?.target.result)
              .then((image: HTMLImageElement) => {
                makeUpload(image);
              })
              .catch(err => {
                const { path } = err;

                setLoadingThumbs(oldItem => [...oldItem, { id: path[0].id, error: true }]);
              });
          } else {
            /* eslint-disable-next-line */
            console.error('type of image data is not a string');
          }
        });

        picReaderImage.readAsDataURL(file);
      }
    }
  };

  const fetchImages = async (codproduto: string) => {
    setLoading(true);

    try {
      await MidiasInternasService.list(codproduto).then(response => {
        const { data } = response.data;
        setImagesList(data);
        setCodProduto(codproduto);
      });
    } catch {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Produto não encontrado'
      });

      setCodProduto(null);
      setImagesList([]);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = ({ codproduto }: { codproduto: string }) => fetchImages(codproduto);

  return (
    <TemplateBase>
      <h1>Upload de Fotos</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3>Localizar Produto</h3>
                </Grid>
                <Grid item xs={8} md={4}>
                  <TextFieldHookForms
                    label="Codigo do produto"
                    placeholder="Codigo do produto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...register('codproduto')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button type="submit" variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Paper>

      {storeCodeProduto && (
        <Box mt={2} mb={2}>
          Imagens de: <strong>{storeCodeProduto}</strong>
        </Box>
      )}

      <Grid container spacing={4} className={style.imagensGrid}>
        {storeCodeProduto && (
          <Grid item>
            <label htmlFor="file" className={style.fileLabel}>
              <span style={{ display: 'none' }}>upload Image</span>
              <input
                type="file"
                className={style.fileInput}
                id="file"
                onChange={handleFileInput}
                multiple
                name="fotos[]"
                accept="image/png, image/gif, image/jpeg, image/tiff, image/bmp"
              />
              <div className={clsx(style.paperImagem, style.uploadBox)}>
                <AddIcon className={style.iconAdd} />
              </div>
            </label>
          </Grid>
        )}

        {loadingThumbs.map(({ id, error }) => {
          return (
            <Grid key={`item-${id}`} item>
              <div className={clsx(style.paperImagem, style.uploadBox, error ? style.uploadBoxError : {})}>
                {error ? (
                  <IconButton type="button" onClick={() => handleDeleteThumb(id)} className={style.buttonDeleteThumb}>
                    <DeleteIcon className={style.iconTrash} />
                  </IconButton>
                ) : (
                  <CircularProgress size={20} />
                )}
              </div>
            </Grid>
          );
        })}
        {imagesList.length > 0 &&
          imagesList
            .map((item: MidiasInternas) => (
              <Grid item key={item.id}>
                <Paper className={style.paperImagem} elevation={2}>
                  <IconButton type="button" onClick={() => window.open(item.url)} className={style.buttonImage}>
                    <img src={item.url} alt="" width="100%" />
                  </IconButton>
                </Paper>
              </Grid>
            ))
            .reverse()}
      </Grid>
    </TemplateBase>
  );
}

export default UploadImagens;
