import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import TemplateBase from 'components/template-base/template';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useForm } from 'react-hook-form';
import { TransferenciasData } from 'interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInputCurrency from 'components/form/custom-input-currency';
import alertValidationMessage from 'helpers/alert-validation-message';
import TransferenciasServices from 'services/ferramentas/TransferenciasServices';
import Loading from 'components/loading';
import { TextFieldHookForms, SelectHookForms } from 'components/form/text-field-hook-forms';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import { format } from 'date-fns';

const validationSchema = Yup.object().shape({
  contadebito: Yup.string().required('O campo Conta Débito é obrigatório'),
  contacredito: Yup.string().required('O campo Conta Crédito é obrigatório'),
  valor: Yup.number().moreThan(0, 'O campo Valor deve ser maior que 0').required('O campo Valor é obrigatório'),
  datadebito: Yup.string().required('O campo Data Débito é obrigatório'),
  motivo: Yup.string().required('O campo Motivo é obrigatório'),
  moiptoken: Yup.string(),
  codpedido: Yup.string(),
  codoperadora: Yup.string()
});

function CriarAgendar() {
  document.title = 'Ferramentas | Criar/Agendar Transferências';

  const [valor, setValor] = useState('0');
  const [fullLoading, setLoadingFull] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  const onSubmit = async (data: any) => { // eslint-disable-line

    const { moiptoken, codpedido, ...rest } = data;
    const formattedData = { ...rest } as TransferenciasData;
    setLoadingFull(true);

    try {
      if (moiptoken && moiptoken !== '') {
        formattedData.moiptoken = moiptoken;
      }

      if (codpedido && codpedido !== '') {
        formattedData.codpedido = parseInt(codpedido.toString(), 10);
      }

      if (formattedData.codoperadora) {
        formattedData.codoperadora = parseInt(`${formattedData.codoperadora}`, 10);
      }

      formattedData.valor = parseFloat(formattedData.valor.toString());
      formattedData.datadebito = format(new Date(formattedData.datadebito), 'yyyy/MM/dd');

      await TransferenciasServices.criar(formattedData).then(() => {
        Swal.fire('Transferência feita com sucesso', '', 'success').then(() => {
          reset();
          setValor('0');
        });
      });
    } catch (error) {
      if (error?.response?.data?.error?.data?.permite_forcar) {
        Swal.fire({
          title: `${error?.response?.data?.error.message} Deseja forçar a transferência?`,
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Cancelar`
        }).then(async result => {
          if (result.isConfirmed) {
            await TransferenciasServices.criar({
              ...formattedData,
              forcar: true
            }).then(() => {
              Swal.fire('Transferência feita com sucesso', '', 'success').then(() => {
                reset();
                setValor('0');
              });
            });
          }
        });
      }
    } finally {
      setLoadingFull(false);
    }
  };

  const handleValor = (event: React.ChangeEvent<HTMLInputElement>) => setValor(event.target.value);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);
  return (
    <TemplateBase>
      <div>
        <h1>Criar/agendar transferência entre contas</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={1} minWidth="460px" width="55%">
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel>Operadora</InputLabel>
            <SelectHookForms
              native
              variant="outlined"
              label="Operadora"
              fullWidth
              margin="dense"
              {...register('codoperadora')}
            >
              <option value="21">Iugu</option>
              <option value="3">Moip</option>
            </SelectHookForms>
          </FormControl>
          <TextFieldHookForms
            label="Conta Débito"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            {...register('contadebito')}
          />
          <TextFieldHookForms
            label="Token Conta Débito"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            {...register('moiptoken')}
          />
          <TextFieldHookForms
            label="Conta Crédito"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            {...register('contacredito')}
          />
          <CustomInputCurrency
            name="valor"
            label="Valor"
            register={register}
            onChange={handleValor}
            fullWidth
            value={valor}
            variant="outlined"
            margin="dense"
            size="small"
          />
          <DatePickerHookForm name="datadebito" control={control} />

          <TextFieldHookForms
            label="ID do pedido"
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            {...register('codpedido')}
          />
          <TextFieldHookForms
            label="Motivo"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            margin="dense"
            size="small"
            {...register('motivo')}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" color="primary" variant="contained">
              Enviar
            </Button>
          </Box>
        </Box>
      </form>
      <Loading open={fullLoading} />
    </TemplateBase>
  );
}

export default CriarAgendar;
