import React, { createContext, useState, useMemo } from 'react';

type HandleStepsData = {
  step1: boolean;
  setStep1: React.Dispatch<React.SetStateAction<boolean>>;
  step2: boolean;
  setStep2: React.Dispatch<React.SetStateAction<boolean>>;
};

const HandleStepsContext = createContext<HandleStepsData>({} as HandleStepsData);

export function HandleStepsProvider({ children }: { children: React.ReactElement | React.ReactElement[] }) {
  const [step1, setStep1] = useState<boolean>(true);
  const [step2, setStep2] = useState<boolean>(false);

  const values = useMemo(() => ({ step1, setStep1, step2, setStep2 }), [step1, setStep1, step2, setStep2]);

  return <HandleStepsContext.Provider value={values}>{children}</HandleStepsContext.Provider>;
}

export function useHandleSteps() {
  const context = React.useContext(HandleStepsContext);

  if (!context) {
    throw new Error('useHandleSteps deve usado dentro de um HandleStepsProvider');
  }

  return context;
}
