import makeStyles from '@mui/styles/makeStyles';
import { orange, grey } from '@mui/material/colors';

const vipIconStyles = makeStyles(() => ({
  isVip: {
    color: orange[600]
  },
  isNotVip: {
    color: grey[500]
  }
}));

export default vipIconStyles;
