import React from 'react';

import TemplateBase from 'components/template-base/template';

function Home() {
  document.title = 'Dashboard';

  return (
    <TemplateBase>
      <h1>Dashboard</h1>
    </TemplateBase>
  );
}

export default Home;
