import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import styles from '../styles';

export const InformacoesExatas = memo(() => {
  const { register } = useFormContext();
  const classes = styles();

  return (
    <Box py={1}>
      <Typography variant="overline" color="textSecondary" className={classes.titles}>
        INFORMAÇÕES EXATAS SOBRE O PRODUTO:
      </Typography>
      <Grid container spacing={2} className={classes.contentWrap} alignItems="center">
        <Grid item xs={4}>
          <TextFieldHookForms
            variant="outlined"
            label="Modelo Exato"
            placeholder="Modelo Exato"
            fullWidth
            size="small"
            className={classes.textInput}
            {...register('modelo_exato')}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <TextField
            name="material"
            variant="outlined"
            label="Material"
            placeholder="Material"
            fullWidth
            size="small"
            className={classes.textInput}
            inputRef={register}

          />
        </Grid> */}
        {/* <Grid item xs={4}>
          <TextField
            name="tamanho"
            variant="outlined"
            label="Tamanho"
            placeholder="Tamanho"
            fullWidth
            size="small"
            className={classes.textInput}
            inputRef={register}

          />
        </Grid> */}
        {/* <Grid item xs={4}>
          <TextField
            name="cor"
            variant="outlined"
            label="Cor"
            placeholder="Cor"
            fullWidth
            size="small"
            className={classes.textInput}
            inputRef={register}

          />
        </Grid> */}
        <Grid item xs={4}>
          <TextFieldHookForms
            variant="outlined"
            label="Código"
            placeholder="Código"
            fullWidth
            size="small"
            className={classes.textInput}
            {...register('codigo')}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldHookForms
            variant="outlined"
            label="Ano"
            placeholder="Ano"
            fullWidth
            className={classes.textInput}
            inputProps={{ maxLength: 4 }}
            size="small"
            {...register('ano')}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
