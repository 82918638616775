import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const LoadingStyles = makeStyles(() => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff'
  }
}));

export default LoadingStyles;
