import { green, grey, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles(() => ({
  containerDropzone: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: grey[500],
    backgroundColor: grey[100],
    cursor: 'pointer',
    padding: '16px 0'
  },
  textDefault: {
    textAlign: 'center',
    fontWeight: 500,
    color: grey[500]
  },
  dragActive: {
    borderColor: green[500]
  },
  dragRejected: {
    borderColor: red[500]
  },
  textActive: {
    color: green[500]
  },
  textRejected: {
    color: red[500]
  },
  paper: {
    width: 120,
    height: 120,

    overflow: 'hidden'
  },
  buttonImage: {
    border: 0,
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
}));
