import { QueroVenderConverterProdutos, QueroVenderMergeBatchs, QueroVenderCaptadoraVendedora } from 'interfaces';
import api from '../api';

const list = (status: string, queryMeta?: string) => {
  const query = queryMeta !== undefined ? `&${queryMeta}` : '';

  return api.get(`quero-vender?status=${status}${query}`);
};

const get = (id: string) => {
  return api.get(`quero-vender/${id}`);
};

const send = (id: string) => {
  return api.post(`quero-vender/${id}/send`);
};

const sendPreNegociation = (id: string) => {
  return api.post(`quero-vender/${id}/send-pre-negociation`);
};

const discard = (id: string) => {
  return api.post(`quero-vender/${id}/discard`);
};

const lock = (id: string) => {
  return api.post(`quero-vender/${id}/lock`);
};

const unlock = (id: string) => {
  return api.delete(`quero-vender/${id}/lock`);
};

const convert = (id: string, data: QueroVenderConverterProdutos) => {
  return api.post(`quero-vender/${id}/convert`, data);
};

const merge = (id: number, data: QueroVenderMergeBatchs) => {
  return api.post(`quero-vender/${id}/merge`, data);
};

const logs = (id: number) => {
  return api.get(`quero-vender/${id}/logs`);
};

const match_seller = (id: number, data: QueroVenderCaptadoraVendedora) => {
  return api.patch(`quero-vender/${id}/seller`, data);
};

const finish = (id: string) => {
  return api.post(`quero-vender/${id}/finish`);
};

const moverAgendamento = (batch: number) => {
  return api.post(`quero-vender/${batch}/mover-agendamento-postagem`);
};

const moverAvaliacaoFisica = (batch: number) => {
  return api.post(`quero-vender/${batch}/mover-avaliacao-fisica`);
};

const bip = (codproduto: string, status?: number) => {
  const queryStatus = status ? `&status=${status}` : '';

  return api.get(`quero-vender/item/bip?codigo=${codproduto}${queryStatus}`);
};

const bulk = (batch: number) => {
  return api.post(`quero-vender/${batch}/bulk-aprovacao-fisica`);
};

export default {
  list,
  get,
  send,
  sendPreNegociation,
  discard,
  lock,
  unlock,
  convert,
  merge,
  logs,
  match_seller,
  finish,
  moverAgendamento,
  moverAvaliacaoFisica,
  bip,
  bulk
};
