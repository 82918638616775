import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import TemplateBase from 'components/template-base/template';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AcervoService from 'services/acervo/AcervoService';

function Reetiquetagem() {
  document.title = 'Reetiquetagem';

  const [origem, setOrigem] = useState<string>('');
  const [destino, setDestino] = useState<string>('');

  const ref_origem = useRef<HTMLInputElement>(null);
  const ref_destino = useRef<HTMLInputElement>(null);
  const ref_button = useRef<HTMLButtonElement>(null);

  const handleReetiquetagem = () => {
    if (!origem || !destino) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'É preciso preencher os campos'
      });

      return false;
    }

    Swal.fire({
      title: 'Mover Grupo de Acervo',
      text: 'Tem certeza que deseja mover este grupo?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        AcervoService.moverAcervo({
          origem,
          destino
        })
          .then(() => {
            Swal.fire('Reetiquetagem feita com sucesso', '', 'success').then(() => {
              setTimeout(() => {
                ref_origem?.current?.focus();
              }, 300);
            });

            setOrigem('');
            setDestino('');
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error?.response?.data?.error?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
            }).then(() => {
              setTimeout(() => {
                ref_button?.current?.blur();
                ref_destino?.current?.blur();
                ref_origem?.current?.focus();
              }, 300);
              setOrigem('');
              setDestino('');
            });
          });
      }
    });

    return null;
  };

  const handleOrigemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrigem(e.target.value);
  };
  const handleDestino = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestino(e.target.value);
  };

  const handleOrigemKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (ref_destino?.current) {
        ref_destino.current.focus();
      }
    }
  };

  const handleDestinoKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ref_button?.current?.focus();
      ref_button?.current?.click();
      ref_origem?.current?.blur();
      ref_destino?.current?.blur();
    }
  };

  return (
    <TemplateBase>
      <h1>Reetiquetagem de grupo de acervo: </h1>
      <Paper elevation={1}>
        <Box p={3}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <TextField
                name="origem"
                autoFocus
                value={origem}
                fullWidth
                inputRef={ref_origem}
                onChange={handleOrigemChange}
                onKeyPress={handleOrigemKeypress}
                label="Origem"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <SwapHorizIcon fontSize="large" color="disabled" />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="destino"
                value={destino}
                onChange={handleDestino}
                onKeyPress={handleDestinoKeypress}
                inputRef={ref_destino}
                fullWidth
                label="Destino"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2}>
              <Button type="button" ref={ref_button} color="primary" variant="contained" onClick={handleReetiquetagem}>
                Mover
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </TemplateBase>
  );
}

export default Reetiquetagem;
