import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Pagination from '@mui/material/Pagination';
import TemplateBase from 'components/template-base/template';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PedidoProduto, Categoria, ApiListMeta } from 'interfaces';
import FormControl from '@mui/material/FormControl';
import LocalizacaoProdutoService from 'services/localizacao/LocalizacaoProdutoService';
import Loading from 'components/loading';
import { AntTab, AntTabs, TabPanel } from 'components/tab';
import MarcarProdutosLocalizadosStyle from './style';
import Produto from './produto';

interface MarcarProdutosLocalizadosContextData {
  setPedidosLocalizados: React.Dispatch<React.SetStateAction<PedidoProduto[] | undefined>>;
  setPedidosNaoLocalizados: React.Dispatch<React.SetStateAction<PedidoProduto[] | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMeta: React.Dispatch<React.SetStateAction<ApiListMeta | undefined>>;
  getFiltersFromLocalStorage: () => { prioridade?: number; codcategoriaprincipal?: number };
  valueTab: number;
}

const localStorageNameSpace = 'EUBackoffice:filtrosLocalizacao';
const getFiltersFromLocalStorage = () => {
  const filtrosStored = localStorage?.getItem(localStorageNameSpace);

  if (filtrosStored) {
    return JSON.parse(filtrosStored);
  }

  return null;
};

const filtrosStored = getFiltersFromLocalStorage();

export const MarcarProdutosLocalizadosContext = createContext<MarcarProdutosLocalizadosContextData>(
  {} as MarcarProdutosLocalizadosContextData
);

function MarcarProdutosLocalizados() {
  document.title = 'Marcar Produtos Localizados';

  const classes = MarcarProdutosLocalizadosStyle();
  const [valueTab, setValueTab] = useState<number>(0);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [pedidosLocalizados, setPedidosLocalizados] = useState<PedidoProduto[]>();
  const [pedidosNaoLocalizados, setPedidosNaoLocalizados] = useState<PedidoProduto[]>();
  const [categoriasProdutos] = useState<Categoria[]>();
  const [valuePrioridade, setValuePrioridade] = useState<number>(filtrosStored ? Number(filtrosStored.prioridade) : 0);
  const [valueCategoria, setValueCategoria] = useState<string>(
    filtrosStored ? filtrosStored.codcategoriaprincipal : ''
  );
  const [meta, setMeta] = useState<ApiListMeta>();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const handleChangeTabs = (event: React.ChangeEvent<unknown>, newValue: number) => setValueTab(newValue);

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => setPage(value);

  const handleChangePrioridade = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueChecked = Number(event.target.checked);

    setValuePrioridade(valueChecked);
    saveFilterLocalStorage({ prioridade: valueChecked.toString(), codcategoriaprincipal: valueCategoria });
  };

  const handleChangeCategoria = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueCategoria(event.target.value);
    saveFilterLocalStorage({ prioridade: valuePrioridade.toString(), codcategoriaprincipal: event.target.value });
  };

  const saveFilterLocalStorage = ({
    prioridade,
    codcategoriaprincipal
  }: {
    prioridade: string;
    codcategoriaprincipal: string;
  }) => {
    localStorage.setItem(
      localStorageNameSpace,
      JSON.stringify({
        prioridade,
        codcategoriaprincipal
      })
    );
  };

  const handleLimparProduto = () => {
    setValuePrioridade(0);
    setValueCategoria('');
    saveFilterLocalStorage({ prioridade: '0', codcategoriaprincipal: '' });
  };

  const buildQuery = (categoria?: string, prioridade = 0) => {
    let query = '';

    query += `?prioridade=${prioridade}`;

    if (categoria) {
      query += `&codcategoriaprincipal=${categoria}`;
    }

    return query;
  };

  const fetchProdutosNaoLocalizados = useCallback(async () => {
    try {
      const query = buildQuery(valueCategoria, valuePrioridade);

      setLoading(true);

      await LocalizacaoProdutoService.list(query).then(({ data }) => {
        setPedidosNaoLocalizados(data.data);
        setMeta(data.meta);
      });
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [valuePrioridade, valueCategoria]);

  const fetchProdutosLocalizados = useCallback(async () => {
    try {
      let query = buildQuery(valueCategoria, valuePrioridade);

      query = `?page=${page}&${query.replace('?', '')}`;

      setLoading(true);

      await LocalizacaoProdutoService.localizados(query).then(({ data }) => {
        setPedidosLocalizados(data.data);
        setMeta(data.meta);
      });
    } catch {
      //
    } finally {
      setLoading(false);
    }
  }, [valuePrioridade, valueCategoria, page]);

  useEffect(() => {
    if (filtrosStored) {
      const { prioridade, codcategoriaprincipal } = filtrosStored;

      setValuePrioridade(Number(prioridade));
      setValueCategoria(codcategoriaprincipal);
    }
  }, []);

  useEffect(() => {
    if (valueTab) {
      fetchProdutosLocalizados();
    } else {
      fetchProdutosNaoLocalizados();
    }
  }, [valueTab, valuePrioridade, valueCategoria, fetchProdutosNaoLocalizados, fetchProdutosLocalizados, page]);

  const contextValue = useMemo(
    () => ({
      setPedidosNaoLocalizados,
      setPedidosLocalizados,
      setLoading,
      setMeta,
      getFiltersFromLocalStorage,
      valueTab
    }),
    [setPedidosNaoLocalizados, setPedidosLocalizados, setLoading, setMeta, valueTab]
  );

  return (
    <TemplateBase>
      <h1>Pedidos Aprovados</h1>

      <Grid container alignItems="center">
        <Grid item>
          <Button onClick={() => setOpenFilters(!openFilters)} className={classes.buttonFilter}>
            Filtrar por
            {openFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </Grid>
      </Grid>

      <Collapse in={openFilters}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <FormControl variant="standard" component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="pecas_prioridade"
                      checked={Boolean(valuePrioridade)}
                      color="primary"
                      onChange={handleChangePrioridade}
                    />
                  }
                  label="Peças de prioridade"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography>
                <strong>Categoria</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" component="fieldset">
              <RadioGroup row value={valueCategoria} defaultValue={valueCategoria}>
                {categoriasProdutos?.map(item => {
                  return (
                    <FormControlLabel
                      key={item.codcategoria}
                      value={`${item.codcategoria}`}
                      control={<Radio color="primary" name="categorias" onChange={handleChangeCategoria} />}
                      label={item.nome}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Button variant="contained" size="small" color="primary" onClick={handleLimparProduto}>
                Limpar filtros
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Collapse>

      <Box mt={3} mb={3}>
        {meta && meta?.total > 0 && (
          <Typography>
            Falta localizar <strong>{meta?.total} peças</strong>{' '}
          </Typography>
        )}
      </Box>

      <AntTabs value={valueTab} onChange={handleChangeTabs} aria-label="ant example">
        <AntTab label="Não localizados" />
        <AntTab label="Localizados" />
      </AntTabs>
      <MarcarProdutosLocalizadosContext.Provider value={contextValue}>
        <TabPanel value={valueTab} index={0}>
          <Grid container spacing={3}>
            {pedidosNaoLocalizados && pedidosNaoLocalizados.length
              ? pedidosNaoLocalizados.map(item => <Produto key={item.codigo} item={item} />)
              : 'Nenhum resultado'}
          </Grid>
        </TabPanel>
        <TabPanel value={valueTab} index={1}>
          <Grid container spacing={3}>
            {pedidosLocalizados && pedidosLocalizados.length
              ? pedidosLocalizados.map(item => <Produto key={item.codigo} item={item} />)
              : 'Nenhum resultado'}
          </Grid>
          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </TabPanel>
      </MarcarProdutosLocalizadosContext.Provider>
      <Loading open={loading} />
    </TemplateBase>
  );
}

export default MarcarProdutosLocalizados;
