import { Cancel } from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MarketingService } from 'services/marketing/MarketingService';
import { IProduct } from 'services/marketing/types';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';
import { RouteParams } from '.';
import { AlertDelete } from '../components/alert-delete';

export function Produtos() {
  const navigate = useNavigate();
  const { id } = useParams<RouteParams>();

  const { loading, setLoading } = useLoading();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [productSelectId, setProductSelectId] = useState<number>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loadingSearch, setLoadingSearch] = useState(false);

  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      setLoadingSearch(true);
      MarketingService.buscarProdutos(debouncedQuery).then(response => {
        setSearchedProducts(response.produtos);
        setLoadingSearch(false);
      });
    }
  }, [debouncedQuery]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      MarketingService.getProdutosDaCampanha(id, page).then(response => {
        setProducts(response.data);
        setTotalPages(response.meta.last_page);
        setLoading(false);
      });
    }
  }, [id, setLoading, page]);

  const handleOpenModalDelete = (productId: number) => {
    setProductSelectId(productId);
    setOpenModalDelete(true);
  };

  const handleRemoveProduct = async () => {
    if (productSelectId) {
      setOpenModalDelete(false);
      setLoading(true);
      await MarketingService.removeProduct(id!, productSelectId);
      setProducts(products.filter(product => product.codproduto !== productSelectId));
      setLoading(false);
    }
  };

  const handleAddProduct = async (product: IProduct) => {
    setLoading(true);
    const { attached } = await MarketingService.adicionarProduto(id!, product.codproduto);
    const isExists = attached.length === 0;
    if (isExists) {
      setLoading(false);
      Swal.fire('Produto já foi adicionado', '', 'error');
      return;
    }
    setQuery('');
    setSearchedProducts([]);
    setProducts([product, ...products]);
    setLoading(false);
  };

  return (
    <Box mt={2} gap={2} display="flex" flexDirection="column">
      <Autocomplete
        freeSolo
        inputValue={query}
        loading={loadingSearch}
        clearOnEscape
        onInputChange={(_, value, reason) => {
          if (reason === 'clear') {
            setSearchedProducts([]);
          }
          setQuery(value);
        }}
        onChange={(_, value) => {
          if (value) {
            handleAddProduct(value as IProduct);
          }
        }}
        filterOptions={option => option}
        options={searchedProducts}
        getOptionLabel={option => (option as IProduct).nome}
        renderInput={params => <TextField {...params} label="Pesquise um produto para adicionar a coleção" />}
        renderOption={(props, option, _, ownerState) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              display="flex"
              alignItems="center"
              gap={2}
              sx={{
                borderRadius: '8px',
                margin: '5px',
                [`&.${autocompleteClasses.option}`]: {
                  padding: '8px'
                }
              }}
              component="li"
              {...optionProps}
            >
              <img
                width={36}
                height={36}
                src={option?.imagens_vitrine?.[0]?.small}
                style={{ borderRadius: 8 }}
                alt="imagem produto"
              />
              {ownerState.getOptionLabel(option)}
              {` - ${option?.codigo}`}
            </Box>
          );
        }}
      />

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Produto</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          {products?.length > 0 ? (
            <TableBody>
              {products.map(product => (
                <TableRow key={product.nome}>
                  <TableCell align="left">
                    <Box display="flex" alignItems="center" gap={1}>
                      <img
                        width={36}
                        height={36}
                        src={product?.imagens_vitrine?.[0]?.small}
                        style={{ borderRadius: 8 }}
                        alt="imagem produto"
                      />
                      <p>{product.nome}</p>
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    <Button variant="text" onClick={() => handleOpenModalDelete(product.codproduto)}>
                      <Cancel color="action" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            !loading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    Nenhum produto cadastrado
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          )}
        </Table>
        <Box display="flex" flex={1} height={50} justifyContent="center" alignItems="center">
          <Pagination
            color="primary"
            count={totalPages}
            page={page}
            onChange={(_, currentPage) => {
              setPage(currentPage);
            }}
          />
        </Box>
      </TableContainer>
      <Box display="flex" alignItems="center" gap={1}>
        <Button
          style={{ height: 36.5 }}
          variant="outlined"
          onClick={() => {
            navigate('/painel-marketing/campanhas');
          }}
        >
          Voltar
        </Button>
        <Button
          style={{ height: 36.5 }}
          variant="contained"
          color="success"
          onClick={() => {
            Swal.fire('Campanha salva!', '', 'success');
          }}
        >
          Salvar
        </Button>
      </Box>
      <AlertDelete
        title="Tem certeza que deseja deletar esse produto?"
        open={openModalDelete}
        close={() => setOpenModalDelete(false)}
        remove={handleRemoveProduct}
      />
    </Box>
  );
}
