import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormAvaliacao } from 'context/form-avaliacao';

import { useFiltros } from 'context/filtros';
import { RequerAtencao } from '../requer-atencao';

import styles from '../styles';
import { FiltroRadio } from '../filtros-radio';

export const Status = memo(() => {
  const { item } = useFormAvaliacao();
  const classes = styles();
  const { filtroState } = useFiltros();

  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      <Grid item xs={6}>
        <Box py={1}>
          <Typography variant="overline" color="textSecondary" className={classes.titles}>
            Status:
          </Typography>

          <div>
            <FiltroRadio
              apiFiltroVariacoes={filtroState?.condicao?.variacoes}
              produtoFiltroVariacoes={item?.produto?.filtros?.condicao?.variacoes}
              name={filtroState?.condicao?.field}
              defaultValue="seminovo"
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <RequerAtencao />
      </Grid>
    </Grid>
  );
});
