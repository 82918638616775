import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const preAvaliacaoStyle = makeStyles(() => ({
  space: {
    marginBottom: theme.spacing(6)
  },
  mergeBtn: {
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto'
    }
  },
  sendButton: {
    marginLeft: theme.spacing(1)
  },
  locked: {
    opacity: 0.5
  },
  colCheckbox: {
    width: '1rem'
  },
  chk: {
    padding: 0
  },
  linkAvaliar: {
    color: theme.palette.primary.main
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed'
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  iconNotification: {
    color: theme.palette.warning.main
  },
  iconSugestao: {
    color: theme.palette.warning.main
  },
  iconFluxoAntigo: {
    color: theme.palette.secondary.dark
  }
}));

export default preAvaliacaoStyle;
