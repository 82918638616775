import React, { useEffect, useState, useRef, useCallback } from 'react';
import TemplateBase from 'components/template-base/template';
import ModelosService from 'services/modelos/ModelosService';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import { AutoCompleteMarcasPayload, AutocompleteModelosPayload, CampoCategorias, ApiListMeta } from 'interfaces';
import Grid from '@mui/material/Grid2';
import { useLoading } from 'context/loading';
import AutocompleteCategorias from 'components/form/autocompleteCategorias';
import AutocompleteMarcas from 'components/form/autocompleteMarcas';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import { ListRow } from './ListRow';

function ListarModelos() {
  document.title = 'Ferramentas';

  const nomeModelo = useRef<HTMLInputElement>(null);

  const [modelos, setModelos] = useState<AutocompleteModelosPayload[] | null>(null);
  const [codCategoria, setCodCategoria] = useState<number | null>(null);
  const [codMarca, setCodMarca] = useState<number | null>(null);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);

  const { setLoading } = useLoading();

  const navigate = useNavigate();

  // Cria um ref para o autocomplete de categoria
  const autoCompleteCategoriaRef = useRef<{ clearAutoComplete: () => void }>(null);

  // Cri um ref para o autocomplete de marca
  const autoCompleteMarcaRef = useRef<{ clearAutoComplete: () => void }>(null);

  const getModelos = (query = '', currentPage = 1) => {
    return ModelosService.listar(query, currentPage).then(response => {
      setModelos(response.data.data);
      setMeta(response.data.meta);
    });
  };

  const handleCodCategoria = (categoria: CampoCategorias) => {
    if (categoria === null) {
      setCodCategoria(null);
    } else if (categoria?.codcategoria) {
      setCodCategoria(categoria.codcategoria);
    }
  };

  const handleCodMarca = (marca: AutoCompleteMarcasPayload | null) => {
    if (marca === null) {
      setCodMarca(null);
    } else if (marca?.codmarca) {
      setCodMarca(marca.codmarca);
    }
  };

  const handleSearch = useCallback(
    (query: string) => {
      getModelos(query, page);
    },
    [page]
  );

  const buildQuery = (): string => {
    const query = [];

    if (nomeModelo.current && nomeModelo.current.value) {
      query.push(`nome=${nomeModelo.current.value}`);
    }

    if (codCategoria) {
      query.push(`codcategoria=${codCategoria}`);
    }
    if (codMarca) {
      query.push(`codmarca=${codMarca}`);
    }

    return query.join('&');
  };

  const handleSearchButton = () => {
    const query: string = buildQuery();
    setLoading(true);

    getModelos(query).finally(() => {
      setLoading(false);
    });
  };

  const handleLimpar = () => {
    if (nomeModelo && nomeModelo.current) {
      nomeModelo.current.value = '';
    }

    autoCompleteCategoriaRef.current?.clearAutoComplete();
    autoCompleteMarcaRef.current?.clearAutoComplete();

    handleSearch('');
  };

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const query: string = buildQuery();
    handleSearch(query);
  }, [handleSearch]); // eslint-disable-line

  return (
    <TemplateBase>
      <Box my={2}>
        <Paper elevation={1}>
          <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h2>Filtrar modelos</h2>
              <div>
                <Button color="primary" variant="contained" onClick={() => navigate('/ferramentas/modelos/cadastrar')}>
                  Cadastrar novo modelo
                </Button>
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid size={4}>
                <TextField
                  size="small"
                  label="Nome do modelo"
                  variant="outlined"
                  name="nome"
                  fullWidth
                  inputRef={nomeModelo}
                  InputLabelProps={{
                    style: {
                      fontSize: 13
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: 13
                    }
                  }}
                />
              </Grid>
              <Grid size={4}>
                <AutocompleteCategorias categoriaPai getCategoria={handleCodCategoria} ref={autoCompleteCategoriaRef} />
              </Grid>
              <Grid size={4}>
                <AutocompleteMarcas getMarcaSelected={handleCodMarca} ref={autoCompleteMarcaRef} />
              </Grid>
            </Grid>
            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid>
                  <Button color="primary" variant="contained" onClick={handleSearchButton}>
                    Buscar
                  </Button>
                </Grid>
                <Grid>
                  <Button variant="contained" onClick={handleLimpar}>
                    Limpar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
      {modelos && modelos.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box ml={2}>Nenhum modelo foi encontrado</Box>
        </Box>
      )}
      {modelos && modelos.length > 0 && (
        <>
          <Paper elevation={1}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Categoria</TableCell>
                  <TableCell>Marca</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {modelos.map(modelo => (
                  <ListRow key={modelo.id} modelo={modelo} doRefresh={getModelos} />
                ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
    </TemplateBase>
  );
}

export default ListarModelos;
