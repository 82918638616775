import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

export default makeStyles(() =>
  createStyles({
    formSpace: {
      marginTop: theme.spacing(2)
    },
    checkBox: {
      marginLeft: 0,
      '& .MuiFormControlLabel-root': {
        marginLeft: 0
      },
      '& .MuiCheckbox-root, & .MuiRadio-root': {
        padding: 2
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 12
      }
    },
    textInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      },

      '& .MuiInputLabel-root': {
        fontSize: 13
      }
    }
  })
);
