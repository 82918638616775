import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const LocalizarProdutosStyle = makeStyles(() => ({
  buttonFilter: {
    marginLeft: -8
  },
  cover: {
    width: 145,
    height: 145,
    marginRight: 20,
    borderRadius: '50%'
  },
  verLogs: {
    marginTop: 6
  },
  chip: {
    cursor: 'pointer'
  },
  wrapperInfos: {
    marginLeft: 6,
    marginTop: 6
  },
  textInfos: {
    lineHeight: 1.2
  },
  textInfosTitle: {
    fontSize: 16,
    marginBottom: 5
  },
  textInfosSmall: {
    fontSize: 13
  },
  starIcon: {
    color: yellow[600],
    fontSize: 30
  },
  card: {
    cursor: 'pointer',
    padding: 0
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  wrapperCircleIcon: {
    fontSize: 0
  },
  undoButton: {
    padding: 0
  },
  textLocalizado: {
    fontSize: 15,
    textTransform: 'uppercase'
  }
}));

export default LocalizarProdutosStyle;
