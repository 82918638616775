import makeStyles from '@mui/styles/makeStyles';

const alertMergeStyle = makeStyles(() => ({
  listBatchsRelacionadas: {
    marginTop: 15
  },
  listItemBatchsRelacionadas: {
    padding: 0
  },
  iconMergeBatchs: {
    minWidth: 35
  }
}));

export default alertMergeStyle;
