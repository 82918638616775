import React, { lazy, Suspense, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import { QueroVenderItemVendedora } from 'interfaces';
import VendedoraDevolucaoService from 'services/devolucao/VendedoraDevolucaoService';
import { BadgePortadorRetirada } from 'components/custom-badge/badge-portador-retirada';
import withStyles from '@mui/styles/withStyles';
import { useLoading } from 'context/loading';
import { green, orange } from '@mui/material/colors';
import devolucaoStyles from './styles';
import { BotaoGerarUrl } from './botao-gerar-url-devolucao';
import { BotaoRegerarDevolucao } from './botao-regerar-devolucao';

const ModalAgendarRetirada = lazy(() => import('./modal-agendar-retirada'));

type Props = {
  contentHeaderRight: React.ReactElement;
  contentHeaderLeft: string | React.ReactElement;
  color: string;
  vendedora: QueroVenderItemVendedora;
  children: React.ReactNode;
  codigo?: string;
  codpedido?: number;
  refreshSearch?: () => void;
  hideAcoesPedido?: boolean;
  retirada?: boolean;
  returnType?: string;
  pedidoNaoPago?: boolean;
  refreshPedido?: () => void;
};

const PurpleButton = withStyles(() => ({
  root: {
    color: '#FFF',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

function BoxDevolution({ text }: { text: string }) {
  return (
    <Box
      sx={{
        width: 'auto',
        height: 45,
        borderRadius: 1,
        bgcolor: orange[300],
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        padding: '0 12px',
        fontSize: 16,
        marginRight: 2
      }}
    >
      {text}
    </Box>
  );
}

export function CardDevolucao(props: Props) {
  const styles = devolucaoStyles();

  const {
    contentHeaderRight,
    contentHeaderLeft,
    color,
    vendedora,
    children,
    codigo = undefined,
    refreshSearch = undefined,
    hideAcoesPedido = false,
    retirada = false,
    returnType,
    pedidoNaoPago = false,
    refreshPedido = null,
    codpedido
  } = props;

  const { setLoading } = useLoading();

  const [showModalRetirada, setShowModalRetirada] = useState(false);

  const handleCancelarPedido = () => {
    setLoading(true);
    VendedoraDevolucaoService.cancelar_pedido(codigo as string)
      .then(() => {
        Swal.fire('Pedido cancelado com sucesso', '', 'success').then(() => {
          refreshSearch?.();
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseModal = () => {
    setShowModalRetirada(false);
  };

  const handleAprovarPedido = () => {
    if (retirada) {
      setShowModalRetirada(true);
    } else {
      setLoading(true);
      VendedoraDevolucaoService.aprovar_pedido(codigo as string)
        .then(() => {
          Swal.fire('Pedido aprovado com sucesso', '', 'success').then(() => {
            refreshSearch?.();
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleMarcarNaoPago = () => {
    VendedoraDevolucaoService.marcar_nao_pago(codigo as string)
      .then(() => {
        Swal.fire('Pedido marcado como não pago', '', 'success').then(() => {
          refreshSearch?.();
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {showModalRetirada && (
        <Suspense fallback="...">
          <ModalAgendarRetirada
            onCloseModal={handleCloseModal}
            codigo={codigo as string}
            refreshSearch={refreshSearch}
            endereco={`${vendedora.endereco}, ${vendedora.numero} - ${vendedora.cidade} - ${vendedora.uf}`}
          />
        </Suspense>
      )}

      <Card className={styles.cardSpace}>
        {codigo && (
          <Box
            paddingLeft={2}
            paddingTop={2}
            fontSize={26}
            fontWeight={600}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Box display="flex" alignItems="center">
              Pedido {codpedido}-{codigo}{' '}
              <Box ml={2}>
                {pedidoNaoPago ? (
                  <BotaoRegerarDevolucao codigo={codigo} refreshPedido={refreshPedido} />
                ) : (
                  <BotaoGerarUrl codigo={codigo} />
                )}
              </Box>
            </Box>
            {returnType && <BoxDevolution text={returnType} />}
          </Box>
        )}
        <CardHeader
          title={
            <Box display="inline-flex" width="100%" justifyContent="space-between" alignItems="center">
              <Box color="#9B9B9B" fontSize={14} display="inline-flex">
                <Box component="div" width={16} height={16} borderRadius="100%" bgcolor={color} mr={1} />
                {contentHeaderLeft}
              </Box>
              <Box color="#9B9B9B" fontSize={14}>
                {contentHeaderRight}
              </Box>
            </Box>
          }
        />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Produtos</TableCell>
                <TableCell>Reprovado por</TableCell>
                <TableCell>Devolução por</TableCell>
                <TableCell>Data solicitação</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>

          <Box borderRadius="4px" bgcolor="#FAFAFA" border="1px solid" borderColor="#DCDCDC" p={2} mt={2}>
            <strong>Endereço de devolução:</strong>
            <Box mt={1}>
              {vendedora.endereco}, {vendedora.numero} - {vendedora.cidade} - {vendedora.uf}
            </Box>
          </Box>

          {!hideAcoesPedido && (
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
              <Box>{retirada && <BadgePortadorRetirada nospace />}</Box>
              <Box>
                <PurpleButton variant="contained" onClick={handleMarcarNaoPago} style={{ marginRight: 10 }}>
                  Enviar tudo para doação
                </PurpleButton>
                {!retirada && (
                  <Button color="secondary" variant="contained" onClick={handleCancelarPedido}>
                    cancelar pedido
                  </Button>
                )}
                <Button color="primary" variant="contained" onClick={handleAprovarPedido} style={{ marginLeft: 10 }}>
                  forçar confirmação
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
