import makeStyles from '@mui/styles/makeStyles';
import { green, red } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const preAvaliacaoStyle = makeStyles(() => ({
  space: {
    marginBottom: theme.spacing(2)
  },
  mergeBtn: {
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto'
    }
  },
  iconClienteNovo: {
    color: green[500],
    marginLeft: 10
  },
  boxClienteNovo: {
    backgroundColor: green[100],
    color: green[500],
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 3,
    padding: '0.4rem',
    boxSizing: 'border-box',
    fontSize: '0.8rem',
    maxHeight: 31,
    marginLeft: 15,

    '& > svg': {
      fontSize: '1.2rem',
      marginRight: 2
    }
  },
  title: {
    display: 'inline-block',
    marginRight: '0.8rem'
  },
  sendButton: {
    marginLeft: theme.spacing(1)
  },
  locked: {
    opacity: 0.5
  },
  colCheckbox: {
    width: '1rem'
  },
  chk: {
    padding: 0
  },
  linkAvaliar: {
    color: theme.palette.primary.main
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed'
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  iconFluxoAntigo: {
    color: theme.palette.secondary.dark
  },
  fluxoAntigo: {
    position: 'relative',
    top: '-0.3rem',
    padding: '0.5rem',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '3px',
    backgroundColor: theme.palette.secondary.dark
  },
  iconFluxoAntigoAlerta: {
    position: 'relative',
    top: '0.2rem',
    marginRight: '0.3rem',
    fontSize: '1rem'
  },
  iconBolsasAcessorios: {
    color: red[500]
  }
}));

export default preAvaliacaoStyle;
