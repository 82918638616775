import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import { useFiltros } from 'context/filtros';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { CheckboxHookForms } from 'components/form/text-field-hook-forms';
import styles from '../styles';

export function FilterGenero() {
  const { filtroState, fetchFiltrosTamanho } = useFiltros();
  const { disableForm, campoCategoria, item, campoMarca } = useFormAvaliacao();

  const classes = styles();
  const { register } = useFormContext();

  const produtoFiltroVariacoes = useMemo(() => {
    if (item?.produto?.filtros?.genero) return item?.produto?.filtros?.genero.variacoes;

    return [];
  }, [item]);

  const apiFiltroGeneroVariacoes = useMemo(() => {
    if (!filtroState) return null;
    if (Object.keys(filtroState).length === 0 || !filtroState.genero) return null;
    return filtroState.genero.variacoes;
  }, [filtroState]);

  const [gendersSelectedIds, setGenderSelectedIds] = useState<number[] | null>(null);

  const handleAddGenderId = (values: number[]) => {
    const gendersSelected: number[] = gendersSelectedIds ? [...gendersSelectedIds] : [];

    for (let index = 0; index < values.length; index += 1) {
      const element = values[index];

      if (!gendersSelected.includes(element)) {
        gendersSelected.push(element);
      }
    }

    setGenderSelectedIds(gendersSelected);
  };

  const handleRemoveGenderId = (values: number[]) => {
    const gendersSelected: number[] = gendersSelectedIds ? [...gendersSelectedIds] : [];

    const newState = gendersSelected.filter(gender => values.indexOf(gender) < 0);

    setGenderSelectedIds(newState);
  };

  const handleChange = (value: string, checked: boolean) => {
    try {
      if (checked) {
        handleAddGenderId([parseInt(value, 10)]);
      } else {
        handleRemoveGenderId([parseInt(value, 10)]);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleFetchFiltroTamanho = useCallback(() => {
    if (campoCategoria?.codcategoria && gendersSelectedIds) {
      fetchFiltrosTamanho(campoCategoria.codcategoria, campoMarca?.codmarca, gendersSelectedIds.join(','));
    }
  }, [campoCategoria, campoMarca, gendersSelectedIds, apiFiltroGeneroVariacoes]); //eslint-disable-line

  function defaultCheckedFilters(codfiltrovariacao: number) {
    if (!produtoFiltroVariacoes) return false;

    return produtoFiltroVariacoes.some(variacao => variacao.codfiltrovariacao === codfiltrovariacao);
  }

  useEffect(() => {
    if (gendersSelectedIds) {
      handleFetchFiltroTamanho();
    }
  }, [handleFetchFiltroTamanho, gendersSelectedIds]);

  const { ref, onChange, ...regiterProps } = register('cadastro_genero');

  if (apiFiltroGeneroVariacoes) {
    return (
      <FormGroup row>
        {apiFiltroGeneroVariacoes.map(variacao => (
          <FormControlLabel
            key={`${variacao.slug}`}
            className={classes.checkBox}
            ref={ref}
            {...regiterProps}
            control={
              <CheckboxHookForms
                disabled={disableForm}
                value={variacao.codfiltrovariacao}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  handleChange(event.target.value, checked);
                  onChange(event);
                }}
                defaultChecked={defaultCheckedFilters(variacao.codfiltrovariacao)}
              />
            }
            label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
          />
        ))}
      </FormGroup>
    );
  }

  if (produtoFiltroVariacoes?.length === 0) return <div>-</div>;

  return (
    <FormGroup row>
      {produtoFiltroVariacoes?.map(variacao => (
        <FormControlLabel
          key={`${variacao.slug}`}
          className={classes.checkBox}
          ref={ref}
          {...regiterProps}
          control={
            <CheckboxHookForms disabled={disableForm} value={variacao.codfiltrovariacao} color="primary" checked />
          }
          label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
        />
      ))}
    </FormGroup>
  );
}
