import { Box, Tab, Tabs } from '@mui/material';
import TemplateBase from 'components/template-base/template';
import { useEffect, useState } from 'react';
import { TabPanel } from 'components/tab';
import { IGetDetailsCampaign } from 'services/marketing/types';
import { MarketingService } from 'services/marketing/MarketingService';
import { useParams } from 'react-router-dom';
import { useLoading } from 'context/loading';
import { GreenSwitch } from 'components/custom-switch';

import { Produtos } from './produtos';
import { Configuracoes } from './configuracoes';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export type RouteParams = {
  id: string;
  tabSelected: string;
};

function EditarCampanhas() {
  const { id, tabSelected } = useParams<RouteParams>();
  const { setLoading } = useLoading();
  const [tabValue, setTabValue] = useState(tabSelected ? Number(tabSelected) : 0);

  const [campaign, setCampaign] = useState<IGetDetailsCampaign>();
  const [activeCampaign, setActiveCampaign] = useState(true);

  useEffect(() => {
    setActiveCampaign(campaign?.status === 'A');
  }, [campaign]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      MarketingService.getDetalhesCampanha(id).then(data => {
        setCampaign(data);
        setLoading(false);
      });
    }
  }, [id, setLoading]);

  const changeTab = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleToggleCampaign = async () => {
    setActiveCampaign(state => !state);
    await MarketingService.toggleStatusCampanha(id!, activeCampaign ? 'I' : 'A');
  };

  return (
    <TemplateBase>
      <Box display="flex" flexDirection="column" mb={5} minHeight="100vh">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <h1 style={{ marginBottom: 0 }}>Editar campanha</h1>
            <p style={{ marginTop: 0 }}>{campaign?.nome}</p>
          </Box>
          {campaign && (
            <Box display="flex" alignItems="center">
              <GreenSwitch checked={activeCampaign} onChange={handleToggleCampaign} color="secondary" />
              <p>{activeCampaign ? 'Ativada' : 'Desativada'}</p>
            </Box>
          )}
        </Box>
        <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={changeTab}>
          <Tab label="Configurações" {...a11yProps(0)} />
          <Tab label="Produtos" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Configuracoes campaign={campaign} onUpdate={setCampaign} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Produtos />
        </TabPanel>
      </Box>
    </TemplateBase>
  );
}

export default EditarCampanhas;
