import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function Barcode() {
  return (
    <SvgIcon width="22" height="18" viewBox="0 0 22 18" color="primary">
      <path d="M0 0H4.55V1.5H1.5V4.55H0V0ZM17.425 0H21.975V4.55H20.475V1.5H17.425V0ZM20.475 16.5V13.45H21.975V18H17.425V16.5H20.475ZM1.5 13.45V16.5H4.55V18H0V13.45H1.5ZM5.85 2.75H6.9V15.225H5.85V2.75ZM2.825 2.75H4.825V15.225H2.825V2.75ZM8.9 2.75H10.975V15.225H8.9V2.75ZM16.1 2.75H17.15V15.225H16.1V2.75ZM18.175 2.75H19.125V15.225H18.175V2.75ZM12.025 2.75H15.05V15.225H12.025V2.75Z" />
    </SvgIcon>
  );
}
