import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function Medal(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.41699 8.13334L5.01699 6.21668L3.43366 4.98334H5.36699L6.00033 3.00001L6.61699 4.98334H8.56699L6.98366 6.21668L7.56699 8.13334L6.00033 6.95001L4.41699 8.13334ZM2.06699 14.3333V9.26668C1.56699 8.74446 1.20866 8.17223 0.991992 7.55001C0.775326 6.92779 0.666992 6.30001 0.666992 5.66668C0.666992 4.15557 1.1781 2.8889 2.20033 1.86668C3.22255 0.844455 4.48921 0.333344 6.00033 0.333344C7.51144 0.333344 8.7781 0.844455 9.80033 1.86668C10.8225 2.8889 11.3337 4.15557 11.3337 5.66668C11.3337 6.30001 11.2253 6.92779 11.0087 7.55001C10.792 8.17223 10.4337 8.74446 9.93366 9.26668V14.3333L6.00033 13.0167L2.06699 14.3333ZM6.00033 10C7.21144 10 8.23644 9.58057 9.07533 8.74168C9.91421 7.90279 10.3337 6.87779 10.3337 5.66668C10.3337 4.45557 9.91421 3.43057 9.07533 2.59168C8.23644 1.75279 7.21144 1.33334 6.00033 1.33334C4.78921 1.33334 3.76421 1.75279 2.92533 2.59168C2.08644 3.43057 1.66699 4.45557 1.66699 5.66668C1.66699 6.87779 2.08644 7.90279 2.92533 8.74168C3.76421 9.58057 4.78921 10 6.00033 10Z" />
    </SvgIcon>
  );
}
