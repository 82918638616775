import React, { useEffect, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Barcode } from 'components/icons';
import CheckingService from 'services/checking';
import Loading from 'components/loading';
import { TablePedidosRow } from './tablePedidosRow';
import { useCheckingProvider } from '../CheckingProvider';
import CheckingStyle from './style';

export function CheckingLayout() {
  const {
    pedidos,
    meta,
    filterLocalizarBip,
    filterPedidos,
    hasPagination,
    fetchPedidos,
    fetchPedidoPorCodigo,
    setPedidos,
    setPage,
    page,
    title,
    devolucao,
    subtitle
  } = useCheckingProvider();

  document.title = `${title}`;

  const [codPedidoBusca, setCodPedidoBusca] = useState<string>('');
  const [codPedidoBuscaBip, setCodPedidoBuscaBip] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const classes = CheckingStyle();

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleLocalizarPedidoCodigo = () => {
    fetchPedidoPorCodigo(codPedidoBusca);
  };

  const handleLocalizarPedidoCodigoBip = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setLoading(true);
      filterLocalizarBip.add('codigo', `${codPedidoBuscaBip}`);

      CheckingService.localizar_bip(filterLocalizarBip.getQuery())
        .then(response => {
          setPedidos([response.data]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCodigoBip = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodPedidoBuscaBip(e.target.value);
  };

  const handleCodPedidoBusca = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodPedidoBusca(e.target.value);
  };

  const handleLimparBusca = () => {
    filterPedidos.remove('codigo');
    fetchPedidos();
    setCodPedidoBusca('');
    setCodPedidoBuscaBip('');
  };

  useEffect(() => {
    fetchPedidos();
  }, [filterPedidos, fetchPedidos]);

  return (
    <TemplateBase>
      <h1>{subtitle}</h1>

      <Card>
        <CardContent>
          <h3>Localizar pedido</h3>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                className={classes.field}
                autoFocus
                variant="outlined"
                size="small"
                onKeyUp={handleLocalizarPedidoCodigoBip}
                onChange={handleCodigoBip}
                value={codPedidoBuscaBip}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Barcode />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="flex-end" flexDirection="column">
                <TextField
                  className={classes.field}
                  label="Número do pedido"
                  variant="outlined"
                  size="small"
                  value={codPedidoBusca}
                  onChange={handleCodPedidoBusca}
                />{' '}
                <Box mt={1}>
                  <Button variant="contained" onClick={handleLimparBusca}>
                    Limpar
                  </Button>
                  <Box ml={1} component="span">
                    <Button color="primary" variant="contained" onClick={handleLocalizarPedidoCodigo}>
                      Buscar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {pedidos.length ? (
        <Paper className={classes.paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {devolucao && <TableCell>Vip?</TableCell>}

                <TableCell>Data de aprovação</TableCell>
                <TableCell>Produtos</TableCell>
                <TableCell>N˚ pedido</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {pedidos.map(pedido => (
                <TablePedidosRow devolucao={pedido.devolucao} key={pedido.codpedido} pedido={pedido} />
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <Box p={10} display="inline-flex" justifyContent="center" width="100%">
          Não há pedidos{' '}
        </Box>
      )}

      <Box display="flex" justifyContent="center" mt={3}>
        {hasPagination && <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />}
      </Box>

      <Loading open={loading} />
    </TemplateBase>
  );
}
