import React, { useCallback, useEffect, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import WarningIcon from '@mui/icons-material/Warning';
import ModeracaoService from 'services/peer-two-peer/ModeracaoService';
import { ApiListMeta, Produto } from 'interfaces';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { Barcode } from 'components/icons';
import Paper from '@mui/material/Paper';
import { useLoading } from 'context/loading';
import { useNavigate } from 'react-router-dom';
import { ListagemDevolutiva } from './listagem-devolutiva';
import { ListagemAprovacao } from './listagem-aprovacao';
import ModeracaoProduto from './styles';

type DataApi = {
  data: Produto[];
  meta: ApiListMeta;
};

function ModeracaoListagem() {
  document.title = 'Moderação P2P | Listagem';

  const [valueTab, setValueTab] = useState('U');
  const [dataAprovacao, setDataAprovacao] = useState<DataApi | null>(null);
  const [dataDevolutiva, setDataDevolutiva] = useState<DataApi | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buscaVendedor, setBuscaVendedor] = useState('');

  const { setLoading } = useLoading();

  const styles = ModeracaoProduto();

  const navigate = useNavigate();

  const handleChangeLista = (event: React.ChangeEvent<any>, newValue: string) => setValueTab(newValue); // eslint-disable-line

  const fetchListaProdutosAprovacao = async (status: string, page?: number) => {
    return ModeracaoService.listar(status, page).then(response => {
      setDataAprovacao(response.data);
    });
  };

  const fetchListaProdutosDevolutiva = async (status: string, page?: number) => {
    return ModeracaoService.listar(status, page).then(response => {
      setDataDevolutiva(response.data);
    });
  };

  const fetchListas = useCallback(() => {
    Promise.all([fetchListaProdutosAprovacao('U'), fetchListaProdutosDevolutiva('V')]).finally(() => {
      setIsLoading(false);
    });
  }, []);

  const handleBuscaVendedor = (event: React.ChangeEvent<HTMLInputElement>) => setBuscaVendedor(event.target.value);

  const handleSearchBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setLoading(true);
      const el = document.getElementById('codigoProduto') as HTMLInputElement;

      ModeracaoService.produto(el?.value)
        .then(() => {
          navigate(`/parceiros/moderacao-produtos/${el.value}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const sendBuscaVendedor = () => {
    setLoading(true);
    ModeracaoService.busca(valueTab, buscaVendedor)
      .then(response => {
        if (valueTab === 'U') {
          setDataAprovacao(response.data);
        }
        if (valueTab === 'V') {
          setDataDevolutiva(response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearBuscaVendedor = () => {
    setBuscaVendedor('');
    fetchListas();
  };

  const CustomTab = withStyles(() => ({
    root: {
      whiteSpace: 'nowrap',
      maxWidth: 'fit-content'
    }
  }))(Tab);

  useEffect(() => {
    setIsLoading(true);
    fetchListas();
  }, [fetchListas]);

  return (
    <TemplateBase>
      <h1>Moderação P2P</h1>

      {isLoading ? (
        <div>caregando...</div>
      ) : (
        <>
          <Box my={2}>
            <Tabs value={valueTab} onChange={handleChangeLista} indicatorColor="primary">
              <CustomTab value="U" label={`Pendente Aprovação (${dataAprovacao?.data?.length})`} />
              <CustomTab value="V" label={`Pendente Devolutiva Vendedor (${dataDevolutiva?.data?.length})`} />
            </Tabs>
          </Box>
          <Paper elevation={1} className={styles.paperBusca}>
            <Card>
              <CardContent>
                <h3>Identificar produto</h3>

                <Box maxWidth={450}>
                  <Box my={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoFocus
                      onKeyUp={handleSearchBip}
                      InputProps={{
                        id: 'codigoProduto',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Barcode />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>

                  <Box my={2} display="flex">
                    <TextField
                      name="busca_vendedor"
                      label="Buscar Vendedor"
                      placeholder="Buscar Vendedor"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={buscaVendedor}
                      style={{ marginRight: 10 }}
                      onChange={handleBuscaVendedor}
                    />
                    <Button onClick={clearBuscaVendedor} style={{ marginRight: 10 }} variant="contained">
                      Limpar
                    </Button>
                    <Button onClick={sendBuscaVendedor} color="primary" variant="contained">
                      Buscar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Paper>

          {dataAprovacao && dataAprovacao?.data?.length > 0 && valueTab === 'U' && (
            <ListagemAprovacao dataAprovacao={dataAprovacao} fetchListaProdutos={fetchListaProdutosAprovacao} />
          )}
          {dataAprovacao && dataAprovacao?.data?.length === 0 && valueTab === 'U' && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
              <WarningIcon fontSize="large" color="disabled" />
              <Box component="span" ml={2}>
                Nenhuma produto encontrado
              </Box>
            </Box>
          )}
          {dataDevolutiva && dataDevolutiva?.data?.length > 0 && valueTab === 'V' && (
            <ListagemDevolutiva dataDevolutiva={dataDevolutiva} fetchListaProdutos={fetchListaProdutosDevolutiva} />
          )}
          {dataDevolutiva && dataDevolutiva?.data?.length === 0 && valueTab === 'V' && (
            <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
              <WarningIcon fontSize="large" color="disabled" />
              <Box component="span" ml={2}>
                Nenhuma produto encontrado
              </Box>
            </Box>
          )}
        </>
      )}
    </TemplateBase>
  );
}

export default ModeracaoListagem;
