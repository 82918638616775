import React, { createContext, useCallback, useMemo, useState } from 'react';
import { Produto } from 'interfaces';

export type ProdutoSelected = Pick<
  Produto,
  'custo_devolucao' | 'nome' | 'codigo' | 'codproduto' | 'imagens' | 'vendedora' | 'repasse'
>;

type SelecaoProdutosData = {
  produtosSelected: ProdutoSelected[];
  setProdutosSelected: React.Dispatch<React.SetStateAction<ProdutoSelected[]>>;
  handleProductsSelection: (produto: ProdutoSelected, checked: boolean) => void;
};

const SelecaoProdutosContext = createContext<SelecaoProdutosData>({} as SelecaoProdutosData);

export function SelecaoProdutosProvider({ children }: { children: React.ReactElement }) {
  const [produtosSelected, setProdutosSelected] = useState<ProdutoSelected[] | []>([]);

  const handleProductsSelection = useCallback(
    (produto: ProdutoSelected, checked: boolean) => {
      if (checked) {
        const indexToUpdate = produtosSelected.findIndex(item => item.codproduto === produto.codproduto);

        if (indexToUpdate === -1) {
          setProdutosSelected(prevState => {
            const result = [...prevState];

            result.push(produto);

            return result;
          });
        }
      } else {
        setProdutosSelected(prevState => {
          const result = [...prevState];
          return result.filter(item => item.codproduto !== produto.codproduto);
        });
      }
    },
    [produtosSelected, setProdutosSelected]
  );

  const value = useMemo(
    () => ({
      produtosSelected,
      setProdutosSelected,
      handleProductsSelection
    }),
    [produtosSelected, setProdutosSelected, handleProductsSelection]
  );

  return <SelecaoProdutosContext.Provider value={value}>{children}</SelecaoProdutosContext.Provider>;
}

export function useSelecaoProdutos() {
  const context = React.useContext(SelecaoProdutosContext);

  if (!context) {
    throw new Error('useSelecaoProdutos deve usado dentro de um SelecaoProdutosProvider');
  }

  return context;
}
