import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useGlobalNotification } from 'context/global-notification';
import VendedoraDevolucaoService from 'services/devolucao/VendedoraDevolucaoService';
import { grey } from '@mui/material/colors';

export function ModalUrlDevolucao(props: { codigo: string; handleClose: () => void }) {
  const { CopyToClipBoard } = useGlobalNotification();
  const { codigo, handleClose } = props;
  const [urlDevolucao, setUrlDevolucao] = useState('');

  const handleClickCopy = () => {
    const txt = document.getElementById(`codigo_${codigo}`) as HTMLInputElement;

    if (txt.value) {
      CopyToClipBoard(txt.value.toString());
    }
  };

  const isDisable = useMemo(() => {
    return urlDevolucao.length === 0;
  }, [urlDevolucao]);

  useEffect(() => {
    VendedoraDevolucaoService.gerar_nova_url(codigo as string).then(result => {
      setUrlDevolucao(result.data.url_devolucao);
    });
  }, [codigo]);

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>Url de devolucao</DialogTitle>
      <DialogContent>
        <Box
          borderRadius="4px"
          bgcolor="#FAFAFA"
          border="1px solid"
          borderColor="#DCDCDC"
          mt={2}
          color={grey[600]}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box p={2} width="100%">
            <input
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                border: 0,
                color: grey[600]
              }}
              id={`codigo_${codigo}`}
              type="text"
              readOnly
              disabled={isDisable}
              value={decodeURI(urlDevolucao)}
            />
          </Box>
          <Box mr={2}>
            <IconButton onClick={handleClickCopy} disabled={isDisable} size="large">
              <FileCopyIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
