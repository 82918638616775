import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
import { PedidoProduto, FilaPicking } from 'interfaces';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import PickingService from 'services/picking';
import { BadgePortadorRetirada } from 'components/custom-badge/badge-portador-retirada';
import { format } from 'date-fns';
import VipIcon from 'components/vip-icon';

import CardProductPickingStyle from './styles';

type ProdutoProps = {
  item: PedidoProduto;
  data_aprovacao: string | null;
  atraso: boolean;
  acervo: FilaPicking['acervo'];
  setParentLoading: (loading: boolean) => void;
  btNaoEncontrado?: boolean;
  loadListaPicking: () => void;
  cliente_vip: number | null;
  devolucao?: boolean;
  data_agendamento_retirada?: string | null;
};

export function CardProductPicking({
  item,
  data_aprovacao,
  atraso,
  acervo,
  setParentLoading,
  btNaoEncontrado = false,
  loadListaPicking,
  cliente_vip,
  devolucao = false,
  data_agendamento_retirada = null
}: ProdutoProps) {
  const classes = CardProductPickingStyle();

  const {
    produto,
    codigo,
    codpedido,
    codpedidoproduto,
    imagens,
    prioridade,
    unico,
    localizacao,
    itens_inclusos_extra,
    lacre
  } = item;

  const [openDialog, setOpenDialog] = useState(false);

  const handleProdutoNaoEncontrado = () => {
    setOpenDialog(false);
    setParentLoading(true);
    PickingService.marcarNaoLocalizado(codpedidoproduto).finally(() => {
      setParentLoading(false);
      loadListaPicking();
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Card className={classes.card}>
      <div className={classes.cardContent}>
        <div className={classes.wrapperStatus}>
          {unico === true || atraso === true ? (
            <>
              {unico === true ? (
                <div>
                  <LooksOneIcon className={`${classes.iconUnique}`} />
                </div>
              ) : (
                ''
              )}
              {atraso === true ? (
                <>
                  <div className={`${classes.textAtraso}`}>Em atraso</div>
                  {data_aprovacao && (
                    <div className={`${classes.textAtraso}`}>
                      Aprovado em {format(new Date(data_aprovacao), 'dd/MM/yyyy HH:mm')}
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
              {prioridade === 1 ? <div className={`${classes.textPrioridade}`}>Prioridade</div> : ''}
            </>
          ) : (
            ''
          )}
        </div>
        <Box display="inline-flex" alignItems="flex-start" width="100%">
          <Box mr={2}>{devolucao && <VipIcon isVip={cliente_vip === 1} />}</Box>
          <Avatar variant="square" className={classes.avatar} src={imagens?.[0]?.small} />

          <div>
            {data_agendamento_retirada && (
              <Box ml={1}>
                <BadgePortadorRetirada nospace date={format(new Date(data_agendamento_retirada), 'dd/MM/yyyy')} />
              </Box>
            )}
            <div className={classes.wrapperInfos}>
              <Box mb={1} display="inline-flex" alignItems="center">
                <Typography className={classes.textInfos}>
                  <div>COD: {codigo}</div>
                </Typography>
                <Box ml={2}>
                  <Typography className={classes.textCodpedido}>Pedido #{codpedido}</Typography>
                </Box>
              </Box>
              <Typography className={classes.textInfosTitle}>
                <strong>{produto}</strong>
              </Typography>
              <Grid container>
                {acervo && (
                  <>
                    {acervo.centro_distribuicao && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>CD:</strong> {acervo.centro_distribuicao}
                        </Typography>
                      </Grid>
                    )}
                    {acervo.andar && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>Andar:</strong> {acervo.andar}
                        </Typography>
                      </Grid>
                    )}
                    {acervo.posicao && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>Posição:</strong> {acervo.posicao}
                        </Typography>
                      </Grid>
                    )}
                    {acervo.altura && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>Altura:</strong> {acervo.altura}
                        </Typography>
                      </Grid>
                    )}
                    {acervo.caixa && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>Caixa:</strong> {acervo.caixa}
                        </Typography>
                      </Grid>
                    )}
                    {lacre && (
                      <Grid item xs={6}>
                        <Typography className={classes.textInfos}>
                          <strong>Lacre:</strong> {lacre}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
                {localizacao && (
                  <Grid item xs={12}>
                    <Typography className={classes.textInfos}>
                      <strong>Localização:</strong> {localizacao}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Box>

        <Box mt={1} display="inline-flex" justifyContent="flex-end" alignItems="center" width="100%">
          <Typography className={classes.textItensInclusos}>
            {itens_inclusos_extra && (
              <>
                <strong>Itens Inclusos: </strong>
                {itens_inclusos_extra}
              </>
            )}
          </Typography>

          {btNaoEncontrado && (
            <Box mr={2}>
              <Button variant="contained" size="small" color="primary" onClick={handleOpenDialog}>
                Produto não encontrado
              </Button>
            </Box>
          )}
        </Box>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tem certeza que deseja adicionar esse produto como não encontrado?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Não</Button>
          <Button onClick={handleProdutoNaoEncontrado}>Sim</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
