import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import alertValidationMessage from 'helpers/alert-validation-message';
import { useEntradaProdutos } from 'pages/avaliacao/context/entrada-produtos';
import { QueroVenderEntradaProduto } from 'interfaces';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextFieldHookForms, SelectHookForms } from 'components/form/text-field-hook-forms';
import LocalizarRemessaStyle from './style';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
      width: '100%'
    }
  })
);

/**
 * Regras das validações do form
 */
const schema = Yup.object().shape({
  codremessa: Yup.string().required('O código remessa é obrigatório'),
  nomeremessa: Yup.string(),
  forma_entrada: Yup.string()
});

/**
 * COMPONENTE
 */
function LocalizarRemessa() {
  const style = LocalizarRemessaStyle();
  const classes = useStyles();

  const {
    localizarRemessa,
    buscaNome,
    setBuscaNome,
    setFormaEntrada,
    formaEntrada,
    setDataAgendamento,
    dataAgendamento
  } = useEntradaProdutos();

  /**
   * Resgata dados do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors }
  } = useForm<QueroVenderEntradaProduto>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = (dataForm: QueroVenderEntradaProduto) => {
    localizarRemessa(dataForm);
  };

  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  const handleFormaEntrada = (event: SelectChangeEvent<unknown | null>) => {
    setFormaEntrada(event.target.value as string);
  };

  /**
   * Seleciona data da remessa para imprimira etiqueta
   */
  const handleDateChange = (date: Date | null) => {
    setDataAgendamento(date);
  };

  /**
   * Limpa campos de busca
   */

  const limpaCamposDeBusca = () => {
    setBuscaNome('');
    setFormaEntrada('');
    setDataAgendamento(null);
    setValue('codremessa', '');
  };

  /**
   * Template
   */
  return (
    <Paper elevation={1} className={style.paperBusca}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xl={4} lg={5}>
              <h3>Dar entrada em Remessa</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextFieldHookForms
                  {...register('codremessa')}
                  label="Código de Remessa"
                  placeholder="Código de Remessa"
                  variant="outlined"
                  size="small"
                />
                <Button type="submit" variant="contained" color="primary" className={style.spaceButton}>
                  Localizar
                </Button>
              </form>
            </Grid>
          </Grid>
          <h3>Localizar remessa</h3>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextFieldHookForms
                {...register('nomeremessa')}
                label="Nome ou código do cliente"
                placeholder="Nome ou código do cliente"
                variant="outlined"
                size="small"
                value={buscaNome}
                fullWidth
                onChange={handleBuscaNome}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" size="small" className={classes.formControl}>
                <InputLabel>Forma de entrada</InputLabel>
                <SelectHookForms
                  variant="outlined"
                  native
                  value={formaEntrada}
                  {...register('forma_entrada')}
                  onChange={handleFormaEntrada}
                  label="Forma de entrada"
                  inputProps={{
                    id: 'outlined-age-native-simple'
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value="coleta">Coleta</option>
                  <option value="correios">Correios</option>
                  <option value="presencial">Presencial</option>
                </SelectHookForms>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Data da remessa"
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        size: 'small',
                        fullWidth: true
                      }
                    }}
                    className={style.inputDate}
                    value={dataAgendamento}
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            {/* <Grid item xs={3}>
              <TextField
                inputRef={register}
                name="nomeremessa"
                label="Código de autorização de postagem"
                placeholder=""
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid> */}
          </Grid>
          <Button sx={{ mt: 1 }} type="submit" variant="contained" color="primary" onClick={limpaCamposDeBusca}>
            Limpar
          </Button>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default LocalizarRemessa;
