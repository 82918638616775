import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import formatPrice from 'helpers/format-price';
import { ProdutoImagens } from 'interfaces';
import { grey } from '@mui/material/colors';
import devolucaoStyles from './styles';

type Props = {
  nome: string;
  custo_dev: number;
  codigo: string;
  imagens: ProdutoImagens[];
};

export function ProdutoSelect(props: Props) {
  const classes = devolucaoStyles();

  const { nome, custo_dev, codigo, imagens } = props;

  return (
    <Box display="flex" alignItems="center" py={2} textAlign="left">
      <div>
        <Avatar alt="Remy Sharp" src={imagens?.[0]?.thumbnail} className={classes.avatarImage} />
      </div>
      <Box pl={1}>
        <div>
          <Box color={grey[600]}>{codigo}</Box>

          <Box py={1} lineHeight={1.2} fontWeight={700}>
            {nome}
          </Box>

          <Box color={grey[600]}>Custo dev: {formatPrice(custo_dev)}</Box>
        </div>
      </Box>
    </Box>
  );
}
