import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();
const ModalTableLogs = makeStyles(() => ({
  root: {
    width: '100%',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: 'inline'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default ModalTableLogs;
