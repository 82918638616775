import { Box, Button, Pagination, TextField } from '@mui/material';
import TemplateBase from 'components/template-base/template';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MarketingService } from 'services/marketing/MarketingService';

import { ICampaign } from 'services/marketing/types';

import { useLoading } from 'context/loading';
import { AlertDelete } from './components/alert-delete';

function Campanhas() {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [campaignSelectedId, setCampaignSelectedId] = useState<number>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [query, setQuery] = useState<string>();
  const [isSearching, setIsSearching] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState(query);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
      setPage(1);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (!!debouncedQuery && !isSearching) return;

    setLoading(true);
    MarketingService.getCampanhas(page, debouncedQuery).then(response => {
      setCampaigns(response.data);
      setTotalPages(response.meta.last_page);
      setLoading(false);
    });
  }, [page, setLoading, isSearching, debouncedQuery]);

  const handleGoToEdit = (id: number) => {
    navigate(`/painel-marketing/campanhas/editar/${id}`);
  };

  const handleGoToCreate = () => {
    navigate('/painel-marketing/campanhas/criar');
  };

  const handleOpenInSite = (url: string) => {
    window.open(`https://www.etiquetaunica.com.br/trends/${url}`, '_blank');
  };

  const handleOpenModalDelete = (id: number) => {
    setCampaignSelectedId(id);
    setOpenModalDelete(true);
  };

  const handleRemoveCampaign = async () => {
    setLoading(true);
    await MarketingService.deletarCampanha(campaignSelectedId!);
    setCampaigns(campaigns.filter(campaign => campaign.codcampanha !== campaignSelectedId));
    setOpenModalDelete(false);
    setLoading(false);
  };

  const handleSearchCampaigns = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;

    if (text.length < 3) {
      setQuery(undefined);
    } else {
      setQuery(text);
      setIsSearching(text.length >= 3);
    }
  };

  return (
    <TemplateBase>
      <Box display="flex" flexDirection="column" mb={5} minHeight="100vh">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h1>Campanhas</h1>
          <Button style={{ height: 36.5 }} variant="outlined" onClick={handleGoToCreate}>
            Adicionar campanha
          </Button>
        </Box>
        <TextField
          fullWidth
          style={{ marginBottom: 24 }}
          label="Busque pelo código ou nome da campanha"
          variant="outlined"
          onChange={handleSearchCampaigns}
        />

        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="center">Campanha</TableCell>
                <TableCell align="right">Ordem de exibição</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {campaigns.map(campaign => (
                <TableRow key={campaign.codcampanha}>
                  <TableCell align="left" component="th" scope="row">
                    {campaign.codcampanha}
                  </TableCell>
                  <TableCell align="center">{campaign.nome}</TableCell>
                  <TableCell align="center">{campaign.ordemexibicao ?? '-'}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="success" onClick={() => handleGoToEdit(campaign.codcampanha)}>
                      Editar
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      variant="outlined"
                      onClick={() => {
                        handleOpenInSite(campaign.url);
                      }}
                    >
                      Ver no site
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      variant="outlined"
                      color="error"
                      onClick={() => handleOpenModalDelete(campaign.codcampanha)}
                    >
                      Remover
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" flex={1} height={50} justifyContent="center" alignItems="center">
            <Pagination
              color="primary"
              count={totalPages}
              page={page}
              onChange={(_, currentPage) => {
                setPage(currentPage);
              }}
            />
          </Box>
        </TableContainer>
        <AlertDelete
          title="Tem certeza que deseja deletar essa campanha?"
          open={openModalDelete}
          close={() => setOpenModalDelete(false)}
          remove={handleRemoveCampaign}
        />
      </Box>
    </TemplateBase>
  );
}

export default Campanhas;
