import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const UploadImagensStyle = makeStyles(() => ({
  fileLabel: {
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer'
  },
  fileInput: {
    position: 'absolute',
    left: -9999,
    visibility: 'hidden'
  },
  paperImagem: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    overflow: 'hidden'
  },
  uploadBox: {
    border: '1px dashed #CCC',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconAdd: {
    fontSize: 45,
    color: '#CCC'
  },
  iconTrash: {
    color: 'red'
  },
  uploadBoxError: {
    border: '1px dashed red'
  },
  buttonDeleteThumb: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    cursor: 'pointer'
  }
}));

export default UploadImagensStyle;
