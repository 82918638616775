import React, { useEffect, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import { TableHead } from '@mui/material';
import PedidoProdutoService from 'services/pedido-produto/PedidoProdutoService';
import PedidosService from 'services/pedidos/pedidosService';
import { Pedido } from 'interfaces';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';

function AssociaVendedorInternoCanal() {
  document.title = 'Ferramentas | Associar Vendedor Interno - Canal';
  const [pedido, setPedido] = useState<Pedido | null>(null);
  const [canais, setCanais] = useState<string[]>([]);
  const [syncText, setSyncText] = useState(false);
  const [textFieldValues, setTextFieldValues] = useState<
    { index: number; codigo_vendedora: string; canal_venda: string; codpedidoproduto: number }[]
  >([]);
  const [disabled, setDisabled] = useState(false);
  const [codpedido, setCodPedido] = useState('');
  const productsLength = pedido?.produtos.length;

  const { setLoading } = useLoading();

  /**
   * Controla o checkbox para replicar primeiro captador
   */
  const handleCheckboxChange = () => {
    setSyncText(!syncText);
  };

  /**
   * Assinala o valor do primeiro input para o resto dos inputs
   */
  const assignValuesFromFirst = (
    codigo_vendedora = textFieldValues[0]?.codigo_vendedora || '',
    canal_venda = textFieldValues[0]?.canal_venda || canais[0]
  ) => {
    if (pedido) {
      try {
        setTextFieldValues(
          Array(productsLength)
            .fill(0)
            .map((_, index) => ({
              index,
              codigo_vendedora,
              canal_venda,
              codpedidoproduto: pedido.produtos[index].codpedidoproduto
            }))
        );
      } catch (error) {
        throw new Error(`Error on assignValuesFromFirst:${error}`);
      }
    }
  };

  /**
   * Atribui a todos os campo valores vazios, exceto ao primeiro
   */
  const resetValuesExceptFirst = () => {
    setTextFieldValues(prevValues => {
      return prevValues.map((_, index) => {
        if (index !== 0) {
          return {
            index,
            codigo_vendedora: '',
            canal_venda: canais[0],
            codpedidoproduto: prevValues[index].codpedidoproduto
          };
        }

        return _;
      });
    });
  };

  /**
   * Apenas para abstrair o controle dos inputs e selects
   */
  const handleChangeTextAndSelect = (
    textValue: string,
    selectValue: string,
    index: number,
    codpedidoproduto: number
  ) => {
    if (syncText) {
      assignValuesFromFirst(textValue, selectValue);
    } else {
      setTextFieldValues(prevValue => {
        const newValue = [...prevValue];

        newValue[index] = {
          ...newValue[index],
          codigo_vendedora: textValue,
          canal_venda: selectValue,
          codpedidoproduto
        };

        return newValue;
      });
    }
  };

  /**
   * Controla o onChange dos inputs
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, codpedidoproduto: number) => {
    const { value } = event.target;

    handleChangeTextAndSelect(value, textFieldValues[index]?.canal_venda || canais[0], index, codpedidoproduto);
  };

  /**
   * Controla o onChange dos selects
   */
  const handleChangeSelect = (event: SelectChangeEvent<string>, index: number, codpedidoproduto: number) => {
    const { value } = event.target;

    handleChangeTextAndSelect(textFieldValues[index]?.codigo_vendedora, value, index, codpedidoproduto);
  };

  /**
   * Busca canais de venda
   */
  const fetchCanaisVenda = () => {
    PedidoProdutoService.canais_venda().then(response => {
      setCanais(response.data.data.canais[0]);
    });
  };

  /**
   * Busca pedido
   */
  const fetchPedido = () => {
    setLoading(true);
    PedidosService.buscar_pedido(codpedido)
      .then(response => {
        setPedido(response.data);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Controla o onChange do input codpedido
   */
  const handleCodPedido = (event: React.ChangeEvent<HTMLInputElement>) => setCodPedido(event.target.value);

  /**
   * Manda array com codigo do vendedor interno para associar ao pedido
   */
  const handleAssociarVendedor = () => {
    const pedidoproduto = textFieldValues.map(value => ({
      codpedidoproduto: value.codpedidoproduto,
      codigo_vendedora: value.codigo_vendedora,
      canal_venda: value.canal_venda
    }));

    setLoading(true);

    PedidoProdutoService.associar_vendedor(pedidoproduto)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Vendedores associados!'
        }).then(() => {
          doRefresh();
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Zera todo o state do componente
   */
  const doRefresh = () => {
    setPedido(null);
    setCodPedido('');
    setSyncText(false);
    setTextFieldValues([]);
  };

  useEffect(() => {
    if (pedido?.produtos && canais) {
      setTextFieldValues(
        pedido?.produtos.map((item, index) => ({
          index,
          codpedidoproduto: item.codpedidoproduto,
          codigo_vendedora: item.vendedor_interno?.codigo || '',
          canal_venda: item.canal_venda || canais[0]
        }))
      );
    }
  }, [pedido, canais]);

  useEffect(() => {
    if (syncText) {
      assignValuesFromFirst();
    } else {
      resetValuesExceptFirst();
    }
    setDisabled(syncText);
  }, [syncText]); // eslint-disable-line

  useEffect(() => {
    fetchCanaisVenda();
  }, []);

  return (
    <TemplateBase>
      <Paper elevation={1}>
        <Card>
          <CardContent>
            <h3>Pesquisar Pedido</h3>
            <Box>
              <TextField label="ID do pedido" value={codpedido} size="small" onChange={handleCodPedido} />
              <Button variant="contained" sx={{ ml: 2 }} onClick={fetchPedido}>
                Pesquisar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      {pedido && (
        <Box>
          <h2>Pedido #{pedido.codigo}</h2>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell colSpan={2}>
                  <Checkbox checked={syncText} onChange={handleCheckboxChange} sx={{ ml: '-11px' }} />
                  replicar captador a todos os produtos deste pedido
                </TableCell>
              </TableRow>
            </TableHead>
            {pedido?.produtos.map((item, index) => (
              <TableRow key={item.codpedidoproduto}>
                <TableCell>
                  <Avatar src={item.imagens[0]?.thumbnail} alt="" />
                </TableCell>
                <TableCell>{item.codigo}</TableCell>
                <TableCell>{item.nome}</TableCell>
                <TableCell sx={{ width: { lg: 200, md: 130 } }}>
                  <TextField
                    disabled={index > 0 ? disabled : false}
                    value={textFieldValues[index]?.codigo_vendedora}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(event, index, item.codpedidoproduto)
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{ width: { lg: 300, md: 200 } }}>
                  <Select
                    native
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={index > 0 ? disabled : false}
                    value={textFieldValues[index]?.canal_venda}
                    onChange={(event: SelectChangeEvent<string>) =>
                      handleChangeSelect(event, index, item.codpedidoproduto)
                    }
                  >
                    {canais?.map(canal => (
                      <option key={canal} value={canal}>
                        {canal}
                      </option>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </Table>

          <Box sx={{ py: 2 }}>
            <Button onClick={handleAssociarVendedor} variant="contained">
              Associar
            </Button>
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default AssociaVendedorInternoCanal;
