import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const EditarDataAgendamentoStyle = makeStyles(() => ({
  modalContent: {
    minWidth: '500px'
  },
  inputDate: {
    width: '100%'
  },
  buttonEditInfo: {
    background: 'transparent',
    color: theme.palette.primary.main,
    border: 0,
    cursor: 'pointer'
  }
}));

export default EditarDataAgendamentoStyle;
