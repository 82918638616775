import React from 'react';
import { Medal, PercentSheet, WarningBell, VipStar, Partner } from 'components/icons';
import { Cluster } from 'interfaces';
import { CustomBadge, CustomBadgeProps } from '.';

const withIcon = (type: string) => {
  switch (type) {
    case 'vip':
      return <VipStar fontSize="small" />;

    case 'iguatemi-gold':
    case 'iguatemi-silver':
    case 'iguatemi-black':
      return <Medal fontSize="small" />;

    case 'compliance':
      return <WarningBell fontSize="small" />;

    case 'parceiro':
      return <Partner fontSize="small" />;

    case 'comissao-diferenciada-1':
    case 'comissao-diferenciada-2':
      return <PercentSheet fontSize="small" />;

    default:
      return null;
  }
};

const mapperCustomBadgeProps = (cluster: Cluster): CustomBadgeProps => ({
  bgcolor: cluster.backoffice_estilo.bg_color,
  color: cluster.backoffice_estilo.text_color,
  icon: withIcon(cluster.identificador),
  text: cluster.backoffice_label,
  bold: cluster.identificador === 'compliance',
  nospace: false
});

export function BadgeClusters(props: { cluster: Cluster }) {
  const { cluster } = props;
  const customBadgeProps = mapperCustomBadgeProps(cluster);

  return <CustomBadge {...customBadgeProps} />;
}
