import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Card, CardContent, Grid, Typography, Button, CardActionArea, Avatar, Badge, Chip, Paper } from '@mui/material';
import { PedidoProduto, ProdutosAprovados } from 'interfaces';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StarIcon from '@mui/icons-material/Star';
import Lightbox from 'react-image-lightbox';
import { format } from 'date-fns';
import LocalizacaoProdutoService from 'services/localizacao/LocalizacaoProdutoService';
import { MarcarProdutosLocalizadosContext } from '.';
import LocalizarProdutosStyle from './style';

interface Props {
  item: PedidoProduto;
}

const customModalStyles = {
  overlay: {
    zIndex: 9999
  }
};

function Produto(props: Props) {
  const { item } = props;
  const classes = LocalizarProdutosStyle();

  const [timer, setTimer] = useState<number>(3);
  const [timerRender, setTimerRender] = useState<boolean>(false);
  const [marcado, setMarcado] = useState<boolean>(false);
  /* eslint-disable-next-line */
  const [timerInterval, setTimerInterval] = useState<ReturnType<typeof setInterval>>();
  const { setPedidosNaoLocalizados, setPedidosLocalizados, setMeta, getFiltersFromLocalStorage, valueTab } = useContext(
    MarcarProdutosLocalizadosContext
  );

  let filterStored: { prioridade?: number; codcategoriaprincipal?: number };

  if (getFiltersFromLocalStorage()) {
    filterStored = getFiltersFromLocalStorage();
  }

  const [imagesModal, setImagesModal] = useState<{
    url: string;
    index: number;
    show: boolean;
  }>({
    url: '',
    index: 0,
    show: false
  });

  const produtoLocalizado = Boolean(valueTab);

  const undoCounter = useCallback(
    (left = timer) => {
      return new Promise(resolve => {
        let tleft = left;
        const interval = setInterval(() => {
          tleft -= 1;

          if (tleft === 0) {
            resolve(tleft);
            clearInterval(interval);
          }

          setTimer(oldValue => {
            const t = oldValue - 1;
            return t;
          });
        }, 1000);

        setTimerInterval(interval);
      });
    },
    [timer]
  );

  const clearCount = useCallback(() => {
    if (timerInterval) clearInterval(timerInterval);

    setTimerRender(false);
    setTimer(3);
  }, [timerInterval]);

  const controlesCardProduto = (() => {
    let timeout: ReturnType<typeof setTimeout>;

    const marcarProduto = () => {
      tornaProdutoLocalizado(item.codpedidoproduto, data => {
        setTimerRender(true);
        setMarcado(true);

        undoCounter().then(() => {
          setTimerRender(false);
          setPedidosNaoLocalizados(data.data);
          setMeta(data.meta);
        });
      });
    };

    const desmarcarProduto = () => {
      removeLocalizacaoProduto(item.codpedidoproduto, data => {
        setTimerRender(true);
        setMarcado(false);

        undoCounter().then(() => {
          setTimerRender(false);
          setPedidosLocalizados(data.data);
          setMeta(data.meta);
        });
      });
    };

    const controlaPressaoCard = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.type === 'mousedown') {
        if (!marcado) {
          timeout = setTimeout(marcarProduto, 700);
        } else {
          timeout = setTimeout(desmarcarProduto, 700);
        }
      } else {
        clearTimeout(timeout);
      }
    };

    return {
      controlaPressaoCard
    };
  })();

  const handleClickUndo = () => {
    clearCount();
    setMarcado(!marcado);

    if (marcado) {
      removeLocalizacaoProduto(item.codpedidoproduto);
    } else {
      tornaProdutoLocalizado(item.codpedidoproduto);
    }
  };

  const tornaProdutoLocalizado = (codpedidoproduto: number, cb?: (params: ProdutosAprovados) => void) => {
    LocalizacaoProdutoService.marcar_localizado(
      codpedidoproduto,
      filterStored?.prioridade,
      filterStored?.codcategoriaprincipal || undefined
    ).then(({ data }) => {
      if (cb) cb(data);
    });
  };

  const removeLocalizacaoProduto = (codpedidoproduto: number, cb?: (params: ProdutosAprovados) => void) => {
    LocalizacaoProdutoService.desfazer_localizado(
      codpedidoproduto,
      filterStored?.prioridade,
      filterStored?.codcategoriaprincipal || undefined
    ).then(({ data }) => {
      if (cb) cb(data);
    });
  };

  const { controlaPressaoCard } = controlesCardProduto;

  const handlePular = (codpedidoproduto: number) => {
    LocalizacaoProdutoService.pular(
      codpedidoproduto,
      filterStored?.prioridade,
      filterStored?.codcategoriaprincipal || undefined
    ).then(({ data }) => {
      setPedidosNaoLocalizados(data.data);
      setMeta(data.meta);
    });
  };

  useEffect(() => {
    if (produtoLocalizado) {
      setMarcado(true);
    }
  }, [produtoLocalizado]);

  return (
    <>
      {imagesModal.show && (
        <Lightbox
          mainSrc={item.imagens[imagesModal.index].large}
          onCloseRequest={() =>
            setImagesModal({
              url: '',
              index: 0,
              show: false
            })
          }
          reactModalStyle={customModalStyles}
          nextSrc={item.imagens[(imagesModal.index + 1) % item.imagens.length].large}
          prevSrc={item.imagens[(imagesModal.index + item.imagens.length - 1) % item.imagens.length].large}
          onMoveNextRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + 1) % item.imagens.length
            })
          }
          onMovePrevRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + item.imagens.length - 1) % item.imagens.length
            })
          }
        />
      )}
      <Grid item xs={12} key={item.codpedido}>
        <Card className={classes.card} onMouseDown={controlaPressaoCard} onMouseUp={controlaPressaoCard}>
          <CardActionArea>
            <CardContent>
              <Grid container direction="row" flexWrap="nowrap" alignItems="center" spacing={2}>
                <Grid item>
                  <Paper elevation={2}>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      badgeContent={item.prioridade ? <StarIcon className={classes.starIcon} /> : null}
                    >
                      <Avatar
                        variant="square"
                        onClick={() =>
                          setImagesModal({
                            url: item?.imagens[0]?.small,
                            index: 0,
                            show: true
                          })
                        }
                        className={classes.avatar}
                        src={item?.imagens[0]?.small}
                      />
                    </Badge>
                  </Paper>
                </Grid>
                <Grid item>
                  <div>
                    <Chip
                      color="primary"
                      icon={marcado ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                      label={marcado ? 'Produto localizado' : 'Produto não localizado'}
                      className={classes.chip}
                    />
                    {timerRender && (
                      <>
                        <span> | </span>
                        <Button size="small" className={classes.undoButton} onClick={handleClickUndo}>
                          Desfazer {timer}
                        </Button>
                      </>
                    )}
                  </div>
                  <div className={classes.wrapperInfos}>
                    <Typography className={`${classes.textInfos} ${classes.textInfosTitle}`}>
                      <strong>{item.produto}</strong>
                    </Typography>
                    <Typography className={`${classes.textInfos} ${classes.textInfosSmall}`}>
                      <strong>Marca:</strong> {item.marca}
                    </Typography>
                    <Typography className={`${classes.textInfos} ${classes.textInfosSmall}`}>
                      <strong>Codigo:</strong> {item.codigo}
                    </Typography>
                    <Typography className={`${classes.textInfos} ${classes.textInfosSmall}`}>
                      <strong>Criado:</strong> {format(new Date(item.criacao), 'dd/MM/yyyy')}
                    </Typography>

                    {item.localizacao && (
                      <Typography className={`${classes.textInfos} ${classes.textInfosSmall}`}>
                        <strong>Localização:</strong> <span>{item.localizacao}</span>
                      </Typography>
                    )}
                  </div>
                  <Button className={classes.verLogs} size="small" color="primary">
                    Ver Logs
                  </Button>
                  {!produtoLocalizado && (
                    <Button
                      className={classes.verLogs}
                      size="small"
                      color="primary"
                      onClick={() => handlePular(item.codpedidoproduto)}
                    >
                      Pular
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
}

export default Produto;
