import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const agendamentoStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  colCheckbox: {
    width: '1rem'
  },
  mergeBtn: {
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto'
    }
  }
}));

export default agendamentoStyle;
