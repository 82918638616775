import React, { useState, useCallback, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import TemplateBase from 'components/template-base/template';
import Pagination from '@mui/material/Pagination';

import { QueroVenderBatch, ApiListMeta } from 'interfaces';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import useDebounce from 'hooks/debounce';

import WarningIcon from '@mui/icons-material/Warning';

import avaliacaoFisicaNegociacaoStyle from './style';
import BatchRow from './batchRow';

function AvaliacaoFisicaNegociacao() {
  document.title = `Avaliação | Negociação de Avaliação Física`;

  const style = avaliacaoFisicaNegociacaoStyle();

  const [buscaNome, setBuscaNome] = useState('');

  const [batches, setBatches] = useState<QueroVenderBatch[]>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  // const { channel } = usePusher();
  // const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  const [isPagination, setIsPagination] = useState(false);

  const nomeCliente = useDebounce(buscaNome, 500);

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Busca os batches em negociação
   */

  const fetchBatches = useCallback(() => {
    const perPage = 15;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}`; // eslint-disable-line

    if (!isPagination) setPage(1);

    setLoading(true);

    QueroVenderService.list('22', queryMeta).then(response => {
      if (response) {
        setBatches(response?.data.data);
        setMeta(response?.data.meta);
      } else {
        // setBatch([]);
      }
      setLoading(false);
    });
  }, [isPagination, nomeCliente, page]);

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Controla disparo do enter para localizar produto por bip
   */

  // const handleLocalizarPorCodigoBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     const el = document.getElementById('codigoProduto') as HTMLInputElement;

  //     if (el) {
  // QueroVenderItemService.bip(el.value)
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(e => {
  //     if (e.response.status === 404) {
  //       Swal.fire('Oops', 'Código inválido', 'error').then(() => {
  //         el.value = '';
  //       });
  //     }
  //     if (e.response.status === 400) {
  //       Swal.fire('Oops', 'Produto não encontrado', 'error').then(() => {
  //         el.value = '';
  //       });
  //     }
  //   });
  //     }
  //   }
  // };

  /**
   * Handler paginação - Modifica página ativa
   */
  // const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  //   setIsPagination(true);
  // };

  useEffect(() => {
    fetchBatches();
  }, [fetchBatches]);

  return (
    <TemplateBase>
      <h1>Negociação de Avaliação Física</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <h3>Identificar produto</h3>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  name="nomeremessa"
                  label="Buscar Vendedor"
                  placeholder="Buscar Vendedor"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={buscaNome}
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {loading && <Box>Carregando ...</Box>}
      {!loading && batches.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Vip?</TableCell>
                  <TableCell>Vendedor</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Produtos</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {batches && batches.map(batch => <BatchRow key={batch.codquerovender} batch={batch} />)}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}

      {!loading && batches.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma Remessa encontrada
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default AvaliacaoFisicaNegociacao;
