import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import devolucaoStyles from './styles';

function CardSkeleton() {
  const styles = devolucaoStyles();
  return (
    <Card className={styles.cardSpace}>
      <Box padding={2} fontSize={26} fontWeight={600}>
        <Skeleton variant="rectangular" width={210} height={30} />
        <Box mt={2}>
          <Skeleton variant="rectangular" width={175} height={18} />
        </Box>
        <Box mt={2}>
          <Skeleton variant="rectangular" width="100%" height={73} />
        </Box>
        <Box mt={2}>
          <Skeleton variant="rectangular" width="100%" height={73} />
        </Box>
        <Box mt={2}>
          <Skeleton variant="rectangular" width="100%" height={73} />
        </Box>
      </Box>
    </Card>
  );
}

export default CardSkeleton;
