import React from 'react';
import { Button, Grid, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import Alert from '@mui/material/Alert';
import { MergeType } from '@mui/icons-material';

import { QueroVenderBatch } from 'interfaces';
import Pluralize from 'components/utils/pluralize';
import useMergeBatchs from '../../pages/avaliacao/hooks/merge-batchs';

import alertMergeStyle from './style';

interface PropsAlertMerge {
  batch: QueroVenderBatch;
  loading(show: boolean): void;
  refresh: number;
  setRefresh(show: number): void;
  exibirQuantidade: 'aceitos' | 'total';
}

function AlertMerge(props: PropsAlertMerge) {
  const style = alertMergeStyle();

  const { batch, loading, refresh, setRefresh, exibirQuantidade } = props;

  const setMergeBatchs = useMergeBatchs();

  /**
   * Merge das Batchs do mesmo vendedor
   */
  const handleMergeBatchs = () => {
    const dataBatchs = {
      codquerovender: batch.codquerovender,
      codBatchs: batch.relacionados.map(item => item.codquerovender),
      cbSuccess: () => {
        setRefresh(refresh + 1);
      },
      cbFinally: () => {
        loading(false);
      }
    };

    loading(true);
    setMergeBatchs(dataBatchs);
  };

  const exibirRelacionados = (itens: 'aceitos' | 'total') => {
    return batch?.relacionados.map(item => (
      <Grid item lg={3} md={4} key={item.codquerovender}>
        <ListItem component="div" className={style.listItemBatchsRelacionadas}>
          <ListItemIcon className={style.iconMergeBatchs}>
            <MergeType />
          </ListItemIcon>
          <ListItemText
            primary={`Remessa #${item.codquerovender} - ${
              item.count[itens] > 1 ? `${item.count[itens]} produtos` : `${item.count[itens]} produto`
            }`}
            secondary={item.volume?.descricao}
          />
        </ListItem>
      </Grid>
    ));
  };

  return (
    <>
      <Alert
        severity="warning"
        action={
          <Button color="primary" size="small" variant="contained" onClick={handleMergeBatchs}>
            Unir remessas
          </Button>
        }
      >
        <Pluralize
          quantidade={batch?.relacionados.length}
          singular="Existe outra remessa"
          plural={`Existem outras ${batch?.relacionados.length} remessas`}
        />
        {` do mesmo vendedor para avaliacão. Gostaria de unir essas remessas?`}
      </Alert>

      <Grid container spacing={2} className={style.listBatchsRelacionadas}>
        {exibirQuantidade === 'aceitos' && exibirRelacionados('aceitos')}
        {exibirQuantidade === 'total' && exibirRelacionados('total')}
      </Grid>
    </>
  );
}

export default AlertMerge;
