import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

const formFieldsGrid = makeStyles(() => ({
  inputsReadOnly: {
    '& input': {
      color: grey[500]
    }
  }
}));

export default formFieldsGrid;
