import React, { ChangeEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import TextField from '@mui/material/TextField';
import style from './style';

interface Props {
  codquerovenderitem: number;
  onClose(): void;
  doRefresh(): void;
  setExpanded(value: boolean): void;
}

export function ModalRecusarNegociacao(props: Props) {
  const { codquerovenderitem, onClose, doRefresh, setExpanded } = props;
  const [motivo, setMotivo] = useState<string>('');

  const classes = style();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMotivo(event.target.value);
  };

  const handleRecusarNegociacao = () => {
    const obj = { acao: 'recusar', motivo };

    QueroVenderItemService.sugestaoValor(codquerovenderitem, obj)
      .then(() => {
        doRefresh();
        setExpanded(false);
        onClose();
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Escreva um motivo para a recusa</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.modalRecusa}
          label="Motivo"
          value={motivo}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={!motivo.length}
          onClick={handleRecusarNegociacao}
          color="primary"
          variant="contained"
          autoFocus
        >
          Recusar Negociação
        </Button>
      </DialogActions>
    </Dialog>
  );
}
