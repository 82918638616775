import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CalculadoraValores from 'components/calculadora-de-valores';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { CampoCategorias } from 'interfaces';

export function CalculoValores() {
  const { register } = useFormContext();

  const { item, temComissionada, codloja, campoCategoria, batchComissionamentosDiferenciados, setIsRequesting } =
    useFormAvaliacao();

  const categoria = useMemo<CampoCategorias | null>(() => campoCategoria, [campoCategoria]);

  const valorLoja = useMemo(() => {
    if (item?.produto?.precificacao.loja) {
      return `${item?.produto?.precificacao.loja}`;
    }
    if (item?.valor_loja) {
      return `${item?.valor_loja}`;
    }

    return '';
  }, [item]);

  const handleIsCalculating = (value: boolean) => {
    setIsRequesting(value);
  };

  return (
    <Box my={3}>
      <Grid item xs={12}>
        <CalculadoraValores
          negociacaoAtual={item?.negociacao_atual}
          categoria={categoria}
          codloja={codloja}
          captadora={temComissionada}
          comissionamentoDiferenciado={batchComissionamentosDiferenciados}
          disabled={!categoria?.codcategoria}
          register={register}
          codquerovenderitem={item?.codquerovenderitem}
          hideValorParcelado
          itemValorLoja={valorLoja}
          showPrecoLoja
          isCalculating={handleIsCalculating}
        />
      </Grid>
    </Box>
  );
}
