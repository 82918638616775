import makeStyles from '@mui/styles/makeStyles';
import { indigo } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const EntradaManual = makeStyles(() => ({
  wrapForm: {
    padding: theme.spacing(2)
  },
  boxField: {
    // padding: 10
  },
  labelField: {
    display: 'block',
    marginBottom: 1,
    fontWeight: 'bold'
  },
  titles: {
    fontSize: '0.85rem'
  },
  select: {
    maxWidth: 240,

    '& .MuiSelect-select': {
      padding: '7px 13px'
    }
  },
  tableHead: {
    backgroundColor: indigo[500]
  },
  tableCell: {
    color: 'white'
  },
  sacolaIdendtify: {
    width: 20,
    height: 27,
    background: '#000',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 5
  }
}));

export default EntradaManual;
