import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LoadingProvider } from 'context/loading';
import { GlobalNotificationProvider } from 'context/global-notification';
import AppProvider from 'context';
import Routes from 'routes';
import api from 'services/api';
import theme from './styles/global';

function App() {
  useEffect(() => {
    (async function getUser() {
      const page = window.location.pathname;

      if (page === '/' || page === '/login') return;

      const responseUser = await api.get('me');

      if (responseUser) {
        localStorage.setItem('EUBackoffice:user', JSON.stringify(responseUser.data));
      }
    })();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter basename={`${process.env.REACT_APP_SUBFOLDER}`}>
        <AppProvider>
          <GlobalNotificationProvider>
            <LoadingProvider>
              <Routes />
              <ThemeProvider theme={theme}>
                <CssBaseline />
              </ThemeProvider>
            </LoadingProvider>
          </GlobalNotificationProvider>
        </AppProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
