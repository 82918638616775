import api from '../api';

const canais_venda = () => {
  return api.get('pedido-produto/canais-venda');
};
const associar_vendedor = (body: { codpedidoproduto: number; codigo_vendedora: string; canal_venda: string }[]) => {
  return api.patch('associacao-vendedora', { pedidoproduto: body });
};

export default { canais_venda, associar_vendedor };
