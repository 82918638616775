import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AcessoTemporarioService from 'services/ferramentas/AcessoTemporarioService';
import { useLoading } from 'context/loading';

export function InserirEmail({
  setStep,
  setEmail,
  setPassword
}: {
  setStep: (step: number) => void;
  setEmail: (email: string) => void;
  setPassword: (email: string) => void;
}) {
  const { setLoading } = useLoading();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    AcessoTemporarioService.gerar(data.get('email') as string)
      .then(response => {
        setStep(2);
        setPassword(response.data.senha_temporaria);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="E-mail do usuário"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Criar Acesso
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
