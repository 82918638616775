import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';

import { FormAvaliacaoProvider } from 'context/form-avaliacao';
import CardContent from '@mui/material/CardContent';
import { QueroVenderItem } from 'interfaces';
import { ModalRejeitarItem } from 'components/modal-rejeitar-item';
import ModalBipPedido from 'components/modal-bip-pedido';
import AvatarProduto from 'components/avaliacao-fisica/avatar-produto';

import { ItensInclusos } from 'components/avaliacao-fisica/itens-inclusos';
import { Informacoes } from 'components/avaliacao-fisica/informacoes';
import { InformacoesProduto } from 'components/avaliacao-fisica/informacoes-produto';
import { InformacoesExatas } from 'components/avaliacao-fisica/informacoes-exatas';
import { CalculoValores } from 'components/avaliacao-fisica/calculo-valores';
import { Observacoes } from 'components/avaliacao-fisica/observacoes';
import { Status } from 'components/avaliacao-fisica/status';
import { RodapeProdutoIndividual } from 'components/avaliacao-fisica/rodape';
import styles from 'components/avaliacao-fisica/styles';
import { ValorPreAvaliacao } from 'components/avaliacao-fisica/valor-pre-avaliacao';
import { ProdutoBonus } from 'components/avaliacao-fisica/produto-bonus';
import { useStatusInterface } from '../hooks/status-interface';
import { PublicacaoModo } from '../../../components/avaliacao-fisica/publicacao-modo';

interface Props {
  item?: QueroVenderItem;
  codQueroVender: number | undefined;
  temComissionada: boolean;
  isProduto?: boolean;
  codloja?: number;
}

function AvaliacaoFisica(props: Props) {
  const {
    item = {} as QueroVenderItem,
    temComissionada,
    codQueroVender,
    isProduto = false,
    codloja = undefined
  } = props;
  const [modalRejeitarItem, setModalRejeitarItem] = useState<boolean>(false);
  const [modalBipPedidoAceito, setModalBipPedidoAceito] = useState<boolean>(false);
  const [modalBipPedidoRejeitado, setModalBipPedidoRejeitado] = useState<boolean>(false);

  const classes = styles();

  const { statusText, statusColor } = useStatusInterface(1);

  /**
   * Abre modal Bip Pedido rejeitado
   */

  const openModalBipPedidoRejeidado = () => {
    setModalBipPedidoRejeitado(true);
  };

  /**
   * Abre modal Bip Pedido aceito
   */

  const openModalBipPedidoAceito = () => {
    setModalBipPedidoAceito(true);
  };

  /**
   * Abre modal de Rejeitar Item
   */

  const openModalRejeitarItem = () => {
    setModalRejeitarItem(true);
  };

  /**
   * Fecha modal de Rejeitar Item
   */

  const closeModalRejeitarItem = () => {
    setModalRejeitarItem(false);
  };

  const FormAvaliacaoProviderProps = {
    item,
    isProduto,
    temComissionada,
    codQueroVender,
    codloja,
    tipoAvaliacao: 'produto_individual'
  };

  return (
    <FormAvaliacaoProvider {...FormAvaliacaoProviderProps}>
      {item?.codquerovenderitem && modalRejeitarItem && (
        <ModalRejeitarItem
          codquerovenderitem={item.codquerovenderitem}
          onClose={closeModalRejeitarItem}
          openModalBipPedidoRejeidado={openModalBipPedidoRejeidado}
        />
      )}
      {modalBipPedidoAceito && (
        <ModalBipPedido
          title="Produto aceito"
          codproduto={item.produto?.codproduto as number}
          onClose={() => setModalBipPedidoAceito(false)}
        />
      )}
      {modalBipPedidoRejeitado && (
        <ModalBipPedido
          title="Produto rejeitado"
          codproduto={item.produto?.codproduto as number}
          onClose={() => setModalBipPedidoRejeitado(false)}
        />
      )}

      <Paper>
        <ProdutoBonus />
      </Paper>
      <Card>
        <CardContent className={classes.cardContent}>
          <AvatarProduto color={statusColor} text={statusText} imagem={item?.produto?.imagens?.[0]?.thumbnail} />
          <Informacoes />
          <CalculoValores />
          <ValorPreAvaliacao />
          <ItensInclusos />
          <Status />
          <InformacoesProduto />
          <InformacoesExatas />
          <Observacoes />
          <PublicacaoModo />
        </CardContent>
        <CardActions className={classes.actions}>
          <RodapeProdutoIndividual
            openModalRejeitarItem={openModalRejeitarItem}
            openModalBipPedidoAceito={openModalBipPedidoAceito}
          />
        </CardActions>
      </Card>
    </FormAvaliacaoProvider>
  );
}

export default AvaliacaoFisica;
