import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { FiltroStateBody } from 'context/filtros';
import { FiltrosVariacao } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import { RadioHookForms } from 'components/form/text-field-hook-forms';
import styles from '../styles';

type Props = {
  apiFiltroVariacoes: FiltroStateBody['variacoes'];
  produtoFiltroVariacoes?: FiltrosVariacao[];
  name: string | undefined;
  defaultValue: string;
};

export function FiltroRadio(props: Props) {
  const classes = styles();
  const { disableForm, campoCategoria } = useFormAvaliacao();
  const { register } = useFormContext();
  const { apiFiltroVariacoes, produtoFiltroVariacoes = [], name, defaultValue } = props;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  if (apiFiltroVariacoes && apiFiltroVariacoes.length > 0 && name) {
    return (
      <FormControl variant="standard" component="div" className={classes.checkBox}>
        <RadioGroup name={name} row value={value} onChange={handleChange}>
          {apiFiltroVariacoes.map(variacao => (
            <FormControlLabel
              key={`${variacao.slug}_from_${campoCategoria?.url}`}
              className={classes.checkBox}
              control={<RadioHookForms disabled={disableForm} {...register(name)} color="primary" />}
              label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
              value={variacao.slug}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (produtoFiltroVariacoes?.length === 0) return <div>-</div>;

  return name ? (
    <FormControl variant="standard" component="div" className={classes.checkBox}>
      <RadioGroup name={name} row>
        {produtoFiltroVariacoes?.map(variacao => (
          <FormControlLabel
            key={`${variacao.slug}_from_${campoCategoria?.url}`}
            className={classes.checkBox}
            control={<RadioHookForms disabled={disableForm} {...register(name)} checked color="primary" />}
            label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  ) : null;
}
