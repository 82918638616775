type Params = {
  text: string;
  cbSuccess: () => void;
  cbError: (err: any) => void; // eslint-disable-line
};

export async function copyToClipboard(params: Params) {
  const { text, cbSuccess, cbError } = params;

  try {
    await navigator.clipboard.writeText(text);
    cbSuccess?.();
  } catch (err) {
    cbError?.(err);
  }
}
