import React, { createContext, useState, useContext, useMemo } from 'react';
import Loading from 'components/loading';

type LoadingData = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadingContext = createContext<LoadingData>({} as LoadingData);

export function LoadingProvider({ children }: { children: React.ReactElement | React.ReactElement[] }) {
  const [loading, setLoading] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      loading,
      setLoading
    }),
    [loading, setLoading]
  );

  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Loading open={loading} />
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading deve usado dentro de um LoadingProvider');
  }

  return context;
}
