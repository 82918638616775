import api from '../api';

const categorias = (q: string, isNivel1 = false) => {
  if (q?.length >= 3) {
    return api.get(`categorias?${isNivel1 ? 'nivel=1' : ''}&q=${encodeURIComponent(q)}`);
  }

  return null;
};

const marcas = (q: string) => {
  if (q?.length >= 3) {
    return api.get(`marcas?q=${encodeURIComponent(q)}`);
  }
  return null;
};

const modelos = ({ nome, codmarca, codcategoria }: { nome?: string; codmarca: number; codcategoria: number }) => {
  const query = [];

  if (nome && nome.length >= 3) {
    query.push(`nome=${nome}`);
  }

  if (codmarca) {
    query.push(`${`codmarca=${codmarca}`}`);
  }

  if (codcategoria) {
    query.push(`${`codcategoria=${codcategoria}`}`);
  }

  return api.get(`modelos?${query.join('&')}&codcategoria_usar_raiz=1`);
};

export default {
  categorias,
  marcas,
  modelos
};
