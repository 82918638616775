import React, { useEffect, useMemo, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FiltrosVariacao } from 'interfaces';
import { UseFormRegister } from 'react-hook-form';
import { CheckboxHookForms } from 'components/form/text-field-hook-forms';
import CheckboxGeneroStyles from './styles';

type Props = {
  variacoes: Partial<FiltrosVariacao>[] | undefined;
  name: string;
  label: string;
  slug: string;
  disabled?: boolean;
  register?:UseFormRegister<any> | null; // eslint-disable-line
  defaultValue: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
};

export function CheckboxGenero(props: Props) {
  const styles = CheckboxGeneroStyles();
  const { variacoes, name, label, slug, disabled, register, defaultValue, onChange } = props;

  const [checkboxValue, setCheckboxValue] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setCheckboxValue(checked);
    if (onChange) {
      onChange(e, checked);
    }
  };

  const isChecked = useMemo(() => {
    return variacoes?.some(variacao => variacao?.slug === slug);
  }, [slug, variacoes]);

  useEffect(() => {
    if (isChecked) {
      setCheckboxValue(isChecked);
    }
  }, [isChecked]);

  return (
    <FormControlLabel
      className={styles.checkBox}
      control={
        <CheckboxHookForms
          value={defaultValue}
          onChange={handleChange}
          defaultChecked={isChecked}
          {...register?.(name)}
          color="primary"
          checked={checkboxValue}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
}

CheckboxGenero.defaultProps = {
  disabled: false,
  register: null,
  onChange: null
};
