import React, { useEffect, useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';

import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';

import LifecycleService from 'services/lifecycle/lifecycle';
import useDebounce from 'hooks/debounce';
import { LifecycleProduto, ApiListMeta } from 'interfaces';

import LifecycleVoucherStyle from './style';

function LifecycleVoucher() {
  document.title = 'Lifecycle | Voucher';

  const style = LifecycleVoucherStyle();

  const [buscaProduto, setBuscaProduto] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(false);
  const [isPagination, setIsPagination] = useState(false);
  const [page, setPage] = useState(1);
  const nomeProduto = useDebounce(buscaProduto, 500);

  /**
   * Busca por nome
   */
  const handleBuscaProduto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaProduto(e.target.value);
  };

  /**
   * Resgata produtos
   */
  const getProdutos = useCallback(() => {
    const perPage = 30;
    const nome = nomeProduto ? `&q=${nomeProduto}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${meta?.per_page || perPage}${nome}`;

    if (!isPagination) setPage(1);

    setLoading(true);
    LifecycleService.list(queryMeta).then(response => {
      if (response) {
        setProdutos(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setProdutos([]);
      }
      setLoading(false);
    });
  }, [page, nomeProduto]); // eslint-disable-line

  useEffect(() => {
    getProdutos();
  }, [getProdutos]);

  /**
   * Dar baixa no Voucher
   */
  const handleVoucherEnviado = (codpedidoproduto: number) => {
    setLoading(true);
    LifecycleService.pay(codpedidoproduto).then(response => {
      if (response) {
        const removeProduto = produtos.filter((item: LifecycleProduto) => item.codpedidoproduto !== codpedidoproduto);

        Swal.fire('Voucher enviado com sucesso', '', 'success');
        setProdutos(removeProduto);
      }
      setLoading(false);
    });
  };

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  return (
    <>
      <TemplateBase>
        <h2>Voucher</h2>

        <Paper elevation={1} className={style.paperBusca}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={4}>
                  <h3>Localizar Produto</h3>
                  <TextField
                    name="nomeproduto"
                    label="Nome do produto"
                    placeholder="Nome do produto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleBuscaProduto}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        {loading && <Box>Carregando ...</Box>}
        {!loading && produtos?.length > 0 && (
          <>
            <Paper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>

                    <TableCell align="center">Valor Voucher</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produtos &&
                    produtos.map((produto: LifecycleProduto) => (
                      <TableRow key={produto.codpedidoproduto}>
                        <TableCell>{produto.nome}</TableCell>
                        <TableCell align="center">
                          {produto.valorvoucher.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => handleVoucherEnviado(produto.codpedidoproduto)}
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Voucher enviado
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>

            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
            </Box>
          </>
        )}
        {!loading && produtos?.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
            <WarningIcon fontSize="large" color="disabled" />
            <Box component="span" ml={2}>
              Nenhum produto encontrado
            </Box>
          </Box>
        )}
      </TemplateBase>

      <Loading open={loading} />
    </>
  );
}

export default LifecycleVoucher;
