import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Pluralize from 'components/utils/pluralize';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { QueroVenderBatch } from 'interfaces';
import ModalTableLogs from 'components/modal-table-logs';
import { ClientClusters } from 'components/client-clusters';
import { useNavigate } from 'react-router-dom';
import { BatchIconsStatus } from './batchIconStatus';
import { ItemRow } from './batchItemRow';

type Props = {
  batch: QueroVenderBatch;
};

function BatchRow(props: Props) {
  const [open, setOpen] = useState(false);
  const [openLogs, setOpenLogs] = useState<boolean>(false);

  const { batch } = props;

  const navigate = useNavigate();

  /* Controle abertura da modal de logs */
  const handleOpenLogs = () => setOpenLogs(true);

  // Controla fechamento de modal de logs
  const handleCloseModalOpenLogs = () => setOpenLogs(false);

  /* Controla abrir e fechar do collapse */
  const openItens = () => setOpen(!open);

  return (
    <>
      <TableRow>
        <TableCell>
          <ClientClusters cliente={batch.cliente} />
        </TableCell>
        <TableCell>{batch?.cliente?.nome}</TableCell>
        <TableCell>{batch?.cliente?.vendedora?.codigo}</TableCell>
        <TableCell>
          {batch.itens.length} <Pluralize quantidade={batch.itens.length} singular="produto" plural="produtos" />
          <IconButton aria-label="expand row" size="small" onClick={openItens}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(`/avaliacao/avaliacao-fisica-negociacao/${batch.codquerovender}`);
            }}
          >
            Avaliar
          </Button>
        </TableCell>
        <TableCell>{batch.itens.length > 0 && <BatchIconsStatus itens={batch.itens} />}</TableCell>
        <TableCell>
          <Button color="primary" onClick={handleOpenLogs}>
            Logs
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0, backgroundColor: 'rgb(245, 245, 245)' }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mt={2} marginBottom="-1px">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Itens</TableCell>
                    <TableCell>Avaliação</TableCell>

                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batch.itens && batch.itens.map(item => <ItemRow key={item.codquerovenderitem} {...item} />)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <ModalTableLogs
        logs={batch.logs}
        title={`Log do batch #${batch.codquerovender}`}
        openLogs={openLogs}
        handleCloseModal={handleCloseModalOpenLogs}
      />
    </>
  );
}

export default BatchRow;
