import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const TabPickingStyle = makeStyles(() => ({
  tab: {
    backgroundColor: theme.palette.background.paper
  }
}));

export default TabPickingStyle;
