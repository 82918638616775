import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TemplateBase from 'components/template-base/template';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { FiltrosProvider } from 'context/filtros';
import { QueroVenderItem } from 'interfaces';
import { withClusters, WithClustersProps } from 'components/with-clusters';
import { BadgeClusters } from 'components/custom-badge/badge-clusters';
import ModalAssociarComissionada from 'components/avaliacao-fisica/modal-associar-comissionada';
import { formatTextPhone } from 'helpers/format-text-phone';
import FormAvaliacaoFisica from './form-avaliacao';

import style from './style';

const PageContent = withClusters((props: WithClustersProps) => {
  document.title = 'Avaliação Física do Produto';

  const { fetchClustersVendedora, fetchClustersComissionada, clustersVendedora, clustersComissionada } = props;

  const classes = style();
  const { codquerovenderitem } = useParams() as { codquerovenderitem: string };
  const [modalAssociarComissionada, setModalAssociarComissionada] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<QueroVenderItem>({} as QueroVenderItem);

  /**
   * Busca de item
   */

  const fetchItem = async () => {
    await QueroVenderItemService.details(codquerovenderitem).then(response => {
      setItem(response.data.data);

      if (response.data.data?.produto?.vendedora)
        fetchClustersVendedora(response.data.data?.produto?.vendedora?.cliente.codcliente);
      if (response.data.data.batch.comissionada)
        fetchClustersComissionada(response.data.data?.batch?.comissionada?.codcliente);
    });
  };

  /**
   * Função callback chamada após associar   código vendedora/captadora
   */

  const handleAfterMatchSeller = () => {
    setModalAssociarComissionada(false);
    setLoading(true);

    fetchItem().then(() => {
      setLoading(false);
      setShowForm(true);
    });
  };

  /**
   * Abre modal para associar codigo vendedora/captadora
   */

  const openMatchSellerModal = () => {
    setModalAssociarComissionada(true);
  };

  /**
   * Fecha modal para associar codigo vendedora/captadora
   */

  const closeMatchSellerModal = () => {
    setModalAssociarComissionada(false);
  };

  useEffect(() => {
    setLoading(true);

    fetchItem().then(() => {
      setLoading(false);
      setShowForm(true);
    });
  }, []); //eslint-disable-line

  /**
   * Template
   */

  return (
    <TemplateBase>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          {modalAssociarComissionada && (
            <ModalAssociarComissionada
              afterMatchSeller={handleAfterMatchSeller}
              onCancel={closeMatchSellerModal}
              batch={item.batch}
            />
          )}
          {showForm && (
            <>
              <div className={classes.space}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h1>Avaliação Física</h1>
                    <h3>{item?.produto?.vendedora?.nome}</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <strong>Email:</strong> {item?.produto?.vendedora?.email}
                    </div>
                    <div>
                      <strong>Telefone: </strong> {formatTextPhone(item?.produto?.vendedora?.telefone1)}
                    </div>
                    <div>
                      <strong>Código do vendedor:</strong> {item?.produto?.vendedora?.codigo}
                      <button type="button" className={classes.btEditar} onClick={openMatchSellerModal}>
                        editar
                      </button>
                    </div>
                    <Box display="inline-flex" flexWrap="wrap">
                      {clustersVendedora.length > 0 &&
                        clustersVendedora.map(cluster => <BadgeClusters key={cluster.id} cluster={cluster} />)}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {item.batch.comissionada?.codigo ? (
                      <div>
                        <strong>Código do captador:</strong> {item.batch.comissionada?.codigo}
                        <button type="button" className={classes.btEditar} onClick={openMatchSellerModal}>
                          editar
                        </button>
                      </div>
                    ) : (
                      <div>
                        <Button color="secondary" variant="contained" onClick={openMatchSellerModal}>
                          adicionar captador
                        </Button>
                      </div>
                    )}

                    {clustersComissionada.length > 0 &&
                      clustersComissionada.map(cluster => <BadgeClusters key={cluster.id} cluster={cluster} />)}
                  </Grid>
                </Grid>
              </div>

              <Box mb={2}>
                <FormAvaliacaoFisica
                  item={item}
                  codQueroVender={item?.codquerovenderitem}
                  temComissionada={Boolean(item?.batch?.comissionada)}
                  codloja={item?.produto?.vendedora?.codloja}
                />
              </Box>
            </>
          )}
        </>
      )}
    </TemplateBase>
  );
});

function AvaliacaoFisicaDetails() {
  return (
    <FiltrosProvider>
      <PageContent />
    </FiltrosProvider>
  );
}

export default AvaliacaoFisicaDetails;
