import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import { Filtro } from 'interfaces';

import FiltrosService from 'services/filtros/FiltrosService';
import { slugToCamelCase } from 'helpers/slug-to-camelcase';
import { useLoading } from 'context/loading';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

interface FiltrosContextData {
  fetchFiltros: (codcategoria?: number, codmarca?: number, codfiltrovariacaopai?: string) => void;
  fetchFiltrosTamanho: (codcategoria: number, codmarca?: number, codfiltrovariacaopai?: string) => void;
  filtroState: FiltroState;
  handleFetchFilter: (slug: string, codcategoria?: number, codmarca?: number, codfiltrovariacaopai?: string) => Promise<any>; // eslint-disable-line
}

export type FiltroStateBody = {
  field?: string;
} & Filtro;

export type FiltroState = {
  [filter: string]: FiltroStateBody;
};

const FiltrosContext = createContext<FiltrosContextData>({} as FiltrosContextData);

function FiltrosProvider({ children }: { children: React.ReactElement }) {
  const [filtroState, setFiltro] = useState<FiltroState>({} as FiltroState);
  const navigate = useNavigate();

  const { setLoading } = useLoading();

  const handleFetchFilter = useCallback(
    (slug: string, codcategoria?: number, codmarca?: number, codfiltrovariacaopai?: string) => {
      if (slug === 'tamanho') {
        return FiltrosService.fetch_filtro_variacoes({ filtro: slug, codcategoria, codmarca, codfiltrovariacaopai });
      }

      if (slug === 'detalhes-criticos') {
        return FiltrosService.fetch_filtro_variacoes({ filtro: slug, codcategoria, codmarca });
      }

      return FiltrosService.fetch_filtro_variacoes({ filtro: slug, codcategoria, codmarca });
    },
    []
  );

  const fetchFiltros = useCallback(
    (codcategoria?: number, codmarca?: number, codfiltrovariacaopai?: string) => {
      if (codcategoria) {
        setLoading(true);

        FiltrosService.fetch_filtros({
          codcategoria
        })
          .then(({ data: { data } }) => {
            const state = [];

            for (let i = 0; i < data.length; i += 1) {
              const element = data[i];

              const fetchFilter = handleFetchFilter(element.slug, codcategoria, codmarca, codfiltrovariacaopai);

              state.push(
                fetchFilter.then(response => {
                  return {
                    [slugToCamelCase(element.slug)]: {
                      nome: element.nome,
                      variacoes: response.data.data,
                      slug: element.slug,
                      field: `cadastro_${element.slug.replace(/-/g, '_')}`,
                      codfiltro: element.codfiltro
                    }
                  };
                })
              );
            }

            Promise.all([...Object.values(state)])
              .then(async response => {
                setFiltro(response.reduce((acc: FiltroState, curr: FiltroState) => ({ ...acc, ...curr }), {}));
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch(error => {
            setLoading(false);

            if (error.response.status === 500) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: 'Ocorreu um erro inesperado no servidor. Por favor, tente novamente!'
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  navigate(-1);
                }
              });
            }
          });
      }
    },
    [setFiltro, setLoading, navigate, handleFetchFilter]
  );

  const fetchFiltrosTamanho = useCallback(
    (codcategoria: number, codmarca?: number, codfiltrovariacaopai?: string) => {
      const fetchFilter = handleFetchFilter('tamanho', codcategoria, codmarca, codfiltrovariacaopai);
      const newState = { ...filtroState };

      fetchFilter.then(response => {
        newState.tamanho.variacoes = response.data.data;
        setFiltro(newState);
      });
    },
    [setFiltro, filtroState, handleFetchFilter]
  );

  const value = useMemo(
    () => ({ fetchFiltros, fetchFiltrosTamanho, filtroState, handleFetchFilter }),
    [fetchFiltros, fetchFiltrosTamanho, filtroState, handleFetchFilter]
  );

  return <FiltrosContext.Provider value={value}>{children}</FiltrosContext.Provider>;
}

function useFiltros() {
  const context = useContext(FiltrosContext);

  if (!context) {
    throw new Error('useFiltros deve ser utilizado dentro de um FiltrosProvider');
  }

  return context;
}

export { FiltrosProvider, useFiltros };
