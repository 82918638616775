import api from '../api';

const list = (query: string) => {
  return api.get(`pedido-produto/pedidos-aprovados${query}`);
};

const marcar_localizado = (codpedidoproduto: number, prioridade?: number, codcategoriaprincipal?: number) => {
  return api.patch(`pedido-produto/${codpedidoproduto}/localizado`, {
    prioridade,
    codcategoriaprincipal
  });
};

const pular = (codpedidoproduto: number, prioridade?: number, codcategoriaprincipal?: number) => {
  return api.patch(`pedido-produto/${codpedidoproduto}/pular`, {
    prioridade,
    codcategoriaprincipal
  });
};

const localizados = (query: string) => {
  return api.get(`pedido-produto/localizados${query}`);
};

const desfazer_localizado = (codpedidoproduto: number, prioridade?: number, codcategoriaprincipal?: number) => {
  return api.patch(`pedido-produto/${codpedidoproduto}/nao-localizado`, {
    prioridade,
    codcategoriaprincipal
  });
};

const localizar = (codigo_produto: string) => {
  return api.get(`produtos/${codigo_produto}?relacoes[]=logs&relacoes[]=acervo&relacoes[]=querovenderitem`);
};

export default {
  list,
  marcar_localizado,
  pular,
  localizados,
  desfazer_localizado,
  localizar
};
