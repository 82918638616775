import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const drawerWidth = 270;
const theme = createTheme();

const TplBaseStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  // content: {
  //   flexGrow: 1,
  //   padding: theme.spacing(3)
  // },
  // root: {
  //   display: 'flex'
  // },

  // toolbar: {
  //   paddingRight: 24 // keep right padding when drawer closed
  // },

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },

  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen
  //   })
  // },

  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  },

  // menuButton: {
  //   marginRight: 36
  // },

  menuButtonHidden: {
    display: 'none'
  },

  title: {
    flexGrow: 1
  },

  // // drawerPaper: {
  // //   position: 'relative',
  // //   whiteSpace: 'nowrap',
  // //   width: drawerWidth,
  // //   transition: theme.transitions.create('width', {
  // //     easing: theme.transitions.easing.sharp,
  // //     duration: theme.transitions.duration.enteringScreen
  // //   })
  // // },

  // drawerPaperClose: {
  //   overflowX: 'hidden',
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen
  //   }),
  //   width: theme.spacing(7),
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9)
  //   }
  // },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }

  // paper: {
  //   padding: theme.spacing(2),
  //   display: 'flex',
  //   overflow: 'auto',
  //   flexDirection: 'column'
  // },

  // fixedHeight: {
  //   height: 240
  // },

  // drawerPaper: {
  //   width: drawerWidth
  // }
}));

export default TplBaseStyles;
