import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ExcluirPecaService from 'services/ferramentas/ExcluirPecaService';
import { useForm, Controller } from 'react-hook-form';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';

function ExcluirPeca() {
  document.title = 'Ferramentas';

  const [codproduto, setCodProduto] = useState('');
  const [produto, setProduto] = useState<{ nome: string; codigo: string } | null>(null);

  const { register, handleSubmit, control } = useForm<{
    motivo: string;
    outro: string;
  }>();

  const { setLoading } = useLoading();

  /**
   * Função para capturar o valor do campo de busca
   */
  const handleChangeBusca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodProduto(event.target.value);
  };

  /**
   * Envia o valor do campo de busca para a API
   */

  const handleSubmitBusca = (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);

    ExcluirPecaService.validar(codproduto)
      .then(response => {
        setProduto(response.data);
      })
      .catch(() => {
        setCodProduto('');
        setProduto(null);
      })
      .finally(() => setLoading(false));
  };

  /**
   * Envia motivo de exclusão para a API
   */
  const onSubmit = ({ ...rest }) => {
    const data = { ...rest };

    if (data.motivo === 'outro') {
      data.motivo = data.outro;
    }

    if (data.motivo === '' || data.motivo === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selecione ou escreva um motivo para a exclusão'
      });
    } else {
      Swal.fire({
        title: 'Tem certeza que deseja excluir o produto?',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Cancelar`
      }).then(result => {
        if (result.isConfirmed) {
          setLoading(true);
          ExcluirPecaService.excluir(codproduto, data.motivo).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Operação realizada com sucesso',
              text: 'O produto foi excluído com sucesso'
            }).finally(() => {
              setCodProduto('');
              setProduto(null);
              setLoading(false);
            });
          });
        }
      });
    }
  };

  /**
   * Cancelar exclusão
   */

  const handleCancel = () => {
    setCodProduto('');
    setProduto(null);
  };

  return (
    <TemplateBase>
      <h1>Excluir Peça</h1>

      <Paper elevation={1}>
        <Box p={2}>
          <h3>Localizar Produto: </h3>
          <form onSubmit={handleSubmitBusca}>
            <TextField label="Código Produto" value={codproduto} onChange={handleChangeBusca} size="small" />
            <Button color="primary" variant="contained" type="submit" sx={{ ml: 2 }}>
              Buscar
            </Button>
          </form>
        </Box>
      </Paper>

      {produto && (
        <Paper elevation={1} sx={{ mt: 2 }}>
          <Box p={2}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 32 }}>
              {produto.nome}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={2}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Para concluir a exclusão do produto, você deve nos informar o motivo:
                </Typography>
                <FormControl>
                  <Controller
                    name="motivo"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup onChange={field.onChange}>
                        <Grid container>
                          <Grid size={6}>
                            <FormControlLabel value="Peça duplicada" control={<Radio />} label="Peça duplicada" />
                          </Grid>
                          <Grid size={6}>
                            <FormControlLabel value="Peça de conjunto" control={<Radio />} label="Peça de conjunto" />
                          </Grid>
                          <Grid size={6}>
                            <FormControlLabel
                              value="Peça criada em código errado"
                              control={<Radio />}
                              label="Peça criada em código errado"
                            />
                          </Grid>
                          <Grid size={6}>
                            <FormControlLabel value="outro" control={<Radio />} label="Outro motivo:" />
                            <TextField {...register('outro')} size="small" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="secondary" onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button type="submit" variant="contained" color="primary" sx={{ ml: 2 }}>
                  Sim, excluir produto
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      )}
    </TemplateBase>
  );
}

export default ExcluirPeca;
