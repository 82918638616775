import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styles from '../styles';

type Props = {
  children: React.ReactChild;
  title: string;
  pathBase: string;
  activePage: string;
};

export function SubmenuItem(props: Props) {
  const { children, title, pathBase, activePage } = props;

  const pathBaseReg = new RegExp(pathBase, 'g');
  const selected = !!activePage.match(pathBaseReg);

  const [open, setOpen] = useState(selected);
  const style = styles();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem>
        <ListItemButton className={style.nested} onClick={handleClick}>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
}
