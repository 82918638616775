import React, { useContext, useState, createContext, useMemo } from 'react';
import { CampoCategorias, CampoMarcas, FiltrosVariacao, QueroVenderItem } from 'interfaces';
import { useForm, FormProvider } from 'react-hook-form';
import Loading from 'components/loading';

type FormAvaliacaoContextProps = {
  setTipoMarca: React.Dispatch<React.SetStateAction<'N' | 'I' | '' | undefined>>;
  setCampoCategoria: React.Dispatch<React.SetStateAction<CampoCategorias | null>>;
  setCampoGenero: React.Dispatch<React.SetStateAction<FiltrosVariacao>>;
  setCampoTamanho: React.Dispatch<React.SetStateAction<FiltrosVariacao[]>>;
  setCampoMarca: React.Dispatch<React.SetStateAction<CampoMarcas | null>>;
  setMarcaMultipla: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoriaMultipla: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingFull: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRequesting: React.Dispatch<React.SetStateAction<boolean>>;
  setModeloRequired: React.Dispatch<React.SetStateAction<boolean>>;
  tipoMarca?: 'N' | 'I' | '' | null;
  codcategoria: string | undefined;
  codmarca: string | undefined;
  campoCategoria: CampoCategorias | null;
  campoMarca: CampoMarcas | null;
  campoGenero: FiltrosVariacao;
  campoTamanho: FiltrosVariacao[];
  marcaMultipla: boolean;
  categoriaMultipla: boolean;
  showCampoComprimento: boolean;
  isRequesting: boolean;
  modeloRequired: boolean;
  readOnly: boolean | undefined;
  isReadOny: (fn: (arg?: any) => void) => (arg?: any) => void;// eslint-disable-line
  publicacaoModo: string | undefined | null;
  setPublicacaoModo: React.Dispatch<React.SetStateAction<string | null>>;
} & FormAvaliacaoProviderProps;

const FormAvaliacaoContext = createContext({} as FormAvaliacaoContextProps);

type FormAvaliacaoProviderProps = {
  item?: QueroVenderItem;
  isProduto: boolean;
  temComissionada: boolean;
  codQueroVender: number | undefined;
  codloja: number | undefined;
  disableForm?: boolean;
  batchComissionamentosDiferenciados?: boolean;
  tipoAvaliacao?: string;
  batchProdutosSemBonus?: boolean | undefined;
  readOnly?: boolean;
};

export function FormAvaliacaoProvider({
  children,
  ...props
}: { children: React.ReactNode } & FormAvaliacaoProviderProps) {
  const { item, batchComissionamentosDiferenciados, readOnly, ...rest } = props;
  const [tipoMarca, setTipoMarca] = useState<'N' | 'I' | ''>();
  const [campoCategoria, setCampoCategoria] = useState<CampoCategorias | null>(item?.atributos?.categoria);
  const [campoMarca, setCampoMarca] = useState<CampoMarcas | null>(item?.atributos.marca);
  const [loadingFull, setLoadingFull] = useState(false);
  const [campoGenero, setCampoGenero] = useState<FiltrosVariacao>({} as FiltrosVariacao);
  const [campoTamanho, setCampoTamanho] = useState<FiltrosVariacao[]>([]);
  const [modeloRequired, setModeloRequired] = useState(false);

  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const [marcaMultipla, setMarcaMultipla] = useState<boolean>(false);
  const [categoriaMultipla, setCategoriaMultipla] = useState<boolean>(false);

  const [publicacaoModo, setPublicacaoModo] = useState<null | string>(null);

  const renderTamanhoInternacional = (params?: FiltrosVariacao[]) => {
    if (!params) return [];
    return params.map(tamanho => tamanho.nome).join(', ');
  };

  const isReadOny = (fn: (arg: any) => void) => (arg: any) => { // eslint-disable-line
    if (!readOnly) {
      fn(arg);
    }
  };

  const showCampoComprimento = useMemo(() => !!campoCategoria?.url?.match(/vestido(s)?|saia(s)?/g), [campoCategoria]);

  const defaultValues = useMemo(
    () => ({
      codquerovender: undefined,
      codcategoria: undefined,
      codmarca: undefined,
      modelo: item?.produto?.descricao5,
      precoloja: null,
      precovendedor: null,
      precocomissionada: null,
      precoavista: null,
      precovenda: null,
      referencia: item?.link_referencia,
      itens_inclusos: null,
      sem_bonus: null,
      itens_inclusos_extra: null,
      comprado_eu: null,
      condicao: null,
      ano: item?.ano,
      cor: item?.cor,
      codigo: item?.codigo,
      material: item?.material,
      modelo_exato: item?.modelo_exato,
      tamanho: item?.tamanho,
      observacoes_internas: item?.observacoes_internas,
      observacoes: item?.observacoes,
      detalhes_criticos: item?.produto?.detalhes_criticos,
      cadastro_comprimento: [],
      cadastro_cor: [],
      cadastro_material: [],
      cadastro_tamanho: [],
      cadastro_detalhes_criticos: [],
      cadastro_estado_conservacao: [],
      sugestao_valor_aceita: null,
      cadastro_tamanho_internacional: renderTamanhoInternacional(
        item?.produto?.filtros?.['tamanho-internacional']?.variacoes
      ),
      cadastro_genero: null,
      publicacao_modo: null
    }),
    [item]
  );

  const methods = useForm({ defaultValues });

  const { codmarca, codcategoria } = methods.getValues();

  const values = useMemo(
    () => ({
      setCampoMarca,
      setTipoMarca,
      setCampoCategoria,
      setCampoGenero,
      setCampoTamanho,
      setCategoriaMultipla,
      setMarcaMultipla,
      setLoadingFull,
      setIsRequesting,
      setModeloRequired,
      readOnly,
      isReadOny,
      item,
      batchComissionamentosDiferenciados,
      marcaMultipla,
      categoriaMultipla,
      codcategoria,
      codmarca,
      ...rest,
      tipoMarca,
      campoCategoria,
      campoGenero,
      campoTamanho,
      campoMarca,
      showCampoComprimento,
      isRequesting,
      publicacaoModo,
      setPublicacaoModo,
      modeloRequired
    }),
    [
      batchComissionamentosDiferenciados,
      campoCategoria,
      campoGenero,
      campoMarca,
      campoTamanho,
      categoriaMultipla,
      codcategoria,
      codmarca,
      isReadOny,
      isRequesting,
      item,
      marcaMultipla,
      modeloRequired,
      publicacaoModo,
      readOnly,
      rest,
      showCampoComprimento,
      tipoMarca
    ]
  );

  return (
    <FormAvaliacaoContext.Provider value={values}>
      <FormProvider {...methods}>{children}</FormProvider>
      <Loading open={loadingFull} />
    </FormAvaliacaoContext.Provider>
  );
}

export function useFormAvaliacao(): FormAvaliacaoContextProps {
  const context = useContext(FormAvaliacaoContext);

  if (!context) {
    throw new Error('useFormAvaliacao deve usado dentro de um FormAvaliacaoProvider');
  }

  return context;
}
