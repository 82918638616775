import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const formGerarRelatorioStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  modalContent: {
    minWidth: '500px'
  },
  modalActions: {
    minWidth: '500px',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  inputDate: {
    width: '100%'
  }
}));

export default formGerarRelatorioStyle;
