import React from 'react';
// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PauseIcon from '@mui/icons-material/Pause';
// layouts
import { blue, red, orange } from '@mui/material/colors';
import { PropostaRecusada } from '../avaliacao-fisica-negociacao/layout-proposta-recusada';
import { PropostaEnviada } from '../avaliacao-fisica-negociacao/layout-proposta-enviada';
import { NovaSugestao } from '../avaliacao-fisica-negociacao/layout-nova-sugestao';
import { PropostaPausada } from '../avaliacao-fisica-negociacao/layout-proposta-pausada';
//
import { factoryRoundedIconsComponent, CustomSvgIconProps } from '../avaliacao-fisica-negociacao/rounded-icons';

type StatusInterface = {
  LayoutNegociacao: React.ElementType;
  statusColor: string;
  Icon: React.ComponentType<CustomSvgIconProps>;
  statusText: string;
};

const status1Color = blue[500];
const status2Color = red[500];
const status10Color = blue[500];
const status20Color = orange[300];
const status30Color = red[500];
const status50Color = red[600];

export const StyledMoreIcon = factoryRoundedIconsComponent({ color: status10Color, Icon: MoreHorizIcon });
export const StyledBellIcon = factoryRoundedIconsComponent({ color: status20Color, Icon: NotificationsIcon });
export const StyledPauseIcon = factoryRoundedIconsComponent({ color: status50Color, Icon: PauseIcon });
export const StyledClearIcon = factoryRoundedIconsComponent({ color: status30Color, Icon: ClearIcon });

export function useStatusInterface(status: number) {
  switch (status) {
    case 1:
      return {
        statusColor: status1Color,
        statusText: 'em avaliacao'
      } as StatusInterface;

    case 2:
      return {
        statusColor: status2Color,
        statusText: 'aguardando resposta'
      } as StatusInterface;

    case 10:
      return {
        LayoutNegociacao: PropostaEnviada,
        statusColor: status10Color,
        Icon: StyledMoreIcon,
        statusText: 'aguardando resposta do vendedor'
      } as StatusInterface;

    case 20:
      return {
        LayoutNegociacao: NovaSugestao,
        statusColor: status20Color,
        Icon: StyledBellIcon,
        statusText: 'nova sugestão de valor'
      } as StatusInterface;

    case 30:
      return {
        LayoutNegociacao: PropostaRecusada,
        statusColor: status30Color,
        Icon: StyledClearIcon,
        statusText: 'desistiu de vender'
      } as StatusInterface;

    case 50:
      return {
        LayoutNegociacao: PropostaPausada,
        statusColor: status50Color,
        Icon: StyledPauseIcon,
        statusText: 'negociação pausada'
      } as StatusInterface;

    default:
      throw new Error('useStatusInterface requires a valid status: 10, 20, 30, 50');
  }
}
