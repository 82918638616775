import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Produto } from 'interfaces';

type Props = {
  produtos: Produto[];
};

function ButtonAvaliar({ children, codproduto }: { children: string; codproduto: string }) {
  const navigate = useNavigate();

  const handleBtAvaliar = () => {
    navigate(`/parceiros/moderacao-produtos/${codproduto}`);
  };

  return (
    <Button variant="contained" color="primary" onClick={handleBtAvaliar}>
      {children}
    </Button>
  );
}

export function ListProdutos(props: Props) {
  const { produtos } = props;

  return (
    <Paper>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Produto</TableCell>
            <TableCell>Vendedor</TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Cadastrado em</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {produtos.map(produto => (
            <TableRow key={produto?.codproduto}>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Avatar alt="" src={produto?.imagens[0]?.thumbnail} />
                  <Box ml={1}>{produto?.nome}</Box>
                </Box>
              </TableCell>
              <TableCell>{produto?.vendedora?.nome_completo || '-'}</TableCell>
              <TableCell>{produto?.codigo}</TableCell>
              <TableCell>-</TableCell>
              <TableCell>
                {produto?.status === 'V' ? (
                  <ButtonAvaliar codproduto={produto?.codigo}>Ver avaliação</ButtonAvaliar>
                ) : (
                  <ButtonAvaliar codproduto={produto?.codigo}>Avaliar</ButtonAvaliar>
                )}
              </TableCell>
              <TableCell>{/* <Button color="primary">logs</Button> */}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
