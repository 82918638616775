import { ComissionadaPayload } from 'interfaces/comissionada';
import api from '../api';

const getListaLojas = (queryMeta?: string) => {
  const query = queryMeta !== undefined ? `?${queryMeta}` : '';
  return api.get(`lojas${query}`);
};

const getLoja = (codloja: number | string) => {
  return api.get(`lojas/${codloja}`);
};

const getFaixasAtivasPorLoja = (codloja: number | string) => {
  return api.get(`comissionada/tabela/${codloja}`);
};

const getFaixasInativasPorLoja = (codloja: number | string) => {
  return api.get(`comissionada/historico/${codloja}`);
};

const salvar = (codloja: number | string, data: ComissionadaPayload) => {
  return api.post(`comissionada/salvar/${codloja}`, data);
};

export default { getListaLojas, getLoja, getFaixasAtivasPorLoja, getFaixasInativasPorLoja, salvar };
