import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import { useRetiradaPostagem } from 'pages/avaliacao/context/agendamento';
import QueroVenderRemessaService from 'services/quero-vender/QueroVenderRemessaService';
import alertValidationMessage from 'helpers/alert-validation-message';
import { QueroVenderRetirada, QueroVenderBatch } from 'interfaces';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import formRetiradaStyles from './style';

/**
 * Regras das validações
 */
const schema = Yup.object().shape({
  agendamento: Yup.string().required('Data obrigatória'),
  endereco: Yup.string()
});

/**
 * COMPONENTE
 */
function FormRetirada() {
  const style = formRetiradaStyles();

  const { openModalRetirada, setOpenModalRetirada, codquerovender, enderecos, batches, setBatch } =
    useRetiradaPostagem();

  const [userEndereco, setUserEndereco] = useState('');
  const [loadingFull, setLoadingFull] = useState(false);

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<QueroVenderRetirada>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = (data: QueroVenderRetirada) => {
    const formData = { ...data };

    formData.agendamento = format(new Date(formData.agendamento), 'yyy-MM-dd');

    setOpenModalRetirada(false);
    setLoadingFull(true);
    QueroVenderRemessaService.retirada(codquerovender, formData)
      .then(response => {
        if (response) {
          const listBatches = batches.filter((item: QueroVenderBatch) => {
            return item.codquerovender !== response?.data.codquerovender;
          });

          // Atualiza estado das Batchs
          setBatch(listBatches);
          Swal.fire('Código enviado', '', 'success');
        }
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  useEffect(() => {
    if (openModalRetirada && enderecos.length > 0) {
      const enderecoCompleto = enderecos.some(item => item.padrao)
        ? enderecos.filter(item => item.padrao)[0]
        : enderecos[enderecos.length - 1];
      const { endereco, numero, complemento, bairro, cidade, uf, cep } = enderecoCompleto;

      setUserEndereco(`CEP: ${cep} | ${endereco}, ${numero}, ${complemento} - ${bairro} - ${cidade} - ${uf}`);
    }

    return () => {
      setUserEndereco('');
    };
  }, [enderecos, openModalRetirada]);

  /**
   * Fecha modal do form
   */
  const handleCloseModal = () => {
    setOpenModalRetirada(false);
  };

  /**
   * Template
   */
  return (
    <>
      <Dialog open={openModalRetirada} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agendar retirada</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={style.modalContent}>
            <DatePickerHookForm label="Data de retirada" name="agendamento" control={control} />

            <TextFieldHookForms
              {...register('endereco')}
              label="Endereço"
              multiline
              rows={3}
              variant="outlined"
              className={style.endereco}
              defaultValue={userEndereco}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              Agendar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Loading open={loadingFull} />
    </>
  );
}

export default FormRetirada;
