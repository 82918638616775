import api from '../api';

const listar = (id: number, page: number, per_page = 10) => {
  return api.get(`/elementos/${id}/usos?per_page=${per_page}&page=${page}`);
};

const detalhes = (id: string) => {
  return api.get(`/elementos/usos/${id}`);
};

const atualizar = (id: string, data: any) => { //eslint-disable-line
  return api.put(`/elementos/usos/${id}`, data);
};

const criar = (id: string, data: any) => { //eslint-disable-line
  return api.post(`/elementos/${id}/usos`, data);
};

const remover = (id: string) => {
  return api.delete(`/elementos/usos/${id}`);
};

export default { listar, detalhes, atualizar, criar, remover };
