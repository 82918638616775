import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ModeracaoService from 'services/peer-two-peer/ModeracaoService';
import { useForm } from 'react-hook-form';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';

type Motivo = {
  value: string;
  label: string;
  ativo: boolean;
};

export function ModalRejeitarProduto({ handleClose, codproduto }: { handleClose: () => void; codproduto: number }) {
  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const { loading, setLoading } = useLoading();
  const [outroMotivo, setOutroMotivo] = useState('');

  const navigate = useNavigate();

  const { handleSubmit, register } = useForm();

  useEffect(() => {
    setLoading(true);
    ModeracaoService.recusa_motivos()
      .then(response => {
        setMotivos(response.data);
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  const onSubmit = (data: { [key: string]: boolean }) => {
    const motivosSelecionados = handleMapperMotivos(data);

    if (motivosSelecionados.length === 0 && outroMotivo.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',

        text: 'Selecione ou preencha algum motivo'
      });
    } else {
      setLoading(true);
      ModeracaoService.concluir_moderacao(`${codproduto}`, {
        recusado: true,
        moderacao_recusa_motivos: handleMapperMotivos(data),
        moderacao_recusa_motivo_complemento: outroMotivo
      })
        .then(() => {
          Swal.fire('Produto foi rejeitado com sucesso', '', 'success').then(() => {
            navigate('/parceiros/moderacao-produtos');
          });
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleMapperMotivos = (data: { [key: string]: boolean }) => Object.keys(data).filter(key => data[key]);
  const handleOutroMotivo = (event: React.ChangeEvent<HTMLInputElement>) => setOutroMotivo(event.target.value);

  return !loading ? (
    <Dialog open fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Rejeitar Produto</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" component="fieldset">
            <FormGroup>
              {motivos.length === 0 ? (
                <div>carregando...</div>
              ) : (
                motivos?.map(motivo => {
                  return (
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      {...register(motivo?.value)}
                      label={motivo?.label}
                    />
                  );
                })
              )}
            </FormGroup>
          </FormControl>
          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              onChange={handleOutroMotivo}
              rows={2}
              value={outroMotivo}
              variant="outlined"
              label="Outro Motivo"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : null;
}
