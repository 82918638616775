import makeStyles from '@mui/styles/makeStyles';

const PagamentosVendedorasStyle = makeStyles(() => ({
  paper: {
    marginBottom: 30
  },

  buttonBuscar: {
    marginLeft: 10
  },

  wrapInputBusca: {
    marginTop: 10
  },

  wrapModalButtons: {
    margin: '25px 0',
    textAlign: 'right'
  },

  buttonModalCancelar: {
    marginRight: '10px'
  }
}));

export default PagamentosVendedorasStyle;
