import React, { useCallback, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { QueroVenderItem } from 'interfaces';
import { FiltrosProvider } from 'context/filtros';
import { withItemStatusClass } from './withItemStatusClass';
import style from '../Item/style';

export interface ItemBatchTextProps {
  item: QueroVenderItem;
  index?: number;
  isExpanded?: boolean;
  doRefresh?(): void;
  codQueroVender?: number;
  temComissionada: boolean;
  codloja?: number;
  closeButton?: boolean;
  onClickCloseButton?(): void;
  batchComissionamentosDiferenciados?: boolean;
  batchProdutosSemBonus?: boolean;
  statusClass?: string;
  statusText?: string;
}

function ItemBatchText(props: ItemBatchTextProps) {
  const classes = style();
  const {
    item,
    index = undefined,
    isExpanded = false,
    doRefresh = undefined,
    codQueroVender = undefined,
    temComissionada,
    codloja = undefined,
    closeButton = false,
    onClickCloseButton = undefined,
    batchComissionamentosDiferenciados = false,
    batchProdutosSemBonus = false,
    statusClass = '',
    statusText = ''
  } = props;

  const [expanded, setExpanded] = useState(isExpanded);
  const [formComponent, setFormComponent] = useState<React.ReactElement>();

  /* Seta o status do produto */

  const importFormAvaliacao = () => import('../../avaliacao-fisica-batch/form-avaliacao');

  const renderFormAvaliacao = useCallback(async () => {
    const Component = await importFormAvaliacao();

    setFormComponent(
      /* eslint-disable-next-line */
      <Component.default
        item={item}
        key={item.codquerovenderitem}
        isProduto={item?.produto !== null}
        closeButton={closeButton}
        isExpanded={isExpanded}
        doRefresh={doRefresh}
        codQueroVender={codQueroVender}
        temComissionada={temComissionada}
        onClickCloseButton={onClickCloseButton}
        handleCardExpand={(value: boolean) => setExpanded(value)}
        codloja={codloja}
        batchComissionamentosDiferenciados={batchComissionamentosDiferenciados}
        batchProdutosSemBonus={batchProdutosSemBonus}
      />
    );
  }, [
    onClickCloseButton,
    item,
    closeButton,
    isExpanded,
    doRefresh,
    codQueroVender,
    temComissionada,
    codloja,
    batchComissionamentosDiferenciados,
    batchProdutosSemBonus
  ]);

  /**
   * Handler do click no botão de expandir o card
   */
  const handleExpandClick = () => {
    renderFormAvaliacao();
    setTimeout(() => setExpanded(!expanded), 100);
  };

  useEffect(() => {
    if (expanded) {
      renderFormAvaliacao();
    }
  }, [renderFormAvaliacao, expanded]);

  return (
    <Card className={classes.root}>
      {item && (
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Tooltip placement="right" title={`#${item.codquerovenderitem}`}>
              <Avatar src={item?.fotos[0]?.imagem} className={`${statusClass} ${classes.avatar}`}>
                {index}
              </Avatar>
            </Tooltip>
          }
          action={
            <>
              {/* eslint-disable */}
              {item?._links.etiqueta_produto && (
                <Tooltip placement="top" title="Imprimir Etiqueta">
                  <IconButton
                    onClick={() => window.open(item?._links.etiqueta_produto, '_blank', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=700,height=400,left=200,top=100')}
                    size="large">
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              )}
              {/* eslint-enable */}
              <IconButton onClick={handleExpandClick} size="large">
                {expanded ? <Remove /> : <Add />}
              </IconButton>
            </>
          }
          title={item?.produto ? item.produto.nome : item.titulo}
          subheader={statusText}
        />
      )}

      <form>
        <FiltrosProvider>
          <Collapse in={expanded}>{formComponent}</Collapse>
        </FiltrosProvider>
      </form>
    </Card>
  );
}

export default withItemStatusClass(ItemBatchText);
