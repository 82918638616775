import React from 'react';
import Button from '@mui/material/Button';
import QueroVenderItemService, { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
  sugerir_aceitar?: boolean;
};

export function BotaoEnviarSugestao(props: Props) {
  const styles = avaliacaoFisicaNegociacaoStyle();

  const { handleSubmit } = useFormContext<AprovacaoFisica>();

  const { codquerovenderitem, doRefresh, disabled = false, sugerir_aceitar = false } = props;

  const { setLoading } = useLoading();

  const handleOperation = async (data: AprovacaoFisica) => {
    const body: AprovacaoFisica = { ...data };

    body.acao = 'sugerir';

    if (data.precovendedor) {
      body.preco = parseFloat(data.precovendedor);
      delete body.precovendedor;
    }

    if (codquerovenderitem) {
      setLoading(true);

      QueroVenderItemService.aprovacao_fisica(codquerovenderitem as number, body)
        .then(() => {
          if (sugerir_aceitar) {
            QueroVenderItemService.aprovacao_fisica(codquerovenderitem, {
              acao: 'aceitar'
            })
              .then(() => {
                Swal.fire('Operação realizada com sucesso', 'A proposta foi alterada e aceita', 'success');
                doRefresh();
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            Swal.fire('Operação realizada com sucesso', 'A proposta foi alterada', 'success');
            doRefresh();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    console.error('codquerovenderitem does not exist');
  };

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={disabled}
      className={styles.button}
      onClick={handleSubmit(handleOperation)}
    >
      enviar sugestão
    </Button>
  );
}
