import React, { useCallback } from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormatBaseProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function InputCurrency(props: NumberFormatCustomProps) {
  const { inputRef, onChange, name, ...other } = props;

  const currencyFormatter = useCallback((value: string) => {
    const valueField = Number(value);
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(valueField / 100);

    return amount;
  }, []);

  return (
    <NumberFormatBase
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value
          }
        });
      }}
      prefix="R$"
      format={currencyFormatter}
    />
  );
}

export default InputCurrency;
