import makeStyles from '@mui/styles/makeStyles';
import { orange, blue } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const avaliacaoFisicaNegociacaoStyle = makeStyles(() => ({
  space: {
    marginBottom: theme.spacing(6)
  },

  cardHeader: {
    '& .MuiCardHeader-action': {
      marginTop: 23
    },
    '& .MuiAvatar-root': {
      width: 90,
      height: 90
    }
  },

  button: {
    marginRight: theme.spacing(1)
  },

  avatar: {
    width: 100,
    height: 100
  },

  starIcon: {
    color: orange[600]
  },

  root: {
    marginBottom: '20px',
    marginTop: '20px'
  },

  cardContent: {
    paddingBottom: 0
  },

  locked: {
    opacity: 0.5
  },

  linkAvaliar: {
    color: theme.palette.primary.main
  },

  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed'
  },

  paperBusca: {
    marginBottom: theme.spacing(5)
  },

  iconNotification: {
    color: theme.palette.warning.main
  },

  actions: {
    textAlign: 'right',
    backgroundColor: '#eee',
    justifyContent: 'flex-end'
  },

  textInput: {
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
      '&::placeholder': {
        fontSize: 13
      }
    },

    '& .MuiInputLabel-root': {
      fontSize: 13
    }
  },

  titleStrong: {
    fontWeight: 700,
    color: '#000'
  },

  titleStrongBlue: {
    fonSize: 16,
    fontWeight: 700,
    color: blue[500]
  },

  buttonAction: {
    textAlign: 'center',
    color: blue[500]
  },

  status10: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: blue[500]
  }
}));

export default avaliacaoFisicaNegociacaoStyle;
