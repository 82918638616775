import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import { ArrowUpwardRounded } from '@mui/icons-material';
import ClientesService from 'services/clientes/ClientesService';
import { useLoading } from 'context/loading';
import UnificarClientesService from '../../../services/clientes/UnificarClientesService';
import TemplateBase from '../../../components/template-base/template';

function UnificarContaCliente() {
  document.title = 'Unificar Contas de Clientes';

  const [codcliente, setCodCliente] = useState<string>('');
  const [codclienteUnificar, setCodClienteUnificar] = useState<string>('');

  const { setLoading } = useLoading();

  const handleCodClienteInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodCliente(event.target.value);
  };

  const handleCodClienteUnificarInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodClienteUnificar(event.target.value);
  };

  const handleUnificarClientes = async () => {
    if (!codcliente || !codclienteUnificar) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'É nescessário preencher os dois campos'
      });

      return;
    }

    try {
      setLoading(true);
      const clienteMantido = await ClientesService.buscar(undefined, codcliente);
      const clienteUnificado = await ClientesService.buscar(undefined, codclienteUnificar);

      setLoading(false);
      Swal.fire({
        title: 'Tem certeza que deseja continuar?',
        html: `
          <div style="text-align: left">
            <div><strong>Cliente mantido:</strong> ${codcliente}</div>
            <div><strong>Nome:</strong> ${clienteMantido.data.nome}</div>
            <div><strong>CPF:</strong> ${clienteMantido.data.cpf} </div>
            <div><strong>E-mail:</strong> ${clienteMantido.data.email}</div>
          </div>
          <br />
          <div style="text-align: left">
            <div><strong>Cliente a ser unificado:</strong> ${codclienteUnificar}</div>
            <div><strong>Nome:</strong> ${clienteUnificado.data.nome}</div>
            <div><strong>CPF:</strong> ${clienteUnificado.data.cpf} </div>
            <div><strong>E-mail:</strong> ${clienteUnificado.data.email}</div>
          </div>
          <br />
          <div>
            Tem certeza que deseja unificar esses 2 clientes? <strong>Esta ação não poderá ser revertida. CONFIRME OS IDS!</strong> O de cima será mantido, o de baixo será apagado.
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Não`
      }).then(result => {
        setLoading(true);

        if (result.isConfirmed) {
          UnificarClientesService.unificar(codcliente, codclienteUnificar)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Clientes unificados com sucesso!',
                confirmButtonText: `OK`
              }).then(() => {
                setCodCliente('');
                setCodClienteUnificar('');
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);

      if (error.response.status === 404) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.message
        });
      }
    }
  };

  return (
    <TemplateBase>
      <Paper style={{ padding: 15 }}>
        <Box py={2}>O de cima será mantido, o de baixo será apagado.</Box>
        <Box py={2}>
          <TextField
            label="ID do cliente que vai ser mantido (ex: 100040)"
            autoFocus
            variant="outlined"
            fullWidth
            value={codcliente}
            onChange={handleCodClienteInput}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Box>
          <ArrowUpwardRounded />
        </Box>
        <Box py={2}>
          <TextField
            label="ID do cliente que vai ser unificado (ex: 100040)"
            variant="outlined"
            fullWidth
            value={codclienteUnificar}
            onChange={handleCodClienteUnificarInput}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handleUnificarClientes}>
          Unificar
        </Button>
      </Paper>
    </TemplateBase>
  );
}

export default UnificarContaCliente;
