import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { Produto, AutocompleteModelosPayload, CampoCategorias, CampoMarcas, FiltrosVariacao } from 'interfaces';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import AutocompleteCategorias from 'components/form/autocompleteCategorias';
import AutocompleteMarcas from 'components/form/autocompleteMarcas';
import AutocompleteModelos from 'components/form/autocompleteModelos';
import AutoCompleteService from 'services/autocomplete/AutoCompleteService';
import Box from '@mui/material/Box';
import { CheckboxGenero } from 'components/avaliacao-fisica/checkbox-genero';
import { useFormContext } from 'react-hook-form';
import { useFiltros } from 'context/filtros';
import { FiltroCheckbox } from 'components/avaliacao-fisica/filtros-checkbox';
import AutocompleteFiltros from 'components/form/autocompleteFiltrosOptions';

type Props = {
  produto: Produto | null;
};

export function EtapaCadastro(props: Props) {
  const { produto } = props;
  const [modelos, setModelos] = useState<AutocompleteModelosPayload[]>([]);
  const [campoCategoria, setCampoCategoria] = useState<CampoCategorias | undefined>(produto?.categoria_principal);
  const [campoMarca, setCampoMarca] = useState<CampoMarcas | undefined>(produto?.marca);
  const [material, setMaterial] = useState<FiltrosVariacao[]>([]);
  const [genero, setGenero] = useState<FiltrosVariacao[]>([]);
  const [itensInclusos, setItensInclusos] = useState<FiltrosVariacao[]>([]);
  const [tamanho, setTamanho] = useState<FiltrosVariacao[]>([]);
  const [tamanhoInternacional, setTamanhoInternacional] = useState<FiltrosVariacao[]>([]);
  const [comprimento, setComprimento] = useState<FiltrosVariacao[]>([]);
  const [cor, setCor] = useState<FiltrosVariacao[]>([]);
  const [detalhesCriticos, setDetalhesCriticos] = useState<FiltrosVariacao[]>([]);

  const { register, getValues } = useFormContext();

  const { handleFetchFilter } = useFiltros();

  const handleFilters = useCallback(
    (codcategoria?: number, codmarca?: number) => {
      handleFetchFilter('material', codcategoria, codmarca).then(response => setMaterial(response.data.data));
      handleFetchFilter('genero', codcategoria, codmarca).then(response => setGenero(response.data.data));
      handleFetchFilter('itens-inclusos', codcategoria, codmarca).then(response =>
        setItensInclusos(response.data.data)
      );
      handleFetchFilter('tamanho', codcategoria, codmarca).then(response => setTamanho(response.data.data));
      handleFetchFilter('tamanho-internacional', codcategoria, codmarca).then(response =>
        setTamanhoInternacional(response.data.data)
      );
      handleFetchFilter('comprimento', codcategoria, codmarca).then(response => setComprimento(response.data.data));
      handleFetchFilter('cor', codcategoria, codmarca).then(response => setCor(response.data.data));
      handleFetchFilter('detalhes-criticos', codcategoria, codmarca).then(response =>
        setDetalhesCriticos(response.data.data)
      );
    },
    [handleFetchFilter]
  );

  const handleCategorias = (value: CampoCategorias) => {
    setCampoCategoria(value);
  };

  const handleCampoMarcas = (value: CampoMarcas) => {
    setCampoMarca(value);
  };

  const modeloDefaultValue = useMemo(() => {
    return produto?.modelo || produto?.modelo_especifico;
  }, [produto]);

  const produtoreadOnly = useMemo(() => {
    return produto?.status === 'V';
  }, [produto]);

  const handleGeneroChange = () => {
    const generosSelecionados = getValues('cadastro_genero');

    if (campoCategoria?.codcategoria && generosSelecionados) {
      handleFetchFilter(
        'tamanho',
        campoCategoria.codcategoria,
        campoMarca?.codmarca,
        generosSelecionados.join(',')
      ).then(response => setTamanho(response.data.data));
    }
  };

  useEffect(() => {
    if (campoCategoria && campoMarca) {
      AutoCompleteService.modelos({
        codmarca: campoMarca?.codmarca as number,
        codcategoria: campoCategoria?.codcategoriapai || (campoCategoria?.categoriapai?.codcategoria as number)
      })?.then(({ data: { data } }: { data: { data: AutocompleteModelosPayload[] } }) => {
        setModelos(data);
      });

      handleFilters(
        campoCategoria?.codcategoriapai || (campoCategoria?.categoriapai?.codcategoria as number),
        campoMarca.codmarca
      );
    }
  }, [campoCategoria, campoMarca]); // eslint-disable-line

  const variacoesGenero = produto?.filtros.genero.variacoes;

  return (
    <div>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <AutocompleteCategorias
              disabled={produtoreadOnly}
              categoria={produto?.categoria_principal}
              getCategoria={handleCategorias}
              register={register}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteMarcas
              disabled={produtoreadOnly}
              marca={produto?.marca}
              getMarcaSelected={handleCampoMarcas}
              register={register}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteModelos
              codmarca={produto?.marca?.codmarca}
              codcategoria={produto?.categoria_principal?.codcategoriapai || produto?.categoria_principal?.codcategoria}
              options={modelos}
              defaultValue={modeloDefaultValue}
              register={register}
              disabled={produtoreadOnly}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="overline" color="textSecondary">
        Gênero
      </Typography>

      <Grid container spacing={2}>
        <Grid item>
          <FormGroup row>
            <>
              {genero.map(variacao => (
                <CheckboxGenero
                  key={variacao.slug}
                  disabled={produtoreadOnly}
                  name="cadastro_genero"
                  slug={variacao.slug}
                  register={register}
                  label={variacao.nome}
                  variacoes={variacoesGenero}
                  defaultValue={variacao.codfiltrovariacao}
                  onChange={handleGeneroChange}
                />
              ))}
            </>
          </FormGroup>
        </Grid>
      </Grid>
      <Box py={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <AutocompleteFiltros
              register={register}
              filtro={cor}
              disabled={produtoreadOnly}
              defaultValue={produto?.filtros?.cor?.variacoes}
              name="cadastro_cor"
              label="Cor"
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFiltros
              register={register}
              filtro={material}
              disabled={produtoreadOnly}
              defaultValue={produto?.filtros?.material?.variacoes}
              name="cadastro_material"
              label="Material"
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFiltros
              register={register}
              filtro={comprimento}
              disabled={produtoreadOnly}
              defaultValue={produto?.filtros?.comprimento?.variacoes}
              name="cadastro_comprimento"
              label="Comprimento"
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFiltros
              register={register}
              filtro={tamanho}
              disabled={produtoreadOnly}
              defaultValue={produto?.filtros?.tamanho?.variacoes}
              name="cadastro_tamanho"
              label="Tamanho"
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFiltros
              register={register}
              filtro={tamanhoInternacional}
              disabled={produtoreadOnly}
              defaultValue={produto?.filtros?.['tamanho-internacional']?.variacoes}
              name="cadastro_tamanho_internacional"
              label="Tamanho Internacional"
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={1}>
        <>
          <Typography variant="overline" color="textSecondary">
            Itens Inclusos
          </Typography>
          {itensInclusos.length > 0 && produto?.filtros['itens-inclusos'] && (
            <FiltroCheckbox
              name="cadastro_itens_inclusos"
              register={register}
              categoria={produto?.categoria_principal}
              apiFiltroVariacoes={itensInclusos}
              disabled={produtoreadOnly}
              produtoFiltroVariacoes={produto?.filtros['itens-inclusos']?.variacoes}
            />
          )}
        </>
      </Box>

      <Box py={1}>
        <>
          <Typography variant="overline" color="textSecondary">
            Detalhes Críticos
          </Typography>
          {detalhesCriticos.length > 0 && produto?.filtros['detalhes-criticos'] && (
            <FiltroCheckbox
              name="cadastro_detalhes_criticos"
              register={register}
              categoria={produto?.categoria_principal}
              apiFiltroVariacoes={detalhesCriticos}
              disabled={produtoreadOnly}
              produtoFiltroVariacoes={produto?.filtros['detalhes-criticos']?.variacoes}
            />
          )}
        </>
      </Box>
    </div>
  );
}
