import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import EditarCodRemessaStyle from './style';

interface EditarCodRemessaProps {
  onClose: () => void;
  cod_remessa: string;
}

function EditarCodRemessa(props: EditarCodRemessaProps) {
  const style = EditarCodRemessaStyle();
  const { onClose, cod_remessa } = props;

  const [codRemessaValue, setCodRemessaValue] = useState<string>(cod_remessa);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodRemessaValue(e.target.value);
  };

  /**
   * Resgata dados do form e dispara mensagem
   */
  const { register, handleSubmit } = useForm<{ cod_remessa: string }>();

  const onSubmit = (data: { cod_remessa: string }) => {
    return data;
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Editar Código de Remessa</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={style.modalContent}>
          <TextFieldHookForms
            {...register('cod_remessa')}
            id="cod_remessa"
            placeholder="Código da Remessa"
            variant="outlined"
            size="small"
            fullWidth
            value={codRemessaValue}
            onChange={handleValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditarCodRemessa;
