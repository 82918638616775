import React, { useEffect, useMemo, useState, useRef } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ModeracaoEtapa, Produto, FiltrosVariacao } from 'interfaces';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomInputCurrency from 'components/form/custom-input-currency';
import CalculoPrecoService from 'services/calculo-preco/CalculoPrecoService';
import { debounce } from 'components/utils/debounce';
import { useFiltros } from 'context/filtros';
import { useFormContext } from 'react-hook-form';
import { RadioHookForms } from 'components/form/text-field-hook-forms';
import ModeracaoProdutoStyles from './styles';

type Props = {
  dados?: ModeracaoEtapa['dados'];
  produto: Produto | null;
};

export function EtapaPreco(props: Props) {
  const { dados, produto } = props;

  const createEtapaId = (value: string) => `App\\Services\\Produto\\Etapas\\Models\\Dado\\${value}`;
  const [fieldPrecoCusto, setFieldPrecoCusto] = useState('');
  const [fieldPrecoVendedor, setFieldPrecoVendedor] = useState('');
  const [fieldPrecoLoja, setFieldPrecoLoja] = useState('');
  const [loadingCalculo, setLoadingCalculo] = useState(false);
  const [estadoConservacao, setEstadoConservacao] = useState<FiltrosVariacao[]>([]);
  const precoCusto = dados?.filter(dado => dado.id === createEtapaId('PrecoCusto'))[0];
  const precoLoja = dados?.filter(dado => dado.id === createEtapaId('PrecoLoja'))[0];
  const fieldPrecoCustoRef = useRef<HTMLInputElement | null>(null);

  const styles = ModeracaoProdutoStyles();

  const { handleFetchFilter } = useFiltros();

  const { register } = useFormContext();

  const [estadoConservacaoValue, setEstadoConservacaoValue] = useState<string>();

  const checkboxDefaultValue = useMemo(() => {
    return produto?.filtros['estado-conservacao'].variacoes.map(variacao => variacao.codfiltrovariacao)[0];
  }, [produto]);

  const sugestaoEU = useMemo(() => {
    return produto?.sugerir_preco;
  }, [produto]);

  const produtoreadOnly = useMemo(() => {
    return produto?.status === 'V';
  }, [produto]);

  const handleChangeEstadoConservacao = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEstadoConservacaoValue((e.target as HTMLInputElement).value);

  const calcularPreco = (valor: number) => {
    if (produto) {
      setLoadingCalculo(true);
      CalculoPrecoService.calcularPreco(produto?.categoria_principal.codcategoria, valor)
        .then(response => {
          setFieldPrecoVendedor(response.data.split.vendedora);
        })
        .finally(() => {
          setLoadingCalculo(false);
        });
    }
  };

  const debounceCalculoPrecoCusto = useMemo(
    () =>
      debounce((value: string) => {
        calcularPreco(Number(value));
      }, 1300),
    [] // eslint-disable-line
  );

  const handleChangePrecoCusto = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceCalculoPrecoCusto(e.target.value);
    setFieldPrecoCusto(e.target.value);
  };

  const handleChangePrecoLoja = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldPrecoLoja(e.target.value);
  };

  useEffect(() => {
    setFieldPrecoCusto(sugestaoEU ? '0' : `${produto?.precificacao?.venda_a_vista}`);
    setFieldPrecoVendedor(sugestaoEU ? '0' : `${produto?.repasse?.vendedor}`);
    setFieldPrecoLoja(sugestaoEU ? '0' : `${produto?.precificacao?.loja}`);
  }, [produto, sugestaoEU]);

  useEffect(() => {
    handleFetchFilter('estado-conservacao').then(response => setEstadoConservacao(response.data.data));
  }, []); // eslint-disable-line

  return (
    <Box py={2}>
      <FormControl variant="standard" component="fieldset">
        <FormLabel component="legend">
          <Typography variant="overline" color="textSecondary">
            Estado de conservação
          </Typography>
        </FormLabel>
        <RadioGroup
          row
          value={estadoConservacaoValue}
          onChange={handleChangeEstadoConservacao}
          defaultValue={`${checkboxDefaultValue}`}
        >
          {estadoConservacao.map(variacao => {
            return (
              <FormControlLabel
                key={variacao.codfiltrovariacao}
                value={`${variacao.codfiltrovariacao}`}
                control={<RadioHookForms {...register('cadastro_lei_conservacao')} color="primary" />}
                label={variacao.nome}
                className={styles.checkBox}
                disabled={produtoreadOnly}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Box pt={3}>
        <Grid container spacing={2}>
          {precoCusto?.exibir && (
            <Grid item>
              <CustomInputCurrency
                variant="outlined"
                value={fieldPrecoCusto}
                label="Preço Custo"
                size="small"
                name="precovenda"
                inputRef={fieldPrecoCustoRef}
                className={styles.textInput}
                onChange={handleChangePrecoCusto}
                disabled={loadingCalculo || produtoreadOnly}
                register={register}
              />
            </Grid>
          )}
          <Grid item>
            <CustomInputCurrency
              variant="outlined"
              value={fieldPrecoVendedor}
              label="Preço Vendedor"
              size="small"
              name="precovendedor"
              disabled
              className={styles.textInput}
              register={register}
            />
          </Grid>
          {precoLoja?.exibir && (
            <Grid item>
              <CustomInputCurrency
                variant="outlined"
                value={fieldPrecoLoja}
                label="Preço Loja"
                size="small"
                name="precoloja"
                className={styles.textInput}
                onChange={handleChangePrecoLoja}
                disabled={produtoreadOnly}
                register={register}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

EtapaPreco.defaultProps = {
  dados: null
};
