import React, { useState } from 'react';
import Button from '@mui/material/Button';
import VendedoraDevolucaoService from 'services/devolucao/VendedoraDevolucaoService';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';

export function BotaoRegerarDevolucao({
  codigo,
  refreshPedido
}: {
  codigo: string;
  refreshPedido: (() => void) | null;
}) {
  const [disabled, setDisabled] = useState(false);
  const { setLoading } = useLoading();

  /**
   * Validacao para saber se o pedido vai ser todo regerado, parciamente ou não vai ser regerado
   * regra de negocio: https://app.clickup.com/t/8689ne1eb
   */

  const handleValidacaoRegerarPedido = () => {
    setLoading(true);

    VendedoraDevolucaoService.regerar_pedido_validacao(codigo)
      .then(({ data: { validacao, produtos_bipados } }) => {
        setDisabled(true);

        if (validacao === 'cancelar-gerar') showModalCancelarGeral();
        if (validacao === 'desvincular-gerar') showModalDesvincularGeral({ produtos_bipados });
        if (validacao === 'manter-doacao') showModalManterDoacao();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Exibe modal que avisa que todos os itens do pedido já foram doados
   */

  const showModalManterDoacao = () => {
    Swal.fire({
      title: `Pedido ${codigo} já foi doado!`,
      html: 'Não foi possível regerar este pedido <br />pois todas as peças já foram doadas!'
    }).then(() => {
      setDisabled(false);
    });
  };

  /**
   * Exibe modal para regerar pedido que possui ALGUNS itens bipados
   */
  const showModalDesvincularGeral = ({ produtos_bipados }: { produtos_bipados: string }) => {
    Swal.fire({
      title: `Regerar Pedido ${codigo}`,
      html: `
        <div style="text-align: center">
          Este pedido não foi pago e está na fila de doação. <br />
          <strong><span style="color: red;">Mas atencão!</span> Foi verificado que este pedido possui itens
  que foram bipados como doados:</strong>
          <p>${produtos_bipados}</p>
          <strong>Tem certeza que deseja regerar este pedido?</strong><br />
          Ao confirmar, o pedido será regerado com os itens
          que ainda não foram bipados.
        </div>
      `,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não'
    }).then(handleModalConfirmOrDeny);
  };

  /**
   * Exibe modal para regerar pedido que NÃO POSSUI itens bipados
   */
  const showModalCancelarGeral = () => {
    Swal.fire({
      title: `Regerar Pedido ${codigo}`,
      html: 'Este pedido não foi pago e está na fila de doação. <br /> Tem certeza que deseja regerar este pedido?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não'
    }).then(handleModalConfirmOrDeny);
  };

  /**
   * Controla se o pedido vai ser regerado ou não confirme interacao com modal de validcão
   */

  const handleModalConfirmOrDeny = (result: { isConfirmed: boolean }) => {
    if (result.isConfirmed) {
      showModalPedidoRegerado();
    } else {
      setDisabled(false);
    }
  };
  /**
   * Modal de quando o pedido é regerado com sucesso
   */

  const showModalPedidoRegerado = () => {
    showModalProcessando();
    VendedoraDevolucaoService.regerar_pedido(codigo)
      .then(({ data: { pedido: codpedido } }) => {
        Swal.close();
        Swal.fire('Pedido regerado com sucesso', `O novo código para este pedido é: ${codpedido}`, 'success');
        refreshPedido?.();
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  /**
   * Modal de processamento do pedido que está sendo regerado
   */

  const showModalProcessando = () => {
    Swal.fire({
      title: 'Processando...',
      html: 'Aguarde enquanto o pedido é regerado',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  };

  return (
    <Button
      variant="contained"
      disableElevation
      color="warning"
      disabled={disabled}
      onClick={handleValidacaoRegerarPedido}
    >
      Regerar Pedido
    </Button>
  );
}
