import api from '../api';

export type ModelosData = {
  codmarca: number;
  codcategoria: number;
  nome: string;
  imagem: string;
};

const cadastrar = (data: ModelosData) => {
  return api.post(`/modelos`, data);
};

const listar = (query: string, page: number) => {
  return api.get(`/modelos?${query ? `${query}&` : ''}per_page=30&page=${page}&codcategoria_usar_raiz=1`);
};

const atualizar = (data: ModelosData, id?: number) => {
  return api.patch(`/modelos/${id}`, data);
};

const deletar = (id: number) => {
  return api.delete(`/modelos/${id}`);
};

const upload = (data: any) => { // eslint-disable-line
  return api.post(`/modelos/upload`, data);
};

export default {
  cadastrar,
  listar,
  atualizar,
  deletar,
  upload
};
