import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const AguardandoEnvioStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  }
}));

export default AguardandoEnvioStyle;
