import React from 'react';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { PackingProvider } from '../components/packing/PackingProvider';

function PackingDevolucao() {
  const filter = new Criteria();

  filter.add('tipo_pedido', '1');
  filter.add('codstatuspedido[]', '17');

  return (
    <PackingProvider title="Devolução" filter={filter}>
      <PackingProvider.PackingLayout />
    </PackingProvider>
  );
}

export default PackingDevolucao;
