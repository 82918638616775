import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { QueroVenderItem } from 'interfaces';
import { ModalHistoricoValor } from './modal-historico-valor';

type Props = {
  item: QueroVenderItem | undefined;
};

export function BotaoHistoricoValor(props: Props) {
  const { item } = props;
  const [historicoValor, setHistoricoValor] = useState(false);

  return (
    <div>
      {item?.sugestoes && (
        <>
          <Button color="primary" size="small" onClick={() => setHistoricoValor(true)}>
            ver histórico de valor
          </Button>

          <ModalHistoricoValor
            isOpen={historicoValor}
            onClose={() => setHistoricoValor(false)}
            sugestoes={item.sugestoes}
            avaliador={item?.avaliador?.nome}
          />
        </>
      )}
    </div>
  );
}
