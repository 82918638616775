import makeStyles from '@mui/styles/makeStyles';

const formRetiradaStyles = makeStyles(() => ({
  modalContent: {
    minWidth: '500px'
  },
  inputDate: {
    width: '100%'
  },
  endereco: {
    width: '100%',
    marginTop: '15px',
    fontSize: '12px'
  }
}));

export default formRetiradaStyles;
