import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Pedido } from 'interfaces';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormEndereco, FormEnderecoType } from 'components/form-endereco';
import Swal from 'sweetalert2';
import CheckingService from 'services/checking';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import alertValidationMessage from 'helpers/alert-validation-message';

type Props = {
  onClose: () => void;
  pedido: Pedido | null;
  onRefresh: () => void;
};

const validationSchema = Yup.object().shape({
  cep: Yup.string().required('O campo Cep é obrigatório'),
  endereco: Yup.string().required('O campo Rua é obrigatório'),
  numero: Yup.string().required('O campo Numero é obrigatório'),
  bairro: Yup.string().required('O campo Bairro é obrigatório'),
  cidade: Yup.string().required('O campo Cidade é obrigatório'),
  uf: Yup.string().required('O campo UF é obrigatório'),
  complemento: Yup.string().optional()
});

function ModalEditarEndereco(props: Props) {
  const { onClose, pedido, onRefresh } = props;

  const defaultValues = useMemo(() => {
    return {
      cep: pedido?.cep,
      endereco: pedido?.endereco,
      numero: pedido?.numero,
      complemento: pedido?.complemento,
      bairro: pedido?.bairro,
      cidade: pedido?.cidade,
      uf: pedido?.uf
    };
  }, [pedido]);

  const methods = useForm<FormEnderecoType>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: FormEnderecoType) => {
    CheckingService.editar_pedido(pedido?.codigo, sanitize(data)).then(() => {
      Swal.fire('Endereço atualizado com sucesso', '', 'success').then(({ isConfirmed }) => {
        if (isConfirmed) {
          onClose();
          onRefresh();
        }
      });
    });
  };

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0) {
      alertValidationMessage(methods.formState.errors);
    }
  }, [methods.formState.errors]);

  const sanitize = (data: FormEnderecoType) => {
    const cp_data = { ...data };

    if (cp_data.cep) {
      cp_data.cep = cp_data.cep.replace(/\D/g, '');
    }

    return cp_data;
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>Atualizar endereço</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <FormEndereco />
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={methods.handleSubmit(onSubmit)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEditarEndereco;
