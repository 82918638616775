import { useState } from 'react';
import { QueroVenderItem } from 'interfaces';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ModalTableLogs from 'components/modal-table-logs';
import { format } from 'date-fns';

type Props = {
  item: Partial<QueroVenderItem>;
  nome: string | undefined;
  thumbnail?: string;
};

export function CardDevolucaoItem(props: Props) {
  const { item, nome, thumbnail = undefined } = props;

  const [openLogs, setOpenLogs] = useState<boolean>(false);

  /**
   * Abre modal de logs
   */

  const handleOpenLogs = () => setOpenLogs(true);

  /**
   * Fecha modal de logs
   */

  const handleCloseLogs = () => setOpenLogs(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <Box display="inline-flex" alignItems="center">
            <Avatar src={thumbnail} alt="">
              I
            </Avatar>
            {nome && <Box ml={1}>{nome}</Box>}
          </Box>
        </TableCell>
        <TableCell>{item?.reprovado_por ?? '-'}</TableCell>
        <TableCell>{item?.devolucao_por ?? '-'}</TableCell>
        <TableCell>{item?.data_recusado ? format(new Date(item.data_recusado), 'dd/MM/yy hh:mm:ss') : '–'}</TableCell>
        <TableCell>
          {item?.produto?.logs && (
            <Button color="primary" onClick={handleOpenLogs}>
              Logs
            </Button>
          )}
        </TableCell>
      </TableRow>
      {item?.produto?.logs && (
        <ModalTableLogs
          handleCloseModal={handleCloseLogs}
          logs={item?.produto?.logs}
          title={`Logs do item #${item.codquerovenderitem}`}
          openLogs={openLogs}
        />
      )}
    </>
  );
}
