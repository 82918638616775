import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const LocalizarRemessaStyle = makeStyles(() => ({
  spaceButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingBottom: 7,
    paddingTop: 8
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  inputDate: {
    width: '100%',
    marginTop: 0
  }
}));

export default LocalizarRemessaStyle;
