import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import QueroVenderConverterEmProdutoService from 'services/quero-vender/QueroVenderConverterEmProdutoService';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import { FormQueroConverterData } from '../functions';
import { useValidateSchema } from './useValidateSchema';
import { useMapperPayload } from './useMapperPayload';
import styles from '../styles';

type Props = {
  openModalRejeitarItem(): void;
  openModalBipPedidoAceito(): void;
};

export function RodapeProdutoIndividual(props: Props) {
  const { openModalRejeitarItem, openModalBipPedidoAceito } = props;
  const { codQueroVender, isRequesting } = useFormAvaliacao();
  const { handleSubmit } = useFormContext<FormQueroConverterData>();
  const { setLoading } = useLoading();
  const { validate } = useValidateSchema();
  const { mapper } = useMapperPayload();

  const classes = styles();

  /**
   * Salvar Produto
   */

  const handleSalvarProduto = async (data: FormQueroConverterData) => {
    setLoading(true);

    try {
      await validate(data);
      const payload = mapper(data);

      if (!codQueroVender) {
        throw new TypeError('Código do batch é inexistente');
      }

      await QueroVenderConverterEmProdutoService.convert_produto_individual(codQueroVender, payload).then(() => {
        Swal.fire('O produto foi aceito', '', 'success').then(() => {
          setTimeout(() => {
            openModalBipPedidoAceito();
          }, 200);
        });
      });
    } catch (error) {
      if (error instanceof TypeError) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.message
        });
      }
      if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.btnsLeft}>
        <Button size="small" disabled={isRequesting} onClick={openModalRejeitarItem}>
          Rejeitar
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          size="small"
          disabled={isRequesting}
          className={classes.btnSalvar}
          onClick={handleSubmit(handleSalvarProduto)}
        >
          Aceitar Produto
        </Button>
      </Grid>
    </Grid>
  );
}
