import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateIcon from '@mui/icons-material/Create';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import TemplateBase from 'components/template-base/template';

import { CategoriaComissionamento } from 'interfaces/comissionamento';
import { LojaComissionada } from 'interfaces/comissionada';
import { CalculoPreco } from 'interfaces/calculopreco';
import CalculoPrecoService from 'services/calculo-preco/CalculoPrecoService';
import createTheme from '@mui/material/styles/createTheme';
import api from '../../services/api';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  resultadoCalculoPreco: {
    marginTop: theme.spacing(2)
  }
}));

function ComissionamentoLista() {
  document.title = 'Ferramentas';

  const [categorias, setCategorias] = useState<CategoriaComissionamento[]>([]);
  const [categoriasBase, setCategoriasBase] = useState<CategoriaComissionamento[]>([]);
  const [lojas, setLojas] = useState<LojaComissionada[]>([]);
  const [loading, setLoading] = useState(true);
  const [opens, setOpens] = useState<number[]>([]);
  /* calculo de preco */
  const [calcPreco, setCalcPreco] = useState<string>('');
  const [calcCodcategoria, setCalcCodcategoria] = useState<number>(0);
  const [calcCodloja, setCalcCodloja] = useState<number>(0);
  const [resultadoCalculoPreco, setResultadoCalculoPreco] = useState<CalculoPreco>();

  const classes = useStyles();

  // Toggles the display status of nested subcategories lists
  const handleClick = (codcategoria: number) => {
    const cloneOpens = [...opens];
    const indexOfElement = cloneOpens.indexOf(codcategoria);

    if (indexOfElement > -1) {
      cloneOpens.splice(indexOfElement, 1);
    } else {
      cloneOpens.push(codcategoria);
    }
    setOpens(cloneOpens);
  };

  const isOpen = (index: number) => {
    return opens.indexOf(index) > -1;
  };

  const calcularPreco = () => {
    CalculoPrecoService.calcularPreco(calcCodcategoria, calcPreco, calcCodloja).then(response => {
      setResultadoCalculoPreco(response.data);
    });
  };

  /* puxa lista de categorias do servidor e monta a árvore de categorias */
  useEffect(() => {
    api.get('categorias').then(response => {
      const listaCategorias: CategoriaComissionamento[] = response.data.data.sort(
        (a: CategoriaComissionamento, b: CategoriaComissionamento) => {
          const textA = a.nome.toUpperCase();
          const textB = b.nome.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          return 0;
        }
      );
      // filtra categorias base onde codcategoriapai === 1
      const tempCategoriasBase: CategoriaComissionamento[] = listaCategorias.filter(e => e.codcategoriapai === 1);
      setCategorias(listaCategorias);
      setCategoriasBase(tempCategoriasBase);
      setLoading(false);
    });

    api.get('lojas?per_page=9999999').then(response => {
      setLojas(response.data.data);
    });
  }, []);

  /**
   * Template
   */
  return (
    <TemplateBase>
      <h1>Comissionamento</h1>

      <Paper elevation={1} className={classes.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <h3>Calcular preço</h3>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  name="preco"
                  label="Preço do produto"
                  placeholder="Preço do produto"
                  variant="outlined"
                  size="small"
                  onChange={e => {
                    setCalcPreco(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel id="categoria-search-label">Categoria</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="categoria-search-label"
                    id="categoria-search-select"
                    label="Categoria"
                    onChange={(e: SelectChangeEvent<number | null>) => {
                      setCalcCodcategoria(e.target.value as number);
                    }}
                  >
                    {categorias.map((itemCategoria: CategoriaComissionamento) => (
                      <MenuItem key={itemCategoria.codcategoria} value={itemCategoria.codcategoria}>
                        {itemCategoria.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" variant="outlined" fullWidth>
                  <InputLabel id="loja-search-label">Loja/vendedora</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="loja-search-label"
                    id="loja-search-select"
                    label="Loja/vendedora"
                    value={calcCodloja}
                    onChange={(e: SelectChangeEvent<number | null>) => {
                      setCalcCodloja(e.target.value as number);
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Loja/vendedora
                    </MenuItem>
                    {lojas.map((itemLoja: LojaComissionada) => (
                      <MenuItem key={itemLoja.codloja} value={itemLoja.codloja}>
                        {itemLoja.nome} {itemLoja.sobrenome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  endIcon={<AttachMoneyIcon />}
                  onClick={calcularPreco}
                >
                  Calcular preço
                </Button>
              </Grid>
            </Grid>

            {resultadoCalculoPreco ? (
              <>
                <Grid container spacing={1} className={classes.resultadoCalculoPreco}>
                  <Grid item xs={3}>
                    <b>Faixa de Comissão:</b> {resultadoCalculoPreco.faixa_comissao_eu.codigo}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <b>Preço Boleto:</b> R$ {resultadoCalculoPreco.preco.boleto}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Preço Parcelado:</b> R$ {resultadoCalculoPreco.preco.parcelado}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <b>Valor vendedora:</b> R$ {resultadoCalculoPreco.split.vendedora}
                  </Grid>
                  <Grid item xs={3}>
                    <b>Comissão EÚ:</b> {resultadoCalculoPreco.faixa_comissao_eu.comissao * 100}%
                  </Grid>
                  {resultadoCalculoPreco.faixa_comissionada ? (
                    <Grid item xs={3}>
                      <b>Comissionada:</b> R$ {resultadoCalculoPreco.split.comissionada} (
                      {resultadoCalculoPreco.faixa_comissionada.comissionada * 100}%)
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Paper>
      {loading && <Box my={2}>carregando...</Box>}
      {!loading && categoriasBase.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma categoria de comissionamento encontrada
          </Box>
        </Box>
      )}
      {!loading && categoriasBase.length > 0 && (
        <Paper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Categoria</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoriasBase &&
                categoriasBase.map(item => (
                  <TableRow key={item.codcategoria}>
                    <TableCell padding="none">
                      <ListItemButton onClick={() => handleClick(item.codcategoria)}>
                        <ListItemIcon>
                          {isOpen(item.codcategoria) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemIcon>
                        <ListItemText primary={item.nome} />
                        <Link to={`${item.codcategoria}`}>
                          <CreateIcon />
                        </Link>
                      </ListItemButton>
                      {categorias.filter(e => e.codcategoriapai === item.codcategoria) ? (
                        <Collapse in={isOpen(item.codcategoria)} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {categorias
                              .filter(e => e.codcategoriapai === item.codcategoria)
                              .map(subCategoriaItem => (
                                <ListItemButton className={classes.nested}>
                                  <ListItemText primary={subCategoriaItem.nome} />
                                  <Link to={`${subCategoriaItem.codcategoria}`}>
                                    <CreateIcon />
                                  </Link>
                                </ListItemButton>
                              ))}
                          </List>
                        </Collapse>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </TemplateBase>
  );
}

export default ComissionamentoLista;
