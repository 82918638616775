import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import alertValidationMessage from 'helpers/alert-validation-message';
import Loading from 'components/loading';
import PagamentoVendedoras, { PayloadModalTentarNovamente } from 'services/ferramentas/PagamentosVendedoras';

import { RadioHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';
import PagamentosVendedorasStyle from '../style';

type ModalTentarNovamenteProps = {
  openModal: boolean;
  setModalTentar: (status: boolean) => void;
  codPedidoProduto: number;
  fetchPagamentos: () => void;
};

const schema = Yup.object().shape({
  motivo: Yup.string().required('Motivo é obrigatório')
});

function ModalTentarNovamente(props: ModalTentarNovamenteProps) {
  const classes = PagamentosVendedorasStyle();
  const { openModal, setModalTentar, codPedidoProduto, fetchPagamentos } = props;
  const [loadingFull, setLoadingFull] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm<PayloadModalTentarNovamente>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  const onSubmit = ({ role, ...data }: PayloadModalTentarNovamente) => {
    const formData = { ...data };

    formData.captadora = role === 'captadora';
    formData.vendedora = role === 'vendedora';

    setModalTentar(false);
    setLoadingFull(true);

    PagamentoVendedoras.tentarNovamente(codPedidoProduto, formData)
      .then(response => {
        if (response) {
          Swal.fire('Nova tentativa realizada com sucesso', '', 'success').then(() => {
            fetchPagamentos();
          });
        }
      })
      .catch(() => {
        // Erro tratado no interceptor
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <>
      <Dialog open={openModal} onClose={() => setModalTentar(false)}>
        <DialogTitle id="form-dialog-title">Tentar novamente</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormControl variant="standard" fullWidth>
              <RadioGroup row defaultValue="vendedora">
                <FormControlLabel
                  value="vendedora"
                  control={<RadioHookForms color="primary" {...register('role')} />}
                  label="Vendedora"
                />
                <FormControlLabel
                  value="captadora"
                  control={<RadioHookForms color="primary" {...register('role')} />}
                  label="Captadora"
                />
              </RadioGroup>
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <TextFieldHookForms variant="standard" {...register('motivo')} label="Motivo" multiline maxRows={6} />
            </FormControl>

            <div className={classes.wrapModalButtons}>
              <Button variant="contained" className={classes.buttonModalCancelar} onClick={() => setModalTentar(false)}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                OK
              </Button>
            </div>
          </DialogContent>
        </form>
      </Dialog>

      <Loading open={loadingFull} />
    </>
  );
}

export default ModalTentarNovamente;
