import api from '../api';

const getFiltrosLocalizacao = () => {
  return api.get('/picking/filtros');
};

const getFilaPicking = (query: string) => {
  return api.get(`/picking/fila?${query}`);
};

const pularProduto = (codpedidoproduto: number) => {
  return api.patch(`/picking/pular/${codpedidoproduto}`);
};

const separarProduto = (codigodebarras: number | string, query = '') => {
  return api.patch(`/picking/separar-produto/${codigodebarras}?${query}`);
};

const marcarNaoLocalizado = (codpedidoproduto: number) => {
  return api.patch(`/pedido-produto/${codpedidoproduto}/nao-localizado`);
};

const produtosNaoLocalizados = (query: string) => {
  return api.get(`pedido-produto/nao-localizados?${query}`);
};

const marcarResolvido = (codpedidoproduto: number, data: { observacao: string }) => {
  return api.patch(`pedido-produto/${codpedidoproduto}/resolvido`, { ...data });
};

const separarProdutoFotografia = (codproduto: string, status: number) => {
  return api.get(`/picking/separar-produto-fotografia?codigo=${codproduto}&status=${status}`);
};

const getFiltrosOrigem = () => {
  return api.get('/picking/filtros-origem');
};

export default {
  getFiltrosOrigem,
  getFiltrosLocalizacao,
  getFilaPicking,
  pularProduto,
  separarProduto,
  marcarNaoLocalizado,
  produtosNaoLocalizados,
  marcarResolvido,
  separarProdutoFotografia
};
