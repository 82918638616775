import React, { createContext, useMemo, useState } from 'react';

import { QueroVenderBatch, Endereco } from 'interfaces';

interface RetiradaPostagemContextData {
  openModalPostagem: boolean;
  setOpenModalPostagem: React.Dispatch<React.SetStateAction<boolean>>;
  openModalRetirada: boolean;
  setOpenModalRetirada: React.Dispatch<React.SetStateAction<boolean>>;
  codquerovender: number;
  setCodquerovender: React.Dispatch<React.SetStateAction<number>>;
  enderecos: Array<Endereco>;
  setEnderecos: React.Dispatch<React.SetStateAction<Array<Endereco>>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  batches: Array<QueroVenderBatch>;
  setBatch: React.Dispatch<React.SetStateAction<Array<QueroVenderBatch>>>;
}

const RetiradaPostagemContext = createContext<RetiradaPostagemContextData>({} as RetiradaPostagemContextData);

function RetiradaPostagemProvider({ children }: { children: React.ReactElement }) {
  const [openModalPostagem, setOpenModalPostagem] = useState(false);
  const [openModalRetirada, setOpenModalRetirada] = useState(false);
  const [codquerovender, setCodquerovender] = useState(0);
  const [enderecos, setEnderecos] = useState<Array<Endereco>>([]);
  const [loading, setLoading] = useState(false);
  const [batches, setBatch] = useState<Array<QueroVenderBatch>>([]);

  const values = useMemo(
    () => ({
      openModalPostagem,
      setOpenModalPostagem,
      openModalRetirada,
      setOpenModalRetirada,
      codquerovender,
      setCodquerovender,
      enderecos,
      setEnderecos,
      loading,
      setLoading,
      batches,
      setBatch
    }),
    [
      openModalPostagem,
      setOpenModalPostagem,
      openModalRetirada,
      setOpenModalRetirada,
      codquerovender,
      setCodquerovender,
      enderecos,
      setEnderecos,
      loading,
      setLoading,
      batches,
      setBatch
    ]
  );

  return <RetiradaPostagemContext.Provider value={values}>{children}</RetiradaPostagemContext.Provider>;
}

function useRetiradaPostagem() {
  const context = React.useContext(RetiradaPostagemContext);

  if (!context) {
    throw new Error('useRetiradaPostagem deve usado dentro de um RetiradaPostagemProvider');
  }

  return context;
}

export { RetiradaPostagemProvider, useRetiradaPostagem };
