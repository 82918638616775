import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch, QueroVenderItem } from 'interfaces';

interface BatchesContextData {
  fetchBatches(id: string): Promise<{ data: QueroVenderBatch }>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  batch: QueroVenderBatch;
  loading: boolean;
  contagemProdutos: { aceitos: number; reprovados: number; naoRecebidos: number };
}

const BatchesContext = createContext<BatchesContextData>({} as BatchesContextData);

function BatchesProvider({ children }: { children: React.ReactElement }) {
  const [batch, setBatch] = useState<QueroVenderBatch>({} as QueroVenderBatch);
  const [loading, setLoading] = useState<boolean>(true);
  const [contagemProdutos, setContagemProdutos] = useState({
    aceitos: 0,
    reprovados: 0,
    naoRecebidos: 0
  });

  const fetchBatches = useCallback(async (id = '') => {
    const result = await QueroVenderService.get(id);

    setBatch(result.data);
    setContagemProdutos(handleContagemProdutos(result.data.itens));

    return result;
  }, []);

  const handleContagemProdutos = (itens: QueroVenderItem[]) => {
    const produtos = {
      aceitos: 0,
      reprovados: 0,
      naoRecebidos: 0
    };

    for (let i = 0; i < itens.length; i += 1) {
      const item = itens[i];

      if (typeof item?.aceite_fisico === 'boolean' && item?.aceite_fisico) {
        produtos.aceitos += 1;
      }
      if (typeof item?.aceite_fisico === 'boolean' && !item?.aceite_fisico) {
        produtos.reprovados += 1;
      }

      if (typeof item?.recebido === 'boolean' && item?.recebido === false) {
        produtos.naoRecebidos += 1;
      }
    }

    return produtos;
  };

  const values = useMemo(
    () => ({ fetchBatches, setLoading, loading, batch, contagemProdutos }),
    [fetchBatches, setLoading, loading, batch, contagemProdutos]
  );

  return <BatchesContext.Provider value={values}>{children}</BatchesContext.Provider>;
}

const useBatchesProvider = () => {
  const context = useContext(BatchesContext);

  if (!context) {
    throw new Error('Use esse hooke dentro de um BatchesProvider');
  }

  return context;
};

export { BatchesProvider, useBatchesProvider };
