import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Send } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';
import QueroVenderSplitBatchService, { SplitData } from 'services/quero-vender/QueroVenderSplitBatchService';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import alertValidationMessage from 'helpers/alert-validation-message';
import { QueroVenderItem, Cliente, Sacola } from 'interfaces';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import entradaManualStyle from './style';

interface ItensSacolas {
  [index: number]: number;
}

interface SacolasSelectProps {
  codquerovenderitem: number;
  itensSacolas: ItensSacolas;
  sacolas: Sacola[];
  getItensSacolas(param: ItensSacolas): void;
}

function SacolaSelect(props: SacolasSelectProps) {
  const { codquerovenderitem, itensSacolas, sacolas, getItensSacolas } = props;
  const style = entradaManualStyle();

  const cpItensSacolas = { ...itensSacolas };

  const [value, setValue] = useState<number | null>(itensSacolas[codquerovenderitem]);

  const handleChange = (event: SelectChangeEvent<number | null>) => {
    cpItensSacolas[codquerovenderitem] = Number(event.target.value);
    setValue(Number(event.target.value));
    getItensSacolas(cpItensSacolas);
  };

  useEffect(() => {
    setValue(itensSacolas[codquerovenderitem]);
  }, [itensSacolas, codquerovenderitem]);

  return (
    <Select
      native
      error={!value}
      variant="outlined"
      className={style.select}
      fullWidth
      onChange={handleChange}
      value={value}
    >
      <option value="">-</option>
      {sacolas && sacolas.map((sacola: Sacola) => <option value={sacola.id}>{sacola.label}</option>)}
    </Select>
  );
}

/**
 * COMPONENTE
 */
function EntradaManual() {
  const style = entradaManualStyle();

  document.title = 'Pré-Avaliação';

  const [loadingFull, setLoadingFull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itensCategorizados, setItensCategorizados] = useState<QueroVenderItem[]>([]);
  const [sacolas, setSacolas] = useState<Sacola[]>([]);
  const [itensSacolas, setItensSacolas] = useState<ItensSacolas>({});
  const [cliente, setCliente] = useState<Cliente>();
  const [codVendedora, setCodVendedora] = useState<string>();

  const { codquerovender } = useParams() as { codquerovender: string };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm();

  const adicionaProdutosSacolas = useCallback(
    (object: ItensSacolas) => {
      const objectKeys: string[] = Object.keys(object);
      const objectSacolas = {} as {
        [index: number]: number[];
      };

      if (sacolas) {
        for (let i = 0; i < sacolas?.length; i += 1) {
          const { id } = sacolas[i];

          objectSacolas[id] = [];
        }
      }

      for (let i = 0; i < objectKeys.length; i += 1) {
        if (object[Number(objectKeys[i])] === null) {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Verifique os produtos sem sacola na tabela'
          });

          return false;
        }

        if (objectSacolas[object[Number(objectKeys[i])]]) {
          objectSacolas[object[Number(objectKeys[i])]].push(Number(objectKeys[i]));
        }
      }

      return objectSacolas;
    },
    [sacolas]
  );

  const codQueroVenderFetch = useCallback(async () => {
    setLoading(true);
    await QueroVenderService.get(codquerovender).then(response => {
      setCliente(response.data.cliente);
      setCodVendedora(response.data.vendedora?.codigo);
    });
    await QueroVenderSplitBatchService.get_info(codquerovender).then(response => {
      setItensCategorizados(response.data.itens);
      setItensSacolas(response.data.sacolas);
    });
    setLoading(false);
  }, [codquerovender]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (codquerovender) {
      codQueroVenderFetch();
    }
  }, [codquerovender, codQueroVenderFetch]);

  useEffect(() => {
    QueroVenderSplitBatchService.get_tipos().then(response => {
      setSacolas(response.data);
    });
  }, []);

  /* eslint-disable-next-line */
  const validationSchema = (data: any) => {
    const { vendedora } = data;
    /* eslint-disable-next-line */
    const schemaData = {} as any;

    if (vendedora || vendedora?.length === 0) {
      schemaData.vendedora = Yup.string().required('O campo código da vendedora é obrigatório');
    }

    Object.keys(data).forEach(value => {
      const id = value.replace(/\D/g, '');

      if (data[`quantidade_${id}`]?.length > 0 || data[`descricao_${id}`]?.length > 0) {
        schemaData[`quantidade_${id}`] = Yup.string().required(`O Campo quantidade ${id} é obrigatório`);
        schemaData[`descricao_${id}`] = Yup.string().required(`O Campo descricao ${id} é obrigatório`);
      }
    });

    return schemaData;
  };

  /* eslint-disable-next-line */
  const validaPreenchientoCarrinhos = (data: any) => {
    const campos = Object.keys(data);

    for (let i = 0; i < campos.length; i += 1) {
      if (campos[i].match('quantidade')) {
        if (data[campos[i]].length > 0) {
          return false;
        }
      }
    }

    Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'Pelo menos uma das sacolas precisa ser preenchida'
    });
  };

  /**
   * Submit Form
   */

  /* eslint-disable-next-line */
  const onSubmit = async (data: any) => {
    const body: SplitData = {
      sacolas: []
    };

    let sacolasPreenchidas = false;

    /* eslint-disable-next-line */
    let produtosAdicionados: any;

    if (itensCategorizados.length > 0) {
      produtosAdicionados = adicionaProdutosSacolas(itensSacolas);

      if (!produtosAdicionados) {
        return false;
      }
    }

    if (codquerovender) {
      body.codquerovender = codquerovender;
    }

    if (data.vendedora) {
      body.vendedora_codigo = data.vendedora;
    }

    if (data.captadora) {
      body.captadora_codigo = data.captadora;
    }

    for (let i = 0; i < sacolas.length; i += 1) {
      if (data[`quantidade_${sacolas[i].id}`].length > 0 && data[`descricao_${sacolas[i].id}`].length > 0) {
        const obj = {
          sacola_tipo_id: sacolas[i].id,
          extra: data[`quantidade_${sacolas[i].id}`] as number,
          itens: [],
          descricao: data[`descricao_${sacolas[i].id}`]
        };
        if (produtosAdicionados) {
          obj.itens = produtosAdicionados[sacolas[i].id];
        }
        body?.sacolas?.push(obj);

        sacolasPreenchidas = true;
      }
    }

    try {
      validaPreenchientoCarrinhos(data);
      const objSchema = validationSchema(data);

      const schema = Yup.object().shape(objSchema);
      await schema.validate(data, { abortEarly: false });

      if (!sacolasPreenchidas) {
        return false;
      }

      setLoadingFull(true);

      await QueroVenderSplitBatchService.split(body).then(() => {
        Swal.fire(
          `${codquerovender ? 'Remessas criadas com sucesso' : 'Remessa criada com sucesso'}`,
          '',
          'success'
        ).then(() => {
          navigate('/avaliacao/avaliacao-fisica-batch');
        });
      });
    } catch (error) {
      if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    } finally {
      setLoadingFull(false);
    }

    return null;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  /**
   * Template
   */

  return (
    <TemplateBase>
      <h1>Entrada de produtos Manual</h1>
      {loading ? (
        'Carregando...'
      ) : (
        <>
          <Box paddingY={2}>
            {cliente && (
              <>
                <div>
                  <strong>Nome da cliente: </strong>
                  <span>{cliente?.nome}</span>
                </div>
                <div>
                  <strong>Email da cliente: </strong>
                  <span>{cliente?.email}</span>
                </div>
              </>
            )}
            {codVendedora && (
              <div>
                <strong>Código da vendedora: </strong>
                <span>{codVendedora}</span>
              </div>
            )}
          </Box>

          {itensCategorizados?.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Box ml={2}>
                  <h2>Produtos Categorizados</h2>
                </Box>
                <Table>
                  <TableHead className={style.tableHead}>
                    <TableRow>
                      <TableCell className={style.tableCell}>Produto</TableCell>
                      <TableCell className={style.tableCell}>Sacola</TableCell>
                    </TableRow>
                  </TableHead>
                  {itensCategorizados && (
                    <TableBody>
                      {(rowsPerPage > 0 && itensCategorizados
                        ? itensCategorizados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : itensCategorizados
                      ).map((item: QueroVenderItem) => {
                        return (
                          <TableRow>
                            <TableCell width={300}>
                              <Box flexDirection="row" display="flex" alignItems="center">
                                <Avatar src={item.fotos[0]?.imagem} alt="" />
                                <Box ml={2}>{item.titulo}</Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <div>
                                {itensSacolas && (
                                  <SacolaSelect
                                    codquerovenderitem={item.codquerovenderitem}
                                    itensSacolas={itensSacolas}
                                    sacolas={sacolas}
                                    getItensSacolas={(i: ItensSacolas) => setItensSacolas(i)}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                rowsPerPageOptions={[5]}
                count={itensCategorizados.length}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            </>
          )}

          <Paper elevation={1} className={style.wrapForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="column" spacing={2}>
                {!codquerovender && (
                  <>
                    <Grid item xs={5}>
                      <label htmlFor="vendedora" className={style.labelField}>
                        Vendedora:
                      </label>
                      <TextFieldHookForms
                        {...register('vendedora')}
                        id="vendedora"
                        placeholder="Código da vendedora"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <label htmlFor="captadora" className={style.labelField}>
                        Captadora:
                      </label>
                      <TextFieldHookForms
                        {...register('captadora')}
                        id="captadora"
                        placeholder="Código da captadora"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </>
                )}

                {sacolas?.map(item => {
                  return (
                    <>
                      <Grid item xs={5}>
                        <Typography variant="overline" color="textSecondary" className={style.titles}>
                          <Box display="inline-flex" alignItems="center">
                            <LocalMallIcon style={{ color: item.cor, marginRight: 5 }} />
                            {item.label}
                          </Box>
                        </Typography>
                      </Grid>

                      <Grid item xs={5}>
                        <label htmlFor={`quantidade_${item.id}`} className={style.labelField}>
                          Quantidade de itens:
                        </label>
                        <TextFieldHookForms
                          {...register(`quantidade_${item.id}`)}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <label htmlFor={`descricao_${item.id}`} className={style.labelField}>
                          Descrição:
                        </label>
                        <TextField
                          {...register(`descricao_${item.id}`)}
                          multiline
                          rows={2}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </>
                  );
                })}

                <Grid item xs={5} className={`${style.boxField}`}>
                  <Button type="submit" variant="contained" color="primary" endIcon={<Send />}>
                    Criar remessa
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </>
      )}

      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default EntradaManual;
