import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    textInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      },

      '& .MuiInputLabel-root': {
        fontSize: 13
      }
    }
  })
);
