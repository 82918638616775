import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

export type Atributo = {
  id_atributo: string;
  valor: string;
};

export type FormData = {
  itens_inclusos: number[] | string[];
  atributos: Atributo[] | string[];
  filtrovariacoes: {
    codfiltrovariacao: number;
  }[];
  codmarca: number;
  codcategoria: number;
  precificacao: {
    loja: string;
    venda_a_vista: string;
  };
  modelo_especifico?: string;
  modelo_id?: number;
  precoloja: string;
  precovenda: string;
  [key: string]: any; // eslint-disable-line
};
export function FormModeracaoProvider({ children }: { children: React.ReactElement }) {
  const methods = useForm<FormData>({ defaultValues: {} as FormData });

  return <FormProvider {...methods}>{children}</FormProvider>;
}
