import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ModeracaoEtapa, Produto, ProdutoAtributo } from 'interfaces';
import { useFormContext, UseFormRegister } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';

type Props = {
  dados?: ModeracaoEtapa['dados'];
  produto: Produto | null;
};

function FieldAtributos({
  atributo,
  produtoreadOnly,
  index,
  register
}: {
  atributo: ProdutoAtributo;
  produtoreadOnly: boolean;
  index: number;
  register: UseFormRegister<any>; // eslint-disable-line
}) {
  const [value, setValue] = useState<string | null>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      <TextFieldHookForms
        size="small"
        fullWidth
        variant="outlined"
        defaultValue={atributo?.pivot.valor}
        label={atributo.nome_atributo}
        disabled={produtoreadOnly}
        onChange={handleChange}
        value={value}
        InputProps={{
          endAdornment: <span>{atributo.medida_padrao}</span>
        }}
      />
      <input
        type="hidden"
        {...register(`atributos.${index}`)}
        value={`{ "id_atributo": "${atributo.id}", "valor": "${value || atributo.pivot.valor}" }`}
      />
    </>
  );
}

export function EtapaMedidas(props: Props) {
  const { dados, produto } = props;

  const produtoreadOnly = useMemo(() => {
    return produto?.status === 'V';
  }, [produto]);

  const createEtapaId = (value: string) => `App\\Services\\Produto\\Etapas\\Models\\Dado\\${value}`;

  const medidas = dados?.filter(dado => dado.id === createEtapaId('Medidas'))[0];

  const { register } = useFormContext();

  return medidas?.exibir ? (
    <Box py={2}>
      <Grid container spacing={2}>
        {produto &&
          produto?.atributos?.map((atributo, index) => {
            return (
              <Grid key={atributo.id} item xs={4}>
                <FieldAtributos
                  atributo={atributo}
                  register={register}
                  produtoreadOnly={produtoreadOnly}
                  index={index}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  ) : null;
}
