import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const formPostagemStyles = makeStyles(() => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalContent: {
    minWidth: '500px'
  }
}));

export default formPostagemStyles;
