import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import TemplateBase from 'components/template-base/template';
import { ApiListMeta, QueroVenderBatch } from 'interfaces';
import useDebounce from 'hooks/debounce';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import BatchNovaRemessaRow from './batchNovaRemessaRow';

import AguardandoEnvioStyle from './style';

function AguardandoEnvio() {
  const classes = AguardandoEnvioStyle();

  const [batches, setBatch] = useState<Array<QueroVenderBatch>>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [buscaNome, setBuscaNome] = useState('');

  const nomeCliente = useDebounce(buscaNome, 500);
  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  document.title = `Pré-Avaliação`;

  /**
   * Handler paginação - Modifica página ativa
   */
  const perPage = 30;
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  /**
   * Pega os dados da API e seta no state itens
   */
  const getNovaRemessa = useCallback(() => {
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}`;

    setLoading(true);

    QueroVenderService.list('19', queryMeta)
      .then(response => {
        if (response) {
          setBatch(response?.data.data);
          setMeta(response?.data.meta);
        } else {
          setBatch([]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [nomeCliente, page]);

  useEffect(() => {
    getNovaRemessa();
  }, [getNovaRemessa]);

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  return (
    <TemplateBase>
      <h1>{`Aguardando Envio${renderCount}`}</h1>

      <Paper elevation={1} className={classes.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <h3>Localizar remessa</h3>

                <TextField
                  name="nomeremessa"
                  label="Nome ou código do cliente"
                  placeholder="Nome ou código do cliente"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {loading && <Box>Carregando ...</Box>}
      {!loading && batches.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Produtos</TableCell>
                  <TableCell>Cadastrado em</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {batches &&
                  batches.map((batch: QueroVenderBatch) => (
                    <BatchNovaRemessaRow key={batch.codquerovender} batch={batch} />
                  ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}

      {!loading && batches.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma Remessa encontrada
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default AguardandoEnvio;
