import api from '../api';

const list = (param: string) => {
  return api.get(`/vendedora-devolucoes/dashboard?${param}`);
};

const criar_devolucao = (codloja: string) => {
  return api.post(`vendedora-devolucoes/criar-pedido-devolucao`, {
    codloja
  });
};

export default { list, criar_devolucao };
