import React, { forwardRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Radio, { RadioProps } from '@mui/material/Radio';
import Select, { SelectProps } from '@mui/material/Select';
import Switch, { SwitchProps } from '@mui/material/Switch';

export const TextFieldHookForms = forwardRef<HTMLInputElement, TextFieldProps>(function TextFieldHookForms(
  props: TextFieldProps,
  ref
) {
  return <TextField {...props} inputRef={ref} />;
});

export const CheckboxHookForms = forwardRef<HTMLInputElement, CheckboxProps>(function CheckboxHookForms(
  props: CheckboxProps,
  ref
) {
  return <Checkbox {...props} inputRef={ref} />;
});

export const RadioHookForms = forwardRef<HTMLInputElement, RadioProps>(function RadioHookForms(props: RadioProps, ref) {
  return <Radio {...props} inputRef={ref} />;
});

export const SelectHookForms = forwardRef<HTMLInputElement, SelectProps>(function SelectHookForms(
  props: SelectProps,
  ref
) {
  return <Select {...props} inputRef={ref} />;
});

export const SwitchHookForms = forwardRef<HTMLInputElement, SwitchProps>(function SwitchHookForms(
  props: SwitchProps,
  ref
) {
  return <Switch {...props} inputRef={ref} />;
});
