import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';

import { QueroVenderLogs } from 'interfaces';

import ModalTableLogsStyle from './style';

interface Props {
  openLogs: boolean;
  logs: QueroVenderLogs[] | undefined;
  handleCloseModal: () => void;
  title: string;
}

function ModalTableLogs(props: Props) {
  const style = ModalTableLogsStyle();

  const { logs, openLogs, handleCloseModal, title } = props;

  return (
    <Dialog open={openLogs} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
      <DialogTitle>
        <Typography component="span" variant="h6">
          {title}
        </Typography>
        <IconButton aria-label="close" className={style.closeButton} onClick={handleCloseModal} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <List className={style.root}>
          {logs &&
            logs.map((log: QueroVenderLogs) => (
              <div key={log?.descricao}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'button'
                    }}
                    primary={log?.descricao}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" className={style.inline} color="textPrimary">
                          {`${log?.usuario?.nome} - `}
                        </Typography>
                        {format(new Date(log?.data), 'dd/MM/yyyy HH:mm:ss')}
                      </>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </div>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default ModalTableLogs;
