import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';
import { FilaPicking, ApiListMeta } from 'interfaces';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import Swal from 'sweetalert2';
import PickingService from 'services/picking';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { PickingLayout } from '../components/picking/picking-layout';
import BarCodeStyle from '../components/picking/barcode-picking/styles';
import useFiltrosLocalizacao from '../components/picking/filter-select-picking/useFiltrosLocalizacao';

function Picking() {
  document.title = 'Expedição | Fila de Picking';

  const [filaPicking, setFilaPicking] = useState<FilaPicking[]>([]);
  const [filaComLacre, setFilaComLacre] = useState<FilaPicking[]>([]);
  const [loadingFull, setLoadingFull] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState<number>(1);
  const main = document.getElementsByTagName('main');
  const filter = useMemo(() => new Criteria(), []);
  const barCodeStyle = BarCodeStyle();

  const barCodeRef = useRef<{
    barCodeFocus: () => void;
    barCodeClear: () => void;
  }>(null);

  const handleSelectClose = () => {
    barCodeRef?.current?.barCodeFocus();
  };

  const handleInputKeyPress = (codBarras: string) => {
    setLoadingFull(true);

    PickingService.separarProduto(codBarras)
      .then(response => {
        if ('error' in response.data) {
          if (response.data.error === 0) {
            Swal.fire('Produto separado!', '', 'success');
          }
        }
        loadListaPicking();
        setLoadingFull(false);
      })
      .catch(() => {
        loadListaPicking();
        setLoadingFull(false);
      })
      .finally(() => {
        barCodeRef?.current?.barCodeClear();
      });
  };

  const loadListaPicking = useCallback(() => {
    setLoading(true);

    filter.add('page', `${page}`);

    PickingService.getFilaPicking(filter.getQuery())
      .then(response => {
        const filaPickingResponse = response.data.data.filter(
          (obj: FilaPicking) => !obj.pedidoproduto.lacre && obj.pular === 0
        );
        const filaComLacreResponse = response.data.data.filter(
          (obj: FilaPicking) => obj.pedidoproduto.lacre && obj.pular === 0
        );

        setFilaPicking(filaPickingResponse.length > 0 ? filaPickingResponse : []);
        setFilaComLacre(filaComLacreResponse.length > 0 ? filaComLacreResponse : []);
        setMeta(response.data.meta);
      })
      .finally(() => {
        setLoading(false);
        setLoadingFull(false);
      });
  }, [filter, page]);

  const handleSelectFilter = (value: string) => {
    filter.add('filtro', value);
    barCodeRef?.current?.barCodeFocus();
    loadListaPicking();
  };

  const receiveChildLoadingCall = (isLoading: boolean): void => {
    setLoading(isLoading);
    setLoadingFull(isLoading);
  };

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setLoadingFull(true);
    main[0]?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // puxa lista de produtos pelo PickingService
  useEffect(() => {
    loadListaPicking();
  }, [loadListaPicking]);

  return (
    <TemplateBase>
      <h1>Fila de Picking</h1>

      <PickingLayout>
        <>
          <PickingLayout.FilterSelectPicking
            onSelectChange={handleSelectFilter}
            getFiltrosHook={useFiltrosLocalizacao}
            onSelectClose={handleSelectClose}
          />
          <PickingLayout.TabPicking filaPickingLength={filaPicking.length} filaComLacreLength={filaComLacre.length} />
          <Box height="100vh">
            <PickingLayout.TabPanelPicking loading={loading} tabValue={0}>
              <div>
                {filaPicking.length > 0 &&
                  filaPicking.map(item => (
                    <PickingLayout.CardProductPicking
                      key={item?.pedidoproduto.codpedidoproduto}
                      item={item?.pedidoproduto}
                      loadListaPicking={loadListaPicking}
                      setParentLoading={receiveChildLoadingCall}
                      data_aprovacao={item.data_aprovacao}
                      atraso={item.atraso_picking === 1}
                      acervo={item.acervo}
                      btNaoEncontrado
                      cliente_vip={item?.cliente?.prioridade}
                    />
                  ))}
              </div>
            </PickingLayout.TabPanelPicking>
            <PickingLayout.TabPanelPicking loading={loading} tabValue={1}>
              <div>
                {filaComLacre.length > 0 &&
                  filaComLacre.map(item => (
                    <PickingLayout.CardProductPicking
                      key={item?.pedidoproduto.codpedidoproduto}
                      item={item?.pedidoproduto}
                      loadListaPicking={loadListaPicking}
                      setParentLoading={receiveChildLoadingCall}
                      data_aprovacao={item.data_aprovacao}
                      atraso={item.atraso_picking === 1}
                      acervo={item.acervo}
                      btNaoEncontrado
                      cliente_vip={item?.cliente?.prioridade}
                    />
                  ))}
              </div>
            </PickingLayout.TabPanelPicking>
            {!loading && meta && meta?.total > 0 && (
              <Paper elevation={5} className={barCodeStyle.campoCodBarras}>
                {meta && meta?.last_page > 1 && (
                  <Box display="flex" justifyContent="center" py={2} mt="-8px">
                    <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
                  </Box>
                )}
                <PickingLayout.BarCodePicking handleInputKeyPress={handleInputKeyPress} ref={barCodeRef} />
              </Paper>
            )}
          </Box>
        </>
      </PickingLayout>
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default Picking;
