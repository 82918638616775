import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { QueroVenderItemFoto } from 'interfaces';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fotos from '../Fotos';
import VideoPlaceholder from '../../../../assets/images/video-file.png';

import style from './style';
import 'react-image-lightbox/style.css';

type GaleriaFotosProps = {
  fotos: QueroVenderItemFoto[];
};

const customModalStyles = {
  overlay: {
    zIndex: 9999
  }
};

function GaleriaFotos(props: GaleriaFotosProps) {
  const classes = style();
  const { fotos } = props;
  const filterVideo = fotos.filter(image => image.tipo_midia === 'video');
  const hasVideo = filterVideo.length > 0;

  const [imagesModal, setImagesModal] = useState<{
    url: string;
    index: number;
    show: boolean;
  }>({
    url: '',
    index: 0,
    show: false
  });

  return (
    <>
      {imagesModal.show && (
        <Lightbox
          mainSrc={fotos[imagesModal.index].imagem}
          onCloseRequest={() =>
            setImagesModal({
              url: '',
              index: 0,
              show: false
            })
          }
          reactModalStyle={customModalStyles}
          nextSrc={
            fotos[imagesModal.index + 1] && fotos[imagesModal.index + 1].tipo_midia === 'foto'
              ? fotos[(imagesModal.index + 1) % fotos.length].imagem
              : ''
          }
          prevSrc={
            fotos[imagesModal.index - 1] && fotos[imagesModal.index - 1].tipo_midia === 'foto'
              ? fotos[(imagesModal.index + fotos.length - 1) % fotos.length].imagem
              : ''
          }
          onMoveNextRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + 1) % fotos.length
            })
          }
          onMovePrevRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + fotos.length - 1) % fotos.length
            })
          }
        />
      )}

      <Box mb={1} mt={1}>
        <Typography>Imagens</Typography>
      </Box>

      <div className={classes.wrapFotos}>
        {!fotos.length
          ? 'Nenhuma foto enviada'
          : fotos.map((value, i) => {
              return value.tipo_midia === 'foto' ? (
                <Fotos
                  onClick={() =>
                    setImagesModal({
                      url: value.imagem,
                      index: i,
                      show: true
                    })
                  }
                  codquerovenderfoto={value.codquerovenderfoto}
                  imagem={value.imagem}
                  url={value.imagem}
                  key={value.imagem.split('quero-vender-fotos/')[1]}
                />
              ) : null;
            })}
      </div>

      {hasVideo && (
        <>
          <Box mb={1} mt={1}>
            <Typography>Videos</Typography>
          </Box>

          <Box mb={2}>
            <div className={classes.wrapFotos}>
              {!fotos.length
                ? 'Nenhuma foto enviada'
                : fotos.map(value => {
                    return value.tipo_midia === 'video' ? (
                      <Fotos
                        onClick={() => window.open(value.imagem)}
                        codquerovenderfoto={value.codquerovenderfoto}
                        imagem={VideoPlaceholder}
                        url={VideoPlaceholder}
                        key={value.imagem.split('quero-vender-fotos/')[1]}
                      />
                    ) : null;
                  })}
            </div>
          </Box>
        </>
      )}
    </>
  );
}

export default GaleriaFotos;
