import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const MenuStyles = makeStyles(() => ({
  item: {
    padding: 0
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  doubleNested: {
    paddingLeft: theme.spacing(6)
  }
}));

export default MenuStyles;
