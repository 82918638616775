import React, { useEffect, useState, useCallback, useRef } from 'react';
import TemplateBase from 'components/template-base/template';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Loading from 'components/loading';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Barcode } from 'components/icons';
import Swal from 'sweetalert2';
import { QueroVenderItem, ApiListMeta } from 'interfaces';
import useDebounce from 'hooks/debounce';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import PickingService from 'services/picking';
import { ItemRow } from './item-row';
import ProdutosAvaliadosStyle from './style';

function PickingFotos() {
  document.title = 'Fotografias';

  const [itens, setItens] = useState<QueroVenderItem[]>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [isPagination, setIsPagination] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [barCode, setBarCode] = useState<string>('');
  const [loadingFull, setLoadingFull] = useState<boolean>(false);
  const [filtro, setFiltro] = useState<string>('');

  const codBarras = useRef<HTMLInputElement>(null);

  const [buscaNome, setBuscaNome] = useState('');

  const nomeCliente = useDebounce(buscaNome, 500);

  const styles = ProdutosAvaliadosStyle();

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Busca os batches em negociação
   */

  const fetchBatches = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const filtrocd = filtro.length > 0 ? `&centro_distribuicao=${filtro}` : '';
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}${filtrocd}`; // eslint-disable-line

    if (!isPagination) setPage(1);

    QueroVenderItemService.listar(queryMeta, 30).then(response => {
      if (response) {
        setItens(response?.data.data);
        setMeta(response?.data.meta);
      } else {
        setItens([]);
      }
      setLoading(false);
      setLoadingFull(false);
    });
  }, [isPagination, nomeCliente, page, filtro]);

  const barCodeFocus = () => {
    codBarras?.current?.focus();
  };

  /**
   * Controla filtros radio
   */

  const handleFiltros = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setFiltro(e.target.value);
    setLoadingFull(true);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (codBarras?.current) {
        setLoadingFull(true);
        PickingService.separarProdutoFotografia(codBarras?.current?.value, 30)
          .then(response => {
            if ('error' in response.data) {
              if (response.data.error === 0) {
                Swal.fire('Produto separado!', '', 'success');
              }
            }
            setBarCode('');
            fetchBatches();
            setLoadingFull(false);
            barCodeFocus();
          })
          .catch(() => {
            setBarCode('');
            fetchBatches();
            setLoadingFull(false);
            barCodeFocus();
          });
      }
    }
  };

  const handleOnChangeBarCode = (barCodeString: string) => {
    setBarCode(barCodeString);
  };

  /**
   * Controla disparo do enter para localizar produto por bip
   */

  // const handleLocalizarPorCodigoBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     const el = document.getElementById('codigoProduto') as HTMLInputElement;

  //     if (el) {
  //       // QueroVenderItemService.bip(el.value)
  //       //   .then(response => {
  //       //     console.log(response);
  //       //   })
  //       //   .catch(e => {
  //       //     if (e.response.status === 404) {
  //       //       Swal.fire('Oops', 'Código inválido', 'error').then(() => {
  //       //         el.value = '';
  //       //       });
  //       //     }
  //       //     if (e.response.status === 400) {
  //       //       Swal.fire('Oops', 'Produto não encontrado', 'error').then(() => {
  //       //         el.value = '';
  //       //       });
  //       //     }
  //       //   });
  //     }
  //   }
  // };

  useEffect(() => {
    fetchBatches();
  }, [fetchBatches]);

  return (
    <TemplateBase>
      <h1>Picking de Fotos</h1>
      <Paper elevation={1} className={styles.paperBusca}>
        <Card>
          <CardContent>
            <h3>Identificar produto</h3>
            <Grid container spacing={2}>
              {/* <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  onKeyUp={handleLocalizarPorCodigoBip}
                  InputProps={{
                    id: 'codigoProduto',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Barcode />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid> */}
              <Grid item xs={4}>
                <TextField
                  name="nomeremessa"
                  label="Buscar Vendedor"
                  placeholder="Buscar Vendedor"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={buscaNome}
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              <h3>Filtrar</h3>
              <FormControl variant="standard" component="fieldset">
                <RadioGroup
                  aria-label="galpão"
                  name="filtros"
                  row
                  defaultValue=""
                  value={filtro}
                  onChange={handleFiltros}
                >
                  <FormControlLabel value="" control={<Radio color="primary" />} label="Todos" />
                  <FormControlLabel value="ITAIM" control={<Radio color="primary" />} label="Itaim" />
                  <FormControlLabel value="SAUDE" control={<Radio color="primary" />} label="Saúde" />
                </RadioGroup>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <Paper>
            <Table>
              <TableHead>
                <TableCell>Vip?</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Aprovado em</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Localização</TableCell>
                <TableCell />
                <TableCell />
              </TableHead>
              <TableBody>
                {itens?.map((item: QueroVenderItem) => {
                  return <ItemRow key={item.codquerovenderitem} item={item} />;
                })}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3} mb={10}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>

          <div className={styles.campoCodBarras}>
            <TextField
              name="codbarra"
              size="small"
              id="field-cod-barras"
              variant="outlined"
              autoFocus
              onKeyUp={handleInputKeyPress}
              value={barCode}
              onChange={e => {
                handleOnChangeBarCode(e.target.value);
              }}
              inputRef={codBarras}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Barcode />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <Loading open={loadingFull} />
        </>
      )}
    </TemplateBase>
  );
}

export default PickingFotos;
