import React, { useState, useEffect, useCallback, memo } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import AutocompleteCategorias from 'components/form/autocompleteCategorias';
import AutocompleteMarcas from 'components/form/autocompleteMarcas';
import AutocompleteModelos from 'components/form/autocompleteModelos';
import { useFiltros } from 'context/filtros';

import { AutocompleteModelosPayload } from 'interfaces';
import AutoCompleteService from 'services/autocomplete/AutoCompleteService';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { FilterGenero } from '../filter-genero';
import { FiltroCheckbox } from '../filtros-checkbox';

import styles from '../styles';

export const Informacoes = memo(() => {
  const classes = styles();
  const { fetchFiltros, filtroState } = useFiltros();
  const {
    setTipoMarca,
    setCampoCategoria,
    campoCategoria,
    campoMarca,
    item,
    isProduto,
    disableForm,
    setCategoriaMultipla,
    categoriaMultipla,
    setMarcaMultipla,
    marcaMultipla,
    setCampoMarca,
    modeloRequired,
    setModeloRequired
  } = useFormAvaliacao();

  const { register } = useFormContext();

  const [modelos, setModelos] = useState<AutocompleteModelosPayload[]>([]);

  const handleFetchFiltros = useCallback((codcategoria?: number, codmarca?: number, codfiltrovariacaopai?: string) => {
    if (!disableForm) {
      fetchFiltros(codcategoria, codmarca, codfiltrovariacaopai);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (item) {
      handleFetchFiltros(item.atributos?.categoria?.codcategoria, item.atributos.marca?.codmarca);
    }
  }, [item, handleFetchFiltros]);

  useEffect(() => {
    if (campoCategoria && campoMarca) {
      AutoCompleteService.modelos({
        codmarca: campoMarca?.codmarca as number,
        codcategoria: campoCategoria?.codcategoriapai || (campoCategoria?.codcategoria as number)
      })?.then(({ data: { data } }) => {
        setModelos(data);
        if (data.length > 0) {
          setModeloRequired(true);
        } else {
          setModeloRequired(false);
        }
      });
    }
  }, [campoCategoria, campoMarca, setModeloRequired]);

  return (
    <Box py={1}>
      <Typography variant="overline" color="textSecondary" className={classes.titles}>
        Informações
      </Typography>

      <Grid container spacing={2} className={classes.contentWrap} alignItems="flex-start">
        <Grid item xs={4}>
          <AutocompleteCategorias
            categoria={item?.atributos?.categoria}
            register={register}
            disabled={disableForm}
            getCategoria={value => {
              setCampoCategoria(value);
              setCategoriaMultipla(!!value?.multipla);
              handleFetchFiltros(value?.codcategoria, campoMarca?.codmarca);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteMarcas
            marca={item?.atributos?.marca}
            register={register}
            disabled={disableForm}
            getMarcaSelected={param => {
              setTipoMarca(param?.tipo);
              setMarcaMultipla(!!param?.multipla);
              setCampoMarca(param);
              handleFetchFiltros(campoCategoria?.codcategoria, param?.codmarca);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteModelos
            atributos={item?.atributos}
            register={register}
            disabled={disableForm || !modeloRequired}
            codcategoria={campoCategoria?.codcategoriapai || campoCategoria?.codcategoria}
            codmarca={campoMarca?.codmarca}
            defaultValue={item?.produto?.modelo}
            options={modelos}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldHookForms
            className={classes.textInput}
            label="Complemento do Título"
            {...register('modelo')}
            placeholder="Complemento do Título"
            size="small"
            variant="outlined"
            fullWidth
            disabled={disableForm}
          />
        </Grid>
        {categoriaMultipla && (
          <Grid item xs={12}>
            <TextFieldHookForms
              className={classes.textInput}
              label="Categoria Específica"
              disabled={disableForm}
              placeholder="Categoria Específica"
              size="small"
              variant="outlined"
              fullWidth
              {...register('categoria_especifica')}
            />
          </Grid>
        )}
        {marcaMultipla && (
          <Grid item xs={12}>
            <TextFieldHookForms
              className={classes.textInput}
              label="Marca Específica"
              disabled={disableForm}
              placeholder="Marca Específica"
              size="small"
              variant="outlined"
              fullWidth
              {...register('marca_especifica')}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FiltroCheckbox
            disabled={disableForm}
            register={register}
            categoria={campoCategoria}
            name={filtroState?.detalhesCriticos?.field}
            apiFiltroVariacoes={filtroState?.detalhesCriticos?.variacoes}
            produtoFiltroVariacoes={
              isProduto ? item?.produto?.filtros['detalhes-criticos']?.variacoes : item?.atributos?.itens_inclusos
            }
          />
        </Grid>
        <Grid item xs={12}>
          {filtroState?.detalhesCriticos?.variacoes?.length > 0 && (
            <TextFieldHookForms
              className={classes.textInput}
              label="Detalhes Críticos Extras"
              multiline
              rows={2}
              disabled={disableForm}
              variant="outlined"
              fullWidth
              {...register('detalhes_criticos')}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextFieldHookForms
            className={classes.textInput}
            label="Link de referência"
            disabled={disableForm}
            size="small"
            placeholder="Link de referência"
            variant="outlined"
            fullWidth
            {...register('referencia')}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary" className={classes.titles}>
            Gênero
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FilterGenero />
        </Grid>
      </Grid>
    </Box>
  );
});
