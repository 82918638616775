import React, { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { SwitchHookForms } from 'components/form/text-field-hook-forms';

export const ProdutoBonus = memo(() => {
  const { item, batchProdutosSemBonus } = useFormAvaliacao();

  const [produtoSemBonus, setProdutoSemBonus] = useState(item?.produto?.sem_bonus ?? batchProdutosSemBonus);
  const { register } = useFormContext();

  useEffect(() => {
    setProdutoSemBonus(batchProdutosSemBonus);
  }, [batchProdutosSemBonus]);

  return (
    <Box p={1} mt={1} mb={2} bgcolor="#f1f1f1">
      <FormControlLabel
        control={
          <SwitchHookForms
            checked={produtoSemBonus}
            {...register('sem_bonus')}
            onChange={() => setProdutoSemBonus(!produtoSemBonus)}
            color="secondary"
            disabled
          />
        }
        label={`Produto Sem Bônus: ${produtoSemBonus ? 'Ativado' : 'Desativado'}`}
      />
    </Box>
  );
});
