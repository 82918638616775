export function formatTextCpf(value: string) {
  const sanitize = value.replace(/\D/g, '');
  const firstChunk = (() => {
    if (sanitize.length === 11) {
      return sanitize?.substring(0, 3);
    }

    return sanitize?.substring(0, 2);
  })();

  return `${firstChunk}.${sanitize?.substring(3, 6)}.${sanitize?.substring(6, 9)}-${sanitize?.substring(9)}`;
}
