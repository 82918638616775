import React from 'react';
import { Quiosque } from 'components/icons/quiosque';

import quisoqueIconStyles from './styles';

function QuiosqueIcon({ isQuiosque }: { isQuiosque: boolean }) {
  const styles = quisoqueIconStyles();

  const quiosqueIconColor = isQuiosque ? styles.isQuiosque : styles.isNotQuiosque;

  return <Quiosque style={{ fontSize: 21, marginLeft: 5 }} className={quiosqueIconColor} />;
}

export default QuiosqueIcon;
