const decimalToPercentage = (decimal: number | string) => {
  const decimalNumber = typeof decimal === 'string' ? parseFloat(decimal) : decimal;
  return `${Math.round((decimalNumber + Number.EPSILON) * 100)}%`;
};
const percentageToDecimal = (percentage: string) => {
  const n = parseFloat(percentage.replace('%', '').replace(',', '.'));
  return Number.isNaN(n) ? 0 : n / 100;
};

const decimalToMoney = (decimal: number) => {
  const decimalNumber = typeof decimal === 'string' ? parseFloat(decimal) : decimal;
  return `R$ ${decimalNumber.toFixed(2)}`.replace('.', ',');
};
const moneyToDecimal = (money: string) => {
  const n = parseFloat(money.replace('R$ ', '').split('.').join('').replace(',', '.'));
  return Number.isNaN(n) ? 0.0 : n;
};

/* gera id temporario para identificação dos novos registros a serem adicionados */
const tempId = () => Math.random().toString(16).slice(2);

export { decimalToPercentage, percentageToDecimal, decimalToMoney, moneyToDecimal, tempId };
