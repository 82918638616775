import React, { useMemo, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import TemplateBase from 'components/template-base/template';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useGlobalNotification } from 'context/global-notification';
import Swal from 'sweetalert2';
import { formatTextCpf } from 'helpers/format-text-cpf';
import GeracaoCarrinhoService from 'services/geracao-carrinho/GeracaoCarrinhoService';
import { useLoading } from 'context/loading';
import { CarrinhoProduto, Pacote } from 'interfaces';
import isAlphaNumeric from 'helpers/isAlphaNumeric';
import { green } from '@mui/material/colors';
import ExibirCarrinhoStyles from './styles';
import useFetchCarrinho from './useFetchCarrinho';

function ExibirCarrinho() {
  document.title = 'Ferramenta Geradora de Carrinho';

  const styles = ExibirCarrinhoStyles();

  const { CopyToClipBoard } = useGlobalNotification();

  const { codcarrinho } = useParams() as { codcarrinho: string };
  const { carrinho, loading, setCarrinho, showScreen } = useFetchCarrinho(codcarrinho);
  const [incluiCodproduto, setIncluiCodProduto] = useState<string>('');

  const { setLoading } = useLoading();

  const navigate = useNavigate();
  const location = useLocation();
  const handleChangeCodProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncluiCodProduto(event.target.value);
  };

  const handleRemoverProduto = (codproduto: number) => {
    setLoading(true);
    GeracaoCarrinhoService.remover_produto(codcarrinho, codproduto)
      .then(result => {
        setCarrinho(result.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const geraPayloadIncluirProduto = (codproduto: string) => {
    if (isAlphaNumeric(codproduto)) {
      return {
        codigo: codproduto
      };
    }

    return {
      codproduto
    };
  };

  const handleIncluirProduto = () => {
    if (carrinho) {
      setLoading(true);
      const payload = geraPayloadIncluirProduto(incluiCodproduto);

      GeracaoCarrinhoService.incluir_produto(codcarrinho, payload)
        .then(result => {
          setCarrinho(result.data);
        })
        .finally(() => {
          setIncluiCodProduto('');
          setLoading(false);
        });
    }
  };

  const handleGerarUrl = () => {
    setLoading(true);
    if (carrinho) {
      GeracaoCarrinhoService.fetch_carrinho(codcarrinho, { url: 1 })
        .then(result => {
          openModal(result.data.url);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancelarCarrinho = () => {
    setLoading(true);
    GeracaoCarrinhoService.cancelar_carrinho(codcarrinho)
      .then(() => {
        navigate(`/ferramentas/gerar-carrinho`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openModal = (link: string) => {
    Swal.fire({
      icon: 'success',
      title: 'Carrinho gerado com sucesso!',
      html: `
      Carrinho n° <strong>#${codcarrinho}</strong> <br /><br />
      Link de Pagamento: ${link} <input id="linkCarrinho" hidden value="${link}" /> <br />
      <button id="copyCarrinhoUrl" style="border: 0; color: #FFF; padding: 10px; font-size: 14px; border-radius: .25em; cursor: pointer; margin-top: 10px; background-color: ${green[500]}">Copiar Link</button>
    `,
      didOpen: () => {
        const btCopiarCarrinhoUrl = document.getElementById('copyCarrinhoUrl');

        btCopiarCarrinhoUrl?.addEventListener('click', () => {
          const inputLinkCarrinho: HTMLInputElement | null = document.getElementById(
            'linkCarrinho'
          ) as HTMLInputElement;

          CopyToClipBoard(inputLinkCarrinho?.value);
        });
      }
    });
  };

  const produtos = carrinho?.pacotes
    ? carrinho?.pacotes.reduce((accumulator: CarrinhoProduto[], current: Pacote): CarrinhoProduto[] => {
        return [...accumulator, ...current.produtos];
      }, [])
    : carrinho?.produtos;

  const disableGerarUrl = useMemo(() => {
    return (
      produtos?.some(produto => produto.status !== 'A') ||
      produtos?.some(produto => !produto.estoque) ||
      produtos?.some(produto => produto.disponivel !== 'S') ||
      produtos?.length === 0
    );
  }, [produtos]);

  return (
    <TemplateBase>
      {showScreen && (
        <>
          <h1>Gerar Carrinho: </h1>
          {loading ? (
            <div>Carregando...</div>
          ) : (
            <>
              {carrinho && carrinho?.cliente && (
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Nome:</strong> {carrinho.cliente.nome} <br />
                    <strong>CPF:</strong> {formatTextCpf(carrinho.cliente.cpf)}
                  </Grid>
                </Grid>
              )}
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      onChange={handleChangeCodProduto}
                      variant="outlined"
                      style={{ marginBottom: 8 }}
                      fullWidth
                      value={incluiCodproduto}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={handleIncluirProduto}>
                      Incluir Produto
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Imagem</TableCell>
                        <TableCell>Código</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {produtos &&
                        produtos?.map((produto: CarrinhoProduto) => (
                          <TableRow key={produto.codproduto}>
                            <TableCell>
                              <img
                                alt={produto?.nome}
                                src={produto?.imagens[0]?.thumbnail}
                                className={styles.productImage}
                              />
                            </TableCell>
                            <TableCell>{produto?.codigo}</TableCell>
                            <TableCell>{produto?.nome}</TableCell>
                            <TableCell>
                              {produto?.estoque && produto?.status === 'A' ? 'Disponível' : 'Indisponível'}
                            </TableCell>

                            <TableCell>
                              <Tooltip title="Excluir do carrinho">
                                <IconButton
                                  disabled={!location?.state?.created}
                                  color="primary"
                                  onClick={() => handleRemoverProduto(produto?.codproduto)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
              <Box display="inline-flex" justifyContent="flex-end" width="100%" mt={2}>
                <Button variant="contained" onClick={handleCancelarCarrinho}>
                  Cancelar
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  onClick={handleGerarUrl}
                  variant="contained"
                  color="primary"
                  href="#contained-buttons"
                  disabled={disableGerarUrl}
                >
                  Gerar Url
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </TemplateBase>
  );
}

export default ExibirCarrinho;
