import React, { useState, useEffect } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { withMaskFormat } from 'components/form-fields-grid';
import { useForm, FormProvider } from 'react-hook-form';
import ClientesService, { BuscarClienteParams } from 'services/clientes/ClientesService';
import { Cliente } from 'interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateFromPicker } from 'helpers/format-date-from-picker';
import * as Yup from 'yup';
import alertValidationMessage from 'helpers/alert-validation-message';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';
import { addSeconds, formatISO, addDays } from 'date-fns/fp';
import { compose } from 'lodash/fp';
import { formatStringBoolean } from 'helpers/format-string-boolean';
import { RadioHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { DatePickerHookForm } from 'components/datepicker-hook-form';

const TextFieldCPF = withMaskFormat('###.###.###-##')(TextFieldHookForms);

const validationSchemaAplicarFreteGratis = Yup.object().shape({
  fretegratis_ate: Yup.string().required('Insira uma data para fim do frete grátis'),
  fretegratis_uso_unico: Yup.string().required('Selecione se o cupom é limitado a uma compra').nullable()
});

function FreteGratis() {
  document.title = 'Ferramentas';

  const formBusca = useForm();

  const formAplicarFrete = useForm({
    resolver: yupResolver(validationSchemaAplicarFreteGratis)
  });

  const [campoBusca, setCampoBusca] = useState<string | null>(null);
  const [cliente, setCliente] = useState<Cliente | null>(null);

  const { setLoading } = useLoading();

  const handleCampoBusca = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCampoBusca(e.target.value);
  };

  const onSubmitBuscar = ({ email, cpf }: BuscarClienteParams) => {
    const data: BuscarClienteParams = {};

    if (campoBusca === 'email') {
      data.email = email;
    }

    if (campoBusca === 'cpf') {
      data.cpf = cpf;
    }

    setLoading(true);

    ClientesService.buscar(data)
      .then(response => {
        setCliente(response.data.data[0]);
      })
      .finally(() => [setLoading(false)]);
  };

  const onSubmitAplicarFreteGratis = (data: any) => { // eslint-disable-line
    const body = { ...data, fretegratis_ativo: true };
    const codcliente = cliente?.codcliente;
    const subtractSeconds = addSeconds(-1);
    const addOneDay = addDays(1);
    body.fretegratis_uso_unico = formatStringBoolean(body.fretegratis_uso_unico as '0' | '1');
    body.fretegratis_ate = compose(formatISO, subtractSeconds, addOneDay, formatDateFromPicker)(body.fretegratis_ate);
    setLoading(true);
    ClientesService.atualizar(codcliente, body)
      .then(() => {
        Swal.fire(`Cupom frete grátis foi aplicado!`, '', 'success').then(result => {
          if (result.isConfirmed) {
            setCampoBusca(null);
            setCliente(null);
          }
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formAplicarFrete.formState.errors).length > 0) {
      alertValidationMessage(formAplicarFrete.formState.errors);
    }
  }, [formAplicarFrete.formState.errors]);

  return (
    <TemplateBase>
      <h1>Frete Grátis</h1>

      <Paper elevation={1}>
        <Box p={2}>
          <FormProvider {...formBusca}>
            <form onSubmit={formBusca.handleSubmit(onSubmitBuscar)}>
              <h3>Buscar cliente por: </h3>
              <FormControl variant="standard" component="div">
                <RadioGroup value={campoBusca} row onChange={handleCampoBusca}>
                  <FormControlLabel value="email" control={<Radio color="primary" />} label="E-mail" />
                  <FormControlLabel value="cpf" control={<Radio color="primary" />} label="CPF" />
                </RadioGroup>
              </FormControl>

              {campoBusca === 'email' && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldHookForms
                      variant="outlined"
                      {...formBusca.register('email')}
                      fullWidth
                      size="small"
                      placeholder="Digite o email do cliente"
                      autoFocus
                    />
                  </Grid>
                  <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {campoBusca === 'cpf' && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldCPF
                      variant="outlined"
                      {...formBusca.register('cpf')}
                      fullWidth
                      size="small"
                      placeholder="Digite o CPF do cliente"
                      autoFocus
                    />
                  </Grid>
                  <Grid item>
                    <Button color="primary" variant="contained" type="submit">
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          </FormProvider>
        </Box>
      </Paper>

      {cliente && (
        <Box maxWidth={400} mt={1} p={2}>
          <form onSubmit={formAplicarFrete.handleSubmit(onSubmitAplicarFreteGratis)}>
            <Box fontSize={16}>
              <strong>Nome: </strong> {cliente?.nome} <br />
              <strong>E-mail: </strong> {cliente?.email} <br />
            </Box>
            <Box pt={2}>
              <FormLabel component="legend">Data fim de frete grátis</FormLabel>
              <DatePickerHookForm control={formAplicarFrete.control} name="fretegratis_ate" defaultValue={new Date()} />
            </Box>
            <Box pt={2} mb={2}>
              <FormControl variant="standard" component="div">
                <FormLabel component="legend">Limitado a uma compra?</FormLabel>
                <RadioGroup row name="fretegratis_uso_unico">
                  <FormControlLabel
                    value="1"
                    control={<RadioHookForms color="primary" {...formAplicarFrete.register('fretegratis_uso_unico')} />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="0"
                    control={<RadioHookForms color="primary" {...formAplicarFrete.register('fretegratis_uso_unico')} />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Button color="primary" variant="contained" type="submit">
              Aplicar
            </Button>
          </form>
        </Box>
      )}
    </TemplateBase>
  );
}

export default FreteGratis;
