import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useGlobalNotification } from 'context/global-notification';
import { formatTextCpf } from 'helpers/format-text-cpf';
import { format } from 'date-fns';

type Props = {
  nome: string;
  cpf: string;
  email: string;
  url: string;
  expiracao: string;
  closeModal: () => void;
};

function ModalLinkRedefinirSenhas(props: Props) {
  const { nome, cpf, email, url, expiracao, closeModal } = props;
  const { CopyToClipBoard } = useGlobalNotification();

  const handleClickCopy = () => {
    if (url) {
      CopyToClipBoard(decodeURI(url));
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>Link para redefinir senha</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <strong>Nome:</strong> {nome}
          </Box>
          <Box>
            <strong>CPF:</strong> {formatTextCpf(cpf)}
          </Box>
          <Box>
            <strong>Email:</strong> {email}
          </Box>
          <Box mt={2} width="100%">
            <TextField
              size="small"
              value={decodeURI(url)}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: -15 }}>
                      <IconButton onClick={handleClickCopy} size="large">
                        <FileCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }}
            />
            {expiracao && (
              <Box mt={1}>
                <strong>
                  O link expira {format(new Date(expiracao), 'dd/MM/yyyy')} às {format(new Date(expiracao), 'HH:mm:ss')}
                </strong>
              </Box>
            )}
          </Box>
          <Box mr={2} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={closeModal}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalLinkRedefinirSenhas;
