import withStyles from '@mui/styles/withStyles';
import { green } from '@mui/material/colors';
import { SwitchHookForms } from 'components/form/text-field-hook-forms';

export const GreenSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500]
    }
  },
  checked: {},
  track: {}
})(SwitchHookForms);
