import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CalculadoraValores from 'components/calculadora-de-valores';
import Typography from '@mui/material/Typography';
import { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import { QueroVenderItem } from 'interfaces';
import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { green } from '@mui/material/colors';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { BotaoEnviarDevolucao } from './botao-enviar-devolucao';
import { BotaoPausarNegociacao } from './botao-pausar-negociacao';
import { BotaoEnviarSugestao } from './botao-enviar-sugestao';
import { BotaoHistoricoValor } from './botao-historico-valor';
import { BotaoAceitar } from './botao-aceitar';
import { FormCalculosMotivos } from './form-calculos-motivos';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  item: QueroVenderItem | undefined;
  codloja: number | undefined;
  doRefresh: () => void;
};

const ButtonRetomar = withStyles(() => ({
  root: {
    color: 'white',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

export function PropostaPausada(props: Props) {
  const { item, codloja, doRefresh } = props;

  const { register } = useForm();

  const styles = avaliacaoFisicaNegociacaoStyle();

  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const [novaSugestao, setNovaSugestao] = useState(false);

  const [valorAprovado, setValorAprovado] = useState(false);

  const defaultValuesNegociacao = useMemo(
    () => ({
      preco: 0,
      precoaprazo: 0,
      precoavista: 0,
      precocomissao: 0,
      precocomissionada: 0,
      status: 0,
      observacoes: '',
      revisado: false,
      acao: ''
    }),
    []
  );

  const methods = useForm<AprovacaoFisica>({ defaultValues: defaultValuesNegociacao });

  const handleBtnRetomar = () => setNovaSugestao(true);

  const handleIsCalculating = (value: boolean) => {
    setIsCalculating(value);
  };

  return (
    <Box p={2}>
      <Paper elevation={2}>
        <Box p={2}>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Typography variant="overline" color="textSecondary">
              Negociação:
            </Typography>
            <BotaoHistoricoValor item={item} />
          </Box>

          {/* {TODO: register should not be required} */}
          <CalculadoraValores
            negociacaoAtual={item?.negociacao_inicial}
            categoria={null}
            captadora
            disabled
            codloja={codloja}
            comissionamentoDiferenciado={false}
            register={register}
            codquerovenderitem={item?.codquerovenderitem}
            showPrecoLoja
            itemValorLoja={item?.produto?.precificacao?.loja.toString()}
            xs={2}
          />
          <TextField
            multiline
            rows={3}
            label="Observações na primeira avaliação"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            disabled
            defaultValue={item?.negociacao_inicial?.observacoes}
            className={styles.textInput}
          />
        </Box>
        <Box p={2}>
          <Box mb={3}>
            <Typography variant="overline" color="textSecondary" className={styles.titleStrongBlue}>
              Sugestão do vendedor
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <CalculadoraValores
              negociacaoAtual={item?.negociacao_atual}
              categoria={null}
              captadora
              codloja={codloja}
              comissionamentoDiferenciado={false}
              register={register}
              codquerovenderitem={item?.codquerovenderitem}
              viewOnly
            />
            <TextFieldHookForms
              multiline
              rows={3}
              label="Observações"
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              {...register('observacoes')}
              className={styles.textInput}
              defaultValue={item?.negociacao_atual?.observacoes}
              disabled
            />
            {!novaSugestao && (
              <Box display="inline-flex" justifyContent="flex-end" width="100%">
                <ButtonRetomar onClick={handleBtnRetomar}>retomar negociação</ButtonRetomar>
              </Box>
            )}
            {novaSugestao && (
              <Box display="inline-flex" justifyContent="flex-end" width="100%">
                <BotaoPausarNegociacao
                  codquerovenderitem={item?.codquerovenderitem}
                  doRefresh={doRefresh}
                  disabled={isCalculating}
                />
                <BotaoAceitar
                  disabled={isCalculating}
                  codquerovenderitem={item?.codquerovenderitem}
                  doRefresh={doRefresh}
                />
              </Box>
            )}
          </FormProvider>
        </Box>
        {novaSugestao && (
          <Box p={2}>
            <Box mb={3}>
              <Typography variant="overline" color="textSecondary" className={styles.titleStrong}>
                Sugerir novo valor:
              </Typography>
            </Box>
            <FormProvider {...methods}>
              <FormCalculosMotivos
                defaultValuesNegociacao={defaultValuesNegociacao}
                codloja={codloja}
                handleIsCalculating={handleIsCalculating}
                codquerovenderitem={item?.codquerovenderitem}
              />
              <Box display="inline-flex" justifyContent="space-between" width="100%">
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={valorAprovado}
                        onChange={() => {
                          setValorAprovado(!valorAprovado);
                        }}
                        color="secondary"
                      />
                    }
                    label="Este valor já está aprovado"
                  />
                </div>
                <div>
                  <BotaoEnviarDevolucao
                    observacoes={item?.negociacao_atual?.observacoes}
                    codquerovenderitem={item?.codquerovenderitem}
                    doRefresh={doRefresh}
                  />
                  <BotaoEnviarSugestao
                    disabled={isCalculating}
                    codquerovenderitem={item?.codquerovenderitem}
                    doRefresh={doRefresh}
                    sugerir_aceitar={valorAprovado}
                  />
                </div>
              </Box>
            </FormProvider>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
