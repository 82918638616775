import React, { useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import blue from '@mui/material/colors/blue';
import grey from '@mui/material/colors/grey';

export function DadosAcesso({
  setStep,
  email,
  password
}: {
  setStep: (step: number) => void;
  email: string;
  password: string;
}) {
  const elRef = useRef<HTMLDivElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const total = useRef(100);

  const [counter, setCounter] = React.useState(10);

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus();
      passwordRef.current.select();
    }
  }, [counter]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 0) {
        clearInterval(interval);
        setStep(1);
      } else {
        setCounter(prev => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter, setStep]);

  // TODO:: Fazer os milisegundos serem inferidos da variável counter
  useEffect(() => {
    const interval2 = setInterval(() => {
      total.current -= 1;

      if (total.current === 0) {
        clearInterval(interval2);
      }

      if (elRef.current) {
        elRef.current.style.width = `${total.current}%`;
      }
    }, 100);
  }, []);

  return (
    <div>
      <Alert severity="success">Acesso criado</Alert>

      <h2>Dados de acesso: </h2>
      <form autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              size="small"
              sx={{ width: 400 }}
              id="email"
              label="E-mail de acesso"
              name="email"
              autoComplete="email"
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              size="small"
              sx={{ width: 400 }}
              label="Senha temporária"
              autoComplete="off"
              value={password}
              slotProps={{
                htmlInput: {
                  ref: passwordRef
                }
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: 10,
            marginBottom: 10,
            width: 400,
            height: 10,
            borderRadius: 8,
            overflow: 'hidden',
            backgroundColor: grey[200]
          }}
        >
          <div
            ref={elRef}
            style={{ width: `100%`, height: '100%', backgroundColor: blue[700], transition: 'width 0.5s' }}
          />
        </div>
      </form>
      <span>A senha vai ficar visível por mais {counter} segundos</span>
    </div>
  );
}
