import React, { useEffect, useState, useCallback, useMemo } from 'react';
import TemplateBase from 'components/template-base/template';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ModeracaoService from 'services/peer-two-peer/ModeracaoService';
import { Produto, ModeracaoEtapa } from 'interfaces';
import { formatTextPhone } from 'helpers/format-text-phone';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import AvatarProduto from 'components/avaliacao-fisica/avatar-produto';
import blue from '@mui/material/colors/blue';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { FiltrosProvider } from 'context/filtros';
import { Etapas } from './etapas';
import ModeracaoProdutoStyles from './styles';
import { EtapaFotografia } from './etapa-fotografia';
import { EtapaCadastro } from './etapa-cadastro';
import { EtapaPreco } from './etapa-preco';
import { EtapaMedidas } from './etapa-medidas';
import { FormModeracaoProvider } from './form-moderacao-provider';
import { BotaoFinalizarEtapas } from './botao-finalizar-etapas';
import { BotaoRejeitarProduto } from './boao-rejeitar-produto';

type WrapEtapaProps = {
  id: string;
  onSetEtapa: (etapa: ModeracaoEtapa) => void;
  label: string;
  component?: React.ReactNode;
  key: string;
  sugerir_preco?: boolean;
  disabled?: boolean;
  motivoDefault?: string;
  situacaoDefault: string;
};

function ModeracaoProduto() {
  document.title = 'Moderação P2P | Produto';

  const { codproduto } = useParams() as { codproduto: string };
  const [produto, setProduto] = useState<Produto | null>(null);
  const [etapas, setEtapas] = useState<ModeracaoEtapa[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const styles = ModeracaoProdutoStyles();
  const [etapas_selecionadas, setEtapasSelecionadas] = useState<ModeracaoEtapa[]>([]);
  const [enableFinalizar, setEnableFinalizar] = useState(false);

  const createEtapaId = (value: string) => `App\\Services\\Produto\\Etapas\\Models\\Etapa\\${value}`;

  const produtoreadOnly = useMemo(() => {
    return produto?.status === 'V';
  }, [produto]);

  const fetchProduto = useCallback(async () => {
    return ModeracaoService.produto(codproduto).then(response => {
      setProduto(response.data);
    });
  }, [codproduto]);

  const fetchProdutoEtapas = useCallback(async () => {
    return ModeracaoService.etapas(codproduto).then(response => {
      setEtapas(response.data);
    });
  }, [codproduto]);

  const handleSetEtapa = (value: ModeracaoEtapa, index: number) => {
    setEtapasSelecionadas(prevValue => {
      const array = [...prevValue];

      array[index] = value;

      return array;
    });
  };

  const wrapEtapa = (props: WrapEtapaProps) => {
    const { component, ...rest } = props;

    return <Etapas {...rest}>{component}</Etapas>;
  };

  const handleEnableFinalizar = useCallback(() => {
    const qtdEtapas = etapas.length;
    const qtdEtapasSelecionadas = etapas_selecionadas.filter(etapa => etapa !== undefined).length;

    setEnableFinalizar(qtdEtapas === qtdEtapasSelecionadas);
  }, [etapas, etapas_selecionadas]);

  useEffect(() => {
    handleEnableFinalizar();
  }, [etapas_selecionadas, handleEnableFinalizar]);

  useEffect(() => {
    Promise.all([fetchProduto(), fetchProdutoEtapas()]).finally(() => {
      setIsLoading(false);
    });
  }, [fetchProdutoEtapas, fetchProduto]);

  return (
    <TemplateBase>
      {isLoading ? (
        <div>caregando...</div>
      ) : (
        <>
          <Grid item xs={12}>
            <h1>Moderação P2P</h1>
            <h3>{produto?.vendedora?.nome}</h3>
          </Grid>
          <Grid item xs={6}>
            <div>
              <strong>Email:</strong> {produto?.vendedora?.email}
            </div>
            <div>
              <strong>Telefone: </strong> {formatTextPhone(produto?.vendedora?.telefone1)}
            </div>
            <div>
              <strong>Código do produto:</strong> {produto?.codigo}
            </div>
          </Grid>
          <FiltrosProvider>
            <FormModeracaoProvider>
              <Card className={styles.root}>
                <CardHeader
                  className={styles.cardHeader}
                  avatar={
                    <AvatarProduto
                      titulo={produto?.nome}
                      imagem={produto?.imagens?.[0]?.thumbnail}
                      text="pendente de aprovação"
                      color={blue[500]}
                    />
                  }
                />
                <CardContent style={{ marginTop: -32 }}>
                  {etapas
                    ? etapas.map((etapa, index) => {
                        const etapa_prop: WrapEtapaProps = {
                          id: etapa.id,
                          label: etapa?.label || '',
                          onSetEtapa: etapa_selecionada => handleSetEtapa(etapa_selecionada, index),
                          key: etapa.id,
                          disabled: produtoreadOnly,
                          motivoDefault: etapa.motivo,
                          situacaoDefault: etapa.situacao
                        };

                        if (etapa.id === createEtapaId('Fotografia')) {
                          return wrapEtapa({
                            ...etapa_prop,
                            component: <EtapaFotografia produto={produto} dados={etapa?.dados} />
                          });
                        }
                        if (etapa.id === createEtapaId('Cadastro')) {
                          return wrapEtapa({
                            ...etapa_prop,
                            component: <EtapaCadastro produto={produto} />
                          });
                        }
                        if (etapa.id === createEtapaId('Preco')) {
                          return wrapEtapa({
                            ...etapa_prop,
                            sugerir_preco: produto?.sugerir_preco,
                            component: <EtapaPreco produto={produto} dados={etapa?.dados} />
                          });
                        }
                        if (etapa.id === createEtapaId('Medidas')) {
                          return wrapEtapa({
                            ...etapa_prop,
                            component: <EtapaMedidas produto={produto} dados={etapa?.dados} />
                          });
                        }

                        return <div />;
                      })
                    : null}
                </CardContent>
                <CardActions className={styles.actions}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} className={styles.btnsLeft}>
                      <BotaoRejeitarProduto produtoreadOnly={produtoreadOnly} produto={produto} />
                    </Grid>
                    <Grid item xs={6}>
                      <BotaoFinalizarEtapas
                        enableFinalizar={enableFinalizar}
                        produtoreadOnly={produtoreadOnly}
                        codproduto={codproduto}
                        etapas_selecionadas={etapas_selecionadas}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </FormModeracaoProvider>
          </FiltrosProvider>
        </>
      )}
    </TemplateBase>
  );
}

export default ModeracaoProduto;
