import React from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import FormModelo from '../components/form-modelo';

function CadastrarModelo() {
  document.title = 'Ferramentas';

  return (
    <TemplateBase>
      <Box>
        <Paper elevation={1}>
          <FormModelo title="Cadastrar Modelo" action="cadastrar" />
        </Paper>
      </Box>
    </TemplateBase>
  );
}

export default CadastrarModelo;
