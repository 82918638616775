import React, { useEffect, useState, useCallback } from 'react';

import Loading from 'components/loading';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import WarningIcon from '@mui/icons-material/Warning';

import useDebounce from 'hooks/debounce';

import VendasPorVendedora from 'services/relatorios/VendasPorVendedora';
import TemplateBase from 'components/template-base/template';
import { VendedorBatch, ApiListMeta } from 'interfaces';
// import { EntradaProdutosProvider, useEntradaProdutos } from 'pages/avaliacao/context/entrada-produtos';
import Pagination from '@mui/material/Pagination';
import FormGerarRelatorio from './form-gerar-relatorio';

import gerarRelatorioStyle from './style';

function VendasVendedora() {
  document.title = 'Relatórios | Vendas por vendedora';

  const style = gerarRelatorioStyle();
  const [batches, setBatch] = useState([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [buscaNome, setBuscaNome] = useState('');
  const [modalRelatorio, setModalGerarRelatorio] = useState({
    open: false,
    params: { codloja: '' }
  });

  const [isPagination, setIsPagination] = useState(false);

  const nomeCliente = useDebounce(buscaNome, 500);

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Pega os dados das Batchs da API
   */
  const getBatchs = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&q=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${meta?.per_page || perPage}${nome}`;

    if (!isPagination) setPage(1);

    setLoading(true);
    VendasPorVendedora.list(queryMeta).then(response => {
      if (response) {
        setBatch(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setBatch([]);
      }
      setLoading(false);
    });
  }, [page, nomeCliente]); // eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getBatchs();
  }, [getBatchs]);

  return (
    <>
      {modalRelatorio.open && (
        <FormGerarRelatorio
          params={modalRelatorio.params}
          onClose={() =>
            setModalGerarRelatorio({
              open: false,
              params: { codloja: '' }
            })
          }
        />
      )}
      <TemplateBase>
        <h2>Vendas por vendedora</h2>

        <Paper elevation={1} className={style.paperBusca}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={4}>
                  <h3>Localizar Cliente</h3>
                  <TextField
                    name="nomecliente"
                    label="Nome ou código do cliente"
                    placeholder="Nome ou código do cliente"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleBuscaNome}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        {loading && <Box>Carregando ...</Box>}
        {!loading && batches?.length > 0 && (
          <>
            <Paper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>

                    <TableCell align="center">Cód Cliente</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches &&
                    batches.map((batch: VendedorBatch) => (
                      <TableRow key={batch.codcliente}>
                        <TableCell>{batch?.nome}</TableCell>

                        <TableCell align="center">{batch?.codigo}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() =>
                              setModalGerarRelatorio({
                                open: true,
                                params: {
                                  codloja: batch.codloja
                                }
                              })
                            }
                            type="submit"
                            variant="contained"
                            color="primary"
                          >
                            Gerar Relatório
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>

            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
            </Box>
          </>
        )}

        {!loading && batches?.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
            <WarningIcon fontSize="large" color="disabled" />
            <Box component="span" ml={2}>
              Nenhuma Remessa encontrada
            </Box>
          </Box>
        )}
      </TemplateBase>
      <Loading open={loading} />
    </>
  );
}

function VendasVendedoraPage() {
  return <VendasVendedora />;
}

export default VendasVendedoraPage;
