import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ElementosService from 'services/elementos/ElementosService';
import { ElementoFaixa } from 'interfaces';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';
import { FormFaixa } from './form-faixa';

function DetalhesFaixa() {
  document.title = 'Painel Marketing | Detalhes Faixa';

  const { id } = useParams() as { id: string };
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const defaultValues = async () =>
    ElementosService.detalhes(id)
      .then(({ data: faixa }: { data: ElementoFaixa }) => {
        return {
          texto: faixa?.configuracoes?.linha1_texto_desktop,
          status: faixa?.status === 'ativo',
          cor: faixa?.configuracoes?.linha1_fonte?.cor,
          bgcolor: faixa?.configuracoes?.bg_color,
          link: faixa?.configuracoes?.link,
          data_entrada: new Date(faixa?.agendado_de),
          data_saida: new Date(faixa?.agendado_ate),
          paginas: faixa?.paginas,
          contador: faixa?.configuracoes?.isCronometro,
          label_faixa: faixa?.configuracoes.label
        };
      })
      .finally(() => {
        setIsLoading(false);
      });

  const methods = useForm({ defaultValues });

  /**
   * Recebe os dados do formulário
   */
  function sendFormData(payload: ElementoFaixa) {
    try {
      setLoading(true);
      ElementosService.atualizar(id, payload)
        .then(() => {
          Swal.fire('Faixa Aualizada com sucesso', '', 'success').then(() => {
            navigate('/painel-marketing/gerenciar-faixas');
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <TemplateBase>
      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <h1>Detalhes Faixa</h1>
          <FormFaixa sendFormData={(data: ElementoFaixa) => sendFormData(data)} methods={methods} />
        </>
      )}
    </TemplateBase>
  );
}

export default DetalhesFaixa;
