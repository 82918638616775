import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import EditarDataAgendamentoStyle from './style';

interface EditarDataAgendamentoProps {
  onClose: () => void;
  data_agendamento: Date | string;
}

function EditarDataAgendamento(props: EditarDataAgendamentoProps) {
  const style = EditarDataAgendamentoStyle();
  const { onClose, data_agendamento } = props;

  /**
   * Resgata dados do form e dispara mensagem
   */
  const { handleSubmit, control } = useForm<{ data_agendamento: string }>();

  const onSubmit = (data: { data_agendamento: string }) => {
    return data;
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Editar Data de Agendamento</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={style.modalContent}>
          <DatePickerHookForm
            name="data_agendamento"
            defaultValue={data_agendamento}
            label="Data da remessa"
            control={control}
            disablePast={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditarDataAgendamento;
