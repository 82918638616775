import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const ComissionadaStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  }
}));

export default ComissionadaStyle;
