import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';
import CalculadoraValores from 'components/calculadora-de-valores';
import { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import { QueroVenderItem } from 'interfaces';

import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { BotaoEnviarSugestao } from './botao-enviar-sugestao';
import { BotaoPausarNegociacao } from './botao-pausar-negociacao';
import { BotaoAceitar } from './botao-aceitar';
import { BotaoHistoricoValor } from './botao-historico-valor';
import { FormCalculosMotivos } from './form-calculos-motivos';

import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  item: QueroVenderItem | undefined;
  codloja: number | undefined;
  doRefresh: () => void;
};

export function NovaSugestao(props: Props) {
  const { item, codloja, doRefresh } = props;

  const { register } = useForm();

  const styles = avaliacaoFisicaNegociacaoStyle();

  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  const aprovacaoAutomatica = !!item?.aprovacao_automatica_data;

  const defaultValuesNegociacao = useMemo(
    () => ({
      preco: 0,
      precoaprazo: 0,
      precoavista: 0,
      precocomissao: 0,
      precocomissionada: 0,
      status: 0,
      observacoes: '',
      revisado: false,
      acao: ''
    }),
    []
  );

  const methods = useForm<AprovacaoFisica>({ defaultValues: defaultValuesNegociacao });

  const handleIsCalculating = (value: boolean) => {
    setIsCalculating(value);
  };

  return (
    <Box p={2}>
      <Paper elevation={2}>
        <Box p={2}>
          <Box mb={3} display="flex" justifyContent="space-between">
            <Typography variant="overline" color="textSecondary">
              Negociação:
            </Typography>
            <BotaoHistoricoValor item={item} />
          </Box>

          {/* {TODO: register should not be required} */}
          <CalculadoraValores
            negociacaoAtual={item?.negociacao_inicial}
            categoria={null}
            captadora
            disabled
            codloja={codloja}
            comissionamentoDiferenciado={false}
            register={register}
            codquerovenderitem={item?.codquerovenderitem}
            showPrecoLoja
            itemValorLoja={item?.produto?.precificacao?.loja.toString()}
            xs={2}
          />
          <TextField
            multiline
            rows={3}
            label="Observações na primeira avaliação"
            variant="outlined"
            margin="normal"
            fullWidth
            size="small"
            className={styles.textInput}
            defaultValue={item?.negociacao_inicial?.observacoes}
          />
        </Box>

        <Box p={2}>
          <Box mb={3}>
            <Typography variant="overline" color="textSecondary" className={styles.titleStrongBlue}>
              Sugestão do vendedor
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <CalculadoraValores
              negociacaoAtual={item?.negociacao_atual}
              categoria={null}
              captadora
              codloja={codloja}
              comissionamentoDiferenciado={false}
              register={register}
              codquerovenderitem={item?.codquerovenderitem}
              viewOnly
            />
            <TextFieldHookForms
              multiline
              rows={3}
              label="Observações"
              variant="outlined"
              margin="normal"
              fullWidth
              size="small"
              {...register('motivo')}
              className={styles.textInput}
              defaultValue={item?.negociacao_atual?.observacoes}
            />
            <Box display="inline-flex" justifyContent="flex-end" width="100%">
              <BotaoPausarNegociacao
                codquerovenderitem={item?.codquerovenderitem}
                doRefresh={doRefresh}
                disabled={isCalculating || aprovacaoAutomatica}
              />
              <BotaoAceitar
                disabled={isCalculating || aprovacaoAutomatica}
                codquerovenderitem={item?.codquerovenderitem}
                doRefresh={doRefresh}
              />
            </Box>
          </FormProvider>
        </Box>

        <Box p={2}>
          <Box mb={3}>
            <Typography variant="overline" color="textSecondary" className={styles.titleStrong}>
              Sugerir novo valor:
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <FormCalculosMotivos
              defaultValuesNegociacao={defaultValuesNegociacao}
              codloja={codloja}
              handleIsCalculating={handleIsCalculating}
              codquerovenderitem={item?.codquerovenderitem}
            />
            <Box display="inline-flex" justifyContent="flex-end" width="100%">
              <BotaoEnviarSugestao
                disabled={isCalculating || aprovacaoAutomatica}
                codquerovenderitem={item?.codquerovenderitem}
                doRefresh={doRefresh}
              />
            </Box>
          </FormProvider>
        </Box>
      </Paper>
    </Box>
  );
}
