import React, { useState, useRef } from 'react';
import AutocompleteCategorias from 'components/form/autocompleteCategorias';
import AutocompleteMarcas from 'components/form/autocompleteMarcas';
import Grid from '@mui/material/Grid2';
import { useLoading } from 'context/loading';
import ModelosService, { ModelosData } from 'services/modelos/ModelosService';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { AutocompleteModelosPayload } from 'interfaces';
import UploadImagensStyle from './style';

type FormModeloData = {
  codcategoria: number;
  codmarca: number;
  nome: string;
  imagem: string;
};

type Props = {
  action: 'cadastrar' | 'atualizar';
  onCancel?: () => void;
  title: string;
  modelo?: AutocompleteModelosPayload;
  doRefresh?: () => void;
};

function FormModelo(props: Props) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { action, onCancel = undefined, title, modelo = undefined, doRefresh = undefined } = props;
  const { register, handleSubmit } = useForm<FormModeloData>();
  const { setLoading } = useLoading();
  const [deleteImage, setDeleteImage] = useState(false);
  const [loadingThumbs, setLoadingThumbs] = useState<{
    id: string;
    error: boolean;
    loading: boolean;
    url?: string;
  } | null>(() => {
    if (modelo) {
      return {
        id: modelo.id.toString(),
        error: false,
        loading: false,
        url: modelo.imagem_url || modelo.imagem
      };
    }
    return null;
  });
  const [nomeModelo, setNomeModelo] = useState(modelo?.nome);
  const style = UploadImagensStyle();

  // Cria um ref para o autocomplete de categoria
  const autoCompleteCategoriaRef = useRef<{ clearAutoComplete: () => void }>(null);

  // Cri um ref para o autocomplete de marca
  const autoCompleteMarcaRef = useRef<{ clearAutoComplete: () => void }>(null);

  // Chamada para cadastrar e para atualizar modelo
  const cadastrarModelo = ({ nome, codmarca, codcategoria, imagem }: ModelosData) =>
    ModelosService.cadastrar({ nome, codmarca, codcategoria, imagem }).then(() => {
      Swal.fire('Modelo cadastrado com sucesso', '', 'success');
    });

  const atualizarModelo = ({ nome, codmarca, codcategoria, imagem }: ModelosData) =>
    ModelosService.atualizar({ nome, codmarca, codcategoria, imagem }, modelo?.id).then(() => {
      Swal.fire('Modelo atualizado com sucesso', '', 'success');
    });

  // decide qual chamada utiliza de acordo com a prop action
  const ActionService = action === 'cadastrar' ? cadastrarModelo : atualizarModelo;

  /**
   * Função asincrona para load de imagem
   *
   */

  function imageOnloadAsync(src: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = document.createElement('img');

      img.src = src;
      img.id = Date.now().toString();

      img.onload = () => {
        resolve(img);
      };
      img.onerror = reject;
    });
  }

  async function makeUpload(image: HTMLImageElement) {
    const data = new FormData();
    const { id, src } = image;

    const dataURL = src;

    const blobBin = atob(dataURL.split(',')[1]);
    const array = [];

    for (let i = 0; i < blobBin.length; i += 1) {
      array.push(blobBin.charCodeAt(i));
    }

    const file = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });

    data.append('image', file);

    setLoadingThumbs({ id, error: false, loading: true });

    ModelosService.upload(data).then(response => {
      setLoadingThumbs({ id, error: false, loading: false, url: response.data.url });
    });
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => loopThroughFiles(e.target.files);

  const loopThroughFiles = (files: FileList | null) => {
    if (!files) return;

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];

      if (file.type.match('image')) {
        const picReaderImage = new FileReader();

        picReaderImage.addEventListener('load', event => {
          if (event?.target && typeof event?.target.result === 'string') {
            imageOnloadAsync(event?.target.result)
              .then((image: HTMLImageElement) => {
                makeUpload(image);
              })
              .catch(err => {
                const { path } = err;

                setLoadingThumbs({ id: path[0].id, error: true, loading: false });
              });
          } else {
            /* eslint-disable-next-line */
            console.error('type of image data is not a string');
          }
        });

        picReaderImage.readAsDataURL(file);
      }
    }
  };

  // envio de dados do form para cadastro ou atualizacao
  const onSubmit = ({ nome, codcategoria, codmarca }: FormModeloData) => {
    const body: FormModeloData = {} as FormModeloData;

    body.codcategoria = codcategoria;
    body.codmarca = codmarca;
    body.nome = nome;
    body.imagem = loadingThumbs?.url || '';

    setLoading(true);
    ActionService(body)
      .then(() => {
        onCancel?.();
        doRefresh?.();
        setNomeModelo('');
        autoCompleteCategoriaRef.current?.clearAutoComplete();
        autoCompleteMarcaRef.current?.clearAutoComplete();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteThumb = () => {
    setLoadingThumbs(null);

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  const handleNomeModelo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNomeModelo(event.target.value);
  };

  return (
    <Box p={2} maxWidth={500}>
      <h2>{title}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <AutocompleteCategorias
              categoriaPai
              register={register}
              categoria={modelo?.categoria}
              ref={autoCompleteCategoriaRef}
            />
          </Grid>
          <Grid size={12}>
            <AutocompleteMarcas register={register} marca={modelo?.marca} ref={autoCompleteMarcaRef} />
          </Grid>
          <Grid size={12}>
            <TextField
              size="small"
              value={nomeModelo}
              label="Nome do modelo"
              variant="outlined"
              {...register('nome')}
              onChange={handleNomeModelo}
              fullWidth
              slotProps={{
                inputLabel: { style: { fontSize: 13 } },
                input: { style: { fontSize: 13 } }
              }}
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <Box mb={2}>A resolução da imagem de ver de 860 x 1150</Box>
          <Grid container spacing={2}>
            <Grid>
              <label htmlFor="file" className={style.fileLabel}>
                <span style={{ display: 'none' }}>upload Image</span>
                <input
                  type="file"
                  className={style.fileInput}
                  id="file"
                  onChange={handleFileInput}
                  ref={inputFileRef}
                  accept="image/png, image/gif, image/jpeg, image/tiff, image/bmp"
                />
                <div className={clsx(style.paperImagem, style.uploadBox)}>
                  <AddIcon className={style.iconAdd} />
                </div>
              </label>
            </Grid>
            {loadingThumbs?.error && (
              <Grid>
                <div
                  className={clsx(style.paperImagem, style.uploadBox, loadingThumbs.error ? style.uploadBoxError : {})}
                >
                  <IconButton type="button" onClick={() => handleDeleteThumb()} className={style.buttonDeleteThumb}>
                    <DeleteIcon className={style.iconTrash} />
                  </IconButton>
                </div>
              </Grid>
            )}
            {loadingThumbs && loadingThumbs.url && (
              <Grid>
                <div className={clsx(style.paperImagem, style.uploadBox)}>
                  {loadingThumbs.loading && <CircularProgress size={20} />}
                  {!loadingThumbs.loading && loadingThumbs.url && (
                    <Box
                      onMouseEnter={() => setDeleteImage(true)}
                      onMouseLeave={() => setDeleteImage(false)}
                      onClick={() => handleDeleteThumb()}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '128px',
                        height: '128px'
                      }}
                    >
                      <img width="100%" alt="" src={loadingThumbs.url} />

                      {deleteImage && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            borderRadius: 1,
                            padding: 0.5,
                            cursor: 'pointer',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <DeleteIcon />
                        </Box>
                      )}
                    </Box>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid>
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Grid>
            <Grid>
              {onCancel && (
                <Button variant="contained" onClick={onCancel}>
                  Cancelar
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}

export default FormModelo;
