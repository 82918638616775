import { useState, useEffect } from 'react';
import { PickingFiltro } from 'interfaces/picking';
import PickingService from 'services/picking';

export default function useFiltrosLocalizacao() {
  const [filtros, setFiltros] = useState<PickingFiltro[]>([]);

  useEffect(() => {
    PickingService.getFiltrosLocalizacao().then(response => {
      setFiltros(response.data.data);
    });
  }, []);

  return filtros;
}
