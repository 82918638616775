import api from '../api';

const buscar_bip = (query: string) => {
  return api.get(`pedido/por-bip-etiqueta?${query}`);
};

const finalizar = (codigo: string) => {
  return api.post(`pedidos/${codigo}/packing/finalizar`);
};

export default { buscar_bip, finalizar };
