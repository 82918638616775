import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { ApiListMeta, Produto } from 'interfaces';
import { ListProdutos } from './list-products';

type Props = {
  dataDevolutiva: {
    data: Produto[];
    meta: ApiListMeta;
  };
  fetchListaProdutos: (status: string, page: number) => void;
};

export function ListagemDevolutiva(props: Props) {
  const [page, setPage] = useState(1);
  const {
    dataDevolutiva: { data, meta },
    fetchListaProdutos
  } = props;

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    fetchListaProdutos('V', value);
    setPage(value);
  };

  return (
    <>
      <ListProdutos produtos={data} />

      <Box display="flex" justifyContent="center" mt={3}>
        <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
      </Box>
    </>
  );
}
