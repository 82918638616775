import React, { useState } from 'react';
import ModalInserirQuantidade from './modal-inserir-quantidade';
import ModalCadastrarRejeitados from './modal-cadastrar-rejeitados';

interface Props {
  open: boolean;
  onClose?(): void;
  codquerovender: number;
  doRefresh?(): void;
}

function CadastroProdutosRejeitados(props: Props) {
  const { open, onClose = null, codquerovender, doRefresh = undefined } = props;
  const [quantidadeProdutos, setQuantidadeProdutos] = useState<number>(0);
  const [modalCadastrarRejeitados, setModalCadastrarRejeitado] = useState<boolean>(false);

  const handleCadastroProdutos = (quantidade: string) => {
    setQuantidadeProdutos(Number(quantidade));
    openModalCadastrarRejeitado();
  };

  const openModalCadastrarRejeitado = () => {
    setModalCadastrarRejeitado(true);
  };

  const closeModalCadastrarRejeitado = () => {
    setModalCadastrarRejeitado(false);
  };

  const handleCloseCadastrarRejeitado = () => {
    if (onClose) onClose();
    closeModalCadastrarRejeitado();
  };

  const handleCloseInserirQuantidade = () => {
    if (onClose) onClose();
  };

  return (
    <div>
      <ModalInserirQuantidade
        open={open}
        retornaQuantidade={handleCadastroProdutos}
        onClose={handleCloseInserirQuantidade}
      />
      {modalCadastrarRejeitados && (
        <ModalCadastrarRejeitados
          codquerovender={codquerovender}
          open={modalCadastrarRejeitados}
          quantidade={quantidadeProdutos}
          onClose={handleCloseCadastrarRejeitado}
          doRefresh={doRefresh}
        />
      )}
    </div>
  );
}

export default CadastroProdutosRejeitados;
