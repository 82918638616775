import React, { createContext, useState, useMemo } from 'react';
import { LoadingProvider } from 'context/loading';
import { CardProductPicking } from '../card-product-picking';
import { TabPicking } from '../tab-picking';
import { BarCodePicking } from '../barcode-picking';
import { TabPanelPicking } from '../tab-panel-picking';
import { FilterSelectPicking } from '../filter-select-picking';

type PickingLayoutData = {
  setTab: React.Dispatch<React.SetStateAction<number>>;
  tab: number;
};

export const PickingLayoutContext = createContext<PickingLayoutData>({} as PickingLayoutData);

type Props = {
  children: React.ReactElement;
};

export function PickingLayout(props: Props) {
  const { children } = props;
  const [tab, setTab] = useState(0);

  const value = useMemo(
    () => ({
      tab,
      setTab
    }),
    [tab, setTab]
  );

  return (
    <LoadingProvider>
      <PickingLayoutContext.Provider value={value}>{children}</PickingLayoutContext.Provider>
    </LoadingProvider>
  );
}

PickingLayout.CardProductPicking = CardProductPicking;
PickingLayout.TabPicking = TabPicking;
PickingLayout.BarCodePicking = BarCodePicking;
PickingLayout.TabPanelPicking = TabPanelPicking;
PickingLayout.FilterSelectPicking = FilterSelectPicking;
