import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import QueroVenderItemService, { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import withStyles from '@mui/styles/withStyles';
import { green } from '@mui/material/colors';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
};

const CustomButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

export function BotaoEditarPropostaInicial(props: Props) {
  const styles = avaliacaoFisicaNegociacaoStyle();

  const { handleSubmit } = useFormContext<AprovacaoFisica>();

  const { codquerovenderitem, doRefresh, disabled = false } = props;

  const { setLoading } = useLoading();

  const handleOperation = (data: AprovacaoFisica) => {
    const body: AprovacaoFisica = { ...data };

    body.acao = 'resetar';

    if (data.precovendedor) {
      body.preco = parseFloat(data.precovendedor);
      delete body.precovendedor;
    }

    if (codquerovenderitem) {
      Swal.fire({
        title: 'Tem certeza que alterar a proposta inicial?',
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Cancelar`
      }).then(result => {
        if (result.isConfirmed) {
          setLoading(true);

          QueroVenderItemService.aprovacao_fisica(codquerovenderitem, body)
            .then(() => {
              Swal.fire('Operação realizada com sucesso', 'A proposta foi alterada', 'success');
              doRefresh();
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    } else {
      console.error('codquerovenderitem does not exist');
    }
  };

  return (
    <Box>
      <CustomButton
        color="primary"
        variant="contained"
        disabled={disabled}
        className={styles.button}
        onClick={handleSubmit(handleOperation)}
      >
        Salvar nova proposta
      </CustomButton>
    </Box>
  );
}
