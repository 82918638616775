import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const EntradaProdutosStyle = makeStyles(() => ({
  modalContent: {
    minWidth: '500px'
  },
  inputDate: {
    width: '100%'
  },
  buttonEditInfo: {
    background: 'transparent',
    color: theme.palette.primary.main,
    border: 0,
    cursor: 'pointer'
  },
  tableMark: {
    width: 10,
    height: 10,
    backgroundColor: '#3f51b5',
    display: 'block',
    borderRadius: 10
  },
  btnMoverAvaliacao: {
    whiteSpace: 'nowrap'
  }
}));

export default EntradaProdutosStyle;
