import React from 'react';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { PackingProvider } from '../components/packing/PackingProvider';

function Packing() {
  const filter = new Criteria();

  filter.add('codstatuspedido[]', '17');

  return (
    <PackingProvider title="Expedição | Packing" filter={filter}>
      <PackingProvider.PackingLayout />
    </PackingProvider>
  );
}

export default Packing;
