import makeStyles from '@mui/styles/makeStyles';

const CadastroProdutosRejeitadosStyle = makeStyles(() => ({
  modalTitle: {
    marginTop: 10
  },
  divider: {
    marginTop: 30,
    marginBottom: 30
  }
}));

export default CadastroProdutosRejeitadosStyle;
