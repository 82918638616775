import {
  QueroVenderItemRejeitar,
  QueroVenderCadastrarRejeitar,
  SugestaoValor,
  QueroVenderItemAtualizarAtributos
} from 'interfaces';
import api from '../api';

export interface AcceptanceData {
  aceito: number;
  preco?: number | string;
  observacoes: string | null;
  comprado_eu?: boolean | null;
  link_referencia?: string | null;
  valor_loja?: number | string;
  motivo?: string | null;
  observacoes_internas?: string;
  modelo?: string;
  material?: string;
  tamanho?: string;
  cor?: string;
  codigo?: string;
  ano?: string;
  flag?: number | null;
  categoria?: number;
}

export interface ReceiveData {
  recebido: number;
}

export interface AprovacaoFisica {
  acao: string;
  preco?: number;
  precoavista?: number;
  precovenda?: number;
  precocomissao?: number;
  motivo?: string;
  observacoes?: string;
  precovendedor?: string;
}

const listar = (queryMeta: string, status = 20) => {
  return api.get(`quero-vender/item?status=${status}&${queryMeta}`);
};

const acceptance = (id: number, data: AcceptanceData) => {
  return api.post(`quero-vender/item/${id}/acceptance`, data);
};

const arquivar = (codquerovenderitem: number) => {
  return api.post(`quero-vender/item/${codquerovenderitem}/arquivar-item`);
};

const receive = (id: number, data: ReceiveData) => {
  return api.put(`quero-vender/item/${id}/receive`, data);
};

const rejeitar = (id: number, data: QueroVenderItemRejeitar) => {
  return api.post(`/quero-vender/item/${id}/reject`, data);
};

const rejeitar_individual = (id: number, data: QueroVenderItemRejeitar) => {
  return api.post(`/quero-vender/item/${id}/reject-new-version`, data);
};

const cadastrar_e_rejeitar = (id: number, data: QueroVenderCadastrarRejeitar) => {
  return api.post(`/quero-vender/${id}/item/create-rejected-multiple`, data);
};

const sugestaoValor = (id: number, data: SugestaoValor) => {
  return api.post(`/quero-vender/item/pre-avaliacao/${id}/sugestao`, data);
};

const atualizarItem = (id: number, data: QueroVenderItemAtualizarAtributos) => {
  return api.patch(`/quero-vender/item/${id}/update-attributes`, data);
};

const bip = (codigo: string, status: number) => {
  return api.get(`/quero-vender/item/bip?codigo=${codigo}&status=${status}`);
};

const details = (codquerovenderitem: string) => {
  return api.get(`/quero-vender/item/${codquerovenderitem}/details`);
};

const aprovacao_fisica = (codquerovenderitem: number, data: AprovacaoFisica) => {
  return api.post(`/quero-vender/item/aprovacao-fisica/${codquerovenderitem}/sugestao`, data);
};

export default {
  listar,
  acceptance,
  receive,
  rejeitar,
  cadastrar_e_rejeitar,
  sugestaoValor,
  atualizarItem,
  bip,
  details,
  aprovacao_fisica,
  rejeitar_individual,
  arquivar
};
