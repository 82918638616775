import React from 'react';
import { alpha } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import Box from '@mui/material/Box';

const theme = createTheme();

export function Status({ status }: { status: 'ativo' | 'inativo' }) {
  let label = '';
  const styles: React.CSSProperties = {
    textAlign: 'center',
    borderRadius: 1,
    padding: '0 0.4rem'
  };

  if (status === 'ativo') {
    styles.backgroundColor = alpha(theme.palette.success.light, 0.2);
    styles.color = theme.palette.success.dark;
    label = 'Ativo';
  } else {
    styles.backgroundColor = alpha(theme.palette.warning.light, 0.2);
    styles.color = theme.palette.warning.dark;
    label = 'Inativo';
  }

  return <Box sx={styles}>{label}</Box>;
}
