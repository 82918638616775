import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Produto } from 'interfaces';
import { ModalRejeitarProduto } from './modal-rejeitar-produto';

type Props = {
  produtoreadOnly: boolean;
  produto: Produto | null;
};

export function BotaoRejeitarProduto(props: Props) {
  const { produtoreadOnly, produto } = props;
  const [showModalRejeitar, setShowModalRejeitar] = useState(false);

  const handleRejeitarProduto = () => {
    setShowModalRejeitar(true);
  };

  const handleCloseRejeitarProduto = () => {
    setShowModalRejeitar(false);
  };

  return (
    <>
      <Button size="small" disabled={produtoreadOnly} onClick={handleRejeitarProduto}>
        Rejeitar produto
      </Button>
      {produto && showModalRejeitar && (
        <ModalRejeitarProduto codproduto={produto?.codproduto} handleClose={handleCloseRejeitarProduto} />
      )}
    </>
  );
}
