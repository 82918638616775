import React, { useEffect, useCallback, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import Loading from 'components/loading';
import { useEntradaProdutos } from 'pages/avaliacao/context/entrada-produtos';
import { useAuth } from 'context/auth';
import QueroVenderRemessaService from 'services/quero-vender/QueroVenderRemessaService';
import alertValidationMessage from 'helpers/alert-validation-message';
import { QueroVenderEntradaRemessa, QueroVenderBatch } from 'interfaces';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import formEntradaRemessaStyles from './style';

/**
 * Regras das validações
 */
const schema = Yup.object().shape({
  descricao: Yup.string().required('Descrição é obrigatória'),
  pessoarecebimento: Yup.string()
});

/**
 * COMPONENTE
 */
function FormEntradaRemessa() {
  const style = formEntradaRemessaStyles();

  const { openModalEntradaRemessa, setOpenModalEntradaRemessa, batches, setBatch, dataRemessa } = useEntradaProdutos();
  const { user } = useAuth();
  const [loadingFull, setLoadingFull] = useState(false);

  /**
   * Resgata dados do form e dispara mensagem de validação
   */
  const {
    register,
    handleSubmit,

    formState: { errors }
  } = useForm<QueroVenderEntradaRemessa>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = useCallback(
    (data: QueroVenderEntradaRemessa) => {
      setOpenModalEntradaRemessa(false);
      setLoadingFull(true);

      QueroVenderRemessaService.entrada(dataRemessa.codquerovender, data)
        .then(response => {
          if (response) {
            const listBatches = batches.filter((item: QueroVenderBatch) => {
              return item.codquerovender !== response?.data.codquerovender;
            });

            setBatch(listBatches);
            Swal.fire('Remessa cadastrada', '', 'success');
          }
        })
        .finally(() => {
          setLoadingFull(false);
        });
    },
    [dataRemessa, batches, setOpenModalEntradaRemessa, setBatch, setLoadingFull]
  );

  /**
   * Fecha modal do form
   */
  const handleCloseModal = () => {
    setOpenModalEntradaRemessa(false);
  };

  /**
   * Template
   */
  return (
    <>
      <Dialog open={openModalEntradaRemessa} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Dar Entrada em Remessa</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={style.modalContent}>
            {dataRemessa?.cliente ? (
              <span className={style.labelNome}>
                Vendedora: {`${dataRemessa?.cliente?.nome} | ${dataRemessa.count.aceitos}`}
              </span>
            ) : (
              <span className={style.labelNome}>Aceitos: {dataRemessa.count.aceitos}</span>
            )}

            <input type="hidden" value={user.nome} {...register('pessoarecebimento')} />

            <TextFieldHookForms
              {...register('descricao')}
              autoFocus
              margin="dense"
              multiline
              rows={5}
              label="Descrição"
              variant="outlined"
              className={style.descricao}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              Confirmar Recebimento
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Loading open={loadingFull} />
    </>
  );
}

export default FormEntradaRemessa;
