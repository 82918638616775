import React from 'react';
import Button from '@mui/material/Button';
import QueroVenderItemService, { AprovacaoFisica } from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
};

export function BotaoAceitar(props: Props) {
  const styles = avaliacaoFisicaNegociacaoStyle();

  const { handleSubmit } = useFormContext<AprovacaoFisica>();

  const { codquerovenderitem, doRefresh, disabled = false } = props;

  const { setLoading } = useLoading();

  const handleOperation = (data: AprovacaoFisica) => {
    const body: AprovacaoFisica = { ...data };

    body.acao = 'sugerir';

    if (data.precovendedor) {
      body.preco = parseFloat(data.precovendedor);
      delete body.precovendedor;
    }

    if (codquerovenderitem) {
      setLoading(true);

      QueroVenderItemService.aprovacao_fisica(codquerovenderitem, {
        acao: 'aceitar'
      })
        .then(() => {
          Swal.fire('Operação realizada com sucesso', 'A proposta foi alterada e aceita', 'success');
          doRefresh();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('codquerovenderitem does not exist');
    }
  };

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={disabled}
      className={styles.button}
      onClick={handleSubmit(handleOperation)}
    >
      aceitar proposta
    </Button>
  );
}
