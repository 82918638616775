import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();
const SeparacaoStyle = makeStyles(() => ({
  wrapper: {
    paddingBottom: 50,
    marginTop: 30
  },
  buttonFilter: {
    marginLeft: -8
  },
  cover: {
    width: 145,
    height: 145,
    marginRight: 20,
    borderRadius: '50%'
  },

  wrapperStatus: {
    fontSize: 9,
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    padding: 7,
    lineHeight: 1.3,

    [theme.breakpoints.up('sm')]: {
      fontSize: 13
    }
  },

  iconUnique: {
    color: '#64B5F6',
    backgroundColor: 'white'
  },
  iconUniqueDisabled: {
    color: '#E6E6E6',
    backgroundColor: 'white'
  },
  textAtraso: {
    color: '#E33371'
  },
  textPrioridade: {
    color: '#FFB74D'
  },
  textStatusDisabled: {
    color: '#E6E6E6'
  },
  wrapperInfos: {
    marginLeft: 6,
    marginTop: 6
  },
  textInfos: {
    lineHeight: 1.2,
    fontSize: 20,
    marginBottom: 5
  },
  textInfosTitle: {
    fontSize: 20,
    lineHeight: 1.2,
    marginBottom: 10,
    marginTop: 5
  },
  textItensInclusos: {
    fontSize: 10,
    marginRight: 10,

    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textInfosSmall: {
    fontSize: 10
  },
  starIcon: {
    color: yellow[600],
    fontSize: 30
  },
  card: {
    padding: 0,
    marginTop: 20
  },
  cardContent: {
    position: 'relative',
    padding: 16
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20)
    }
  },
  campoCodBarras: {
    position: 'fixed',
    width: '100%',
    height: 'auto',
    right: 0,
    bottom: 0,
    display: 'inline-flex',
    backgroundColor: '#FAFAFA',
    padding: 16,

    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 270px)'
    }
  },
  empty: {
    textAlign: 'center',
    position: 'absolute',
    height: '100px',
    width: '300px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconEmotion: {
    color: '#81C784',
    fontSize: 70
  },
  textDestino: {
    fontSize: 48,
    color: '#FFFFFF'
  },
  saudePanel: {
    marginTop: theme.spacing(3),
    backgroundColor: '#f44336',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  itaimPanel: {
    marginTop: theme.spacing(3),
    backgroundColor: '#3f51b5',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

export default SeparacaoStyle;
