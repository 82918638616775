import React, { useState } from 'react';
import ClustersServices from 'services/clusters/ClustersServices';
import { Cluster } from 'interfaces';

export type WithClustersProps = {
  fetchClustersVendedora: (codecliente: number) => void;
  fetchClustersComissionada: (codecliente: number) => void;
  fetchClusterDevolucao: (codecliente: number) => void;
  clustersVendedora: Cluster[];
  clustersComissionada: Cluster[];
  clustersDevolucao: Cluster[];
};

export const withClusters = (BaseComponent: React.ElementType): React.ComponentType => {
  const WithClusters = (props: any) => { // eslint-disable-line
    const [clustersVendedora, setClustersVendedora] = useState<Cluster[]>([]);
    const [clustersComissionada, setClustersComissionada] = useState<Cluster[]>([]);
    const [clustersDevolucao, setClustersDevolucao] = useState<Cluster[]>([]);

    const fetchClustersVendedora = (codcliente: number) => {
      ClustersServices.list(codcliente).then(result => {
        setClustersVendedora(result.data.data);
      });
    };

    const fetchClustersComissionada = (codcliente: number) => {
      ClustersServices.list(codcliente).then(result => {
        const responseCluster = result.data.data.filter((cluster: Cluster) => {
          return cluster.identificador.match(/comissao-diferenciada/g);
        });
        setClustersComissionada(responseCluster);
      });
    };

    const fetchClusterDevolucao = (codcliente: number) => {
      ClustersServices.list(codcliente).then(result => {
        setClustersDevolucao(result.data.data);
      });
    };

    return (
      <BaseComponent
        {...props}
        fetchClustersVendedora={fetchClustersVendedora}
        fetchClustersComissionada={fetchClustersComissionada}
        fetchClusterDevolucao={fetchClusterDevolucao}
        clustersVendedora={clustersVendedora}
        clustersComissionada={clustersComissionada}
        clustersDevolucao={clustersDevolucao}
      />
    );
  };

  return WithClusters;
};
