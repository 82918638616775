import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type Props = {
  control: Control<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: Date | string;
  name: string;
  label?: string;
  disablePast?: boolean;
};
export function DatePickerHookForm(props: Props) {
  const { control, defaultValue = new Date(), name, label = '', disablePast = true } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DesktopDatePicker
            label={label}
            format="dd/MM/yyyy"
            ref={field.ref}
            disablePast={disablePast}
            slotProps={{
              textField: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true
              }
            }}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </LocalizationProvider>
  );
}
