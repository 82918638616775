import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Button,
  DialogActions,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider
} from '@mui/material';
import Swal from 'sweetalert2';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { useForm, useFieldArray } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import Loading from 'components/loading';
import styles from '../styles';

interface Props {
  open?: boolean;
  quantidade: number;
  onClose?(): void;
  codquerovender: number;
  doRefresh?(): void;
}

interface ProdutoRejeitado {
  categoria: string;
  marca: string;
  modelo: string;
  motivo: string[];
  outros?: string;
}

interface CamposModalCadastrarRejeitados {
  [rejeicoes: string]: ProdutoRejeitado[];
}

function ModalCadastrarRejeitados(props: Props) {
  const { open = false, quantidade, onClose = null, codquerovender, doRefresh = null } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loadingFull, setLoadingFull] = useState<boolean>(false);

  const groupArrayForms = new Array(quantidade).fill('');

  const classes = styles();

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      rejeicoes: [
        ...groupArrayForms.map(() => ({
          categoria: '',
          marca: '',
          modelo: '',
          motivo: [],
          outros: ''
        }))
      ]
    }
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'rejeicoes'
  });

  useEffect(() => {
    if (open) {
      setOpenModal(open);
    }
  }, [open]);

  const handleCloseModal = () => {
    setOpenModal(false);
    remove();
    if (onClose) onClose();
  };

  const onSubmit = async (data: CamposModalCadastrarRejeitados) => {
    const dataModified = data.rejeicoes.map((item: ProdutoRejeitado) => {
      const payload = {
        titulo: '',
        motivo: ''
      };

      payload.titulo = `${item.categoria} ${item.marca} ${item.modelo}`;
      payload.motivo = `${item.motivo.join(', ')}`;

      if (item.outros && item.outros?.length > 0) {
        payload.motivo = payload.motivo.length > 0 ? payload.motivo.concat(`, ${item.outros}`) : item.outros;
      }

      return payload;
    });

    const schema = Yup.object().shape({
      rejeicoes: Yup.array().of(
        Yup.object().shape({
          categoria: Yup.string().required(),
          marca: Yup.string().required(),
          modelo: Yup.string().required(),
          motivo: Yup.array()
            .of(Yup.string())
            /* eslint-disable */
            .test('motivo-valido', 'Selecione ou escreva um motivo', function (value) {
              if (this.parent.motivo.length > 0 || this.parent.outros.length > 0) {
                return true;
              }
              return false;
            })
            /* eslint-enable */
        })
      )
    });

    try {
      await schema.validate(data, { abortEarly: false });
      setLoadingFull(true);
      await QueroVenderItemService.cadastrar_e_rejeitar(codquerovender, { rejeicoes: dataModified })
        .then(() => {
          Swal.fire('Operação efetuada com sucesso!', '', 'success').then(() => {
            if (doRefresh) {
              handleCloseModal();
              doRefresh();
            }
          });
        })
        .finally(() => {
          setLoadingFull(false);
        });
    } catch (error) {
      if (error.name === 'ValidationError') {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Verifique os campos obrigatórios'
        });
      } else if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    }
  };

  return (
    <>
      <Loading open={loadingFull} />
      <Dialog open={openModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Grid container>
              <Grid item xs={11}>
                <Typography component="div" variant="h6" className={classes.modalTitle}>
                  {/* Cadastro de produtos rejeitados */}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={handleCloseModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {fields.map(({ id }, index) => {
              return (
                <div key={id}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextFieldHookForms
                        label="Categoria*"
                        placeholder="Categoria"
                        variant="outlined"
                        {...register(`rejeicoes.${index}.categoria`)}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Marca*"
                        size="small"
                        placeholder="Marca"
                        variant="outlined"
                        {...register(`rejeicoes.${index}.marca`)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Modelo*"
                        size="small"
                        placeholder="Modelo"
                        variant="outlined"
                        {...register(`rejeicoes.${index}.modelo`)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="div" variant="subtitle1" className={classes.modalTitle}>
                        Motivo*
                      </Typography>
                      <Typography component="div" variant="subtitle2" className={classes.modalTitle}>
                        Selecione e/ou escreva um motivo*
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label="Não trabalhamos com a marca"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="MARCA_NAO_ACEITA" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label="Apresenta manchas"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="MANCHAS" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label="Reprovada na avaliação de autenticidade"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="AUTENTICIDADE" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label=" Possui furos/rasgo"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="Possui furos/rasgo" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label="Peça com detalhes/feita em poliuretano"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="Peça com detalhes/feita em poliuretano" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup row>
                        <FormControlLabel
                          label="Detalhes criticos aparentes"
                          {...register(`rejeicoes.${index}.motivo`)}
                          control={<Checkbox value="DETALHES_CRITICOS" color="primary" />}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Outros"
                        placeholder="Outro"
                        variant="outlined"
                        {...register(`rejeicoes.${index}.outros`)}
                        multiline
                        rows={2}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Cadastrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ModalCadastrarRejeitados;
