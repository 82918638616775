import React, { useEffect } from 'react';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextFieldReadOnlyDefault, TextFieldCPF, TextFieldCelular } from 'components/form-fields-grid';
import { FormEndereco } from 'components/form-endereco';
import VendedoraService from 'services/vendedora/VendedoraService';
import Swal from 'sweetalert2';
import { QueroVenderItemVendedora } from 'interfaces';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import alertValidationMessage from 'helpers/alert-validation-message';

type Props = {
  vendedora?: QueroVenderItemVendedora | undefined;
  onClose: () => void;
};

const validationSchema = Yup.object().shape({
  nome: Yup.string().required('O campo Nome é obrigatório'),
  telefone1: Yup.string().required('O campo Telefone é obrigatório'),
  cep: Yup.string().required('O campo Cep é obrigatório'),
  endereco: Yup.string().required('O campo Rua é obrigatório'),
  numero: Yup.string().required('O campo Numero é obrigatório'),
  bairro: Yup.string().required('O campo Bairro é obrigatório'),
  cidade: Yup.string().required('O campo Cidade é obrigatório'),
  uf: Yup.string().required('O campo UF é obrigatório'),
  email: Yup.string(),
  cpf: Yup.string(),
  cod: Yup.string(),
  complemento: Yup.string()
});

function ModalEditarEndereco(props: Props) {
  const { vendedora = undefined, onClose } = props;
  type SchmemaType = Yup.InferType<typeof validationSchema>;

  const methods = useForm({
    defaultValues: {
      nome: vendedora?.nome,
      email: vendedora?.email,
      cpf: vendedora?.cpf,
      cod: vendedora?.codigo,
      telefone1: vendedora?.telefone1,
      cep: vendedora?.cep,
      endereco: vendedora?.endereco,
      numero: vendedora?.numero,
      complemento: vendedora?.complemento,
      bairro: vendedora?.bairro,
      cidade: vendedora?.cidade,
      uf: vendedora?.uf
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: SchmemaType) => {
    VendedoraService.update({
      codloja: vendedora?.codloja as number,
      nome: data.nome,
      telefone1: data.telefone1.replace(/\D/g, ''),
      cep: data.cep.replace(/\D/g, ''),
      endereco: data.endereco,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      cidade: data.cidade,
      uf: data.uf
    }).then(() => {
      Swal.fire('Endereço atualizado com sucesso', '', 'success');
      onClose();
    });
  };

  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0) {
      alertValidationMessage(methods.formState.errors);
    }
  }, [methods.formState.errors]);

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>Editar endereço</DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="overline" color="textSecondary">
                Informações do vendedor
              </Typography>
            </Grid>
            <TextFieldReadOnlyDefault placeholder="Nome" name="nome" />
            <TextFieldCPF label="CPF" placeholder="XXX.XXX.XXX-XX" name="cpf" />
            <TextFieldReadOnlyDefault placeholder="CÓD" name="cod" />
            <TextFieldReadOnlyDefault placeholder="Email" name="email" />
            <TextFieldCelular label="Telefone" placeholder="(XX) XXXXX-XXXX" name="telefone1" />
            <Grid item xs={4} />
            <FormEndereco />
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={methods.handleSubmit(onSubmit)}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalEditarEndereco;
