import { FormEnderecoType } from 'components/form-endereco';
import api from '../api';

const pedidos = (query: string) => {
  return api.get(`pedidos?${query}`);
};

const pedido = (codigo: string) => {
  return api.get(`pedidos/${codigo}/checking`);
};

const iniciar = (codigo: string) => {
  return api.post(`pedidos/${codigo}/checking/iniciar`);
};

const editar_pedido = (codPedido: string | undefined, data: FormEnderecoType) => {
  return api.put(`pedidos/${codPedido}`, data);
};

const conferir = (data: {
  bip: string;
  codpedido: number;
  data: {
    lacre: string;
    observacao: string;
  };
}) => {
  return api.post('pedido-produto/conferido', { ...data });
};

const conferir_observacoes = (data: {
  bip: string;
  codpedido: number;
  detalhes_criticos: number[];
  data: {
    lacre: string;
    observacao: string;
  };
}) => {
  return api.post('pedido-produto/conferido-detalhes-criticos', { ...data });
};

const imprimir_etiqueta = (codigo: string) => {
  return api.get(`pedidos/${codigo}/etiqueta/url`);
};

const localizar_bip = (query: string) => {
  return api.get(`pedido/por-bip?${query}`);
};

const finalizar = (codigo: string) => {
  return api.post(`pedidos/${codigo}/checking/finalizar`);
};

const imprimir_declaracao = (codigo: string) => {
  return api.get(`pedidos/${codigo}/declaracao/url`);
};

export default {
  pedidos,
  pedido,
  iniciar,
  conferir,
  imprimir_etiqueta,
  finalizar,
  localizar_bip,
  imprimir_declaracao,
  conferir_observacoes,
  editar_pedido
};
