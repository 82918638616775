import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { Send } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { BadgeClusters } from 'components/custom-badge/badge-clusters';
import { AvaliacaoProvider, useAvaliacao } from 'pages/avaliacao/context/pre-avaliacao';
import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch, QueroVenderItem } from 'interfaces';
import { withClusters, WithClustersProps } from 'components/with-clusters';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import AlertMerge from 'components/alert-merge';
import StarIcon from '@mui/icons-material/Star';
import Item from '../components/Item';
import style from './style';

const AvaliacaoDetails = withClusters((props: WithClustersProps) => {
  document.title = 'Pré-avaliação de Produtos';

  const { fetchClustersVendedora, fetchClustersComissionada, clustersVendedora, clustersComissionada } = props;

  const navigate = useNavigate();

  const classes = style();

  const { id } = useParams() as { id: string };
  const { listEditingMode, clearEditing } = useAvaliacao();

  const [batch, setBatch] = useState<QueroVenderBatch>();
  const [loading, setloading] = useState(true);
  const [loadingEnviar, setloadingEnviar] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [avaliados, setAvaliados] = useState<boolean | undefined>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [comissionamentoDiferenciado, setComissionamentoDiferenciado] = useState<boolean>(false);

  const handleSend = () => {
    Swal.fire({
      title: 'Tem certeza que deseja enviar a proposta?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        setloadingEnviar(true);
        QueroVenderService.send(id).then(() => {
          setloadingEnviar(false);
          Swal.fire('Proposta enviada', '', 'success').then(() => {
            navigate('/pre-avaliacao');
          });
        });
      }
    });
  };

  const itemStatus = (item: QueroVenderItem) => {
    if (typeof item.aceito === 'boolean') return 'Avaliado';
    if (item.aceito === 127) return 'Duplicado';

    return 'Aguardando Avaliação';
  };

  useEffect(() => {
    QueroVenderService.get(id).then(response => {
      setBatch(response.data);
      setloading(false);

      // busca clusters de vendedora e captadora
      fetchClustersVendedora(response.data.cliente.codcliente);
      if (response.data.comissionada) {
        fetchClustersComissionada(response.data.comissionada.codcliente);
      }

      // Verifica se existe algum produto sem ser avaliado, caso exista ataualiza o estado
      const itensBatch = [...response.data.itens];
      const allAvaliados = itensBatch.some(elem => elem.aceito === null);
      setAvaliados(allAvaliados);
    });
  }, [id, refresh]); // eslint-disable-line

  const unlockBatch = useCallback(() => {
    QueroVenderService.unlock(id);
    clearEditing();
  }, [id, clearEditing]);

  useEffect(() => {
    QueroVenderService.lock(id);

    return function cleanup() {
      unlockBatch();
    };
  }, [id, unlockBatch]);

  useEffect(() => {
    setIsEditing(listEditingMode.some(item => item.editing));
  }, [listEditingMode]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <div className={classes.space}>
            {batch && batch.relacionados.length > 0 && (
              <AlertMerge
                batch={batch}
                loading={setloading}
                exibirQuantidade="total"
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )}
            <h1 className={classes.title}>{`Produtos #${id}`}</h1>
            {!batch?.fluxo_novo && batch?.fluxo_novo !== undefined && (
              <span className={classes.fluxoAntigo}>
                <NotificationsActive className={classes.iconFluxoAntigoAlerta} />
                Este batch respeitará o fluxo antigo
              </span>
            )}
            {!!batch?.primeira_interacao && (
              <Box className={classes.boxClienteNovo}>
                <StarIcon />
                Primeira Interação
              </Box>
            )}
            <h2>{`${batch?.cliente?.nome} - ${batch?.cliente?.codcliente}`}</h2>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6}>
                <div>
                  <div>
                    <strong>Email: </strong>
                    {batch?.cliente?.email}
                  </div>
                  <div>
                    <strong>Telefone: </strong>
                    {batch?.cliente?.telcelular}
                  </div>
                </div>
                {clustersVendedora.length > 0 && clustersVendedora.map(cluster => <BadgeClusters cluster={cluster} />)}
              </Grid>
              <Grid item xs={6}>
                {/* <div>
                        <strong>Nome captador:</strong>
                      </div> */}
                {batch?.comissionada?.codcliente ? (
                  <div>
                    <strong>Código do captador:</strong> {batch?.comissionada?.codigo}
                    {/* <button type="button" className={classes.btEditar} onClick={openMatchSellerModal}>
                      editar
                    </button> */}
                  </div>
                ) : (
                  <div>
                    {/* <Button color="secondary" variant="contained" onClick={openMatchSellerModal}>
                      adicionar captador
                    </Button> */}
                  </div>
                )}

                {clustersComissionada.length > 0 &&
                  clustersComissionada.map(cluster => <BadgeClusters cluster={cluster} />)}
              </Grid>
            </Grid>
            <Box mt={6}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={comissionamentoDiferenciado}
                        onChange={() => setComissionamentoDiferenciado(!comissionamentoDiferenciado)}
                        color="primary"
                      />
                    }
                    label={`Comissionamentos Diferenciados: ${comissionamentoDiferenciado ? 'Ativado' : 'Desativado'}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.sendButton}
                      endIcon={<Send />}
                      onClick={handleSend}
                      disabled={avaliados || isEditing}
                    >
                      Enviar proposta
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>

          {batch?.itens.map((item, index) => (
            <Item
              doRefresh={() => setRefresh(refresh + 1)}
              key={item.codquerovenderitem}
              item={item}
              index={index + 1}
              status={itemStatus(item)}
              step="preAvaliacao"
              comissionamentoDiferenciado={comissionamentoDiferenciado}
            />
          ))}
        </>
      )}

      <Loading open={loadingEnviar} />
    </TemplateBase>
  );
});

function AvaliacaoDetailsPage() {
  return (
    <AvaliacaoProvider>
      <AvaliacaoDetails />
    </AvaliacaoProvider>
  );
}

export default AvaliacaoDetailsPage;
