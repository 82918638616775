import React, { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import formatPrice from 'helpers/format-price';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sugestoes: {
    preco: number;
    precoaprazo: number;
    precoavista: number;
    precocomissao: number;
    precocomissionada: number;
    observacoes: string;
    status: number;
    revisado: boolean;
  }[];
  avaliador: string;
};

export function ModalHistoricoValor(props: Props) {
  const { sugestoes, isOpen, onClose, avaliador } = props;

  const open = useMemo(() => isOpen, [isOpen]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Histórico de valor
          <IconButton aria-label="close" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Data</TableCell> */}
              <TableCell>Alterado por</TableCell>
              <TableCell>Valor à vista</TableCell>
              <TableCell>Valor vendedor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sugestoes.map(item => (
              <TableRow key={Math.random()}>
                {/* <TableCell>
                  26/06/2022 <br />
                  10:56:27
                </TableCell> */}
                <TableCell>{`${avaliador ? `${avaliador} (avaliador)` : '-'} `}</TableCell>
                <TableCell>{formatPrice(item.precoavista)}</TableCell>
                <TableCell>{formatPrice(item.preco)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
