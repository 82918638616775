import React from 'react';
import Button from '@mui/material/Button';
import ModeracaoService from 'services/peer-two-peer/ModeracaoService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ProdutoService from 'services/produto/ProdutoService';
import { ModeracaoEtapa } from 'interfaces';
import { useLoading } from 'context/loading';
import { FormData, Atributo } from './form-moderacao-provider';

type Props = {
  enableFinalizar: boolean;
  produtoreadOnly: boolean;
  codproduto: string;
  etapas_selecionadas: ModeracaoEtapa[];
};

export function BotaoFinalizarEtapas(props: Props) {
  const { enableFinalizar, produtoreadOnly, codproduto, etapas_selecionadas } = props;
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext<FormData>();
  const { setLoading } = useLoading();

  const onSubmit = ({ ...rest }: FormData) => {
    const data = { ...rest };

    if (data.itens_inclusos && data.itens_inclusos.length) {
      data.itens_inclusos = data.itens_inclusos.map(item_incluso => parseInt(item_incluso as string, 10));
    }

    // Filtra todos os campos que começam com 'cadastro_'. Eles representam a maioria das variações dos filtros
    // transformando-os em um array de objetos com o codfiltrovariacao

    const filtrosVariacoes = Object.keys(data)
      .filter(key => key.match(/cadastro_/g))
      .reduce((acc: { codfiltrovariacao: number }[], curr: string) => {
        if (data[curr] instanceof Array && data[curr].length > 0) {
          acc.push(
            ...data[curr]
              .map((i: string) => Number(i))
              .map((i: number) => ({
                codfiltrovariacao: i
              }))
          );
        } else if (data[curr] && data[curr].length > 0) {
          acc.push(
            ...data[curr]
              .split(',')
              .map((i: string) => Number(i))
              .map((i: number) => ({
                codfiltrovariacao: i
              }))
          );
        }

        return acc;
      }, []);

    if (data.atributos) {
      data.atributos = data.atributos.map(atributo => JSON.parse(atributo as string));
    }

    const body: { [key: string]: any } = { // eslint-disable-line
      filtrovariacoes: filtrosVariacoes,
      codcategoriaprincipal: data.codcategoria,
      codmarca: data.codmarca,
      precificacao: {
        loja: data.precoloja,
        venda_a_vista: data.precovenda
      }
    };

    if (typeof data.modelo_id === 'number' && data.modelo_id) {
      body.modelo_id = data.modelo_id;
    } else {
      body.modelo_especifico = data.modelo_id;
    }

    setLoading(true);

    ModeracaoService.aprovar_etapas(codproduto, etapas_selecionadas).then(() => {
      ModeracaoService.concluir_moderacao(codproduto, { recusado: false })
        .then(() => {
          Promise.all([
            ProdutoService.update(codproduto, body),
            ProdutoService.update_atributos(codproduto, data.atributos as Atributo[])
          ])
            .then(() => {
              Swal.fire('Moderação concluída com sucesso', '', 'success').then(() => {
                navigate('/parceiros/moderacao-produtos');
              });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Button
      type="submit"
      disabled={produtoreadOnly || !enableFinalizar}
      onClick={handleSubmit(onSubmit)}
      size="small"
      variant="contained"
      color="primary"
    >
      Finalizar e enviar
    </Button>
  );
}
