import api from '../api';

const validar = (id: string) => {
  return api.get(`produtos/${id}/excluir/validacao`);
};

const excluir = (id: string, motivo: string) => {
  return api.delete(`produtos/${id}/excluir`, { data: { motivo } });
};

export default { validar, excluir };
