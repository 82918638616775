import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import CustomInputCurrency from 'components/form/custom-input-currency';
import CalculoPrecoService from 'services/calculo-preco/CalculoPrecoService';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid, { GridSize } from '@mui/material/Grid';
import Swal from 'sweetalert2';
import { AutoCompleteCategoriasPayload, QueroVenderItem } from 'interfaces';
import { debounce } from 'components/utils/debounce';
import { UseFormRegister } from 'react-hook-form';

import styles from './styles';

type Props = {
  register: UseFormRegister<any>; // eslint-disable-line
  comissionamentoDiferenciado: boolean | undefined;
  disabled?: boolean;
  negociacaoAtual: QueroVenderItem['negociacao_atual'];
  categoria?: AutoCompleteCategoriasPayload | null;
  codloja?: number;
  captadora?: boolean;
  isPreAvaliacao?: boolean;
  codquerovenderitem?: number | null;
  hideValorParcelado?: boolean;
  showPrecoLoja?: boolean;
  itemValorLoja?: string;
  precoSugerido?: string;
  isCalculating?: (value: boolean) => void;
  xs?: number;
  viewOnly?: boolean;
};

function CalculadoraValores(props: Props) {
  const {
    register,
    comissionamentoDiferenciado,
    disabled = false,
    negociacaoAtual,
    categoria = undefined,
    codloja = undefined,
    captadora = false,
    isPreAvaliacao = false,
    codquerovenderitem = null,
    hideValorParcelado = false,
    showPrecoLoja = false,
    itemValorLoja = undefined,
    isCalculating = undefined,
    precoSugerido = undefined,
    xs = 3,
    viewOnly = false
  } = props;

  const style = styles();

  const [valorVendedor, setValorVendedor] = useState<string>(`${negociacaoAtual?.preco ?? ''}`);
  const [valorParcelado, setValorParcelado] = useState<string>(`${negociacaoAtual?.precoaprazo ?? ''}`);
  const [valoraVista, setValoraVista] = useState<string>(`${negociacaoAtual?.precoavista ?? ''}`);
  const [valorComissionada, setValorComissionada] = useState<string>(`${negociacaoAtual?.precocomissionada ?? ''}`);
  const [valorComissaoEu, setValorComissaoEu] = useState<null | number | string>(
    negociacaoAtual?.precocomissao || null
  );

  const [disableField, setDisableField] = useState(false);

  const [valorLoja, setValorLoja] = useState<string>(itemValorLoja as string);

  const [comissionamentoDiferenciadoProduto, setComissionamentoDiferenciadoProduto] =
    useState(comissionamentoDiferenciado);

  const inputValoraVista = useRef<HTMLInputElement>(null);

  const calcularPreco = useCallback(
    (value: number) => {
      setDisableField(true);
      if (negociacaoAtual && codquerovenderitem) {
        CalculoPrecoService.calcularPrecoFaixaAtual(codquerovenderitem, value)
          .then(response => {
            setValorParcelado(response.data.preco.parcelado ?? 0);
            setValorVendedor(response.data.split.vendedora ?? 0);
            setValorComissionada(response.data.split?.comissionada ?? 0);
            setValorComissaoEu(response.data.split?.comissao_eu ?? 0);
          })
          // eslint-disable-next-line
          .catch((error: any) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error?.response?.data?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
            });
          })
          .finally(() => {
            isCalculating?.(false);
            setDisableField(false);
            inputValoraVista?.current?.focus();
          });
      } else if (categoria?.codcategoria) {
        CalculoPrecoService.calcularPreco(categoria.codcategoria, value, captadora ? codloja : 0)
          .then(response => {
            setValorParcelado(response.data.preco.parcelado ?? 0);
            setValorVendedor(response.data.split.vendedora ?? 0);
            setValorComissionada(response.data.split?.comissionada ?? 0);
            setValorComissaoEu(response.data.split?.comissao_eu ?? 0);
          })
          // eslint-disable-next-line
          .catch((error: any) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error?.response?.data?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
            });
          })
          .finally(() => {
            isCalculating?.(false);
            setDisableField(false);
            inputValoraVista?.current?.focus();
          });
      }
    },
    [codloja, captadora, categoria, codquerovenderitem, negociacaoAtual, isCalculating]
  );

  const calcularPrecoAPrazo = useCallback(
    (value: string) => {
      if (Number(value) === 0 || value === '') {
        setValorParcelado('0');
      } else {
        setDisableField(true);
        CalculoPrecoService.calcularPrecoAPrazo(Number(value))
          .then(response => {
            // 'preco.a_prazo' do résultado da API é associado ao 'setValorParcelado'
            setValorParcelado(response.data.preco.a_prazo);
            isCalculating?.(false);
          })
          // eslint-disable-next-line
        .catch((error: any) => {
            isCalculating?.(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error?.response?.data?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
            });
          })
          .finally(() => {
            setDisableField(false);
          });
      }
    },
    [isCalculating]
  );

  // Debounce que faz calculo de valores automaticamente(batendo na api) quando productComissionamentoDiferenciado é false
  const debounceCalculoPreco = useMemo(
    () =>
      debounce((value: string) => {
        calcularPreco(Number(value));
      }, 1300),
    [categoria, codquerovenderitem, negociacaoAtual] // eslint-disable-line
  );

  // Debounce que faz calculo de parcelamento manualmente quando productComissionamentoDiferenciado é true
  const debouncePrecoaParcelado = useMemo(
    () =>
      debounce((value: string) => {
        isCalculating?.(true);

        calcularPrecoAPrazo(value.toString());
      }, 1300),
    [isCalculating, calcularPrecoAPrazo]
  );

  // Controle do campo valor vendedor quando este for inserido manualmente
  const handleValorVendedor = (e: React.ChangeEvent<HTMLInputElement>) => setValorVendedor(e.target.value);

  // Contrile do campo Valor Comissionada
  const handleComissionada = (e: React.ChangeEvent<HTMLInputElement>) => setValorComissionada(e.target.value);

  // Controle do campo Valor à vista
  // Faz calculo automativo se produtoComissionamentosDiferenciados from false
  // Faz calculo de parcelamento se produtoComissionamentosDiferenciados for true
  const handleValoraVista = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!comissionamentoDiferenciadoProduto) {
      debounceCalculoPreco(e.target.value);
    } else {
      debouncePrecoaParcelado(e.target.value);
    }

    setValoraVista(e.target.value);
    isCalculating?.(true);
  };

  // // Controle do campo Preço Loja
  const handlePrecoLoja = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValorLoja(event.target.value);
  };

  // Zera ou calcula os valores quando muda o comissionamento diferenciado para atualizar a exibição no front
  const handleLayoutOnToggleComissionamento = useCallback(
    (isComissionamentoDiferenciado: boolean | undefined) => {
      if (isComissionamentoDiferenciado) {
        setValorVendedor('0');
        setValorComissaoEu(0);
        setValorComissionada('0');
        calcularPrecoAPrazo(valoraVista);
      } else if (valoraVista) {
        calcularPreco(Number(valoraVista));
      }
    },
    [valoraVista, calcularPreco, calcularPrecoAPrazo]
  );

  const handleComissionamentoDiferenciadoProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComissionamentoDiferenciadoProduto(event.target.checked);
  };

  // atualiza valores caso atualize a negociacao
  useEffect(() => {
    setValorVendedor(`${negociacaoAtual?.preco ?? ''}`);
    setValorParcelado(`${negociacaoAtual?.precoaprazo ?? ''}`);
    setValoraVista(`${negociacaoAtual?.precoavista ?? ''}`);
    setValorComissionada(`${negociacaoAtual?.precocomissionada ?? ''}`);
    setValorComissaoEu(negociacaoAtual?.precocomissao || null);
  }, [negociacaoAtual]);

  useEffect(() => {
    if (comissionamentoDiferenciado !== comissionamentoDiferenciadoProduto) {
      setComissionamentoDiferenciadoProduto(comissionamentoDiferenciado);
    }
  }, [comissionamentoDiferenciado]); // eslint-disable-line

  useEffect(() => {
    handleLayoutOnToggleComissionamento(comissionamentoDiferenciadoProduto);
  }, [comissionamentoDiferenciadoProduto]); // eslint-disable-line


  useEffect(() => {
    if (valoraVista) {
      calcularPreco(Number(valoraVista));
    }
  }, [categoria]); // eslint-disable-line

  const numXs = useMemo(() => {
    if (xs) {
      return xs;
    }

    return 3;
  }, [xs]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        {!viewOnly && (
          <FormControlLabel
            disabled={disabled}
            control={
              <Switch
                checked={comissionamentoDiferenciadoProduto}
                onChange={handleComissionamentoDiferenciadoProduto}
                color="primary"
              />
            }
            label={`Comissionamentos Diferenciados: ${comissionamentoDiferenciadoProduto ? 'Ativado' : 'Desativado'}`}
          />
        )}
      </Grid>
      <Grid item xs={numXs as GridSize}>
        <CustomInputCurrency
          disabled={disabled || disableField}
          label="Valor Final à Vista"
          register={register}
          placeholder="Valor Final à Vista"
          variant="outlined"
          readonly={viewOnly}
          fontBig
          size="small"
          className={style.textInput}
          name="precoavista"
          fullWidth
          inputRef={inputValoraVista}
          value={valoraVista}
          onChange={handleValoraVista}
        />
      </Grid>

      <Grid
        item
        xs={numXs as GridSize}
        style={{
          display: hideValorParcelado ? 'none' : 'block'
        }}
      >
        <CustomInputCurrency
          disabled={disabled || disableField}
          variant="outlined"
          register={register}
          placeholder="Valor parcelado"
          readonly
          className={style.textInput}
          size="small"
          fullWidth
          name={isPreAvaliacao ? 'precoaprazo' : 'precovenda'}
          label="Valor parcelado"
          value={valorParcelado}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>

      <Grid item xs={numXs as GridSize}>
        <CustomInputCurrency
          disabled={disabled || disableField}
          variant="outlined"
          register={register}
          placeholder="Valor vendedor"
          readonly={!comissionamentoDiferenciadoProduto}
          className={style.textInput}
          size="small"
          fullWidth
          name={isPreAvaliacao ? 'preco' : 'precovendedor'}
          label="Valor vendedor"
          value={valorVendedor}
          onChange={handleValorVendedor}
          inputProps={{
            readOnly: disableField
          }}
        />
      </Grid>

      {precoSugerido && (
        <Grid item xs={numXs as GridSize}>
          <CustomInputCurrency
            variant="outlined"
            register={register}
            placeholder="Expectativa do vendedor"
            readonly
            className={style.textInput}
            size="small"
            fullWidth
            name=""
            label="Expectativa do vendedor"
            value={precoSugerido}
          />
        </Grid>
      )}

      {captadora && (
        <Grid item xs={numXs as GridSize}>
          <CustomInputCurrency
            disabled={disabled || disableField}
            variant="outlined"
            register={register}
            placeholder="Valor comissionada"
            className={style.textInput}
            size="small"
            fullWidth
            name="precocomissionada"
            label="Valor comissionada"
            value={valorComissionada}
            onChange={handleComissionada}
            inputProps={{
              readOnly: disableField
            }}
          />
        </Grid>
      )}

      {!isPreAvaliacao && (
        <Grid item xs={numXs as GridSize}>
          <CustomInputCurrency
            disabled
            variant="outlined"
            placeholder="Valor Comissão EU"
            className={style.textInput}
            size="small"
            register={register}
            fullWidth
            label="Valor Comissão EU"
            name="precocomissao"
            value={valorComissaoEu as string}
          />
        </Grid>
      )}

      {!isPreAvaliacao && showPrecoLoja && (
        <Grid item xs={numXs as GridSize}>
          <CustomInputCurrency
            disabled={disabled || disableField}
            variant="outlined"
            placeholder="Preço Loja"
            className={style.textInput}
            size="small"
            register={register}
            fullWidth
            onChange={handlePrecoLoja}
            label="Preço Loja"
            name="precoloja"
            value={valorLoja}
            inputProps={{
              readOnly: disableField
            }}
          />
        </Grid>
      )}

      {!isPreAvaliacao && <input hidden readOnly {...register('precocomissao')} value={Number(valorComissaoEu)} />}
    </Grid>
  );
}

export default CalculadoraValores;
