import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import LocalizacaoProdutoService from 'services/localizacao/LocalizacaoProdutoService';
import { QueroVenderLogs } from 'interfaces';
import Loading from 'components/loading';
import { format } from 'date-fns';
import { Box } from '@mui/material';

function LocalizarProduto() {
  const [codigoProduto, setCodigoProduto] = useState<string>('');
  const [localizacao, setLocalizacao] = useState<string | null>('');
  const [acervo, setAcervo] = useState<string | null>('');
  const [motivoRejeicao, setMotivoRejeicao] = useState<string | null>('');
  const [logs, setLogs] = useState<QueroVenderLogs[] | null>(null);
  const [loadingFull, setLoadingFull] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setCodigoProduto(event.target.value);

  const fetchLocalizacaoProduto = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoadingFull(true);
    await LocalizacaoProdutoService.localizar(codigoProduto)
      .then(({ data }) => {
        setLocalizacao(data?.localizacao);
        setAcervo(data?.acervo?.label);
        setLogs(data?.logs);
        setMotivoRejeicao(data?.querovenderitem?.motivo_rejeicao_fisica);
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  return (
    <TemplateBase>
      <h1>Localizar Produtos</h1>

      <Paper elevation={1}>
        <form onSubmit={fetchLocalizacaoProduto}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    name="codigo_produto"
                    placeholder="ex: A29H2J"
                    variant="outlined"
                    size="small"
                    label="Codigo do produto"
                    fullWidth
                    onChange={onChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button type="submit" color="primary" variant="contained">
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Paper>
      {acervo && (
        <>
          <h2>Posição no Acervo</h2>
          <Typography>{acervo}</Typography>
        </>
      )}

      {localizacao && (
        <>
          <h2>Campo localização</h2>
          <Typography>{localizacao}</Typography>
        </>
      )}

      {motivoRejeicao && (
        <Box display="flex" alignItems="center" gap={1}>
          <h4>Motivo Rejeição:</h4>
          <Typography>{motivoRejeicao}</Typography>
        </Box>
      )}

      {logs && (
        <>
          <h2>Logs:</h2>
          <Table>
            <TableHead>
              <TableCell>Data</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Usuário</TableCell>
            </TableHead>
            <TableBody>
              {logs?.map(item => {
                return (
                  <TableRow>
                    <TableCell>{item?.data ? format(new Date(item.data), 'dd/MM/yyyy') : ''}</TableCell>
                    <TableCell>{item?.descricao}</TableCell>
                    <TableCell>{item?.usuario?.nome}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default LocalizarProduto;
