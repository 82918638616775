import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import Pluralize from 'components/utils/pluralize';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch, QueroVenderItem } from 'interfaces';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

interface PropsBatchRow {
  batch: QueroVenderBatch;
}

function BatchNovaRemessaRow(props: PropsBatchRow) {
  const { batch } = props;
  const navigate = useNavigate();
  const [openProducts, setopenProducts] = useState(false);
  const [produtos, setProdutos] = useState<Array<QueroVenderItem>>();

  const handleEnviarAgendamento = () => {
    const codBatch = batch.codquerovender;

    Swal.fire({
      title: 'Tem certeza que deseja enviar para agendamento?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        QueroVenderService.moverAgendamento(codBatch)
          .then(() => {
            navigate('/avaliacao/agendamento');
          })
          .catch(() => {
            //
          });
      }
    });
  };

  const handleMoverAvaliacao = () => {
    const codBatch = batch.codquerovender;

    Swal.fire({
      title: 'Tem certeza que deseja enviar para Avaliação Física?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        QueroVenderService.moverAvaliacaoFisica(codBatch)
          .then(() => {
            navigate('/avaliacao/avaliacao-fisica-batch');
          })
          .catch(() => {
            //
          });
      }
    });
  };

  /**
   * Abrir produtos do batch
   */
  const openProdutos = useCallback(() => {
    const idBatch = batch.codquerovender.toString();

    QueroVenderService.get(idBatch).then(response => {
      setopenProducts(!openProducts);
      setProdutos(response.data.itens);
    });
  }, [batch, openProducts]);

  return (
    <>
      <TableRow key={batch.codquerovender}>
        <TableCell>
          {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0 ? '[VIP] ' : ''}
          {`${batch?.cliente?.nome} - ${batch.cliente?.codcliente}`}
        </TableCell>

        <TableCell>
          {`${batch.count.total} `}
          <Pluralize quantidade={batch.count.total} singular="produto" plural="produtos" />

          {batch.count.total > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={openProdutos}>
              {openProducts ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>

        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>

        <TableCell>
          <Button
            size="small"
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleEnviarAgendamento}
          >
            Enviar para agendamento
          </Button>
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            onClick={handleMoverAvaliacao}
          >
            Mover para avaliação física
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {produtos &&
                produtos.map(item => (
                  <List key={item.codquerovenderitem} dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={item?.fotos[0]?.imagem} alt="" />
                      </ListItemAvatar>
                      <ListItemText primary={item.titulo} />
                    </ListItem>
                  </List>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BatchNovaRemessaRow;
