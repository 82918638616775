import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Fotos from 'pages/avaliacao/components/Fotos';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import CheckingService from 'services/checking';
import AutocompleteFiltros from 'components/form/autocompleteFiltrosOptions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FiltrosService from 'services/filtros/FiltrosService';

import Lightbox from 'react-image-lightbox';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import { Produto, FiltrosVariacao } from 'interfaces';

import { useAccordionCollapse } from 'context/accordion-collapse';
import CodBarrasIcon from '../../../../../assets/images/icon-cod-barras.png';
import CheckingStyle from './style';

type Props = {
  index: number;
  codpedido: number;
  refresh: () => void;
  devolucao: boolean;
} & Produto;

type ImagesModal = {
  url: string;
  index: number;
  show: boolean;
};

function ProdutoChecking(props: Props) {
  const classes = CheckingStyle();

  const refInputConferir = useRef<HTMLInputElement | null>(null);

  const { index, codpedido, refresh, devolucao, ...rest } = props;

  const { setActiveCollapse, activeCollapse } = useAccordionCollapse();

  const [produto, setProduto] = useState(rest);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [valueLacre, setValueLacre] = useState<string>('');
  const [valueObservacao, setValueObservacao] = useState<string>('');
  const [valueBip, setValueBip] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [filtrosDetalhesCriticos, setfiltrosDetalhesCriticos] = useState<(FiltrosVariacao | string)[]>([]);
  const [comDefeito, setComDefeito] = useState<boolean>(false);
  const [detalhesCriticosIds, setDetalhesCriticosIds] = useState<number[]>([]);

  const customModalStyles = {
    overlay: {
      zIndex: 9999
    }
  };

  const [imagesModal, setImagesModal] = useState<ImagesModal>({
    url: '',
    index: 0,
    show: false
  });

  const nextCollapse = index + 1;

  /**
   * Seleciona classe para colorir borda do avatar de acordo com o estado do produtos
   * (se foi conferido, se foi conferido com defeito ou se ainda não foi conferido)
   */
  const estadoProdutoClasse: string = useMemo(() => {
    if (produto.conferido && produto.conferido.length > 0) {
      return classes.conferido;
    }

    if (produto.conferido_observacoes && produto.conferido_observacoes.length > 0) {
      return classes.conferidoObservacoes;
    }

    return classes.naoConferido;
  }, [produto, classes]);

  /**
   * Helper para adicionar a opção "Outros" aos filtros de detalhes criticos caso ela não existir
   */

  const adicionaFiltroOutros = (filtros: FiltrosVariacao[]) => {
    const temFiltroOutros = filtros.some(filtro => filtro.slug === 'outros');

    if (!temFiltroOutros) {
      return [...filtros, 'Outros'];
    }

    return filtros;
  };

  /**
   * Busca o filtro de detalhes criticos de acordo com a categoria do produto
   */

  const handleFetchFilter = useCallback(
    (codcategoria: number) => {
      FiltrosService.fetch_filtro_variacoes({ filtro: 'detalhes-criticos', codcategoria }).then(response => {
        const filtros = adicionaFiltroOutros(response.data.data);
        setfiltrosDetalhesCriticos(filtros);
      });
    },
    [setfiltrosDetalhesCriticos]
  );

  /**
   * Controla checkbox que marca se produto está ou não com defeito
   */
  const handleChangeComDefeito = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      handleFetchFilter(produto.categoria_principal.codcategoria);
    }

    setComDefeito(event.target.checked);
  };

  const isValueEqualCodigoProduto = () => {
    try {
      if (valueBip.toLowerCase() !== produto.codigo.toLowerCase() && !valueBip.includes(`${produto.codproduto}`)) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'O código bipado não corresponde ao código do produto'
        });

        return false;
      }
    } catch (error) { // eslint-disable-line
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: error
      });
    }
    return true;
  };

  /**
   * Controla o evento de teclado para pega o disparo do Enter no campo "Conferir"
   */
  const handleEnterConferir = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (isValueEqualCodigoProduto()) {
        const data = {} as { lacre: string; observacao: string; detalhes_criticos: number[] };
        let triggerConferir;

        data.lacre = valueLacre;
        data.observacao = valueObservacao;

        if (filtrosDetalhesCriticos.length > 0 && detalhesCriticosIds.length === 0 && comDefeito) {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'É preciso preencher o campo Detalhes Criticos'
          });
        } else {
          setLoading(true);

          if (comDefeito) {
            triggerConferir = CheckingService.conferir_observacoes({
              bip: valueBip,
              codpedido,
              detalhes_criticos: detalhesCriticosIds,
              data
            });
          } else {
            triggerConferir = CheckingService.conferir({
              bip: valueBip,
              codpedido,
              data
            });
          }
          triggerConferir
            .then(response => {
              setProduto(response.data.data);
              refresh();
              Swal.fire('Produto conferido', '', 'success').then(() => {
                setActiveCollapse(nextCollapse);
                // handlePrintEtiqueta();
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  };

  /**
   * Controla o valor do campo "Lacre" no state
   */
  const handleCampoLacre = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueLacre(e.target.value);
  };

  /**
   * Controla o valor do campo "Observação" no state
   */
  const handleCampoObservacao = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueObservacao(e.target.value);
  };

  /**
   * Controla o valor do campo "Conferir" no state
   */
  const handleCampoConferir = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueBip(e.target.value);
  };

  // const handlePrintEtiqueta = () => {
  //   if (nextCollapse === count) {
  //     handleUltimoConferido();
  //   }
  // };

  /**
   * Filtra ids dos detallhes criticos a medida que são selecionados no autocomplete e guarda no state
   */
  const handleSetDetalhesCriticos = useCallback((filters: any) => { // eslint-disable-line
    const ids = filters.map((filter: FiltrosVariacao) => {
      if (typeof filter === 'string') {
        return filter;
      }
      return filter.codfiltrovariacao;
    });

    setDetalhesCriticosIds(ids);
  }, []);

  const handleExpandCard = () => setExpanded(!expanded);

  useEffect(() => {
    setTimeout(() => {
      if (refInputConferir && refInputConferir.current) {
        refInputConferir?.current?.focus();
      }
    }, 100);
  }, [expanded]);

  useEffect(() => {
    const produtoComDefeito = !!(produto.conferido_observacoes && produto.conferido_observacoes.length > 0);

    setValueObservacao(devolucao ? '' : produto.observacao);
    setValueLacre(devolucao ? `Produto de devolução - ${produto?.codigo}` : produto.lacre);
    setComDefeito(produtoComDefeito);

    if (produtoComDefeito) {
      handleFetchFilter(produto.categoria_principal.codcategoria);
    }
  }, [produto, handleFetchFilter, devolucao]);

  useEffect(() => {
    setExpanded(index === activeCollapse);
  }, [activeCollapse, index]);

  return (
    <>
      {imagesModal.show && (
        <Lightbox
          mainSrc={produto?.imagens[imagesModal.index]?.medium}
          onCloseRequest={() =>
            setImagesModal({
              url: '',
              index: 0,
              show: false
            })
          }
          reactModalStyle={customModalStyles}
          nextSrc={produto?.imagens[(imagesModal.index + 1) % produto.imagens.length]?.medium}
          // TODO: Corrigir erro de lint
          prevSrc={produto?.imagens[(imagesModal.index + produto.imagens.length - 1) % produto?.imagens.length]?.medium} // eslint-disable-line
          onMoveNextRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + 1) % produto.imagens.length
            })
          }
          onMovePrevRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + produto.imagens.length - 1) % produto.imagens.length
            })
          }
        />
      )}
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Tooltip placement="right" title="#32222">
              <Avatar src={produto?.imagens?.[0]?.medium} className={`${classes.avatar} ${estadoProdutoClasse}`}>
                {index + 1}
              </Avatar>
            </Tooltip>
          }
          action={
            <IconButton onClick={handleExpandCard} size="large">
              {expanded ? <Remove /> : <Add />}
            </IconButton>
          }
          title={produto?.codigo}
          subheader={produto?.nome}
        />
        <Collapse in={expanded}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography variant="overline" color="textSecondary" className={classes.titles}>
                  Detalhes Críticos
                </Typography>
                <div>{produto?.descricao1?.replaceAll(/<[^>]*>/g, '')}</div>
                {/* <div>
              {produto?.detalhes_criticos.length > 0 ? (
                <ul className={classes.list}>
                  {produto?.detalhes_criticos.map((detalhe: string) => {
                    return <li key={detalhe}>{detalhe}</li>;
                  })}
                </ul>
              ) : (
                <div>{produto?.descricao1?.replaceAll(/<[^>]*>/g, '')}</div>
              )}
            </div> */}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline" color="textSecondary" className={classes.titles}>
                  Localizado por:
                </Typography>
                <div>
                  {produto?.separado_usuario?.nome} <br />
                  {format(new Date(produto?.separado), 'dd/MM/yyyy HH:mm:ss')}
                </div>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline" component="div" color="textSecondary" className={classes.titles}>
                  Lacre
                </Typography>

                <div>
                  <TextField
                    variant="outlined"
                    label="Número do lacre"
                    onChange={handleCampoLacre}
                    value={valueLacre}
                    size="small"
                    fullWidth
                    InputProps={{
                      readOnly: devolucao
                    }}
                  />
                </div>
                <Box mt={1}>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={3}
                    label="Observacão"
                    value={valueObservacao}
                    onChange={handleCampoObservacao}
                    size="small"
                    fullWidth
                    margin="dense"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline" component="div" color="textSecondary" className={classes.titles}>
                  Itens Inclusos
                </Typography>
                <ul className={classes.list}>
                  {produto.itens_inclusos &&
                    produto.itens_inclusos.map((item: string) => {
                      return <li key={item}>{item}</li>;
                    })}
                </ul>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="overline" component="div" color="textSecondary" className={classes.titles}>
                  Conferir
                </Typography>

                <div>
                  <TextField
                    variant="outlined"
                    size="small"
                    inputRef={refInputConferir}
                    fullWidth
                    onKeyUp={handleEnterConferir}
                    onChange={handleCampoConferir}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={CodBarrasIcon} alt="" />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={comDefeito} onChange={handleChangeComDefeito} />}
                  label="Com defeito"
                />
              </Grid>
              <Grid item xs={9}>
                {comDefeito && (
                  <AutocompleteFiltros
                    filtro={filtrosDetalhesCriticos}
                    name=""
                    label="Adicionar Detalhes Criticos"
                    getFiltro={handleSetDetalhesCriticos}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline" color="textSecondary" className={classes.titles}>
                  Imagens
                </Typography>
                <Box display="flex">
                  {produto?.imagens.map((imagem, i) => {
                    return (
                      <Fotos
                        onClick={() =>
                          setImagesModal({
                            url: imagem.medium,
                            index: i,
                            show: true
                          })
                        }
                        key={imagem?.codprodutoimagem}
                        codquerovenderfoto={imagem?.codprodutoimagem}
                        imagem={imagem?.medium}
                        url={imagem?.medium}
                      />
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
        <Loading open={loading} />
      </Card>
    </>
  );
}
export default ProdutoChecking;
