import api from '../api';

export type BuscarClienteParams = {
  email?: string;
  cpf?: string;
};

const buscar = (params?: BuscarClienteParams, codcliente?: string) => {
  return api.get(`/clientes${codcliente ? `/${codcliente}` : ''}`, { params });
};

export type AtualizarClienteParams = {
  fretegratis_ate: string;
  fretegratis_ativo: boolean;
  fretegratis_uso_unico: boolean | string;
};

const atualizar = (codcliente: number | undefined, body: AtualizarClienteParams) => {
  return api.patch(`/clientes/${codcliente}`, { ...body });
};

const link_alteracao_senha = (params: { [k: string]: unknown }) => {
  return api.get(`/clientes/link-alteracao-senha`, { params });
};

export default { buscar, atualizar, link_alteracao_senha };
