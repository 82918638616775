import React, { useState, useEffect } from 'react';
import TemplateBase from 'components/template-base/template';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { FiltrosProvider } from 'context/filtros';
import { LoadingProvider, useLoading } from 'context/loading';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch } from 'interfaces';
import Swal from 'sweetalert2';
import { withClusters, WithClustersProps } from 'components/with-clusters';
import { BadgeClusters } from 'components/custom-badge/badge-clusters';
import { formatTextPhone } from 'helpers/format-text-phone';
import CardDetails from './card-details';

const PageContent = withClusters((props: WithClustersProps) => {
  document.title = 'Negociação';

  const { fetchClustersVendedora, fetchClustersComissionada, clustersVendedora, clustersComissionada } = props;

  const navigate = useNavigate();

  const [batch, setBatch] = useState<QueroVenderBatch | null>(null);
  const [loadingBatch, setLoadingBatch] = useState(false);

  const { setLoading } = useLoading();

  const { codquerovender } = useParams() as { codquerovender: string };

  /**
   * Aceitar todos os itens do batch
   */

  const handleAceitarTodos = () => {
    setLoading(true);
    QueroVenderService.bulk(parseInt(codquerovender, 10))
      .then(() => {
        Swal.fire('Operação efetuada com sucesso!', '', 'success').then(() => {
          navigate('/avaliacao/avaliacao-fisica-negociacao');
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Busca itens do batch
   */

  const fetchBatchItens = () => {
    setLoadingBatch(true);

    QueroVenderService.get(codquerovender)
      .then(response => {
        if (!response?.data?.itens.length) {
          navigate('/avaliacao/avaliacao-fisica-negociacao');
        }
        setBatch(response.data);
        if (response.data.cliente) fetchClustersVendedora(response.data.cliente.codcliente);
        if (response.data.comissionada) fetchClustersComissionada(response.data.comissionada.codcliente);
      })
      .finally(() => {
        setLoadingBatch(false);
      });
  };

  useEffect(() => {
    // fetchGenero().then(() => {

    // });
    fetchBatchItens();
  }, []); //eslint-disable-line

  return (
    <TemplateBase>
      {!loadingBatch && batch ? (
        <>
          <h1>Negociação Avaliação Física</h1>
          <h2>{batch.cliente?.nome}</h2>
          <Grid container spacing={3}>
            <Grid size={6}>
              <div>
                <div>
                  <strong>Email:</strong> {batch.cliente?.email}
                </div>
                <div>
                  <strong>Telefone: </strong> {formatTextPhone(batch.cliente?.telcelular)}
                </div>
                <div>
                  <strong>Código do vendedor:</strong>
                </div>
                <div>
                  <strong>Código do captador:</strong> {batch.cliente?.captador?.codigo}
                </div>
              </div>

              <Box display="inline-flex" flexWrap="wrap">
                {clustersVendedora.length > 0 &&
                  clustersVendedora.map(cluster => <BadgeClusters key={cluster.id} cluster={cluster} />)}
              </Box>
            </Grid>
            <Grid size={6}>
              {/* <div>
                        <strong>Nome captador:</strong>
                      </div> */}
              {batch?.comissionada?.codcliente ? (
                <div>
                  <strong>Código do captador:</strong> {batch?.comissionada?.codigo}
                  {/* <button type="button" className={classes.btEditar} onClick={openMatchSellerModal}>
                      editar
                    </button> */}
                </div>
              ) : (
                <div>
                  {/* <Button color="secondary" variant="contained" onClick={openMatchSellerModal}>
                      adicionar captador
                    </Button> */}
                </div>
              )}

              {clustersComissionada.length > 0 &&
                clustersComissionada.map(cluster => <BadgeClusters cluster={cluster} />)}
            </Grid>
          </Grid>
          <Box mt={5} justifyContent="flex-end" display="flex">
            <Button variant="contained" color="primary" onClick={handleAceitarTodos}>
              Valor aceito em todos os itens
            </Button>
          </Box>

          {batch.itens &&
            batch.itens.map(item => (
              <CardDetails
                key={item.codquerovenderitem}
                comissionada={Boolean(batch.comissionada)}
                codquerovender={parseInt(codquerovender, 10)}
                codloja={batch?.cliente?.captador?.codloja}
                codquerovenderitem={item.codquerovenderitem}
                doRefresh={fetchBatchItens}
                nomeproduto={item?.produto?.nome}
                imagem={item?.produto?.imagens?.[0]?.thumbnail}
                status={item?.negociacao_atual?.status}
              />
            ))}
        </>
      ) : (
        <>carregando...</>
      )}
    </TemplateBase>
  );
});

function AvaliacaoFisicaNegociacaoDetailsPage() {
  return (
    <LoadingProvider>
      <FiltrosProvider>
        <PageContent />
      </FiltrosProvider>
    </LoadingProvider>
  );
}

export default AvaliacaoFisicaNegociacaoDetailsPage;
