import makeStyles from '@mui/styles/makeStyles';
import { grey, green, red } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const CheckingStyle = makeStyles(() => ({
  field: {
    width: 350
  },
  paper: {
    marginTop: 30
  },
  listItem: {
    marginTop: 10,
    marginBottom: 10
  },
  avatar: {
    width: 100,
    height: 100,
    border: `2px solid ${grey[500]}`
  },
  conferido: {
    border: `2px solid ${green[500]}`
  },
  naoConferido: {
    border: `2px solid ${grey[500]}`
  },
  conferidoObservacoes: {
    border: `2px solid ${red[500]}`
  },
  card: {
    marginTop: 20
  },
  titles: {
    fontSize: '0.65rem',
    marginBottom: 10
  },
  list: {
    paddingLeft: '10px',

    '& > li': {
      paddingLeft: '5px',
      listStyleType: '"-"',
      textIndent: '10px'
    }
  },
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginTop: 23
    },

    '& .MuiCardHeader-title': {
      color: '#737373'
    },
    '& .MuiCardHeader-subheader': {
      color: 'black'
    }
  },
  printIcon: {
    color: '#115293'
  },
  printText: {
    color: '#115293',
    textTransform: 'uppercase',
    fontWeight: 400,
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none'
  },
  linkAvaliar: {
    color: theme.palette.primary.main
  }
}));

export default CheckingStyle;
