import React, { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TransferenciasServices from 'services/ferramentas/TransferenciasServices';
import Swal from 'sweetalert2';

export function RemoverTransferencia({
  open = false,
  handleClose,
  codtransferencia,
  handleSuccess
}: {
  open: boolean;
  handleClose: () => void;
  codtransferencia: number;
  handleSuccess: () => void;
}) {
  const modalOpen = useMemo(() => {
    return open;
  }, [open]);

  const [motivo, setMotivo] = useState<string>('');

  const handleMotivo = (event: React.ChangeEvent<HTMLInputElement>) => setMotivo(event.target.value);

  const handleEnviarMotivo = () => {
    Swal.fire({
      title: 'Tem certeza que deseja remover esta transferência?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        TransferenciasServices.remover(codtransferencia, motivo).then(() => {
          handleSuccess();
        });
      }
    });
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Motivo</DialogTitle>
      <DialogContent>
        <TextField multiline rows={4} fullWidth onChange={handleMotivo} variant="outlined" />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleEnviarMotivo}>
          Enviar
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
