import api from '../api';

const gerar_carrinho = (params = {}) => {
  return api.post(`/carrinhos`, params);
};

const fetch_carrinho = (codcarrinho: string, params = {}) => {
  return api.get(`/carrinhos/${codcarrinho}`, { params });
};

const incluir_produto = (codcarrinho: string, payload: { codigo?: string; codproduto?: string }) => {
  return api.post(`/carrinhos/${codcarrinho}/produtos`, payload);
};

const remover_produto = (codcarrinho: string, codproduto: number) => {
  return api.delete(`/carrinhos/${codcarrinho}/produtos/${codproduto}`);
};

const cancelar_carrinho = (codcarrinho: string) => {
  return api.delete(`/carrinhos/${codcarrinho}`);
};

export default {
  gerar_carrinho,
  fetch_carrinho,
  incluir_produto,
  remover_produto,
  cancelar_carrinho
};
