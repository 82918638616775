import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFiltros, FiltroStateBody } from 'context/filtros';

export function useMapperPayload() {
  const { item } = useFormAvaliacao();
  const { filtroState } = useFiltros();

  const mapper = ({ autocompleteCodCategoria, autocompleteCodMarca, autocompleteIdModelo, ...data }: any) => {// eslint-disable-line

    const payload: any = { ...data }; // eslint-disable-line

    if (data.itens_inclusos && data.itens_inclusos.length) {
      payload.itens_inclusos = data.itens_inclusos.map((item_incluso: string) => Number(item_incluso));
    }

    if (data.marca_especifica) {
      payload.marca_especifica = data.marca_especifica;
    }

    if (data.categoria_especifica) {
      payload.categoria_especifica = data.categoria_especifica;
    }

    if (item?.codquerovender) {
      payload.codquerovender = item?.codquerovender;
    }

    Object.values<FiltroStateBody>(filtroState)
      .filter(filtro => ['itens-inclusos', 'condicao'].indexOf(filtro.slug) < 0)
        .reduce((acc: any, curr: FiltroStateBody) => { // eslint-disable-line

        try {
          if (curr.field) {
            if (acc[curr.field] instanceof Array) {
              acc[curr.field] = acc[curr.field].map((i: string) => Number(i));
            } else if (acc[curr.field]) {
              acc[curr.field] = acc[curr.field].split(',').map((i: string) => Number(i));
            }
          }
        } catch (error) {
          console.error(error);
        }

        return acc;
      }, payload);

    Object.keys(payload).forEach((key: string) => {
      if (!payload[key] || payload[key].toString().length === 0) {
        delete payload[key];
      }
    });

    if (typeof data.publicacao_modo !== 'string') {
      payload.publicacao_modo = null;
    }

    return payload;
  };

  return {
    mapper
  };
}
