import React, { useState, useCallback } from 'react';
import { format } from 'date-fns';
import Pluralize from 'components/utils/pluralize';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PrintIcon from '@mui/icons-material/Print';
import ArchiveIcon from '@mui/icons-material/Archive';
import { QueroVenderBatch, QueroVenderItem } from 'interfaces';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import Swal from 'sweetalert2';
import EntradaProdutosStyle from '../style';

interface PropsBatchRow {
  batch: QueroVenderBatch;
  setModalDataAgendamento(args: { open: boolean; data_agendamento: Date | string }): void;
  setModalEditCodRemessa(args: { open: boolean; cod_remessa: string }): void;
  handleEntradaRemessa(remessa: string): void;
  handleDiscardRemessa(codquerovender: number): void;
}

function BatchRow(props: PropsBatchRow) {
  const { batch, setModalDataAgendamento, setModalEditCodRemessa, handleEntradaRemessa, handleDiscardRemessa } = props;
  const style = EntradaProdutosStyle();

  const [open, setOpen] = useState(false);
  const [produtos, setProdutos] = useState<Array<QueroVenderItem>>();

  const navigate = useNavigate();
  /**
   * Abrir produtos do batch
   */
  const openProdutos = useCallback(() => {
    if (!open) {
      const idBatch = batch.codquerovender.toString();

      QueroVenderService.get(idBatch).then(response => {
        const produtosBatch = response.data.itens.filter((item: QueroVenderItem) => item.aceito);

        setProdutos(produtosBatch);
        setOpen(!open);
      });
    } else {
      setOpen(!open);
    }
  }, [batch, open]);

  const handleMoverAvaliacao = () => {
    const codBatch = batch.codquerovender;

    Swal.fire({
      title: 'Tem certeza que deseja enviar para Avaliação Física?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        QueroVenderService.moverAvaliacaoFisica(codBatch)
          .then(() => {
            navigate('/avaliacao/avaliacao-fisica-batch');
          })
          .catch(() => {
            //
          });
      }
    });
  };

  return (
    <>
      <TableRow key={batch.codquerovender}>
        <TableCell>
          {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0 ? '[VIP] ' : ''}
          {batch?.cliente?.nome}
        </TableCell>
        <TableCell>
          {`${batch.count.aceitos} `}

          <Pluralize quantidade={batch.count.aceitos} singular="produto" plural="produtos" />
          {batch.count.aceitos > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={openProdutos}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy')}</TableCell>
        <TableCell align="center">{batch.volume.formarecebimento}</TableCell>
        <TableCell align="center">
          {batch.volume.agendamento ? (
            <button
              type="button"
              onClick={() =>
                setModalDataAgendamento({
                  open: true,
                  data_agendamento: batch?.volume.agendamento ? new Date(batch?.volume.agendamento) : ''
                })
              }
              className={style.buttonEditInfo}
            >
              {format(new Date(batch.volume.agendamento), 'dd/MM/yyyy')}
            </button>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell align="center">
          {batch.volume.formarecebimento === 'correios' ? (
            <button
              type="button"
              onClick={() =>
                setModalEditCodRemessa({
                  open: true,
                  cod_remessa: batch?.volume?.remessa_codigo
                })
              }
              className={style.buttonEditInfo}
            >
              {batch?.volume?.remessa_codigo}
            </button>
          ) : (
            batch?.volume?.remessa_codigo
          )}
        </TableCell>
        <TableCell>
          <Button variant="contained" size="small" onClick={() => handleEntradaRemessa(batch?.volume?.remessa_codigo)}>
            Dar entrada
          </Button>
          <Box mt={1}>
            <Button variant="contained" size="small" className={style.btnMoverAvaliacao} onClick={handleMoverAvaliacao}>
              Mover para avaliação
            </Button>
          </Box>
        </TableCell>
        <TableCell>
          {batch.volume.formarecebimento === 'coleta' && (
            <Box component="span" ml={2}>
              <IconButton
                aria-label="delete"
                color="primary"
                href={batch.volume.remessa_print}
                target="_blank"
                size="large"
              >
                <PrintIcon />
              </IconButton>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Tooltip placement="top" title="Arquivar remessa">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDiscardRemessa(batch.codquerovender)}
              size="large"
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {produtos &&
                produtos.map(item => {
                  if (item.fotos.length > 0) {
                    return (
                      <List key={item.codquerovenderitem} dense>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar src={item.fotos[0].imagem} alt="" />
                          </ListItemAvatar>
                          <ListItemText primary={item.titulo} />
                        </ListItem>
                      </List>
                    );
                  }

                  return null;
                })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BatchRow;
