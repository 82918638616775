import React from 'react';

interface Props {
  value: number;
}

function FormatCurrency(props: Props) {
  const { value } = props;

  const formatBRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  return <span>{formatBRL}</span>;
}

export default FormatCurrency;
