import * as Yup from 'yup';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFiltros, FiltroStateBody } from 'context/filtros';

export function useValidateSchema() {
  const { temComissionada, tipoMarca, campoCategoria, campoTamanho, modeloRequired, categoriaMultipla } =
    useFormAvaliacao();

  const { filtroState } = useFiltros();

  const validate = (data: any) => {  // eslint-disable-line
    const schema: any = { // eslint-disable-line
      codcategoria: Yup.string().required('O campo Categoria é obrigatório'),
      codmarca: Yup.string().required('O campo Marca é obrigatório'),
      modelo: modeloRequired
        ? Yup.string().nullable()
        : Yup.string().required('O campo Complemento Título é obrigatório'),
      precoavista: Yup.string().required('O campo Valor final a vista é obrigatório'),
      categoria_especifica: categoriaMultipla
        ? Yup.string().required('O campo Categoria Específica é obrigatório')
        : Yup.string().nullable(),
      precocomissionada: temComissionada
        ? Yup.string().test(
            'test-preco-comissionada',
            'O campo Valor Comissionada é obrigatório',
            function validaPrecoComissionada() {
              const precoComissionada = parseFloat(data.precocomissionada as string);

              if (temComissionada && precoComissionada && precoComissionada > 0) {
                return true;
              }

              return false;
            }
          )
        : Yup.string().nullable()
    };

    const showAllExcept = ['detalhes-criticos', 'itens-inclusos', 'condicao'];

    Object.values<FiltroStateBody>(filtroState)
      .filter(filtro => showAllExcept.indexOf(filtro.slug) < 0)
        .reduce((acc: any, curr: FiltroStateBody) => { // eslint-disable-line

        if (curr.field) {
          const message = `O campo Cadastro ${curr.nome} é obrigatório`;

          if (curr.field === 'cadastro_comprimento') {
            acc.cadastro_comprimento = campoCategoria?.url?.match(/vestido(s)?|saia(s)?/g)
              ? Yup.array(Yup.number()).min(1, message).required(message)
              : Yup.array().nullable();

            return acc;
          }

          if (curr.field === 'cadastro_tamanho') {
            acc.cadastro_tamanho = Yup.array(Yup.number()).test(
              'test-tamanho-value',
              message,
              function validaTamanho() {
                if (
                  campoTamanho.length === 0 ||
                  (campoTamanho.length === 1 && campoTamanho[0]?.slug === 'sem-etiqueta')
                ) {
                  return false;
                }

                return true;
              }
            );

            return acc;
          }

          if (curr.field === 'cadastro_tamanho_internacional') {
            acc.cadastro_tamanho_internacional =
              tipoMarca === 'I' ? Yup.array(Yup.number()).min(1, message).required(message) : Yup.array().nullable();

            return acc;
          }

          if (curr.field === 'cadastro_genero') {
            acc.cadastro_genero = Yup.array(Yup.number()).min(1, message).required(message);

            return acc;
          }

          acc[curr.field] = Yup.array(Yup.number()).min(1, message).required(message);
        }

        return acc;
      }, schema);

    return Yup.object().shape(schema).validate(data, { abortEarly: false });
  };

  return {
    validate
  };
}
