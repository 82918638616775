import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { FiltrosVariacao, Filtro, Produto } from 'interfaces';
import AutocompleteFiltros from 'components/form/autocompleteFiltrosOptions';
import { FiltroState, useFiltros } from 'context/filtros';
import { UseFormRegister } from 'react-hook-form';
import styles from '../styles';

type Props = {
  produto: Produto | null | undefined;
  disabled?: boolean;
  register?: UseFormRegister<any>; //eslint-disable-line
  showCampoComprimento?: boolean;
  getFiltro?: ((filtro: (FiltrosVariacao | string)[]) => void) | null;
  tipoMarca?: 'N' | 'I' | '' | null;
  showAllExcept?: string[];
};
export function FiltrosAutomplete(props: Props) {
  const { filtroState } = useFiltros();
  const classes = styles();
  const { produto, disabled, register, showCampoComprimento, getFiltro, tipoMarca, showAllExcept = [] } = props;

  const resulFilter: Produto['filtros'] | FiltroState | null = useMemo(() => {
    if (filtroState && Object.keys(filtroState).length > 0) return filtroState;

    if (produto?.filtros) return produto?.filtros;

    return null;
  }, [filtroState, produto?.filtros]);

  const handleAutocompleteFiltro = (param: Filtro): React.ReactElement => {
    if (!param?.field) return <span />;

    return (
      <Grid key={`${param.codfiltro}`} item xs={6}>
        <AutocompleteFiltros
          register={register}
          filtro={param.variacoes}
          disabled={disabled}
          defaultValue={produto?.filtros?.[param.slug]?.variacoes}
          name={param.field}
          label={param.nome}
        />
      </Grid>
    );
  };

  const handleAutocompleteFiltroTamanho = (param: Filtro): React.ReactElement => {
    if (!param?.field) return <span />;

    return (
      <Grid key={`${param.codfiltro}`} item xs={6}>
        <AutocompleteFiltros
          register={register}
          filtro={param.variacoes}
          disabled={disabled}
          defaultValue={produto?.filtros?.[param.slug]?.variacoes}
          name={param.field}
          label={param.nome}
          getFiltro={getFiltro}
          // ref={refTamanhos}
        />
      </Grid>
    );
  };

  const renderAutoCompleteFiltros = () => {
    if (!resulFilter) return <div>-</div>;

    return Object.values<Filtro>(resulFilter)
      .filter(filtro => showAllExcept.indexOf(filtro.slug) < 0)
      .filter(filtro => {
        if (tipoMarca === 'N') {
          return filtro.slug !== 'tamanho-internacional';
        }

        return filtro;
      })
      .filter(filtro => {
        if (!showCampoComprimento) {
          return filtro.slug !== 'comprimento';
        }

        return filtro;
      })
      .map(filter => {
        if (filter.slug === 'tamanho') {
          return handleAutocompleteFiltroTamanho(filter);
        }
        return handleAutocompleteFiltro(filter);
      });
  };

  return (
    <Grid container spacing={2} className={classes.contentWrap} alignItems="center">
      {renderAutoCompleteFiltros()}
    </Grid>
  );
}

FiltrosAutomplete.defaultProps = {
  disabled: false,
  showCampoComprimento: true,
  tipoMarca: null,
  showAllExcept: ['detalhes-criticos', 'itens-inclusos', 'genero', 'condicao'],
  getFiltro: null
};
