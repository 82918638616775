import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const gerarRelatorioStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  modalContent: {
    minWidth: '500px'
  },
  inputDate: {
    width: '100%'
  }
  //   colCheckbox: {
  //     width: '1rem'
  //   },
  //   mergeBtn: {
  //     '&:disabled': {
  //       cursor: 'not-allowed',
  //       pointerEvents: 'auto'
  //     }
  //   }
}));

export default gerarRelatorioStyle;
