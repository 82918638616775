import React from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm } from 'react-hook-form';
import ClientesService from 'services/clientes/ClientesService';
import { useLoading } from 'context/loading';
import ModalLinkRedefinirSenhas from './modal-link-redefinir-senhas';

function RedefinirSenha() {
  document.title = 'Ferramentas | Redefinir Senha';

  const [infoSelected, setInfoSelected] = React.useState<string>('');
  const [showModal, setShowModal] = React.useState(false);
  const [cpf, setCpf] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');
  const [nome, setNome] = React.useState<string>('');
  const [expiracao, setExpiracao] = React.useState<string>('');

  const { register, handleSubmit, setValue } = useForm();

  const { setLoading } = useLoading();

  /**
   * Envia o valor do campo de busca para a API
   */

  const onSubmit = (data: { [k: string]: unknown }) => {
    const filteredObj = Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null)); // eslint-disable-line

    setLoading(true);

    ClientesService.link_alteracao_senha(filteredObj)
      .then(response => {
        setCpf(response.data.cliente.cpf);
        setEmail(response.data.cliente.email);
        setNome(response.data.cliente.nome);
        setUrl(response.data.url);
        setExpiracao(response.data.expiracao);
        setShowModal(true);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    setInfoSelected(value);
    setValue('codcliente', null);
    setValue('codloja', null);
    setValue('codigo_loja', null);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setValue('codcliente', null);
    setValue('codloja', null);
    setValue('codigo_loja', null);
  };

  return (
    <TemplateBase>
      <h2>Redefinir Senha</h2>

      <Paper elevation={1}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid size={5}>
                  <h3>Localizar Cliente</h3>
                  <Select native variant="outlined" size="small" fullWidth onChange={handleChangeSelect}>
                    <option value="">-</option>
                    <option value="codcliente">Informar ID comprador</option>
                    <option value="codloja">Informar ID vendedor</option>
                    <option value="codigo_loja">Informar Código vendedor (alfanumérico)</option>
                  </Select>
                  <Box mt={2}>
                    {infoSelected === 'codcliente' && (
                      <TextField {...register('codcliente')} variant="outlined" size="small" fullWidth />
                    )}
                    {infoSelected === 'codloja' && (
                      <TextField {...register('codloja')} variant="outlined" size="small" fullWidth />
                    )}
                    {infoSelected === 'codigo_loja' && (
                      <TextField {...register('codigo_loja')} variant="outlined" size="small" fullWidth />
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Button variant="contained" sx={{ mt: 2 }} type="submit">
                Gerar Url
              </Button>
            </form>
          </CardContent>
        </Card>
      </Paper>

      {showModal && (
        <ModalLinkRedefinirSenhas
          closeModal={handleCloseModal}
          cpf={cpf}
          email={email}
          url={url}
          nome={nome}
          expiracao={expiracao}
        />
      )}
    </TemplateBase>
  );
}

export default RedefinirSenha;
