import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import ComissionadaService from 'services/comissionada/ComissionadaService';

import TemplateBase from 'components/template-base/template';
import { CategoriaComissionamento } from 'interfaces/comissionamento';
import { LojaComissionada, FaixaComissionada, ComissionadaPayload } from 'interfaces/comissionada';
import ComissionamentoDetailsStyle from './style';
import {
  moneyToDecimal,
  decimalToMoney,
  decimalToPercentage,
  percentageToDecimal,
  tempId
} from '../../comissionamento/ComissionamentoHelpers/ComissionamentoHelpers';

import api from '../../../services/api';

// referente aos paineis
interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tab: {
    backgroundColor: theme.palette.background.paper
  }
}));
// end referente aos paineis

function ComissionadasDetailsPage() {
  document.title = 'Tabela Comissionada';

  const id = parseInt(useParams<{ id: string }>().id as string, 10);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabelaComissionamentoAPIOriginal, setTabelaComissionamentoAPIOriginal] = useState<FaixaComissionada[]>([]);
  const [tabelaComissionamentoAPI, setTabelaComissionamentoAPI] = useState<FaixaComissionada[]>([]);
  const [tabelaComissionamentoNovosRegistros, setTabelaComissionamentoNovosRegistros] = useState<FaixaComissionada[]>(
    []
  );
  const [tabelaComissionamentoRender, setTabelaComissionamentoRender] = useState<FaixaComissionada[]>([]);
  const [tabelaHistorico, setTabelaHistorico] = useState<FaixaComissionada[]>([]);
  const [loja, setLoja] = useState<LojaComissionada>({
    codloja: 0,
    codigo: '',
    nome: '',
    sobrenome: ''
  });
  const [categorias, setCategorias] = useState<Array<CategoriaComissionamento>>([]);
  const [faixasInvalidas, setFaixasInvalidas] = useState<Array<string | number>>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const style = ComissionamentoDetailsStyle();
  const classes = useStyles();

  // funções da tela

  // troca de tabs ativas
  // eslint-disable-next-line
  const mudaTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  /**
   *
   * @param itemId
   * @returns boolean
   * retorna true se a faixa a ser enviada tiver campos invalidos ou conflitantes
   */
  const faixaEstaInvalida = (itemId: string | number) => {
    /* const temp: string[] | number[] = faixasInvalidas.indexOf(itemId) > -1;
    // eslint-disable-next-line
    return temp; */
    return faixasInvalidas.some(e => {
      return e === itemId;
    });
  };

  /* alteraProp
  continuidade do evento onChange dos inputs da tela de detalhe */
  /* modifica propriedades da faixa de comissão */
  /* idItem: int | string: id do banco ou id temporario (string hash) para novos registros
      tipo: string ('registro'|'temp')  (registros do banco | registros a serem adicionados, respectivamente)
      prop: string: a chave da propriedade a ser alterada
      valor: any: o valor da propriedade a ser alterada
  */
  const alteraProp = (idItem: string | number, tipo: string, prop: string, valor: string | number | boolean) => {
    // novos registros
    if (tipo === 'temp') {
      const index = tabelaComissionamentoNovosRegistros.map(e => e.id).indexOf(idItem);
      if (tabelaComissionamentoNovosRegistros[index][prop] !== valor) {
        const tempTabela = [...tabelaComissionamentoNovosRegistros];
        tempTabela[index][prop] = valor;
        tempTabela[index].dirty = true;
        setTabelaComissionamentoNovosRegistros(tempTabela);
      }
    } else if (tipo === 'registro') {
      const index = tabelaComissionamentoAPI.map(e => e.id).indexOf(idItem);
      if (tabelaComissionamentoAPI[index][prop] !== valor) {
        const tempTabela = [...tabelaComissionamentoAPI];
        tempTabela[index][prop] = valor;
        tempTabela[index].dirty = true;
        setTabelaComissionamentoAPI(tempTabela);
      }
    }
  };

  // adiciona novo registro vazio a tabela de novos registros
  const adicionarNovoRegistro = () => {
    const tempTabelaComissionamentoNovosRegistros = [...tabelaComissionamentoNovosRegistros];
    tempTabelaComissionamentoNovosRegistros.push({
      codloja: id,
      codcategoria: 0,
      piso: 0,
      comissionada: 0,
      pago_por: 'EU',
      isNew: 1,
      dirty: false,
      created_at: '',
      created_by: 0,
      deleted_at: '',
      deleted_by: 0,
      criado_por: '',
      deletado_por: '',
      nome_categoria: '',
      id: tempId()
    });
    setTabelaComissionamentoNovosRegistros(tempTabelaComissionamentoNovosRegistros);
  };

  const removeNovoRegistro = (idNovoItem: number | string) => {
    const tempTabelaComissionamentoNovosRegistros = [...tabelaComissionamentoNovosRegistros];
    const index = tempTabelaComissionamentoNovosRegistros.map(e => e.id).indexOf(idNovoItem);
    tempTabelaComissionamentoNovosRegistros.splice(index, 1);
    setTabelaComissionamentoNovosRegistros(tempTabelaComissionamentoNovosRegistros);
  };

  const validacao = () => {
    const camposObrigatorios = ['piso', 'codcategoria', 'comissionada', 'pago_por'];

    /* array apenas com faixas que tiveram modificações */
    const dirtyOnly = tabelaComissionamentoRender.filter(e => e.dirty === true);

    /* validação de campos obrigatorios */
    /* retorna apenas o id da faixa de comissao invalida */
    /* ao final deste loop, 'faixasIncompletas' conterá os ids das faixas incompletas */
    const faixasIncompletas: (string | number)[] = [];
    dirtyOnly.forEach(e => {
      camposObrigatorios.forEach(f => {
        if (!(f in e) && faixasIncompletas.indexOf(e.id) === -1) {
          faixasIncompletas.push(e.id);
        }
      });
    });

    /* valida existencia/preenchimento do campo 'piso' */
    /* ao final deste loop, 'faixasSemCampoPiso' conterá os ids das faixas sem o campo piso */
    /* const faixasSemCampoPiso: (string | number)[] = [];
    tabelaComissionamentoRender.forEach(e => {
      if (e?.piso === '') {
        faixasSemCampoPiso.push(e.id);
      }
    }); */

    /* tempFaixasInvalidas é um array contendo os ids de todas as faixas que apresentaram algum erro */
    const tempFaixasInvalidas: Array<string | number> = [...faixasIncompletas /* , ...faixasSemCampoPiso */].filter(
      (value, index, self) => {
        return self.indexOf(value) === index;
      }
    );

    /* adiciona faixas invalidas, retorna true */
    if (tempFaixasInvalidas.length > 0) {
      setFaixasInvalidas(tempFaixasInvalidas);
      return false;
    }
    /* reseta faixas invalidas, retorna false */
    setFaixasInvalidas([]);
    return true;
  };

  /**
   * verifica se os registros já existentes foram modificados em relação a chamada original da API
   * seta a propriedade 'dirty' para false, caso ambos registros estejam iguais
   */
  const verificaMudancas = () => {
    const campos: string[] = ['piso', 'codcategoria', 'comissionada', 'pago_por', 'inativo'];
    const tempTable: FaixaComissionada[] = [];

    tabelaComissionamentoAPI.forEach(e => {
      if (e.dirty === true) {
        const faixaOriginalAPI = tabelaComissionamentoAPIOriginal.filter(item => item.id === e.id)[0];
        // compara campos
        let hasChanged = false;
        campos.forEach(campo => {
          if (faixaOriginalAPI[campo] !== e[campo]) {
            hasChanged = true;
          }
        });
        e.dirty = hasChanged;
      }
      tempTable.push(e);
    });
    setTabelaComissionamentoAPI(tempTable);
  };

  const handleSalvar = async () => {
    verificaMudancas();

    // retorna true se dados estiverem validos - false, caso contrário
    const resultValidacao = validacao();

    if (resultValidacao === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        html: 'Há campos inválidos na tabela de comissionamento.'
      });
    } else {
      const payload = gerarPayload();

      if (payload?.faixas.length > 0) {
        // eslint-disable-next-line
        console.log(payload);
        await ComissionadaService.salvar(id, payload).then(() => {
          getTabelaComissionamento(id);
          Swal.fire('Tabela de comissionamento atualizada com sucesso.', '', 'success');
        });
      } else {
        // eslint-disable-next-line
        console.log('Payload vazio');
      }
    }
  };

  const gerarPayload = () => {
    const dirtyOnly = tabelaComissionamentoRender.filter(e => e.dirty === true);
    const payload: ComissionadaPayload = { faixas: dirtyOnly };
    return payload;
  };

  // getTabelaComissionamento
  // puxa as faixas ativas por categoria
  const getTabelaComissionamento = (codloja: number) => {
    ComissionadaService.getFaixasAtivasPorLoja(codloja).then(response => {
      if (response.data.length > 0) {
        const tabelaOriginal = response.data;
        const tempTabela = response.data.map((item: FaixaComissionada) => ({ ...item, dirty: false, valid: true }));
        setTabelaComissionamentoAPIOriginal(tabelaOriginal);
        setTabelaComissionamentoAPI(tempTabela);
        setTabelaComissionamentoNovosRegistros([]);
      }
      setLoading(false);
    });
    ComissionadaService.getFaixasInativasPorLoja(codloja).then(response => {
      setTabelaHistorico(response.data);
    });
  };

  useEffect(() => {
    const novaTabela = [...tabelaComissionamentoAPI, ...tabelaComissionamentoNovosRegistros];
    setTabelaComissionamentoRender(novaTabela);
  }, [tabelaComissionamentoAPI, tabelaComissionamentoNovosRegistros]);

  /* seta categoria e a tabela de comissionamento */
  useEffect(() => {
    ComissionadaService.getLoja(id).then(responseLojas => {
      setLoja(responseLojas.data);
    });

    // puxa informação das categorias
    // TODO throw error caso a resposta esteja vazia
    api.get(`categorias`).then(response => {
      const listaCategorias: CategoriaComissionamento[] = response.data.data.sort(
        (a: CategoriaComissionamento, b: CategoriaComissionamento) => {
          const textA = a.nome.toUpperCase();
          const textB = b.nome.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          return 0;
        }
      );
      setCategorias(listaCategorias);
    });

    // puxa as faixas ativas por categoria
    getTabelaComissionamento(id);
  }, [id]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      {loading === false ? (
        <>
          {loja?.nome ? (
            <h1>
              {loja.nome} {loja.sobrenome} ({loja.codigo}) - Tabela de comissionamento
            </h1>
          ) : (
            ''
          )}

          <Paper>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={mudaTab}
                aria-label="Tabela comissionamento"
                className={classes.tab}
              >
                <Tab label="Comissões" {...a11yProps(0)} />
                <Tab label="Histórico de modificações" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Piso</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>% Comissão</TableCell>
                    <TableCell>Pago por</TableCell>
                    <TableCell>Criado por/data</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabelaComissionamentoRender &&
                    tabelaComissionamentoRender.map((comissionamentoItem: FaixaComissionada) => (
                      <TableRow
                        key={comissionamentoItem.id}
                        className={faixaEstaInvalida(comissionamentoItem.id) ? style.faixaInvalida : ''}
                      >
                        <TableCell>
                          <TextField
                            name="piso"
                            label="Piso"
                            placeholder="(ex: R$ 999,99)"
                            variant="outlined"
                            size="small"
                            value={decimalToMoney(comissionamentoItem.piso)}
                            onChange={e =>
                              alteraProp(
                                comissionamentoItem.id,
                                comissionamentoItem?.isNew ? 'temp' : 'registro',
                                'piso',
                                moneyToDecimal(e.target.value)
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Categoria</InputLabel>
                            <Select
                              variant="standard"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined-{}"
                              value={comissionamentoItem.codcategoria}
                              label="Categoria"
                              onChange={(e: SelectChangeEvent<number | null>) => {
                                alteraProp(
                                  comissionamentoItem.id,
                                  comissionamentoItem?.isNew ? 'temp' : 'registro',
                                  'codcategoria',
                                  e.target.value as number
                                );
                              }}
                            >
                              <MenuItem key={0} value={0}>
                                Padrão
                              </MenuItem>
                              {categorias.map((itemCategoria: CategoriaComissionamento) => (
                                <MenuItem key={itemCategoria.codcategoria} value={itemCategoria.codcategoria}>
                                  {itemCategoria.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="Comissionada"
                            label="Comissionada"
                            placeholder="% Comissionada (ex: 10%)"
                            variant="outlined"
                            size="small"
                            value={decimalToPercentage(comissionamentoItem.comissionada)}
                            onChange={e =>
                              alteraProp(
                                comissionamentoItem.id,
                                comissionamentoItem?.isNew ? 'temp' : 'registro',
                                'comissionada',
                                percentageToDecimal(e.target.value)
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-outlined-label">Pago por</InputLabel>
                            <Select
                              variant="standard"
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined-{}"
                              value={comissionamentoItem.pago_por}
                              label="Pago por"
                              onChange={(e: SelectChangeEvent<string | null>) => {
                                alteraProp(
                                  comissionamentoItem.id,
                                  comissionamentoItem?.isNew ? 'temp' : 'registro',
                                  'pago_por',
                                  e.target.value as string
                                );
                              }}
                            >
                              <MenuItem key={0} value="EU">
                                EÚ
                              </MenuItem>
                              <MenuItem key={1} value="vendedora">
                                Vendedora
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          {comissionamentoItem?.isNew === 1 ? (
                            '-'
                          ) : (
                            <>
                              {comissionamentoItem.criado_por}
                              <br />
                              {format(new Date(comissionamentoItem.created_at), 'dd/MM/yyyy H:mm')}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {comissionamentoItem?.isNew === 1 ? (
                            <IconButton
                              onClick={() => removeNovoRegistro(comissionamentoItem.id)}
                              aria-label="delete"
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <Switch
                              checked={!comissionamentoItem.inativo}
                              onChange={e => {
                                /* converte status */
                                const status = e.target.checked ? 1 : 0;

                                /* manda status invertido */
                                alteraProp(comissionamentoItem.id, 'registro', 'inativo', status === 0 ? 1 : 0);
                              }}
                              color="primary"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={adicionarNovoRegistro}
                        endIcon={<AddIcon />}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSalvar}
                        endIcon={<SaveIcon />}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Piso</TableCell>
                    <TableCell>Categoria</TableCell>
                    <TableCell>% Comissão</TableCell>
                    <TableCell>Pago por</TableCell>
                    <TableCell>Criado por/data</TableCell>
                    <TableCell>Desativado em/por</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabelaHistorico &&
                    tabelaHistorico.map(historicoItem => (
                      <TableRow key={historicoItem.id}>
                        <TableCell>{decimalToMoney(historicoItem.piso)}</TableCell>
                        <TableCell>{historicoItem.nome_categoria}</TableCell>
                        <TableCell>{decimalToPercentage(historicoItem.comissionada)}</TableCell>
                        <TableCell style={{ textTransform: 'capitalize' }}>{historicoItem.pago_por}</TableCell>
                        <TableCell>
                          {historicoItem.criado_por}
                          <br /> {format(new Date(historicoItem.created_at), 'dd/MM/yyyy H:mm')}
                        </TableCell>
                        <TableCell>
                          {historicoItem.deletado_por}
                          <br /> {format(new Date(historicoItem.deleted_at), 'dd/MM/yyyy H:mm')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TabPanel>
          </Paper>
        </>
      ) : (
        <Box>Carregando...</Box>
      )}
    </TemplateBase>
  );
}

export default ComissionadasDetailsPage;
