import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import PickingService from 'services/picking';
import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';
import Swal from 'sweetalert2';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { ApiListMeta } from 'interfaces';
import Paper from '@mui/material/Paper';
import { PickingLayout } from '../components/picking/picking-layout';
import BarCodeStyle from '../components/picking/barcode-picking/styles';
import useFiltrosOrigem from '../components/picking/filter-select-picking/useFiltrosOrigem';

function PickingDoacao() {
  document.title = 'Expedição | Picking de doação';
  const [loadingFull, setLoadingFull] = useState<boolean>(false);
  const [filaPicking, setFilaPicking] = useState<any[]>([]); // eslint-disable-line
  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState<number>(1);
  const main = document.getElementsByTagName('main');
  const barCodeStyle = BarCodeStyle();

  const barCodeRef = useRef<{
    barCodeFocus: () => void;
    barCodeClear: () => void;
  }>(null);

  // Cria um fitro
  const filter = useMemo(() => new Criteria(), []);

  filter.add('status', '20');
  filter.add('tipo_pedido', '1');

  const receiveChildLoadingCall = (isLoading: boolean): void => {
    setLoading(isLoading);
    setLoadingFull(isLoading);
  };

  const loadListaPicking = useCallback(() => {
    setLoading(true);
    filter.add('page', `${page}`);

    PickingService.getFilaPicking(filter.getQuery())
      .then(response => {
      const filaPickingResponse = response.data.data.filter((obj: any) => !obj.pedidoproduto.lacre && obj.pular === 0); // eslint-disable-line

        setFilaPicking(filaPickingResponse.length > 0 ? filaPickingResponse : []);
        setLoading(false);
        setLoadingFull(false);
        setMeta(response.data.meta);
        barCodeRef?.current?.barCodeFocus();
      })
      .finally(() => {
        setLoading(false);
        setLoadingFull(false);
      });
  }, [filter, page]);

  const handleSelectFilter = (value: string) => {
    if (value !== '0') {
      filter.add('origem', value);
    } else {
      filter.remove('origem');
    }

    barCodeRef?.current?.barCodeFocus();
    loadListaPicking();
  };

  const handleSelectClose = () => {
    barCodeRef?.current?.barCodeFocus();
  };

  const handleInputKeyPress = (codBarras: string) => {
    setLoadingFull(true);

    PickingService.separarProduto(codBarras, filter.getQuery())
      .then(response => {
        if ('error' in response.data) {
          if (response.data.error === 0) {
            Swal.fire('Produto separado!', '', 'success');
          }
        }

        loadListaPicking();
        setLoadingFull(false);
      })
      .catch(() => {
        loadListaPicking();
        setLoadingFull(false);
      })
      .finally(() => {
        barCodeRef?.current?.barCodeFocus();
        barCodeRef?.current?.barCodeClear();
      });
  };

  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setLoadingFull(true);
    main[0]?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    loadListaPicking();
  }, [loadListaPicking]);

  return (
    <TemplateBase>
      <h1>Fila de Picking de Doação</h1>

      <PickingLayout>
        <Box height="100vh">
          <PickingLayout.FilterSelectPicking
            getFiltrosHook={useFiltrosOrigem}
            onSelectChange={handleSelectFilter}
            onSelectClose={handleSelectClose}
          />
          <PickingLayout.TabPanelPicking loading={loading} tabValue={0}>
            <div>
              {filaPicking.length > 0 &&
                filaPicking.map(item => (
                  <PickingLayout.CardProductPicking
                    key={item?.pedidoproduto.codpedidoproduto}
                    item={item?.pedidoproduto}
                    loadListaPicking={loadListaPicking}
                    setParentLoading={receiveChildLoadingCall}
                    btNaoEncontrado
                    data_aprovacao={item.data_aprovacao}
                    atraso={item.atraso_picking === 1}
                    acervo={item.acervo}
                    cliente_vip={item?.cliente?.prioridade}
                    devolucao
                  />
                ))}
            </div>
          </PickingLayout.TabPanelPicking>
          {!loading && filaPicking.length > 0 && (
            <Paper elevation={5} className={barCodeStyle.campoCodBarras}>
              {!loading && meta && meta?.last_page > 1 && (
                <Box display="flex" justifyContent="center" py={2} mt="-8px">
                  <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
                </Box>
              )}
              <PickingLayout.BarCodePicking handleInputKeyPress={handleInputKeyPress} ref={barCodeRef} />
            </Paper>
          )}
        </Box>
      </PickingLayout>
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default PickingDoacao;
