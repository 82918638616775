import React, { useState, lazy, Suspense } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import Collapse from '@mui/material/Collapse';
import AvatarProduto from 'components/avaliacao-fisica/avatar-produto';
import { QueroVenderItem } from 'interfaces';
import Alert from '@mui/material/Alert';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { useStatusInterface } from '../hooks/status-interface';

import avaliacaoFisicaNegociacaoStyle from './style';

const FormAvaliacaoFisica = lazy(() => import(/* webpackChunkName: "formAvaliacao" */ './form-avaliacao-fisica'));

type Props = {
  comissionada: boolean;
  codquerovender: number;
  codloja: number | undefined;
  codquerovenderitem: number;
  doRefresh: () => void;
  nomeproduto: string | undefined;
  imagem: string | undefined;
  status: number | undefined;
};

function CardDetails(props: Props) {
  const styles = avaliacaoFisicaNegociacaoStyle();

  const [item, setItem] = useState<QueroVenderItem | undefined>();
  const { comissionada, codquerovender, codloja, codquerovenderitem, doRefresh, nomeproduto, imagem, status } = props;

  const [expanded, setExpanded] = useState(false);

  const { statusText, statusColor } = useStatusInterface(status as number);

  const aprovacaoAutomatica = item?.aprovacao_automatica_data;

  const fetchItem = () => {
    return QueroVenderItemService.details(codquerovenderitem.toString()).then(response => {
      setItem(response.data.data);
    });
  };

  /**
   * Handler do click no botão de expandir o card
   */
  const handleExpandClick = () => {
    if (!item) {
      fetchItem().then(() => {
        setExpanded(!expanded);
      });
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <Card className={styles.root}>
      <CardHeader
        className={styles.cardHeader}
        avatar={<AvatarProduto titulo={nomeproduto} imagem={imagem} text={statusText} color={statusColor} />}
        action={
          <IconButton onClick={handleExpandClick} size="large">
            {expanded ? <Remove /> : <Add />}
          </IconButton>
        }
      />

      <form>
        <Collapse in={expanded}>
          {expanded && (
            <Suspense fallback={<>carregando formulário...</>}>
              <>
                {aprovacaoAutomatica && (
                  <Box p={2}>
                    <Alert variant="filled" severity="warning">
                      Produto aprovado automaticamente
                    </Alert>
                  </Box>
                )}
                <FormAvaliacaoFisica
                  codQueroVender={codquerovender}
                  temComissionada={comissionada}
                  isProduto
                  codloja={codloja}
                  item={item}
                  doRefresh={doRefresh}
                  readOnly
                />
              </>
            </Suspense>
          )}
        </Collapse>
      </form>
    </Card>
  );
}

export default CardDetails;
