export class Filter {
  query = '';

  variable = '';

  constructor(
    private _variable: string,
    private value: string
  ) {
    this.variable = _variable;
    this.query = `${_variable}=${value}`;
  }
}
