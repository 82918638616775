import { VendasPorVendedora } from 'interfaces';
import api from '../api';

const list = (queryMeta?: string) => {
  const query = queryMeta !== undefined ? `&${queryMeta}` : '';

  return api.get(`relatorios/vendas-vendedora/loja?${query}`);
};

const fetch = (data: VendasPorVendedora) => {
  return api.post(`relatorios/vendas-vendedora`, data, {
    responseType: 'arraybuffer'
  });
};

export default {
  list,
  fetch
};
