import makeStyles from '@mui/styles/makeStyles';
import { grey, common } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const EtiquetasDevolucaoStyle = makeStyles(() => ({
  vendedorTitle: {
    marginBottom: theme.spacing(4)
  },
  paperBusca: {
    marginBottom: theme.spacing(4)
  },
  iconFluxoAntigo: {
    color: theme.palette.secondary.dark
  },
  submitButton: {
    backgroundColor: theme.palette.info.main,
    color: 'white'
  },
  paperListagem: {
    marginBottom: theme.spacing(2)
  },
  textoObservacoes: {
    whiteSpace: 'pre-line'
  },
  btnGerarDevolucao: {
    backgroundColor: grey[400],
    color: common.black,

    '&:hover': {
      backgroundColor: grey[500]
    }
  },
  cancelarButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  resgatarEtiquetaBox: {
    marginBottom: theme.spacing(8)
  },
  cardCancelado: {
    backgroundColor: grey[300]
  },
  cardDevolvido: {
    backgroundColor: theme.palette.success.light
  }
}));

export default EtiquetasDevolucaoStyle;
