import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const CardProductPickingStyle = makeStyles(() => ({
  wrapperStatus: {
    fontSize: 9,
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    padding: 7,
    lineHeight: 1.3,

    [theme.breakpoints.up('sm')]: {
      fontSize: 13
    }
  },

  iconUnique: {
    color: '#64B5F6',
    backgroundColor: 'white'
  },

  textAtraso: {
    color: '#E33371'
  },
  textPrioridade: {
    color: '#FFB74D'
  },
  textStatusDisabled: {
    color: '#E6E6E6'
  },
  wrapperInfos: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  textInfos: {
    lineHeight: 1.2,
    fontSize: 10,
    marginBottom: 5,

    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textCodpedido: {
    fontSize: 10,
    marginBottom: 5,
    color: '#B9B9B9',

    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textInfosTitle: {
    fontSize: 10,
    lineHeight: 1.2,
    marginBottom: 10,
    marginTop: 5,

    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textItensInclusos: {
    fontSize: 10,
    marginRight: 10,

    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },

  card: {
    padding: 0,
    marginTop: 20
  },
  cardContent: {
    position: 'relative',
    padding: 16
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14)
    }
  }
}));

export default CardProductPickingStyle;
