import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';

import TemplateBase from 'components/template-base/template';

import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { ApiListMeta, QueroVenderBatch, LockedBatch } from 'interfaces';
import { usePusher } from 'context/pusher';
import useDebounce from 'hooks/debounce';

import BatchRow from './batchRow';

import preAvaliacaoStyle from './style';

function AvaliacaoNegociacao() {
  const classes = preAvaliacaoStyle();

  const [batches, setBatch] = useState<Array<QueroVenderBatch>>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [buscaNome, setBuscaNome] = useState('');
  const [isPagination, setIsPagination] = useState(false);
  const [lockedBatch, setLockedBatch] = useState<LockedBatch[]>([]);
  const { channel } = usePusher();
  const nomeCliente = useDebounce(buscaNome, 500);
  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  document.title = `Pré-Avaliação`;

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Pega os dados da API e seta no state itens
   */
  const retrieve = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}`;

    if (!isPagination) setPage(1);

    setLoading(true);
    QueroVenderService.list('18', queryMeta).then(response => {
      if (response) {
        setBatch(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setBatch([]);
      }
      setLoading(false);
    });
  }, [nomeCliente, page]); //eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    retrieve();
  }, [retrieve]);

  /**
   * Carrega lista de batchs e ativa o websocket
   */
  useEffect(() => {
    channel.bind(
      'lock-change',
      (params: { codquerovender: number; locked: boolean; lockInfo: { nome: string; email: string } }) => {
        setLockedBatch(prevValue => {
          if (params.locked) {
            return [
              ...prevValue,
              { codquerovender: params.codquerovender, nome: params.lockInfo.nome, email: params.lockInfo.email }
            ];
          }
          return prevValue.filter(value => value.codquerovender !== params.codquerovender);
        });
      }
    );

    return () => {
      channel.unbind('lock-change');
    };
  }, [channel, retrieve, batches]);

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Check se o batch acesado esta nessa paginação
   */

  const isBatchLocked = (codquerovender: number) => {
    return lockedBatch?.filter(value => value.codquerovender === codquerovender);
  };

  useEffect(() => {
    const lockedBatches = batches
      .filter(batch => batch.locked)
      .map(batch => ({
        nome: batch.lock_info?.nome,
        email: batch.lock_info?.email,
        codquerovender: batch.codquerovender
      }));

    setLockedBatch(lockedBatches);
  }, [batches]);

  return (
    <TemplateBase>
      <h1>{`Negociação de pré-avaliação${renderCount}`}</h1>

      <Paper elevation={1} className={classes.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <h3>Localizar remessa</h3>
                <TextField
                  name="nomeremessa"
                  label="Nome ou código do cliente"
                  placeholder="Nome ou código do cliente"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      {loading && <Box>Carregando ...</Box>}

      {!loading && batches.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Produtos</TableCell>
                  <TableCell>Cadastrado em</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {batches &&
                  batches.map((batch: QueroVenderBatch) => (
                    <BatchRow
                      key={batch.codquerovender}
                      batch={batch}
                      retrieve={retrieve}
                      locked={isBatchLocked(batch.codquerovender)}
                    />
                  ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
      {!loading && batches.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma Remessa encontrada
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default AvaliacaoNegociacao;
