import React from 'react';
import { Paper } from '@mui/material';
import style from './style';

interface Props {
  codquerovenderfoto: number;
  imagem: string;
  url: string;
  onClick?(): void;
}

function Fotos(props: Props) {
  const { codquerovenderfoto, imagem, url, onClick = undefined } = props;
  const classes = style({ imagem });

  return (
    <div className={classes.root}>
      <Paper key={codquerovenderfoto}>
        <button type="button" onClick={onClick} className={classes.button}>
          <img src={url} alt="Ver Foto" />
        </button>
      </Paper>
    </div>
  );
}

export default Fotos;
