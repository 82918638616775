import api from '../api';

const calcularPreco = (codcategoria: number, preco: string | number, codloja?: number) => {
  const codlojaQuery = codloja !== undefined && codloja !== 0 && codloja ? `&codloja=${codloja}` : '';
  return api.get(`calcular-preco?codcategoria=${codcategoria}&preco=${preco}${codlojaQuery}`);
};

const calcularPrecoFaixaAtual = (codquerovenderitem: number, valor: number) => {
  return api.post(`calcular-preco-faixa-atual/${codquerovenderitem}`, { preco: valor });
};

const calcularPrecoAPrazo = (preco_a_vista: number) => {
  return api.get(`produtos/calcular-preco/manual?a_vista=${preco_a_vista}`);
};

export default { calcularPreco, calcularPrecoFaixaAtual, calcularPrecoAPrazo };
