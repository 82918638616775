import Swal from 'sweetalert2';

interface errorObject {
  [key: string]: any; // eslint-disable-line
}

const alertValidationMessage = (errors: errorObject) => {
  const mapErrors = new Map(Object.entries(errors));
  const firstField = mapErrors.get(Object.keys(errors)[0])?.message;

  Swal.fire({
    icon: 'warning',
    title: 'Oops...',
    text: firstField
  });
};

export default alertValidationMessage;
