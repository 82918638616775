import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Grid, Button, Paper } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import TemplateBase from 'components/template-base/template';
import Loading from 'components/loading';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert2';
import { QueroVenderBatch, QueroVenderItem } from 'interfaces';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withClusters, WithClustersProps } from 'components/with-clusters';
import { BadgeClusters } from 'components/custom-badge/badge-clusters';
import ModalAssociarComissionada from 'components/avaliacao-fisica/modal-associar-comissionada';
import AlertMerge from 'components/alert-merge';
import { formatTextPhone } from 'helpers/format-text-phone';
import { FiltrosProvider } from 'context/filtros';
import FormAvaliacaoFisica from './form-avaliacao';
import CadastroProdutosRejeitados from './cadastro-produtos-rejeitados';
import ItemBatchText from '../components/itemBatchText';

import style from './styles';
import { BatchesProvider, useBatchesProvider } from './BatchesProvider';

const PageContent = withClusters((props: WithClustersProps) => {
  document.title = 'Avaliação Física de Produtos';

  const { fetchClustersVendedora, fetchClustersComissionada, clustersVendedora, clustersComissionada } = props;

  const classes = style();
  const navigate = useNavigate();

  const { codquerovenderitem } = useParams() as { codquerovenderitem: string };

  const { batch, fetchBatches, loading, contagemProdutos, setLoading } = useBatchesProvider();

  const [loadingFull, setLoadingFull] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [modalAssociarComissionada, setModalAssociarComissionada] = useState<boolean>(false);
  const [modalCadastroProdutosRejeitados, setModalCadastroProdutosRejeitados] = useState<boolean>(false);
  const [batchComissionamentosDiferenciados, setBatchComissionametosDiferenciados] = useState<boolean>(false);
  const [batchProdutosSemBonus, setBatchProdutosSemBonus] = useState<boolean>(false);
  /**
   * Abre modal para associar codigo vendedora/captadora
   */

  const openMatchSellerModal = () => {
    setModalAssociarComissionada(true);
  };

  /**
   * Fecha modal para associar codigo vendedora/captadora
   */

  const closeMatchSellerModal = () => {
    setModalAssociarComissionada(false);
  };

  /**
   * Busca produtos da batch
   */
  useEffect(() => {
    callFetchBatches(codquerovenderitem);
    /* eslint-disable-next-line */
  }, [codquerovenderitem, refresh]);

  const callFetchBatches = (param: string) => {
    fetchBatches(param).then(({ data }: { data: QueroVenderBatch }) => {
      setLoading(false);

      if (data.cliente) fetchClustersVendedora(data.cliente.codcliente);
      if (data.comissionada) fetchClustersComissionada(data.comissionada.codcliente);
    });
  };

  /**
   * Dispara evento para bloquear/desbloquear edição de uma Batch
   */
  useEffect(() => {
    QueroVenderService.lock(codquerovenderitem);

    return function cleanup() {
      QueroVenderService.unlock(codquerovenderitem);
    };
  }, [codquerovenderitem]);

  const handleFinalizarRemessa = async () => {
    try {
      setLoadingFull(true);

      await QueroVenderService.finish(codquerovenderitem).then(() => {
        Swal.fire('A remessa foi finalizada com sucesso', '', 'success').then(() => {
          navigate('/avaliacao/avaliacao-fisica-batch');
        });
      });
    } catch (error) {
      if (error.errors) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    } finally {
      setLoadingFull(false);
    }
  };

  const handleAddProductCard = () => {
    setAddProduct(true);
  };

  const handleCloseProductCard = () => {
    setAddProduct(false);
  };

  /**
   * Chama o refresh da página
   */

  const doRefresh = useCallback(() => {
    setRefresh(refresh + 1);
  }, [refresh]);

  const handleModalCadastroProdutosRejeitados = () => {
    setModalCadastroProdutosRejeitados(true);
  };

  const handleCloseProdutosRejeitados = () => {
    setModalCadastroProdutosRejeitados(false);
  };

  const handleCallbackMatchSeller = async () => {
    setModalAssociarComissionada(false);
    await fetchBatches(codquerovenderitem);
  };

  const refreshOnAddProduct = () => {
    doRefresh();
    setAddProduct(false);

    // Eu sei, eu sei
    setTimeout(() => {
      setAddProduct(true);
    }, 20);
  };

  const handleSplitRemessa = () => {
    navigate(`/entrada-manual/${batch.codquerovender}`);
  };

  /**
   * Template
   */

  return (
    <>
      <Loading open={loadingFull} />
      <TemplateBase>
        {loading && <div>Carregando...</div>}
        {!loading && modalAssociarComissionada && (
          <ModalAssociarComissionada
            afterMatchSeller={handleCallbackMatchSeller}
            onCancel={closeMatchSellerModal}
            batch={batch}
          />
        )}

        {!loading && !modalAssociarComissionada && (
          <>
            <CadastroProdutosRejeitados
              codquerovender={batch?.codquerovender}
              open={modalCadastroProdutosRejeitados}
              onClose={handleCloseProdutosRejeitados}
              doRefresh={doRefresh}
            />
            <div className={classes.space}>
              {batch.relacionados.length > 0 && (
                <AlertMerge
                  batch={batch}
                  loading={setLoadingFull}
                  exibirQuantidade="aceitos"
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              )}
              <h1>Avaliação Física</h1>
              <h2>{`Produtos #${codquerovenderitem}`}</h2>
              <h2>{`${batch?.cliente?.nome} - ${batch?.cliente?.codcliente}`}</h2>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div>
                    <strong>Email: </strong>
                    {batch?.cliente?.email}
                  </div>
                  <div>
                    <strong>Telefone: </strong>
                    {formatTextPhone(batch?.cliente?.telcelular)}
                  </div>
                  <div>
                    <strong>Código do vendedor: </strong>
                    {batch?.vendedora?.codigo}
                  </div>
                  <div>
                    <strong>Código do captador: </strong>
                    {batch?.comissionada?.codigo}
                  </div>
                  <div>
                    <strong>Total número de produtos: </strong>
                    {batch?.itens?.length}
                  </div>
                  <div>
                    <strong>Produtos aceitos: </strong>
                    {contagemProdutos.aceitos}
                  </div>
                  <div>
                    <strong>Produtos reprovados: </strong>
                    {contagemProdutos.reprovados}
                  </div>
                  <div>
                    <strong>Produtos não recebidos: </strong>
                    {contagemProdutos.naoRecebidos}
                  </div>

                  <Box display="inline-flex" flexWrap="wrap">
                    {clustersVendedora.length > 0 &&
                      clustersVendedora.map(cluster => <BadgeClusters key={cluster.id} cluster={cluster} />)}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  {/* <div>
                        <strong>Nome captador:</strong>
                      </div> */}
                  {batch?.comissionada?.codcliente ? (
                    <div>
                      <strong>Código do captador:</strong> {batch?.comissionada?.codigo}
                      <button type="button" className={classes.btEditar} onClick={openMatchSellerModal}>
                        editar
                      </button>
                    </div>
                  ) : (
                    <div>
                      <Button color="secondary" variant="contained" onClick={openMatchSellerModal}>
                        adicionar captador
                      </Button>
                    </div>
                  )}

                  {clustersComissionada.length > 0 &&
                    clustersComissionada.map(cluster => <BadgeClusters cluster={cluster} />)}
                </Grid>
              </Grid>
            </div>
            <Paper elevation={1}>
              <Box p={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={batchComissionamentosDiferenciados}
                      onChange={() => setBatchComissionametosDiferenciados(!batchComissionamentosDiferenciados)}
                      color="primary"
                    />
                  }
                  label={`Comissionamentos Diferenciados: ${
                    batchComissionamentosDiferenciados ? 'Ativado' : 'Desativado'
                  }`}
                />
              </Box>
              <Box p={2} bgcolor="#f1f1f1">
                <FormControlLabel
                  control={
                    <Switch
                      checked={batchProdutosSemBonus}
                      onChange={() => setBatchProdutosSemBonus(!batchProdutosSemBonus)}
                      color="secondary"
                      disabled
                    />
                  }
                  label={`Produtos sem bônus: ${batchProdutosSemBonus ? 'Ativado' : 'Desativado'}`}
                />
              </Box>
            </Paper>
            <Box mt={4}>
              <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    className={classes.btnFinalizarRemessa}
                    onClick={handleSplitRemessa}
                  >
                    Dividir Remessa
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    className={classes.btnFinalizarRemessa}
                    onClick={handleFinalizarRemessa}
                  >
                    Finalizar Remessa
                  </Button>
                </Grid>
              </Grid>
            </Box>
            {batch?.itens
              .filter((item: QueroVenderItem) => item.aceito)
              .map((item: QueroVenderItem, index: number) => {
                return (
                  <ItemBatchText
                    key={item.codquerovenderitem}
                    item={item}
                    index={index + 1}
                    doRefresh={doRefresh}
                    temComissionada={Boolean(batch?.comissionada)}
                    codloja={batch?.vendedora?.codloja}
                    batchComissionamentosDiferenciados={batchComissionamentosDiferenciados}
                    batchProdutosSemBonus={batchProdutosSemBonus}
                    codQueroVender={batch?.codquerovender}
                  />
                );
              })}
            {addProduct && (
              <Box mb={2}>
                <Card>
                  <FiltrosProvider>
                    <FormAvaliacaoFisica
                      isExpanded
                      closeButton
                      doRefresh={refreshOnAddProduct}
                      codQueroVender={batch?.codquerovender}
                      temComissionada={Boolean(batch?.comissionada)}
                      onClickCloseButton={handleCloseProductCard}
                      codloja={batch?.vendedora?.codloja}
                      batchComissionamentosDiferenciados={batchComissionamentosDiferenciados}
                      batchProdutosSemBonus={batchProdutosSemBonus}
                    />
                  </FiltrosProvider>
                </Card>
              </Box>
            )}

            {!addProduct && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleAddProductCard}
                size="large"
                className={classes.btnAdicionarProduto}
                endIcon={<AddIcon />}
              >
                Adicionar produto
              </Button>
            )}

            <Button
              className={classes.btnAdicionarRejeitados}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              endIcon={<InputIcon />}
              onClick={handleModalCadastroProdutosRejeitados}
            >
              Cadastrar Produtos Rejeitados
            </Button>
          </>
        )}
      </TemplateBase>
    </>
  );
});

function AvaliacaoFisicaBatchDetails() {
  return (
    <BatchesProvider>
      <PageContent />
    </BatchesProvider>
  );
}

export default AvaliacaoFisicaBatchDetails;
