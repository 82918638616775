import React from 'react';
import Button from '@mui/material/Button';

import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useFormContext } from 'react-hook-form';
import { useLoading } from 'context/loading';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
};

export function BotaoPausarNegociacao(props: Props) {
  const styles = avaliacaoFisicaNegociacaoStyle();

  const { handleSubmit } = useFormContext();

  const { codquerovenderitem, doRefresh, disabled = false } = props;

  const { setLoading } = useLoading();

  const handleOperation = () => {
    const body = {
      acao: 'contato'
    };

    if (codquerovenderitem) {
      setLoading(true);

      QueroVenderItemService.aprovacao_fisica(codquerovenderitem, body)
        .then(() => {
          Swal.fire('Operação realizada com sucesso', 'Negociação pausada', 'success');
          doRefresh();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('codquerovenderitem does not exist');
    }
  };

  return (
    <Button
      color="secondary"
      variant="contained"
      disabled={disabled}
      className={styles.button}
      onClick={handleSubmit(handleOperation)}
    >
      pausar negociação
    </Button>
  );
}
