import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { green, grey } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

export default makeStyles(() =>
  createStyles({
    root: {
      marginBottom: '20px'
    },
    cardContent: {
      paddingBottom: 0
    },
    autoChip: {
      '&root': {
        border: '1px solid'
      }
    },
    avatar: {
      width: 100,
      height: 100
    },
    obs: {
      marginTop: '15px',
      marginBottom: '10px'
    },
    mb: {
      marginBottom: '10px'
    },
    aceito: {
      border: '2px solid green'
    },
    em_avaliacao: {
      border: '2px solid red'
    },
    recusado: {
      border: `2px solid ${grey[800]}`
    },
    nao_recebido: {
      border: `2px solid ${grey[400]}`
    },
    novaProposta: {
      backgroundColor: theme.palette.warning.main
    },
    cardHeader: {
      '& .MuiCardHeader-action': {
        marginTop: 23
      }
    },
    actions: {
      textAlign: 'right',
      backgroundColor: '#eee',
      justifyContent: 'flex-end'
    },
    form: {
      marginTop: theme.spacing(5)
    },
    formSpace: {
      marginTop: theme.spacing(2)
    },
    formValor: {
      marginTop: theme.spacing(2)
    },
    wrapFotos: {
      display: 'flex'
    },
    textArea: {
      marginTop: 3,
      '& .MuiOutlinedInput-inputMultiline': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      }
    },
    btnOperation: {
      padding: '2px 4px',
      fontSize: 12
    },
    checkBox: {
      marginLeft: 0,
      '& .MuiFormControlLabel-root': {
        marginLeft: 0
      },
      '& .MuiCheckbox-root, & .MuiRadio-root': {
        padding: 2
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 12
      }
    },
    textInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      },

      '& .MuiInputLabel-root': {
        fontSize: 13
      }
    },
    titles: {
      fontSize: '0.65rem'
    },
    valorPreAvaliacao: {
      marginTop: 15
    },
    formWrapper: {
      marginTop: 10
    },
    btnSalvar: {
      color: green[500]
    },
    contentWrap: {
      marginTop: 3
    },
    btnsLeft: {
      textAlign: 'left'
    },
    compradoEu: {
      marginTop: 5
    },
    closeButton: {
      textAlign: 'right'
    },
    infoProduto: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3)
    }
  })
);
