import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const BatchRowStyles = makeStyles(() => ({
  spaceButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  chk: {
    padding: 0
  },
  locked: {
    opacity: 0.5
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed'
  }
}));

export default BatchRowStyles;
