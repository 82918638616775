import React, { useEffect, useState, lazy, Suspense, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Add, Remove } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CampoCategorias, QueroVenderItem } from 'interfaces';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { useAvaliacao } from 'pages/avaliacao/context/pre-avaliacao';
import FormInfoProduto from 'pages/avaliacao/components/FormInfoProduto';
import FormItemNegociacao from 'pages/avaliacao/pre-avaliacao-negociacao/FormItemNegociacao';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GaleriaFotos from '../GaleriaFotos';
import InformacoesBasicasProduto from '../InformacoesBasicasProduto';

import style from './style';

const FormItemPreAvaliacao = lazy(
  () => import(/* webpackChunkName: "FormItemPreAvaliacao" */ '../../pre-avaliacao/FormItemPreAvaliacao')
);

interface Props {
  item: QueroVenderItem;
  index: number;
  doRefresh(): void;
  status: string;
  step: string;
  comissionamentoDiferenciado?: boolean;
}

function Item(props: Props) {
  const classes = style();
  const { item, index, doRefresh, status, step, comissionamentoDiferenciado = false } = props;
  const [expanded, setExpanded] = useState(false);
  const { setEditingMode } = useAvaliacao();
  const [editing] = useState(false);
  const [openMaisInformacoes, setOpenMaisInformacoes] = useState(false);
  const [campoCategoria, setCampoCategoria] = useState<CampoCategorias | null>(null);

  const isPreAvaliacao = step === 'preAvaliacao';
  const isPreAvaliacaoNegociacao = step === 'preAvaliacaoNegociacao';
  const labelSubmitButton = isPreAvaliacao ? 'Salvar sugestão' : 'Atualizar';
  const isShowItem = expanded || item.aceito === null;

  /**
   * Define valores default do FORM
   */
  const initialStateCommonFields = {
    comprado_eu: Boolean(item.comprado_eu),
    link_referencia: item.link_referencia,
    aceito: '',
    modelo_exato: item.modelo_exato,
    material: item.material,
    tamanho: item.tamanho,
    cor: item.cor,
    codigo: item.codigo,
    ano: item.ano,
    observacoes: item.negociacao_atual?.observacoes || null,
    flag: !!item.flag,
    observacoes_internas: item.observacoes_internas
  };

  const valorLoja = useMemo(() => {
    return item?.valor_loja ? `${item?.valor_loja}` : '';
  }, [item]);

  const initialStateFieldsPreAvaliacaoNegociacao = { ...initialStateCommonFields };
  const initialStateFieldsPreAvaliacao = {
    ...initialStateCommonFields,
    observacoes: item.negociacao_atual?.observacoes || null
  };

  /**
   * Resgata dados do form e dispara mensagem
   */
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: isPreAvaliacao ? initialStateFieldsPreAvaliacao : initialStateFieldsPreAvaliacaoNegociacao
  });

  /**
   * watch campo valor caso o radio "Aceitar" estiver selecionado
   */
  const watchCampoAceito = watch('aceito');

  useEffect(() => {
    if (isPreAvaliacaoNegociacao) return;

    setEditingMode({
      editing,
      id: item.codquerovenderitem
    });
    // eslint-disable-next-line
  }, [editing]);

  /**
   * Monta o schema de validação (Yup)
   */

  const handleSchemaCondicaoPreAvaliacao = () => {
    const defaultSchema = {
      precoavista: Yup.string().when('aceito', {
        is: '1',
        then: schema => schema.required('O campo Valor final à vista é obrigatório')
      }),
      precoaprazo: Yup.string().when('aceito', {
        is: '1',
        then: schema => schema.required('O campo Valor Parcelado é obrigatório')
      }),
      preco: Yup.string().when('aceito', {
        is: '1',
        then: schema => schema.required('O campo Valor Vendedor é obrigatório')
      }),
      observacoes: Yup.string().notRequired(),
      motivo: Yup.string().notRequired(),
      ano: Yup.string()
        .test('test-ano-type', 'O campo ano é inválido', function ValidaAno(value) {
          if (value && !value.match(/\b(19|20)[0-9]{2}\b/)) {
            return false;
          }

          return true;
        })
        .notRequired()
    };

    if (typeof watchCampoAceito === 'string' && watchCampoAceito === '0') {
      defaultSchema.motivo = Yup.string().required('Selecione ou escreva um motivo');
    }

    if (typeof watchCampoAceito === 'string' && watchCampoAceito === '2') {
      defaultSchema.observacoes = Yup.string().required('O campo Observações para o vendedor é obrigatório');
    }

    return defaultSchema;
  };

  const handleSchemaCondicaoPreAvaliacaoNegociacao = () => {
    const defaultSchema = {
      ano: Yup.string()
        .test('test-ano-type', 'O campo ano é inválido', function ValidaAno(value) {
          if (value && !value.match(/\b(19|20)[0-9]{2}\b/)) {
            return false;
          }

          return true;
        })
        .notRequired()
    };

    return defaultSchema;
  };

  // eslint-disable-next-line prettier/prettier

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    const { preco, precoaprazo, precoavista, link_referencia, ano, valor_loja, ...rest } = data;
    const sendData = rest;

    if (valor_loja && valor_loja.length > 0 && valor_loja !== '0') {
      sendData.valor_loja = valor_loja;
    }

    if (link_referencia && link_referencia.length > 0) {
      sendData.link_referencia = link_referencia;
    }

    if (ano && ano.length > 0) {
      sendData.ano = ano;
    }

    if (isPreAvaliacao) {
      if (precoavista && precoavista.length > 0) {
        sendData.precoavista = precoavista;
      }

      if (preco) {
        sendData.preco = preco;
      }

      if (precoaprazo) {
        sendData.precoaprazo = precoaprazo;
      }

      if (data?.motivo instanceof Array) {
        sendData.motivo = data.motivo.join(', ');
      }

      if (data?.outros?.length > 0) {
        sendData.motivo = sendData.motivo.length > 0 ? sendData.motivo.concat(`, ${data.outros}`) : data.outros;
      }
    }

    try {
      // Validações
      const objSchema = isPreAvaliacao
        ? handleSchemaCondicaoPreAvaliacao()
        : handleSchemaCondicaoPreAvaliacaoNegociacao();
      const schema = Yup.object(objSchema);

      await schema.validate(sendData, { abortEarly: false });

      // Campos em comum dos forms das telas de Pré Avaliacão e Negociação
      const payloadCommon = {
        comprado_eu: sendData.comprado_eu,
        link_referencia: sendData.link_referencia,
        valor_loja: sendData.valor_loja,
        modelo_exato: sendData.modelo_exato,
        material: sendData.material,
        tamanho: sendData.tamanho,
        cor: sendData.cor,
        codigo: sendData.codigo,
        ano: sendData.ano,
        flag: sendData.flag ? 1 : null,
        observacoes_internas: sendData.observacoes_internas,
        codcategoria: sendData.codcategoria,
        codmarca: sendData.codmarca
      };

      // FORM da tela de Pré Avaliação
      if (isPreAvaliacao) {
        const payloadPreAvaliacao = {
          ...payloadCommon,
          aceito: Number(sendData.aceito),
          preco: sendData.preco || null,
          precoavista: sendData.precoavista || null,
          precoaprazo: sendData.precoaprazo || null,
          observacoes: sendData.observacoes || sendData.motivo
        };

        QueroVenderItemService.acceptance(item.codquerovenderitem, payloadPreAvaliacao).then(() => {
          doRefresh();
          setExpanded(false);
        });
      }

      // FORM da tela de Negociacão
      if (isPreAvaliacaoNegociacao) {
        QueroVenderItemService.atualizarItem(item.codquerovenderitem, payloadCommon).then(() => {
          doRefresh();
          setExpanded(false);
        });
      }
    } catch (error) {
      // Tratamento de erros da validação do YUP.
      if (error instanceof Yup.ValidationError) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          html: error.errors.join('<br />')
        });
      }
    }
  };

  /**
   * Retorna a classe que deve exibir no avatar
   */
  const classAceito = () => {
    if (isPreAvaliacao) {
      if (item.aceito === null) return undefined;
      return item.aceito ? classes.aceito : classes.recusado;
    }
    if (isPreAvaliacaoNegociacao) {
      return item.negociacao_atual?.status === 20 ? classes.novaProposta : '';
    }

    return '';
  };

  // controla vizualizacao da area de Mais Informações do Produto
  const handleToggleMaisInformacoes = () => {
    setOpenMaisInformacoes(!openMaisInformacoes);
  };

  // controla o layout de mais informações de acordo com o status de aceito
  useEffect(() => {
    if (watchCampoAceito === '0') {
      setOpenMaisInformacoes(false);
    } else {
      setOpenMaisInformacoes(true);
    }
  }, [setOpenMaisInformacoes, watchCampoAceito]);

  /**
   * Monta o componente
   */
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Tooltip placement="right" title={`#${item.codquerovenderitem}`}>
              <Avatar className={classAceito()}>{index}</Avatar>
            </Tooltip>
          }
          action={
            item.aceito !== null ? (
              <IconButton onClick={() => setExpanded(!expanded)} size="large">
                {isShowItem ? <Remove /> : <Add />}
              </IconButton>
            ) : (
              ''
            )
          }
          title={item.titulo}
          subheader={status}
        />

        <Collapse in={isShowItem}>
          <CardContent>
            <GaleriaFotos fotos={item.fotos} />

            {isShowItem && (
              <Suspense fallback={<>carregando formulário...</>}>
                <FormItemPreAvaliacao
                  item={item}
                  aceito={item.aceito}
                  register={register}
                  watch={watch}
                  comissionamentoDiferenciado={comissionamentoDiferenciado}
                  campoCategoria={campoCategoria}
                />

                {isPreAvaliacaoNegociacao && (
                  <FormItemNegociacao
                    codquerovenderitem={item.codquerovenderitem}
                    negociacaoInicial={item.negociacao_inicial}
                    negociacaoAtual={item.negociacao_atual}
                    codcategoria={item.atributos?.categoria?.codcategoria}
                    doRefresh={doRefresh}
                    setExpanded={(value: boolean) => setExpanded(value)}
                  />
                )}
              </Suspense>
            )}

            <InformacoesBasicasProduto
              observacoes={item.observacoes}
              atributos={item.atributos}
              register={register}
              getCategoria={categoria => setCampoCategoria(categoria)}
            />

            <Typography variant="h5" className={classes.infoProduto}>
              Mais informacões do produto
              <IconButton aria-label="expand row" size="small" onClick={handleToggleMaisInformacoes}>
                {openMaisInformacoes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Typography>
            <Collapse in={openMaisInformacoes}>
              <FormInfoProduto register={register} control={control} valorLoja={valorLoja} />
            </Collapse>
          </CardContent>

          <CardActions className={classes.actions}>
            <Button type="submit" size="small" color="primary">
              {labelSubmitButton}
            </Button>
          </CardActions>
        </Collapse>
      </Card>
    </form>
  );
}

export default Item;
