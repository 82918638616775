import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import {
  TableCell,
  TableRow,
  Button,
  Collapse,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';

import Pluralize from 'components/utils/pluralize';

import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { useRetiradaPostagem } from 'pages/avaliacao/context/agendamento';
import { QueroVenderBatch, QueroVenderItem, Endereco, Cliente } from 'interfaces';
import useDiscardBatch from '../../hooks/discard-batch';

import BatchRowStyles from './style';

interface PropsBatchRow {
  batch: QueroVenderBatch;
  listBatchMerge: Array<number>;
  setListBatchMerge(list: Array<number>): void;
  setMergeCodCliente(cod: number | undefined): void;
  getBatchs(): void;
}

/**
 * COMPONENTE
 */
function BatchRow(props: PropsBatchRow) {
  const style = BatchRowStyles();

  const { batch, listBatchMerge, setListBatchMerge, setMergeCodCliente, getBatchs } = props;

  const [open, setOpen] = useState(false);
  const [produtos, setProdutos] = useState<Array<QueroVenderItem>>();

  const { setOpenModalPostagem, setOpenModalRetirada, setCodquerovender, setEnderecos } = useRetiradaPostagem();
  const discardBatch = useDiscardBatch();

  /**
   * Abre modal do form
   */
  const handleOpenModal = (modal: string, codquerovenderBatch: number, enderecos: Array<Endereco> = []) => {
    // Atualiza o estado com o código da batch selecionada
    setCodquerovender(codquerovenderBatch);

    if (modal === 'agendar') {
      setOpenModalRetirada(true);
      setEnderecos(enderecos);
    } else {
      setOpenModalPostagem(true);
    }
  };

  /**
   * Abrir produtos do batch
   */
  const openProdutos = useCallback(() => {
    if (!open) {
      const idBatch = batch.codquerovender.toString();

      QueroVenderService.get(idBatch).then(response => {
        const produtosBatch = response.data.itens.filter((item: QueroVenderItem) => item.aceito);

        setProdutos(produtosBatch);
        setOpen(!open);
      });
    } else {
      setOpen(!open);
    }
  }, [batch, open]);

  /**
   * Handler do checkbox que marca as batchs para fazer o merge
   */
  const handleChkBatch = (e: React.ChangeEvent<HTMLInputElement>, cod: number, cliente: Cliente | null) => {
    // Verifica se batch já existe na lista, se não existir, adiciona a lista se não remove
    if (listBatchMerge.includes(cod)) {
      const filteredBatch = listBatchMerge.filter(item => item !== cod);
      setListBatchMerge(filteredBatch);
    } else {
      setListBatchMerge([...listBatchMerge, cod]);
    }

    if (e.target.checked) {
      setMergeCodCliente(cliente?.codcliente);
    }
  };

  /**
   * Arquiva Remessa
   */
  const handleDiscardRemessa = (codquerovender: number) => {
    const dataDiscard = {
      codquerovender,
      cbSuccess: () => {
        getBatchs();
      }
    };

    discardBatch(dataDiscard);
  };

  /**
   * Manipula classe CSS das linhas das tabelas de batchs
   */
  const classRow = () => {
    const lockedClass = batch.locked ? `${style.locked}` : '';
    const disableClass = batch.disabled ? `${style.disabled}` : '';

    return `${lockedClass} ${disableClass}`;
  };

  /**
   * Template
   */
  return (
    <>
      <TableRow key={batch.codquerovender} className={classRow()}>
        <TableCell>
          <Checkbox
            color="default"
            className={style.chk}
            disabled={batch.disabled || batch.locked}
            onChange={e => handleChkBatch(e, batch.codquerovender, batch?.cliente)}
          />
        </TableCell>
        <TableCell>
          {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0 ? '[VIP] ' : ''}
          {`${batch?.cliente?.nome} - ${batch?.cliente?.codcliente}`}
        </TableCell>
        <TableCell>
          {`${batch.count.aceitos} `}
          <Pluralize quantidade={batch.count.aceitos} singular="produto" plural="produtos" />
          {batch.count.aceitos > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={openProdutos}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            size="small"
            className={style.spaceButton}
            onClick={() => handleOpenModal('agendar', batch.codquerovender, batch.cliente?.enderecos)}
          >
            Agendar retirada
          </Button>
          <Button
            variant="contained"
            size="small"
            className={style.spaceButton}
            onClick={() => handleOpenModal('postagem', batch.codquerovender)}
          >
            Postagem
          </Button>
        </TableCell>
        <TableCell>
          <Tooltip placement="top" title="Arquivar remessa">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDiscardRemessa(batch.codquerovender)}
              size="large"
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {produtos &&
                produtos.map(item => (
                  <List key={item.codquerovenderitem} dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={item?.fotos[0]?.imagem} alt="" />
                      </ListItemAvatar>
                      <ListItemText primary={item.titulo} />
                    </ListItem>
                  </List>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BatchRow;
