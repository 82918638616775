import { ComissionamentoPayload } from 'interfaces/comissionamento';
import api from '../api';

const getFaixasAtivasPorCategoria = (id: number | string) => {
  return api.get(`comissionamento-categoria?codcategoria=${id}`);
};

const getFaixasInativasPorCategoria = (id: number | string) => {
  return api.get(`comissionamento-categoria/historico?codcategoria=${id}`);
};

const salvar = (id: number | string, data: ComissionamentoPayload) => {
  return api.post(`comissionamento-categoria/salvar?codcategoria=${id}`, data);
};

const calcularFaixa = (codcategoria: number | string, preco: number | string) => {
  return api.get(`comissionamento-categoria/calcular-faixa?codcategoria=${codcategoria}&preco=${preco}`);
};

export default { getFaixasAtivasPorCategoria, getFaixasInativasPorCategoria, salvar, calcularFaixa };
