import { Box, Button, TextField } from '@mui/material';
import TemplateBase from 'components/template-base/template';
import { useEffect, useState } from 'react';
import LocalizacaoProdutoService from 'services/localizacao/LocalizacaoProdutoService';
import Dropzone, { FileWithPath } from 'react-dropzone';
import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { uniqueId } from 'lodash';
import { useLoading } from 'context/loading';
import MidiasInternas from 'services/midias-internas/MidiasInternasService';
import Swal from 'sweetalert2';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useUploadImagesQueue } from './hooks/useUploadImagesQueue';
import { styles } from './styles';
import { Image } from './components/Image';
import { SortableItem } from './components/SortableItem';

function AdicionarFotosParceiro() {
  const classes = styles();
  const { setLoading } = useLoading();
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState<number>();

  const [codeProduct, setCodeProduct] = useState('');
  const { initUpload, resetFinishedQueue, finishedQueue, images, setImages } = useUploadImagesQueue(codeProduct);

  useEffect(() => {
    return () => {
      images.forEach(image => URL.revokeObjectURL(image.url));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetProduct = async () => {
    try {
      setLoading(true);
      const response = await LocalizacaoProdutoService.localizar(codeProduct);
      setLoading(false);
      setProductName(response.data.nome);

      setProductId(response.data.codproduto);
    } catch {
      setLoading(false);
    }
  };

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return 'Arraste e solte as imagens aqui, ou clique para selecionar';
    }
    if (isDragReject) {
      return 'Arquivo(s) não suportados';
    }

    return 'Solte as imagens aqui';
  };

  const handleDropAccepted = (acceptedFiles: FileWithPath[]) => {
    if (acceptedFiles.length === 0) return;
    setImages(prevState => [
      ...prevState,
      ...acceptedFiles.map(file => ({ id: uniqueId(), url: URL.createObjectURL(file), data: file }))
    ]);
  };

  const dndSensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));

  const handleOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setImages(prevState => {
        const activeIndex = prevState.findIndex(file => file.id === active.id);
        const overIndex = prevState.findIndex(file => file.id === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      await MidiasInternas.iniciar(codeProduct);
      initUpload();
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (finishedQueue) {
      MidiasInternas.concluir(productId!, { bypass_validacao_template: 1 })
        .then(() => {
          setCodeProduct('');
          setProductName('');
          setLoading(false);
          Swal.fire('Imagens adicionadas', '', 'success');
        })
        .catch(() => setLoading(false))
        .finally(() => {
          resetFinishedQueue();
        });
    }
  }, [finishedQueue, productId, resetFinishedQueue, setLoading]);

  return (
    <TemplateBase>
      <h1>Adicionar Fotos Parceiro</h1>

      <Box display="flex" flex={1} alignItems="center" gap={2}>
        <TextField
          label="Código do produto"
          placeholder="Código do produto"
          variant="outlined"
          size="small"
          style={{ width: 300 }}
          value={codeProduct}
          onChange={e => setCodeProduct(e.target.value)}
        />
        <Button type="button" variant="contained" color="primary" onClick={handleGetProduct}>
          Buscar
        </Button>
      </Box>

      {productName && (
        <>
          <Box display="flex" alignItems="center">
            <h4>Nome do produto:&nbsp;</h4>
            <p>{productName}</p>
          </Box>

          <Box>
            <Dropzone
              onDropAccepted={handleDropAccepted}
              accept={{
                'image/*': []
              }}
            >
              {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => (
                <div
                  {...getRootProps({
                    className: ` dropzone ${classes.containerDropzone} ${isDragAccept ? classes.dragActive : ''} ${isDragReject ? classes.dragRejected : ''}`
                  })}
                >
                  <input {...getInputProps()} />
                  <p
                    className={`${classes.textDefault} ${isDragAccept ? classes.textActive : ''} ${isDragReject ? classes.textRejected : ''}`}
                  >
                    {renderDragMessage(isDragActive, isDragReject)}
                  </p>
                </div>
              )}
            </Dropzone>
            {images.length > 0 && <p>Segure e arraste as fotos para ajustar a ordenação.</p>}
          </Box>
          <DndContext
            modifiers={[restrictToWindowEdges]}
            onDragEnd={handleOnDragEnd}
            collisionDetection={closestCenter}
            sensors={dndSensors}
          >
            <SortableContext items={images}>
              <Box mt={3} display="flex" flexWrap="wrap" gap={2}>
                {images.map(image => (
                  <SortableItem id={image.id} key={image.id}>
                    <Image url={image.url} />
                  </SortableItem>
                ))}
              </Box>
            </SortableContext>
          </DndContext>
          <Box mt={3}>
            <Button disabled={images.length === 0} variant="contained" color="primary" onClick={handleUpload}>
              Fazer upload
            </Button>
          </Box>
        </>
      )}
    </TemplateBase>
  );
}

export default AdicionarFotosParceiro;
