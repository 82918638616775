import React from 'react';
import Grid from '@mui/material/Grid';
import { useFetchDevolucoes } from '../fetch-devolucoes-provider';

export function DadosVendedora() {
  const { loading, vendedora } = useFetchDevolucoes();

  return !loading ? (
    <>
      <h1>Gerar nova devolução</h1>
      <h2>
        {vendedora?.cliente?.nome} | {vendedora?.cliente?.codcliente}
      </h2>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <strong>Cód: </strong>
            {vendedora?.codigo}
          </div>
          <div>
            <strong>CPF: </strong>
            {vendedora?.cpf}
          </div>
          <div>
            <strong>E-mail: </strong>
            {vendedora?.email}
          </div>
          <div>
            <strong>Telefone: </strong>
            {vendedora?.telefone1}
          </div>
        </Grid>
      </Grid>
    </>
  ) : null;
}
