import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { useFormAvaliacao } from 'context/form-avaliacao';

import styles from '../styles';

export function RequerAtencao() {
  const { item } = useFormAvaliacao();

  const classes = styles();

  return (
    <Box py={1}>
      <FormGroup row>
        <FormControlLabel
          className={classes.checkBox}
          control={<Checkbox name="flag" disabled defaultChecked={Boolean(item?.flag)} color="primary" />}
          label="Requer atenção?"
        />
      </FormGroup>
    </Box>
  );
}
