import React, { useEffect, useState } from 'react';
import { Box, Paper, Button, Grid, Card, CardContent } from '@mui/material';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';

import alertValidationMessage from 'helpers/alert-validation-message';
import Loading from 'components/loading';
import PedidosService from 'services/pedidos/pedidosService';

import TemplateBase from 'components/template-base/template';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';

interface forcarCaptura {
  codPedido: string;
}

/**
 * Regras das validações do form
 */
const schema = Yup.object().shape({
  codPedido: Yup.string().required('O código do pedido é obrigatório')
});

function ForcarCaptura() {
  document.title = 'Ferramentas | Forçar Captura de um pedido';

  const [loading, setLoading] = useState(false);

  /**
   * Resgata dados do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors }
  } = useForm<forcarCaptura>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = (dataForm: forcarCaptura) => {
    setLoading(true);
    PedidosService.forceCapture(dataForm.codPedido)
      .then(response => {
        if (response.status === 200) {
          Swal.fire('Pedido capturado com sucesso', '', 'success');
          reset(); // Limpa o form
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TemplateBase>
      <h1>Forçar Captura de um pedido</h1>

      <Paper elevation={1}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xl={4} lg={5}>
                <h3>Código do Pedido</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextFieldHookForms
                    {...register('codPedido')}
                    variant="outlined"
                    size="small"
                    placeholder="Ex.: A1S2D3"
                  />
                  <Box component="span" ml={2}>
                    <Button type="submit" variant="contained" color="primary">
                      Capturar
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      <Loading open={loading} />
    </TemplateBase>
  );
}

export default ForcarCaptura;
