import makeStyles from '@mui/styles/makeStyles';

import { grey, pink } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const devolucaoStyles = makeStyles(() => ({
  cardSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  inputsReadOnly: {
    '& input': {
      color: grey[500]
    }
  },
  titles: {
    fontSize: '0.80rem'
  },
  avatarImage: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[400]
  },
  avatarImageSmall: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[400]
  },
  portadorRetirada: {
    color: pink[500],
    backgroundColor: pink[100],
    fontSize: theme.typography.subtitle2.fontSize,
    borderRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  modalContent: {
    minWidth: '500px'
  },
  inputDate: {
    width: '100%'
  },
  endereco: {
    width: '100%',
    marginTop: '15px',
    fontSize: '12px'
  }
}));

export default devolucaoStyles;
