import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { QueroVenderItemRejeitar } from 'interfaces';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';

import { useForm } from 'react-hook-form';
import { CheckboxHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { TextField } from '@mui/material';

interface Props {
  onClose(): void;
  codquerovenderitem: number;
  modelo?: string;
  openModalBipPedidoRejeidado?: () => void;
  doRefresh?: () => void;
  callBack?: () => void;
}

interface FormRejeitarProdutos extends Omit<QueroVenderItemRejeitar, 'motivo'> {
  motivo: string[];
  outros: string;
}

export function ModalRejeitarItem(props: Props) {
  const {
    onClose,
    codquerovenderitem,
    modelo = null,
    openModalBipPedidoRejeidado = () => undefined,
    doRefresh = () => undefined,
    callBack = () => undefined
  } = props;

  const { campoCategoria, campoMarca, setLoadingFull, tipoAvaliacao } = useFormAvaliacao();

  const [categoria, setCategoria] = useState('');
  const [marca, setMarca] = useState('');
  const [modeloNome, setModeloNome] = useState('');

  useEffect(() => {
    if (campoCategoria) {
      setCategoria(campoCategoria.label_autocomplete || campoCategoria.nome || '');
    }
  }, [campoCategoria]);

  useEffect(() => {
    if (campoMarca) {
      setMarca(campoMarca.nome || '');
    }
  }, [campoMarca]);

  useEffect(() => {
    if (modelo) {
      setModeloNome(modelo);
    }
  }, [modelo]);

  const handleCategoria = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoria(event.target.value);
  };

  const handleMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(event.target.value);
  };

  const handleModelo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModeloNome(event.target.value);
  };

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const { register, handleSubmit } = useForm<FormRejeitarProdutos>();

  /**
   * Enviar dados do form
   *
   */

  const onSubmit = async (data: FormRejeitarProdutos) => {
    const newData = { categoria, marca, modelo: modeloNome, ...data };
    const body: QueroVenderItemRejeitar = {
      titulo: '',
      motivo: ''
    };

    try {
      const schema = Yup.object().shape({
        categoria: Yup.string().required('Informe a categoria do produto'),
        marca: Yup.string().required('Informe a marca do produto'),
        modelo: Yup.string().required('Informe o modelo do produto'),
        motivo:
          data.motivo.length > 0 || data.outros.length > 0
            ? Yup.mixed().notRequired()
            : Yup.array()
                .of(Yup.string())
                .min(1, 'Selecione ou escreva um motivo')
                .required('Selecione ou escreva um motivo')
      });

      await schema.validate(newData, { abortEarly: false });

      body.titulo = `${newData.categoria} ${newData.marca} ${newData.modelo}`;

      if (data.motivo instanceof Array) {
        body.motivo = data.motivo.join(', ');
      }
      if (data.outros.length > 0) {
        body.motivo = body.motivo.length > 0 ? body.motivo.concat(`, ${data.outros}`) : data.outros;
      }

      setLoadingFull(true);
      onClose();

      if (tipoAvaliacao === 'produto_individual') {
        QueroVenderItemService.rejeitar_individual(codquerovenderitem, body)
          .then(() => {
            Swal.fire('O produto foi rejeitado', '', 'success').then(() => {
              setTimeout(() => {
                openModalBipPedidoRejeidado?.();
              }, 300);
              onClose();
              doRefresh?.();
              callBack?.();
            });
          })
          .finally(() => {
            setLoadingFull(false);
          });
      } else {
        QueroVenderItemService.rejeitar(codquerovenderitem, body)
          .then(() => {
            Swal.fire('O produto foi rejeitado', '', 'success').then(() => {
              onClose();
              doRefresh?.();
              callBack?.();
            });
          })
          .finally(() => {
            setLoadingFull(false);
          });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        html: error.errors.join('<br />')
      });
    }
  };

  const { ref: refMotivo, ...propsMotivo } = register('motivo', { value: [] });

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Informe o motivo</DialogTitle>
        <DialogContent>
          <Box py={1}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Categoria"
                  variant="outlined"
                  size="small"
                  onChange={handleCategoria}
                  value={categoria}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField placeholder="Marca" variant="outlined" size="small" onChange={handleMarca} value={marca} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Modelo"
                  variant="outlined"
                  size="small"
                  onChange={handleModelo}
                  value={modeloNome}
                />
              </Grid>
            </Grid>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  key="MARCA_NAO_ACEITA"
                  label="Não trabalhamos com a marca"
                  control={
                    <CheckboxHookForms ref={refMotivo} {...propsMotivo} value="MARCA_NAO_ACEITA" color="primary" />
                  }
                />

                <FormControlLabel
                  key="MANCHAS"
                  label="Apresenta manchas"
                  control={<CheckboxHookForms ref={refMotivo} {...propsMotivo} value="MANCHAS" color="primary" />}
                />

                <FormControlLabel
                  key="AUTENTICIDADE"
                  label="Reprovada na avaliação de autenticidade"
                  control={<CheckboxHookForms ref={refMotivo} {...propsMotivo} value="AUTENTICIDADE" color="primary" />}
                />

                <FormControlLabel
                  key="FUROS"
                  label="Possui furos"
                  control={<CheckboxHookForms ref={refMotivo} {...propsMotivo} value="FUROS" color="primary" />}
                />

                <FormControlLabel
                  key="RASGOS"
                  label="Possui rasgo"
                  control={<CheckboxHookForms ref={refMotivo} {...propsMotivo} value="RASGOS" color="primary" />}
                />

                <FormControlLabel
                  key="DETALHES_CRITICOS"
                  label="Detalhes criticos aparentes"
                  control={
                    <CheckboxHookForms ref={refMotivo} {...propsMotivo} value="DETALHES_CRITICOS" color="primary" />
                  }
                />
              </FormGroup>
            </FormControl>

            <TextFieldHookForms
              label="Outros"
              {...register('outros')}
              placeholder="Outro"
              variant="outlined"
              multiline
              rows={2}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit" variant="contained" color="primary">
            Enviar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
