import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PickingLayoutContext } from '../picking-layout';
import TabPickingStyle from './styles';

type Props = {
  filaPickingLength: number;
  filaComLacreLength: number;
};

export function TabPicking(props: Props) {
  const { filaComLacreLength, filaPickingLength } = props;
  const { setTab, tab } = useContext(PickingLayoutContext);

  const styles = TabPickingStyle();

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <AppBar position="static">
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event: React.ChangeEvent<any>, newValue: number) => setTab(newValue)} // eslint-disable-line
        className={styles.tab}
      >
        <Tab label={`Fila de Picking (${filaPickingLength})`} {...tabProps(0)} />
        <Tab label={`Produtos Com Lacre (${filaComLacreLength})`} {...tabProps(1)} />
      </Tabs>
    </AppBar>
  );
}
