import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';

import api from 'services/api';

interface LoginCredenciais {
  email: string;
  password: string;
}

interface UserState {
  nome?: string;
  email?: string;
}

interface AuthState {
  access_token: string;
  user: UserState;
}

interface AuthContextData {
  user: UserState;
  login(credenciais: LoginCredenciais): Promise<void>;
  logout(): void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: { children: React.ReactElement }) {
  const [loading, setloading] = React.useState(false);

  const [data, setData] = useState<AuthState>(() => {
    const access_token = localStorage.getItem('EUBackoffice:token');
    const user = localStorage.getItem('EUBackoffice:user');

    if (access_token && user) {
      // Add ao HEADER de todos os Requests do Axios o Token de Autenticação
      api.defaults.headers.authorization = `Bearer ${access_token}`;

      return { access_token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  /**
   * LOGIN
   *
   */
  const login = useCallback(
    async (payload: LoginCredenciais) => {
      // Add loading
      setloading(!loading);

      try {
        const responseAuth = await api.post('auth', payload);
        const { access_token } = responseAuth.data;

        // Add ao HEADER de todos os Requests do Axios o Token de Autenticação
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        // Resgata dados do user.
        const responseUser = await api.get('me');
        const user = responseUser.data;

        // Grava token e dados do user no LocalStorage
        localStorage.setItem('EUBackoffice:token', access_token);
        localStorage.setItem('EUBackoffice:user', JSON.stringify(user));

        setData({ access_token, user });

        // Remove loading
        setloading(false);

        return Promise.resolve();
      } catch (error) {
        // Remove loading
        setloading(false);

        return Promise.reject(error);
        // Erros sendo tratado no interceptor do Axios!
      }
    },
    [loading]
  );

  /**
   * LOGOUT
   *
   */
  const logout = useCallback(() => {
    localStorage.removeItem('EUBackoffice:token');
    localStorage.removeItem('EUBackoffice:user');

    setData({} as AuthState);
  }, []);

  const value = useMemo(() => ({ user: data.user, login, logout, loading }), [data, login, logout, loading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve usado dentro de um AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
