import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import alertValidationMessage from 'helpers/alert-validation-message';
import { useAuth } from 'context/auth';
import Collapse from '@mui/material/Collapse';
import loginStyles from './styles';

interface LoginFormData {
  email: string;
  password: string;
  mfa?: string;
}

// Validações
const schema = Yup.object().shape({
  email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória')
});

function Login() {
  document.title = 'Login';

  const style = loginStyles();
  const { login, loading } = useAuth();
  const [inputMfa, setInputMfa] = React.useState({
    show: false,
    message: ''
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<LoginFormData>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  const onSubmit = useCallback(
    (data: LoginFormData) => {
      const body: LoginFormData = {
        email: data.email,
        password: data.password
      };

      if (data.mfa) {
        body.mfa = data.mfa;
      }

      // LOGAR!
      login(body).catch(error => {
        if (error?.response?.status === 401 && error?.response?.data?.mfa) {
          setInputMfa({ show: true, message: error?.response?.data?.mfa.message });
        } else {
          setValue('mfa', '');
        }
      });
    },
    [login, setValue]
  );

  return (
    <Grid container component="main" className={style.root}>
      <Grid size={{ sm: 4, md: 7, xs: false }} className={style.image} />
      <Grid size={{ xs: 12, sm: 8, md: 5 }} component={Paper} elevation={6} square>
        <div className={style.paper}>
          <Avatar className={style.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Etiqueta Única
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
            <TextField label="E-mail" margin="normal" variant="outlined" fullWidth autoFocus {...register('email')} />
            <TextField
              type="password"
              label="Senha"
              margin="normal"
              variant="outlined"
              fullWidth
              {...register('password')}
            />

            <Collapse in={inputMfa.show}>
              <>
                <TextField
                  label="Inserir código de autenticação"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  {...register('mfa')}
                />
                <Box textAlign="center" fontSize={14} color="#333" sx={{ textDecoration: 'underline' }}>
                  {inputMfa.message}
                </Box>
              </>
            </Collapse>
            <Button type="submit" fullWidth variant="contained" color="primary" className={style.submit}>
              Entrar
            </Button>

            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <Link component={RouterLink} to="/login">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>

      <Backdrop className={style.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Login;
