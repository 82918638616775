import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CampoCategorias, QueroVenderItem } from 'interfaces';
import AutocompleteCategorias from 'components/form/autocompleteCategorias';
import AutocompleteMarcas from 'components/form/autocompleteMarcas';
import { UseFormRegister } from 'react-hook-form';
import style from './style';

type Atributos = Pick<QueroVenderItem, 'atributos'>;
type Observacoes = Pick<QueroVenderItem, 'observacoes'>;
type ItensInclusos = Atributos['atributos']['itens_inclusos'][0];
type InformacoesBasicasProdutoProps = {
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  getCategoria: (param: CampoCategorias | null) => void;
} & Atributos &
  Observacoes;

function InformacoesBasicasProduto(props: InformacoesBasicasProdutoProps) {
  const classes = style();
  const { observacoes = '—', atributos, register, getCategoria } = props;

  const renderItensInclusos = () => {
    if (!atributos.itens_inclusos) return 'ERROR';
    if (atributos.itens_inclusos.length === 0) return 'Nenhum';

    const itens: Array<string> = [];

    atributos.itens_inclusos.map((i: ItensInclusos) => itens.push(i.nome));

    return itens.join(', ');
  };

  return (
    <>
      <Grid container direction="column" className={classes.obs}>
        <Typography variant="overline" color="textSecondary">
          Observações
        </Typography>
        {observacoes}
      </Grid>

      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={4}>
          <AutocompleteCategorias
            categoria={atributos.categoria}
            register={register}
            getCategoria={categoria => getCategoria(categoria)}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteMarcas marca={atributos.marca} register={register} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="overline" color="textSecondary">
            Itens inclusos
          </Typography>
          <div>{renderItensInclusos()}</div>
        </Grid>
      </Grid>
    </>
  );
}

export default InformacoesBasicasProduto;
