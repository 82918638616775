import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import Pluralize from 'components/utils/pluralize';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch, Cliente, QueroVenderItem, LockedBatch } from 'interfaces';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import useDiscardBatch from '../../hooks/discard-batch';

import preAvaliacaoStyle from '../style';

interface PropsBatchRow {
  batch: QueroVenderBatch;
  listBatchMerge: Array<number>;
  setListBatchMerge(list: Array<number>): void;
  setMergeCodCliente(cod: number | undefined): void;
  retrieve(): void;
  locked: LockedBatch[];
}

function BatchRow(props: PropsBatchRow) {
  const { batch, setMergeCodCliente, setListBatchMerge, listBatchMerge, retrieve, locked } = props;

  const [open, setOpen] = useState(false);
  const [produtos, setProdutos] = useState<Array<QueroVenderItem>>();

  const isLocked = locked.length > 0;

  const classes = preAvaliacaoStyle();
  const discardBatch = useDiscardBatch();

  /**
   * Manipula classe CSS das linhas das tabelas de batchs
   */
  const classRow = useCallback(() => {
    const lockedClass = isLocked ? `${classes.locked}` : '';
    const disableClass = batch.disabled ? `${classes.disabled}` : '';

    return `${lockedClass} ${disableClass}`;
  }, [batch, classes, isLocked]);

  const linkVer = () => {
    return (
      <Link to={`/pre-avaliacao/batch/${batch.codquerovender}`} className={classes.linkAvaliar}>
        Avaliar
      </Link>
    );
  };

  /**
   * Abrir produtos do batch
   */
  const openProdutos = useCallback(() => {
    const idBatch = batch.codquerovender.toString();

    QueroVenderService.get(idBatch).then(response => {
      setOpen(!open);
      setProdutos(response.data.itens);
    });
  }, [batch, open]);

  /**
   * Handler do checkbox que marca as batchs para fazer o merge
   */
  const handleChkBatch = (e: React.ChangeEvent<HTMLInputElement>, cod: number, cliente: Cliente | null) => {
    // Verifica se batch já existe na lista, se não existir, adiciona a lista se não remove
    if (listBatchMerge.includes(cod)) {
      const filteredBatch = listBatchMerge.filter(item => item !== cod);
      setListBatchMerge(filteredBatch);
    } else {
      setListBatchMerge([...listBatchMerge, cod]);
    }

    if (e.target.checked) {
      setMergeCodCliente(cliente?.codcliente);
    }
  };

  /**
   * Arquiva Remessa
   */
  const handleDiscardRemessa = (codquerovender: number) => {
    const dataDiscard = {
      codquerovender,
      cbSuccess: () => {
        retrieve();
      }
    };

    discardBatch(dataDiscard);
  };

  return (
    <>
      <TableRow key={batch.codquerovender} className={classRow()}>
        <TableCell>
          <Checkbox
            color="default"
            className={classes.chk}
            disabled={batch.disabled || isLocked}
            onChange={e => handleChkBatch(e, batch.codquerovender, batch.cliente)}
          />
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0
              ? '[VIP] '
              : ''}
            {`${batch?.cliente?.nome} - ${batch.cliente?.codcliente}`}
            {!!batch?.primeira_interacao && (
              <Box className={classes.iconClienteNovo}>
                <StarIcon />
              </Box>
            )}
            {batch?.atencao && (
              <Tooltip placement="top" title="Batch contém ao menos (1) bolsa ou (1) acessório">
                <Box component="span" display="inline-block" padding="12px">
                  <LocalMallIcon className={classes.iconBolsasAcessorios} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {`${batch.count.total} `}
          <Pluralize quantidade={batch.count.total} singular="produto" plural="produtos" />
          {batch.count.total > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={openProdutos} disabled={isLocked}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
        <TableCell>
          {isLocked ? (
            <Tooltip placement="top" title={`${locked[0]?.email || batch.lock_info?.email}`}>
              <span>Em uso por {locked[0]?.nome || batch.lock_info?.nome}</span>
            </Tooltip>
          ) : (
            linkVer()
          )}
        </TableCell>
        <TableCell>
          <Box display="inline-flex" alignItems="center">
            <Tooltip placement="top" title="Arquivar remessa">
              <IconButton
                aria-label="delete"
                color="primary"
                onClick={() => handleDiscardRemessa(batch.codquerovender)}
                disabled={isLocked}
                size="large"
              >
                <ArchiveIcon />
              </IconButton>
            </Tooltip>

            {!batch?.fluxo_novo && (
              <Tooltip placement="top" title="Este batch respeitará o fluxo antigo">
                <Link to={`/avaliacao/pre-avaliacao/${batch.codquerovender}`}>
                  <IconButton aria-label="Nova sugestão" className={classes.iconFluxoAntigo} size="large">
                    <NotificationsActive />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {produtos &&
                produtos.map(item => (
                  <List key={item.codquerovenderitem} dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={item.fotos[0]?.imagem} alt="" />
                      </ListItemAvatar>
                      <ListItemText primary={item.titulo} />
                    </ListItem>
                  </List>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BatchRow;
