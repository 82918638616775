import React from 'react';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import formatPrice from 'helpers/format-price';

import { PagamentosVendedorasTypes, StatusStringMap } from 'pages/ferramentas/pagamentos-vendedoras';

import PagamentosItemStyle from './style';

type PagamentoItemProps = {
  pagamento: PagamentosVendedorasTypes;
  listaStatus: StatusStringMap;
  onOpenModalPago: (status: boolean) => void;
  onOpenModalTentarNovamente: (status: boolean) => void;
  setCodPedidoProduto: (codPedidoProduto: number) => void;
};

function PagamentoItem(props: PagamentoItemProps) {
  const classes = PagamentosItemStyle();
  const { pagamento: item, listaStatus, onOpenModalPago, onOpenModalTentarNovamente, setCodPedidoProduto } = props;
  const getStatus = (status: string): string => (status === null ? '-' : listaStatus[status]);

  const formatDate = (data: Date | string) => {
    if (data === '') return '-';

    const date = new Date(data);
    const ano = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const dia = String(date.getDate()).padStart(2, '0');

    return `${dia}/${mes}/${ano}`;
  };

  const handleModalPago = (codPedidoProduto: number) => {
    onOpenModalPago(true);
    setCodPedidoProduto(codPedidoProduto);
  };

  const handleModalTentarNovamente = (codPedidoProduto: number) => {
    onOpenModalTentarNovamente(true);
    setCodPedidoProduto(codPedidoProduto);
  };

  return (
    <TableRow>
      <TableCell>
        #{item.codpedido}
        <br />
        <small>#{item.codpedidoproduto}</small>
      </TableCell>
      <TableCell>
        <div>{item.nome}</div>
        <div>
          <strong>V:</strong> {formatPrice(item.valorrepasse)} <small>({formatDate(item.datapagamento)})</small>
        </div>
        {item.valorcomissionada > 0 && (
          <div>
            <strong>C:</strong> {formatPrice(item.valorcomissionada)}{' '}
            <small>({formatDate(item.datapagamento_captadora)})</small>
          </div>
        )}
      </TableCell>
      <TableCell>
        <div>
          <strong>V:</strong> {getStatus(item.pago)}
          {item.pago === 'E' || item.pago === 'F' || item.pago === 'X' ? (
            <small> ({item.saque_erro_ultimo_vendedora})</small>
          ) : null}
        </div>

        {item.valorcomissionada > 0 && (
          <div>
            <strong>C:</strong> {getStatus(item.pago_captadora)}
            {item.pago_captadora === 'E' || item.pago_captadora === 'F' || item.pago_captadora === 'X' ? (
              <small> ({item.saque_erro_ultimo_captadora})</small>
            ) : null}
          </div>
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          className={classes.ButtonPago}
          onClick={() => handleModalPago(item.codpedidoproduto)}
        >
          Pago
        </Button>
        <Button variant="contained" onClick={() => handleModalTentarNovamente(item.codpedidoproduto)}>
          Tentar novamente
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default PagamentoItem;
