import makeStyles from '@mui/styles/makeStyles';

const avatarProdutoStyles = makeStyles(() => ({
  avatar: {
    width: 90,
    height: 90,
    borderWidth: 2,
    borderStyle: 'solid'
  }
}));

export default avatarProdutoStyles;
