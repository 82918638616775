import makeStyles from '@mui/styles/makeStyles';

const CheckboxGeneroStyles = makeStyles(() => ({
  checkBox: {
    marginLeft: 0,
    '& .MuiFormControlLabel-root': {
      marginLeft: 0
    },
    '& .MuiCheckbox-root, & .MuiRadio-root': {
      padding: 2
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12
    }
  }
}));

export default CheckboxGeneroStyles;
