import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { FiltrosVariacao } from 'interfaces';
import { useFormContext } from 'react-hook-form';
import styles from '../styles';
import { FiltrosAutomplete } from '../filtros-autocomplete';

export function InformacoesProduto() {
  const classes = styles();
  const { tipoMarca, setCampoTamanho, item, showCampoComprimento, disableForm } = useFormAvaliacao();
  const { register } = useFormContext();

  return (
    <Box py={1}>
      <Typography variant="overline" color="textSecondary" className={classes.titles}>
        Informações do Produto
      </Typography>

      <FiltrosAutomplete
        tipoMarca={tipoMarca}
        getFiltro={filtro => setCampoTamanho(filtro as FiltrosVariacao[])}
        produto={item?.produto}
        showCampoComprimento={showCampoComprimento}
        disabled={disableForm}
        register={register}
      />
    </Box>
  );
}
