import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CheckboxProps } from '@mui/material/Checkbox';
import { UseFormRegister } from 'react-hook-form';
import { CheckboxHookForms } from '../text-field-hook-forms';

type Props = {
  register: UseFormRegister<any>; // eslint-disable-line
  getCheckboxProps?: ({ name, value }: { name: string; value: string | number }) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | null, value: boolean) => void;
  defaultChecked?: boolean;
  name: string;
} & CheckboxProps;

export const CheckBoxIndividual = forwardRef<
  {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
    handleClick: () => void;
    checked: boolean;
  },
  Props
>((props: Props, ref) => {
  const { register, getCheckboxProps = undefined, onChange = undefined, defaultChecked = false, name, ...rest } = props;
  const [checked, setChecked] = useState<boolean>(defaultChecked as boolean);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    onChange?.(e, value);
    setChecked(value);
    getCheckboxProps?.({
      name: e.target.name,
      value: e.target.value
    });
  };

  const handleClick = () => {
    setChecked(!checked);
    onChange?.(null, !checked);
  };

  useImperativeHandle(ref, () => ({
    handleChange,
    handleClick,
    checked
  }));

  return <CheckboxHookForms color="primary" {...rest} {...register(name)} checked={checked} onChange={handleChange} />;
});
