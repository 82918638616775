import React, { useMemo } from 'react';
import { ItemBatchTextProps } from './index';
import style from '../Item/style';

export const withItemStatusClass = (BaseComponent: React.ElementType) => {
  function WithItemStatusClass(props: ItemBatchTextProps) {
    const classes = style();
    const { item } = props;

    const classStatusProduto = useMemo(() => {
      if (item?.recebido && item?.aceite_fisico !== null) {
        if (item.aceite_fisico) {
          return {
            statusClass: classes.aceito,
            statusText: 'Avaliado'
          };
        }

        if (!item.aceite_fisico) {
          return {
            statusClass: classes.recusado,
            statusText: 'Recusado'
          };
        }
      }

      if (!item?.recebido) {
        return {
          statusClass: classes.nao_recebido,
          statusText: 'Não Recebido'
        };
      }

      return {
        statusClass: classes.em_avaliacao,
        statusText: 'Em Avaliação'
      };
    }, [item, classes]);

    return <BaseComponent {...props} {...classStatusProduto} />;
  }

  return WithItemStatusClass;
};
