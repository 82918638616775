import axios from 'axios';
import Swal from 'sweetalert2';
import ApiValidationsHandler from '../helpers/api-validations-handler';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.defaults.headers['eu-version'] = '1.3.3';

/**
 * Interceptor das respostas dos requests
 *
 */
api.interceptors.response.use(
  response => response,
  error => {
    // Token Inválido / Usuário ou Senha inválidos
    if (error?.response?.status === 401) {
      const isAuth = error.response.config.url === 'auth';

      // Tratamento específico para logins que precisam ser autenticados via mfa
      if (error?.response?.data && error?.response?.data?.mfa) {
        return Promise.reject(error);
      }

      // Erro de Autenticação
      if (isAuth) {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: error.response.data.message
        });
      } else {
        // Limpa token
        localStorage.removeItem('EUBackoffice:token');
        localStorage.removeItem('EUBackoffice:user');

        // Remove o header do axios
        api.defaults.headers.authorization = null;

        // Envia para o login
        if (process.env.REACT_APP_ENVIRONMENT !== 'sandbox') {
          window.location.href = '/login';
        }
      }
    }

    ApiValidationsHandler(error);

    return Promise.reject(error);
  }
);

export default api;
