import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import { useRetiradaPostagem } from 'pages/avaliacao/context/agendamento';
import QueroVenderRemessaService from 'services/quero-vender/QueroVenderRemessaService';
import alertValidationMessage from 'helpers/alert-validation-message';
import { QueroVenderPostagem, QueroVenderBatch } from 'interfaces';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import formPostagemStyles from './style';

/**
 * Regras das validações
 */
const schema = Yup.object().shape({
  codpostagem: Yup.number().typeError('O código numérico é obrigatório')
});

/**
 * COMPONENTE
 */
function FormPostagem() {
  const style = formPostagemStyles();

  const { openModalPostagem, setOpenModalPostagem, codquerovender, batches, setBatch } = useRetiradaPostagem();

  const [loadingFull, setLoadingFull] = useState(false);

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<QueroVenderPostagem>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = (data: QueroVenderPostagem) => {
    handleCloseModal();
    setLoadingFull(true);
    QueroVenderRemessaService.postagem(codquerovender, data)
      .then(response => {
        if (response) {
          const listBatches = batches.filter((item: QueroVenderBatch) => {
            return item.codquerovender !== response?.data.codquerovender;
          });

          setBatch(listBatches);
          Swal.fire('Código enviado', '', 'success');
        }
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  /**
   * Fecha modal do form
   */
  const handleCloseModal = () => {
    setOpenModalPostagem(false);
  };

  /**
   * Template
   */
  return (
    <>
      <Dialog open={openModalPostagem} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enviar código de postagem</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={style.modalContent}>
            <TextFieldHookForms
              variant="standard"
              {...register('codpostagem')}
              autoFocus
              margin="dense"
              label="Código de postagem"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              Enviar código
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Loading open={loadingFull} />
    </>
  );
}

export default FormPostagem;
