import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    obs: {
      marginTop: '15px',
      marginBottom: '10px'
    }
  })
);
