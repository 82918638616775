import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function Quiosque(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9997 7.35V13C13.9997 13.2667 13.8997 13.5 13.6997 13.7C13.4997 13.9 13.2663 14 12.9997 14H2.98299C2.71633 14 2.48299 13.9 2.28299 13.7C2.08299 13.5 1.98299 13.2667 1.98299 13V7.35C1.67188 7.08333 1.46633 6.75556 1.36633 6.36667C1.26633 5.97778 1.27744 5.58889 1.39966 5.2L2.11633 2.95C2.20522 2.65 2.36077 2.41667 2.58299 2.25C2.80522 2.08333 3.06077 2 3.34966 2H12.5663C12.8774 2 13.1497 2.08611 13.383 2.25833C13.6163 2.43056 13.7774 2.66111 13.8663 2.95L14.5997 5.2C14.7219 5.58889 14.7302 5.97778 14.6247 6.36667C14.5191 6.75556 14.3108 7.08333 13.9997 7.35ZM9.49966 6.83333C9.82188 6.83333 10.0941 6.72778 10.3163 6.51667C10.5386 6.30556 10.6274 6.05 10.583 5.75L10.1663 3H8.49966V5.75C8.49966 6.03889 8.59411 6.29167 8.78299 6.50833C8.97188 6.725 9.21077 6.83333 9.49966 6.83333ZM6.38299 6.83333C6.69411 6.83333 6.95799 6.72778 7.17466 6.51667C7.39133 6.30556 7.49966 6.05 7.49966 5.75V3H5.83299L5.41633 5.75C5.37188 6.03889 5.44966 6.29167 5.64966 6.50833C5.84966 6.725 6.09411 6.83333 6.38299 6.83333ZM3.34966 6.83333C3.61633 6.83333 3.84688 6.74167 4.04133 6.55833C4.23577 6.375 4.34966 6.15 4.38299 5.88333L4.81633 3H3.14966L2.38299 5.43333C2.27188 5.77778 2.31633 6.09722 2.51633 6.39167C2.71633 6.68611 2.99411 6.83333 3.34966 6.83333ZM12.633 6.83333C12.9886 6.83333 13.2691 6.68889 13.4747 6.4C13.6802 6.11111 13.7274 5.78889 13.6163 5.43333L12.8497 3H11.183L11.6163 5.88333C11.6497 6.15 11.7636 6.375 11.958 6.55833C12.1524 6.74167 12.3774 6.83333 12.633 6.83333Z" />
    </SvgIcon>
  );
}
