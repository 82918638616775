import api from '../api';

const bip = (data: { codigo: string; acervo_id?: number }) => {
  return api.post('acervo/bip', { ...data });
};

const reverterBip = (codproduto: number) => {
  return api.post('acervo/bip/reverter', { codproduto });
};

const moverAcervo = ({ origem, destino }: { origem: string; destino: string }) => {
  return api.post('acervo/mover', { origem, destino });
};

export default {
  bip,
  reverterBip,
  moverAcervo
};
