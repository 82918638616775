import api from '../api';

const update = <T,>(codproduto: string, data: T) => {
  return api.patch(`produtos/${codproduto}`, data);
};

const update_atributos = (
  codproduto: string,
  atributos: {
    id_atributo: string;
    valor: string;
  }[]
) => {
  return api.post(`atributos-valores-salvar/${codproduto}`, { atributos });
};

export default { update, update_atributos };
