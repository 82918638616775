import api from '../api';

export type FormData = {
  codigo_loja: string;
  produtos: {
    codproduto: number;
    cobrar_taxa: boolean;
  }[];
  motivo: string;
  cobrar_frete: boolean;
  outros_motivos?: string;
  fieldMotivo: string[];
};

export type ListParams = {
  codloja: string | number;
  params: {
    q?: string;
    perpage?: number;
    page?: number;
  };
};

const list = ({ codloja, params }: ListParams) => {
  const queryParams: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      queryParams.push(`${key}=${value}`);
    }
  });

  return api.get(`vendedora-devolucoes/dashboard/${codloja}/produtos-devolucao-manual?${queryParams.join('&')}`);
};

const criar_pedido = (body: FormData) => {
  return api.post(`vendedora-devolucoes/criar-pedido-devolucao-manual`, body);
};

const cancelar_pedido = (codigo_pedido: string) => {
  return api.post(`vendedora-devolucoes/pedido-devolucao/${codigo_pedido}/cancelar`);
};

const aprovar_pedido = (codigo_pedido: string, body?: { agendamento_retirada: string }) => {
  return api.post(`vendedora-devolucoes/pedido-devolucao/${codigo_pedido}/aprovar`, body);
};

const marcar_nao_pago = (codigo_pedido: string) => {
  return api.post(`vendedora-devolucoes/pedido-devolucao/${codigo_pedido}/marcar-nao-pago`);
};

const dashboard = (codloja: number, status: string, perpage: number, page: number) => {
  return api.get(`vendedora-devolucoes/dashboard/${codloja}/listar/${status}?per_page=${perpage}&page=${page}`);
};

const gerar_nova_url = (codpedido: string) => {
  return api.post(`vendedora-devolucoes/pedido-devolucao/${codpedido}/gerar-nova-url`);
};

const regerar_pedido_validacao = (codpedido: string) => {
  return api.get(`vendedora-devolucoes/regerar-pedido-devolucao/${codpedido}/validacao`);
};

const regerar_pedido = (codpedido: string) => {
  return api.post(`vendedora-devolucoes/regerar-pedido-devolucao/${codpedido}/regerar`);
};

export default {
  list,
  criar_pedido,
  cancelar_pedido,
  aprovar_pedido,
  marcar_nao_pago,
  dashboard,
  gerar_nova_url,
  regerar_pedido_validacao,
  regerar_pedido
};
