import React, { useState } from 'react';
import { TableCell, TableRow, Box, IconButton, Tooltip, Button } from '@mui/material';
import { QueroVenderBatch, LockedBatch, QueroVenderLogs } from 'interfaces';

import { Link } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import ArchiveIcon from '@mui/icons-material/Archive';
import Pluralize from 'components/utils/pluralize';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ModalTableLogs from 'components/modal-table-logs';
import { format } from 'date-fns';
import useDiscardBatch from '../hooks/discard-batch';

import styles from './styles';

type Props = {
  batch: QueroVenderBatch;
  isLocked: boolean;
  getBatchs: () => void;
  locked: LockedBatch[];
};

type ModalLogsProps = {
  logs: QueroVenderLogs[];
  codigo: string | undefined;
  open: boolean;
};

export function BatchRow(props: Props) {
  const { batch, isLocked, getBatchs, locked } = props;
  const style = styles();
  const discardBatch = useDiscardBatch();
  const [openLogs, setOpenLogs] = useState<ModalLogsProps>({
    logs: [],
    codigo: undefined,
    open: false
  });

  /**
   * Abre modal de logs
   */

  const openModalLogs = ({ logs, codigo, open }: ModalLogsProps) => setOpenLogs({ logs, codigo, open });

  /**
   * Fecha a modal de logs
   */

  const handleCloseModalOpenLogs = () => {
    setOpenLogs({
      logs: [],
      codigo: undefined,
      open: false
    });
  };

  /**
   * Arquiva Remessa
   */
  const handleDiscardRemessa = (codquerovender: number) => {
    const dataDiscard = {
      codquerovender,
      cbSuccess: () => {
        getBatchs?.();
      }
    };

    discardBatch(dataDiscard);
  };

  return (
    <>
      <TableRow key={batch.codquerovender} className={isLocked ? style.locked : ''}>
        <TableCell>
          {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0 ? '[VIP] ' : ''}
          {batch?.cliente === null ? '—' : `${batch?.cliente?.nome} - ${batch?.cliente?.codcliente}`}
        </TableCell>
        <TableCell>{batch?.cliente === null ? '—' : batch?.cliente?.vendedora?.codigo}</TableCell>
        <TableCell>{batch?.volume?.remessa_codigo}</TableCell>
        <TableCell>{batch.sacola && <LocalMallIcon style={{ color: batch.sacola.cor }} />}</TableCell>
        <TableCell>{batch?.volume?.descricao}</TableCell>
        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy HH:mm')}</TableCell>
        <TableCell>
          {`${batch.count.aceitos} `}
          <Pluralize quantidade={batch.count.aceitos} singular="produto" plural="produtos" />
        </TableCell>
        <TableCell>
          <Button
            size="small"
            color="primary"
            onClick={() =>
              openModalLogs({
                logs: batch?.logs || [],
                codigo: batch?.codquerovender.toString(),
                open: true
              })
            }
          >
            Logs
          </Button>
        </TableCell>
        <TableCell>
          {isLocked ? (
            <Tooltip placement="top" title={`${locked[0]?.email || batch.lock_info?.email}`}>
              <span>Em uso por {locked[0]?.nome || batch.lock_info?.nome}</span>
            </Tooltip>
          ) : (
            <Link to={`/avaliacao/avaliacao-fisica-batch/${batch.codquerovender}`} className={style.linkAvaliar}>
              Avaliar
            </Link>
          )}
        </TableCell>
        <TableCell>
          <Link to={`/entrada-manual/${batch.codquerovender}`} className={style.linkAvaliar}>
            Dividir Remessa
          </Link>
        </TableCell>
        <TableCell>
          {batch?.volume?.remessa_print && batch?.volume?.formarecebimento === 'presencial' && (
            <Box component="span" ml={2}>
              <IconButton
                aria-label="delete"
                color="primary"
                href={batch?.volume?.remessa_print}
                target="_blank"
                size="large"
              >
                <PrintIcon />
              </IconButton>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Tooltip placement="top" title="Arquivar remessa">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDiscardRemessa(batch?.codquerovender)}
              size="large"
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <ModalTableLogs
        logs={openLogs.logs}
        title={`Log do produto #${openLogs.codigo}`}
        openLogs={openLogs.open}
        handleCloseModal={handleCloseModalOpenLogs}
      />
    </>
  );
}
