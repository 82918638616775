import React from 'react';
import { useAuth } from 'context/auth';

function RestringeMenu({ extra = [], children }: { extra?: string[]; children: React.ReactElement }) {
  const { user } = useAuth();

  const emailsPermitidosPadrao = [
    'ivan@etiquetaunica.com.br',
    'nelson@etiquetaunica.com.br',
    'thiago@etiquetaunica.com.br',
    'admin@eu-sand.com.br'
  ];

  const emailsPermitidos = [...extra, ...emailsPermitidosPadrao];

  const exibeMenu: boolean = emailsPermitidos.indexOf(user.email as string) > -1;

  return exibeMenu ? children : null;
}

export default RestringeMenu;
