import React, { useEffect, useState, useRef, useCallback } from 'react';
import TemplateBase from 'components/template-base/template';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ElementosService from 'services/elementos/ElementosService';
import { ElementoFaixa, ApiListMeta } from 'interfaces';
import { useLoading } from 'context/loading';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { Status } from './status';

function PainelMarketingFaixas() {
  document.title = 'Painel Marketing | Gerenciar Faixas';
  const [faixas, setFaixas] = useState<ElementoFaixa[]>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const firstLoading = useRef(true);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  /**
   * Controla paginação
   */
  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleRemoveFaixa = (id: string) => {
    Swal.fire({
      title: 'Tem certeza que deseja remover a faixa?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        ElementosService.remover(id).then(() => {
          Swal.fire('Faixa Aualizada com sucesso', '', 'success').then(() => {
            listarFaixas();
          });
        });
      }
    });
  };

  const listarFaixas = useCallback(() => {
    // Faz loading aparecer apenas nas chamadas após o primeiro carregamento
    if (!firstLoading.current) {
      setLoading(true);
    }

    ElementosService.listar(9, page)
      .then(response => {
        setFaixas(response.data.data);
        setMeta(response.data.meta);
      })
      .finally(() => {
        setLoading(false);
        setIsLoading(false);
        firstLoading.current = false;
      });
  }, [setLoading, page]);

  /**
   * Controla chamada inicial da listagem de faixa e a chamada de acordo com a paginação
   */
  useEffect(() => {
    listarFaixas();
  }, [page, setLoading, listarFaixas]);

  return (
    <TemplateBase>
      {isLoading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h1>Gerenciar Faixas</h1>

            <div>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => navigate('/painel-marketing/gerenciar-faixas/9/adicionar')}
              >
                Adicionar Faixa
              </Button>
            </div>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Data Entrada</TableCell>
                <TableCell>Data Saída</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {faixas.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Box maxWidth={400}>{item.configuracoes.linha1_texto_desktop}</Box>
                  </TableCell>
                  <TableCell>{format(new Date(item.agendado_de), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>{format(new Date(item.agendado_ate), 'dd/MM/yyyy HH:mm')}</TableCell>
                  <TableCell>
                    <Status status={item.status as 'ativo' | 'inativo'} />
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      onClick={() => navigate(`/painel-marketing/gerenciar-faixas/editar/${item.id}`)}
                    >
                      Editar
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveFaixa(`${item.id}`)}
                    >
                      Remover
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center" p={3}>
            <Pagination count={meta?.last_page} page={page} onChange={handlePagination} color="primary" />
          </Box>
        </>
      )}
    </TemplateBase>
  );
}

export default PainelMarketingFaixas;
