import React, { useState } from 'react';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CustomInputCurrency from 'components/form/custom-input-currency';
import { CheckboxHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';
import style from './style';

type FormInfoProdutoProps = {
  register: UseFormRegister<any>; // eslint-disable-line
  control: Control<any>; // eslint-disable-line
  valorLoja: string;
};

function FormInfoProduto(props: FormInfoProdutoProps) {
  const classes = style();
  const { register, control, valorLoja } = props;
  const [valor_loja, setValorLoja] = useState<string>(valorLoja);

  const handleValorLoja = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValorLoja(event.target.value);
  };

  return (
    <>
      <div className={classes.formSpace}>
        <FormControl variant="standard">
          <Controller
            name="comprado_eu"
            control={control}
            render={({ field: { value } }) => (
              <FormControlLabel
                className={classes.checkBox}
                control={
                  <CheckboxHookForms
                    {...register('comprado_eu')}
                    color="primary"
                    name="comprado_eu"
                    defaultChecked={value}
                  />
                }
                label="Comprado na EÚ?"
                labelPlacement="end"
              />
            )}
          />
        </FormControl>
        <div className={classes.formSpace}>
          <TextFieldHookForms
            variant="outlined"
            label="Link de referência"
            placeholder="Link de referência"
            size="small"
            fullWidth
            {...register('link_referencia')}
            className={classes.textInput}
          />
        </div>
        <div className={classes.formSpace}>
          <CustomInputCurrency
            register={register}
            variant="outlined"
            label="Valor de loja"
            name="valor_loja"
            size="small"
            value={valor_loja}
            onChange={handleValorLoja}
            className={classes.textInput}
          />
        </div>
      </div>

      <Grid container direction="row" className={classes.formSpace} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="overline" color="textSecondary">
            Informações exatas sobre o produto
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Modelo Exato"
            placeholder="Modelo Exato"
            fullWidth
            size="small"
            className={classes.textInput}
            {...register('modelo_exato')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Material"
            placeholder="Material"
            fullWidth
            className={classes.textInput}
            size="small"
            {...register('material')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Tamanho"
            placeholder="Tamanho"
            fullWidth
            className={classes.textInput}
            size="small"
            {...register('tamanho')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Cor"
            placeholder="Cor"
            fullWidth
            className={classes.textInput}
            size="small"
            {...register('cor')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Código"
            placeholder="Código"
            className={classes.textInput}
            fullWidth
            size="small"
            {...register('codigo')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextFieldHookForms
            variant="outlined"
            label="Ano"
            placeholder="Ano"
            className={classes.textInput}
            fullWidth
            inputProps={{ maxLength: 4 }}
            size="small"
            {...register('ano')}
          />
        </Grid>
      </Grid>

      <FormControl variant="standard" className={classes.formSpace}>
        <Controller
          name="flag"
          control={control}
          render={({ field: { value } }) => {
            return (
              <FormControlLabel
                className={classes.checkBox}
                control={<CheckboxHookForms {...register('flag')} color="primary" defaultValue={value} />}
                label="Requer atenção?"
                labelPlacement="end"
              />
            );
          }}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" className={classes.formSpace}>
        <TextFieldHookForms
          id="outlined-textarea"
          label="Observações Internas"
          placeholder="Placeholder"
          className={classes.textInput}
          multiline
          rows={2}
          variant="outlined"
          {...register('observacoes_internas')}
        />
      </FormControl>
    </>
  );
}

export default FormInfoProduto;
