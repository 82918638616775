import Swal from 'sweetalert2';

function ApiValidationsHandler(error: any) {// eslint-disable-line
  // Erro API
  if (error?.response?.status === 500) {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Ocorreu um erro inesperado no servidor. Por favor, tente novamente!'
    });
  }
  // Erro 404
  if (error?.response?.status === 404) {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error?.response?.data?.error?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
    });
  }
  // Erro de Validações da API
  if (error?.response?.status === 422) {
    const listErros = Object.entries(error.response.data.errors);
    const getValidationMessages = listErros
      .reduce<Array<string>>((accItem, item: any) => { // eslint-disable-line
        return [...accItem, ...item[1]];
      }, [])
      .reduce((acc, message) => `${acc}<li>${message}</li>`, '');

    return Swal.fire({
      icon: 'warning',
      title: 'Oops...',
      html: `<ul>${getValidationMessages}</ul>`
    });
  }
  // Mensagens de erro da API (Throw errors)
  if (error?.response) {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.error.message
    });
  }

  return error;
}

export default ApiValidationsHandler;
