import { QueroVenderConverterEmProduto } from 'interfaces';
import api from '../api';

const convert_produto_batch = (id: number, data: QueroVenderConverterEmProduto) => {
  return api.patch(`quero-vender/item/${id}/product`, data);
};
const convert_produto_individual = (id: number, data: QueroVenderConverterEmProduto) => {
  return api.patch(`quero-vender/item/${id}/product-new-version`, data);
};
const add = (data: QueroVenderConverterEmProduto) => {
  return api.post(`quero-vender/item/product`, data);
};

export default {
  convert_produto_batch,
  convert_produto_individual,
  add
};
