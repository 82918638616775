import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TextField from '@mui/material/TextField';
import { Barcode } from 'components/icons';
import InputAdornment from '@mui/material/InputAdornment';

type Props = {
  handleInputKeyPress: (value: string) => void;
};

export type Public = {
  barCodeFocus: () => void;
};

export const BarCodePicking = forwardRef((props: Props, ref) => {
  const [barCode, setBarCode] = useState<string>('');

  const { handleInputKeyPress } = props;

  const codBarras = useRef<HTMLInputElement>(null);

  const handleOnChangeBarCode = (barCodeString: string) => {
    setBarCode(barCodeString);
  };

  const onInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (codBarras?.current) {
        handleInputKeyPress(codBarras?.current.value);
      }
    }
  };

  const handleClickAway = (e: any) => { // eslint-disable-line
    if (e.target.nodeName !== 'SELECT' && e.target?.parentNode?.dataset?.name !== 'bt-pular') {
      barCodeFocus();
    }
  };

  const barCodeClear = () => {
    setBarCode('');
  };

  const barCodeFocus = () => {
    codBarras?.current?.focus();
  };

  useImperativeHandle(ref, () => ({
    barCodeFocus,
    barCodeClear
  }));

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          name="codbarra"
          size="small"
          id="field-cod-barras"
          variant="outlined"
          autoFocus
          onKeyUp={onInputKeyPress}
          value={barCode}
          onChange={e => {
            handleOnChangeBarCode(e.target.value);
          }}
          inputRef={codBarras}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Barcode />
              </InputAdornment>
            )
          }}
        />
      </ClickAwayListener>
    </div>
  );
});
