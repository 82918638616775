import makeStyles from '@mui/styles/makeStyles';
import { orange } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const PickingFotosStyle = makeStyles(() => ({
  btnAdicionarProduto: {
    marginRight: 15
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  starIcon: {
    color: orange[600]
  },
  campoCodBarras: {
    position: 'fixed',
    width: '100%',
    height: 'auto',
    right: 0,
    bottom: 0,
    display: 'inline-flex',
    backgroundColor: '#FAFAFA',
    padding: 16,

    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 270px)'
    }
  }
}));

export default PickingFotosStyle;
