import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const customBadgeStyles = makeStyles(() => ({
  wrapper: {
    fontSize: theme.typography.subtitle2.fontSize,
    borderRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    position: 'relative',
    maxHeight: '36px'
  }
}));

export default customBadgeStyles;
