import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Button, DialogActions, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import styles from '../styles';

interface Props {
  open: boolean;
  retornaQuantidade?(value: string): void;
  onClose?(): void;
}

function ModalInserirQuantidade(props: Props) {
  const { open, retornaQuantidade = false, onClose = null } = props;
  const [value, setValue] = useState<string>('1');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const classes = styles();

  useEffect(() => {
    if (open) {
      setOpenModal(true);
    }
  }, [open]);

  const handleTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      setValue(event.target.value);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setValue('1');
    if (onClose) onClose();
  };

  const handleSolicitar = () => {
    if (retornaQuantidade) {
      retornaQuantidade(value);
      handleCloseModal();
    }
  };

  return (
    <Dialog open={openModal}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={11}>
            <Typography component="div" variant="h6" className={classes.modalTitle}>
              Quantidade de produtos para cadastrado
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="close" onClick={handleCloseModal} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField type="number" value={value} name="qte_produtos" variant="outlined" onChange={handleTextField} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSolicitar}>
          Solicitar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalInserirQuantidade;
