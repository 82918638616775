import React, { useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ModalTableLogs from 'components/modal-table-logs';
// import StarsIcon from '@mui/icons-material/Stars';
import { QueroVenderItem } from 'interfaces';
import VipIcon from 'components/vip-icon';

// import ProdutosAvaliadosStyle from './style';

type Props = {
  item: QueroVenderItem;
};

export function ItemRow(props: Props) {
  // const styles = ProdutosAvaliadosStyle();

  const [openLogs, setOpenLogs] = useState<boolean>(false);

  /* Controle abertura da modal de logs */
  const handleOpenLogs = () => setOpenLogs(true);

  /* Controla fechamento de modal de logs */
  const handleCloseModalOpenLogs = () => setOpenLogs(false);

  const { item } = props;

  return (
    <TableRow>
      <TableCell>
        <VipIcon isVip={Boolean(item?.produto?.vendedora?.cliente?.prioridade)} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Avatar alt="" src={item?.produto?.imagens?.[0]?.thumbnail} />
          <Box ml={1}>{item?.produto?.nome}</Box>
        </Box>
      </TableCell>
      <TableCell>{item?.data_aprovacao ? format(new Date(item?.data_aprovacao), 'dd/MM/yyyy') : '–'}</TableCell>
      <TableCell>{item?.produto?.preparacao}</TableCell>
      <TableCell>{item?.produto?.acervo?.label}</TableCell>
      <TableCell>
        <Button color="primary" onClick={handleOpenLogs}>
          Logs
        </Button>
      </TableCell>
      <TableCell />

      <ModalTableLogs
        logs={item?.produto?.logs}
        title={`Log do item #${item?.codquerovenderitem}`}
        openLogs={openLogs}
        handleCloseModal={handleCloseModalOpenLogs}
      />
    </TableRow>
  );
}
