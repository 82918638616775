import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { ModalUrlDevolucao } from './modal-url-devolucao';

export function BotaoGerarUrl({ codigo }: { codigo: string }) {
  const [showModalGerarUrl, setShowModalGerarUrl] = useState(false);

  /**
   * Abre a modal de gerar url
   */
  const handleGerarUrl = () => {
    setShowModalGerarUrl(true);
  };

  /**
   * Fecha a modal de gerar url
   */
  const handleCloseGerarUrl = () => {
    setShowModalGerarUrl(false);
  };

  return (
    <>
      {showModalGerarUrl && <ModalUrlDevolucao handleClose={handleCloseGerarUrl} codigo={codigo} />}
      <Button variant="contained" disableElevation onClick={handleGerarUrl}>
        gerar url
      </Button>
    </>
  );
}
