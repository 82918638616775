import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';

export type CustomSvgIconProps = SvgIconProps & { disabled?: boolean; tooltip?: string };

export type FactoryRoundedComponent = {
  color: string;
  Icon: React.ComponentType;
};

const withTooltip = (
  BaseComponent: React.ComponentType<CustomSvgIconProps & { disabled: boolean }>
): React.ComponentType<CustomSvgIconProps> => {
  function WithTooltip(props: CustomSvgIconProps) {
    const { tooltip = '', disabled = false, ...rest } = props;

    return (
      <Tooltip title={tooltip as string}>
        <BaseComponent {...rest} disabled={disabled} />
      </Tooltip>
    );
  }

  return WithTooltip;
};

const withRoundedIcon = (BaseComponent: React.ComponentType): React.ComponentType<CustomSvgIconProps> => {
  function WithRoundedIcon(props: CustomSvgIconProps) {
    const { disabled = false, tooltip = '' } = props;

    const RoundedIcon = withStyles({
      root: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: '100%',
        marginLeft: 10,
        fontSize: 25,
        color: disabled ? `${grey[400]} !important` : '',
        borderColor: disabled ? `${grey[400]} !important` : ''
      }
    })(BaseComponent);

    const EnhancedRoundedIcon = tooltip ? withTooltip(RoundedIcon) : RoundedIcon;

    return <EnhancedRoundedIcon {...props} />;
  }

  return WithRoundedIcon;
};

const factoryRoundedIconsStyles = (theme: string) => ({
  root: { color: theme, borderColor: theme }
});

export const factoryRoundedIconsComponent = ({
  color,
  Icon
}: FactoryRoundedComponent): React.ComponentType<CustomSvgIconProps> =>
  withStyles(factoryRoundedIconsStyles(color))(withRoundedIcon(Icon));
