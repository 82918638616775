import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

type Props = {
  title: string;
  open: boolean;
  close: () => void;
  remove: () => void;
};
export function AlertDelete({ title, open, close, remove }: Props) {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <Button onClick={close}>Não</Button>
        <Button onClick={remove}>Sim</Button>
      </DialogActions>
    </Dialog>
  );
}
