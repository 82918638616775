export function formatTextPhone(value: string | undefined) {
  if (value) {
    const sanitize = value.replace(/\D/g, '');

    const phoneChunk = (() => {
      if (sanitize.length > 10) {
        return `${sanitize?.substring(2, 7)}-${sanitize?.substring(7)}`;
      }

      return `${sanitize?.substring(2, 6)}-${sanitize?.substring(6)}`;
    })();

    return `(${sanitize?.substring(0, 2)}) ${phoneChunk}`;
  }

  return '';
}
