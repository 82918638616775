import { useEffect, useState, useCallback } from 'react';
import VendedoraDevolucaoService from 'services/devolucao/VendedoraDevolucaoService';
import { QueroVenderBatch, ApiListMeta, Pedido } from 'interfaces';

type Props = {
  codloja?: number;
  status_inicial?: string;
};

export function useFetchPedidoStatus(props: Props) {
  const { codloja, status_inicial } = props;
  const [tabIndexStatus, setTabIndexStatus] = useState<string>('');
  const [statusPage, setStatusPage] = useState<number>(1);
  const [statusPedidoData, setStatusPedidoData] = useState<Pedido[]>([]);
  const [statusBatchData, setStatusBatchData] = useState<QueroVenderBatch[]>([]);
  const [statusMeta, setStatusMeta] = useState<ApiListMeta | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (codloja && tabIndexStatus) {
      setIsLoading(true);
      VendedoraDevolucaoService.dashboard(codloja, tabIndexStatus, 4, statusPage)
        .then(response => {
          // Pensar em como melhorar esse código
          if (tabIndexStatus === 'batchs_aguardando_devolucao') {
            setStatusBatchData(response.data.data);
          } else {
            setStatusPedidoData(response.data.data);
          }

          setStatusMeta(response.data.meta);

          if (response.data.meta.last_page === 1) {
            setStatusPage(1);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [tabIndexStatus, codloja, statusPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (status_inicial) {
      setTabIndexStatus(status_inicial);
    }
  }, [status_inicial]);

  function getStatusData({ status, page = 1 }: { status?: string; page?: number }) {
    if (status) setTabIndexStatus(status);
    if (page) setStatusPage(page);
  }

  function refresh() {
    fetchData();
  }

  return {
    isLoading,
    statusMeta,
    statusPedidoData,
    statusBatchData,
    getStatusData,
    statusPage,
    refreshPedido: refresh
  };
}
