import React from 'react';
import NotificationsActive from '@mui/icons-material/NotificationsActive';

import headerDetailsStyle from './style';

type HeaderDetailsProps = {
  id: string;
  title: string | undefined;
  email: string | undefined;
  telcelular: string | undefined;
  fluxoNovo?: boolean | undefined;
};

function HeaderDetails(props: HeaderDetailsProps) {
  const { id, title, email, telcelular, fluxoNovo = undefined } = props;
  const classes = headerDetailsStyle();

  return (
    <div>
      <h1 className={classes.title}>{`Produtos #${id}`}</h1>

      {!fluxoNovo && fluxoNovo !== undefined && (
        <span className={classes.fluxoAntigo}>
          <NotificationsActive className={classes.iconFluxoAntigoAlerta} />
          Este batch respeitará o fluxo antigo
        </span>
      )}

      <h2>{title}</h2>
      <div>
        <strong>Email: </strong>
        {email}
      </div>
      <div>
        <strong>Telefone: </strong>
        {telcelular}
      </div>
    </div>
  );
}

export default HeaderDetails;
