import api from '../api';

type ConcluirModeracao = {
  recusado: boolean;
  moderacao_recusa_motivos?: string[];
  moderacao_recusa_motivo_complemento?: string;
};

type AprovarEtapas = {
  id: string;
  situacao: string;
  motivo: string;
}[];

const listar = (status: string, page = 1) => {
  return api.get(`produtos?status=${status}&per_page=30&page=${page}`);
};

const produto = (codproduto: string) => {
  return api.get(`produtos/${codproduto}?relacoes[]=atributos&relacoes[]=modelo`);
};

const etapas = (codproduto: string) => {
  return api.get(`produtos/${codproduto}/etapas`);
};

const recusa_motivos = () => {
  return api.get(`metadados/produtos/moderacao_recusa_motivos`);
};

const busca = (status: string, codloja: string) => {
  return api.get(`produtos?status=${status}&codloja=${codloja}`);
};

const aprovar_etapas = (codproduto: string, body: AprovarEtapas) => {
  return api.put(`produtos/${codproduto}/etapas`, { etapas: body });
};

const concluir_moderacao = (codproduto: string, body: ConcluirModeracao) => {
  return api.post(`produtos/${codproduto}/etapas/concluir_moderacao`, body);
};

export default { listar, produto, etapas, recusa_motivos, busca, aprovar_etapas, concluir_moderacao };
