import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function WarningBell(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.50033 7.86665H6.50033V4.33331H5.50033V7.86665ZM6.00003 9.66665C6.15578 9.66665 6.28644 9.61397 6.39199 9.50861C6.49755 9.40325 6.55033 9.27269 6.55033 9.11695C6.55033 8.96119 6.49765 8.83053 6.39229 8.72498C6.28693 8.61942 6.15637 8.56665 6.00063 8.56665C5.84487 8.56665 5.71421 8.61932 5.60866 8.72468C5.5031 8.83005 5.45033 8.9606 5.45033 9.11635C5.45033 9.2721 5.503 9.40276 5.60836 9.50831C5.71373 9.61387 5.84428 9.66665 6.00003 9.66665ZM0.666992 11.6666V10.6666H2.06699V5.56665C2.06699 4.63138 2.34199 3.80034 2.89199 3.07353C3.44199 2.34672 4.16699 1.88887 5.06699 1.69998V1.21665C5.06699 0.961091 5.15735 0.74998 5.33808 0.583313C5.51879 0.416646 5.73823 0.333313 5.99641 0.333313C6.25458 0.333313 6.47533 0.416646 6.65866 0.583313C6.84199 0.74998 6.93366 0.961091 6.93366 1.21665V1.69998C7.83366 1.88887 8.56144 2.34672 9.11699 3.07353C9.67255 3.80034 9.95033 4.63138 9.95033 5.56665V10.6666H11.3337V11.6666H0.666992ZM6.00033 13.6666C5.64477 13.6666 5.33366 13.5361 5.06699 13.275C4.80033 13.0139 4.66699 12.7 4.66699 12.3333H7.33366C7.33366 12.7 7.2031 13.0139 6.94199 13.275C6.68088 13.5361 6.36699 13.6666 6.00033 13.6666Z" />
    </SvgIcon>
  );
}
