import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CalculoPrecoService from 'services/calculo-preco/CalculoPrecoService';

import InputCurrency from 'components/form/input-currency';
import formatPrice from 'helpers/format-price';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { QueroVenderItemPreco } from 'interfaces';
import { Box } from '@mui/material';
import { ModalRecusarNegociacao } from './modal-recusar';

import style from './style';

type FormItemNegociacaoProps = {
  codquerovenderitem: number;
  negociacaoInicial: QueroVenderItemPreco | undefined;
  negociacaoAtual: QueroVenderItemPreco | undefined;
  doRefresh(): void;
  setExpanded(value: boolean): void;
  codcategoria: number | undefined;
};

function FormItemNegociacao(props: FormItemNegociacaoProps) {
  const classes = style();
  const { codquerovenderitem, negociacaoInicial, negociacaoAtual, doRefresh, setExpanded, codcategoria } = props;

  const [enviarSugestao, setEnviarSugestao] = useState(true);
  const [isNovaSugestaoVendedor, setNovaSugestaoVendedor] = useState<boolean>(negociacaoAtual?.status === 20);
  const [botaoRetomarNegociacao, setBotaoRetomarNegociacao] = useState<boolean>(negociacaoAtual?.status === 50);

  const [modalRecusar, setModalRecusar] = useState(false);

  const isRetomarNegociacao = negociacaoAtual?.status === 50;
  const sugestaoValorRef = useRef<HTMLInputElement>(null);
  const observacaoSugestaoValorRef = useRef<HTMLTextAreaElement>(null);

  const [valorVendedor, setValorVendedor] = useState<null | number>(null);
  const [valorParcelado, setValorParcelado] = useState<null | number>(null);
  const [valoraVista, setValoraVista] = useState<null | number>(null);

  /**
   * Aceita sugestão de valor enviado pelo vendedor
   */
  const aceitarValor = () => {
    Swal.fire({
      title: `Tem certeza que deseja aceitar a nova sugestão de ${formatPrice(Number(negociacaoAtual?.preco))} ?`,
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        try {
          const obj = {
            acao: 'aceitar'
          };

          QueroVenderItemService.sugestaoValor(codquerovenderitem, obj).then(() => {
            doRefresh();
            setExpanded(false);
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  /**
   * Envia Sugestão de Preço
   */
  const sugerirPreco = () => {
    // const preco = sugestaoValorRef.current?.value;
    const preco = valorVendedor;
    const precoaprazo = valorParcelado;
    const precoavista = valoraVista;
    const motivo = observacaoSugestaoValorRef.current?.value;

    Swal.fire({
      title: 'Tem certeza que deseja enviar uma nova sugestão de valor?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        try {
          const obj = {
            // preco: Number(preco?.toString().replace(/\D/g, '')) / 100,
            precoaprazo,
            precoavista,
            preco,
            motivo: motivo !== '' ? motivo : '',
            acao: 'sugerir'
          };

          QueroVenderItemService.sugestaoValor(codquerovenderitem, obj).then(() => {
            doRefresh();
            setExpanded(false);
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  /**
   * Recusa valor e envia mensagem para entrar em contato
   */
  const entrarEmContato = () => {
    Swal.fire({
      title: 'Tem certeza que deseja entrar em contato com o vendedor?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        const obj = { acao: 'contato' };

        QueroVenderItemService.sugestaoValor(codquerovenderitem, obj)
          .then(() => {
            doRefresh();
            setExpanded(false);
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  };

  /**
   * Recusar proposta em negociação
   */
  const recusarProposta = () => {
    Swal.fire({
      title: 'Tem certeza que deseja recusar a negociação?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(() => {
      setModalRecusar(true);
    });
  };

  /**
   * Verifica se se o campo de sugestão de novo valor foi preenchido para habilitar o botão de enviar sugestão
   */
  const handleSugerirNovoValor = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '' || event.target.value === '000') {
      setEnviarSugestao(true);
    } else {
      setEnviarSugestao(false);
    }

    calculoValores(parseInt(event.target.value, 10) / 100);
  };

  /**
   * Retomar negociação mostra o form de nova sugestão de valor
   */
  const handleRetomarNegociacao = () => {
    setNovaSugestaoVendedor(true);
    setBotaoRetomarNegociacao(false);
  };

  const calculoValores = (valor: number) => {
    if (codcategoria && valor?.toString()) {
      CalculoPrecoService.calcularPrecoFaixaAtual(codquerovenderitem, valor)
        .then(response => {
          setValorParcelado(response.data.preco.parcelado);
          setValorVendedor(response.data.split.vendedora);
          setValoraVista(response.data.preco.boleto);
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error?.response?.data?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
          });
        });
    }
  };

  return (
    <Paper elevation={3} className={classes.boxNegociacao}>
      <Typography variant="h5">
        Negociação{' '}
        {!isNovaSugestaoVendedor && (
          <span className={classes.labelAguardandoAprovacao}>(Aguardando aprovacão do vendedor)</span>
        )}
      </Typography>

      <Grid container direction="row" spacing={2} className={classes.formSpace}>
        <Grid item xs={3}>
          <TextField
            disabled
            label="Valor sugerido na primeira avaliação do E.U"
            className={classes.textInput}
            variant="outlined"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={formatPrice(negociacaoInicial ? negociacaoInicial?.precoavista : 0)}
            InputProps={{
              inputComponent: InputCurrency as React.FC
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {negociacaoInicial && (
          <>
            <Grid item>
              <div>
                <strong>Valor Vendedor</strong>
              </div>
              <div>{formatPrice(negociacaoInicial?.preco)}</div>
            </Grid>
            <Grid item>
              <div>
                <strong>Valor Parcelado</strong>
              </div>
              <div>{formatPrice(negociacaoInicial?.precoaprazo)}</div>
            </Grid>
          </>
        )}
      </Grid>

      <Grid container direction="row" spacing={2} className={classes.formSpace}>
        <Grid item xs={6}>
          {negociacaoInicial?.observacoes && (
            <TextField
              disabled
              label="Observações enviadas na primeira avaliação do E.U"
              className={classes.textInput}
              multiline
              fullWidth
              rows={2}
              defaultValue={negociacaoInicial?.observacoes}
              variant="outlined"
            />
          )}
        </Grid>
      </Grid>

      {botaoRetomarNegociacao && (
        <Grid container direction="row" spacing={2} className={classes.formSpace}>
          <Grid item xs={2}>
            <NovaSugestaoValor negociacaoAtual={negociacaoAtual} />

            <Button variant="contained" color="primary" size="medium" onClick={handleRetomarNegociacao}>
              Retomar negociação
            </Button>
          </Grid>
        </Grid>
      )}

      {isNovaSugestaoVendedor && (
        <>
          <Grid container direction="row" alignItems="flex-end" spacing={2}>
            <Grid item xs={6} className={classes.novaSugestao}>
              <NovaSugestaoValor negociacaoAtual={negociacaoAtual} />

              <Button
                variant="contained"
                color="primary"
                size="medium"
                className={classes.buttonAceitarProposta}
                onClick={aceitarValor}
              >
                Aceitar proposta
              </Button>

              {isRetomarNegociacao ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  className={classes.buttonAceitarProposta}
                  onClick={recusarProposta}
                >
                  Recusar Proposta
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  className={classes.buttonAceitarProposta}
                  onClick={entrarEmContato}
                >
                  Recusar e entrar em contato
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2} className={classes.formSpace}>
            <Grid item xs={3}>
              <TextField
                inputRef={sugestaoValorRef}
                name="sugestaoPreco"
                label="Sugerir novo valor a vista"
                className={classes.textInput}
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleSugerirNovoValor}
                InputProps={{
                  inputComponent: InputCurrency as React.FC
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item>
              {!!valorVendedor && (
                <>
                  <div>
                    <strong>Valor vendedora</strong>
                  </div>
                  <div>{formatPrice(valorVendedor)}</div>
                </>
              )}
            </Grid>
            <Grid item>
              {!!valorParcelado && (
                <>
                  <div>
                    <strong>Valor Parcelado</strong>
                  </div>
                  <div>{formatPrice(valorParcelado)}</div>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} className={classes.formSpace}>
            <Grid item xs={6}>
              <TextField
                inputRef={observacaoSugestaoValorRef}
                name="observacao_nova_sugestao"
                label="Observação"
                className={classes.textInput}
                variant="outlined"
                multiline
                fullWidth
                rows={2}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={2} className={classes.formSpace}>
            <Grid item xs={6} className={classes.wrapEnviar}>
              <Button variant="contained" color="primary" size="small" onClick={sugerirPreco} disabled={enviarSugestao}>
                Enviar sugestão
              </Button>
            </Grid>
          </Grid>

          {modalRecusar && (
            <ModalRecusarNegociacao
              codquerovenderitem={codquerovenderitem}
              doRefresh={doRefresh}
              onClose={() => setModalRecusar(false)}
              setExpanded={value => setExpanded(value)}
            />
          )}
        </>
      )}
    </Paper>
  );
}

export default FormItemNegociacao;

type NovaSugestaoValorProps = {
  negociacaoAtual: QueroVenderItemPreco | undefined;
};

function NovaSugestaoValor(props: NovaSugestaoValorProps) {
  const classes = style();
  const { negociacaoAtual } = props;

  return (
    <>
      <Typography variant="h6">
        Nova sugestão de valor:
        <br />{' '}
        <strong className={classes.precoSugestao}>
          {formatPrice(negociacaoAtual ? negociacaoAtual?.precoavista : 0)}
        </strong>
        <Grid container spacing={3}>
          {negociacaoAtual && (
            <>
              <Grid item>
                <div>
                  <strong>Valor Vendedor</strong>
                </div>
                <div>{formatPrice(negociacaoAtual?.preco)}</div>
              </Grid>
              <Grid item>
                <div>
                  <strong>Valor Parcelado</strong>
                </div>
                <div>{formatPrice(negociacaoAtual?.precoaprazo)}</div>
              </Grid>
            </>
          )}
        </Grid>
      </Typography>

      <Box margin="0.5rem 0 1rem 0">
        <TextField
          disabled
          label="Observações da sugestão"
          className={classes.textInput}
          multiline
          fullWidth
          rows={10}
          defaultValue={negociacaoAtual?.observacoes !== null ? negociacaoAtual?.observacoes : ''}
          variant="outlined"
        />
      </Box>
    </>
  );
}
