import { Box, Button, Checkbox, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import TemplateBase from 'components/template-base/template';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloudUpload } from '@mui/icons-material';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { ITypeUpload } from 'services/marketing/types';
import { MarketingService } from 'services/marketing/MarketingService';
import { useLoading } from 'context/loading';
import Swal from 'sweetalert2';

import { VisuallyHiddenInput } from '../components/visually-hidden-input';
import { orderDefault, templateHeaderOptions } from '../constants';

const schema = yup
  .object({
    nome: yup.string().required('Título é obrigatório'),
    descricao: yup.string().optional(),
    templateheader: yup.string().required('Template do header é obrigatório'),
    mostrarsidebar: yup.boolean().required(),
    ordenacao: yup.string().required(),
    exibirtrends: yup.boolean().required(),
    exibirdestaquetrends: yup.boolean().required(),
    exibirhome: yup.boolean().required(),
    ordemexibicao: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      }),
    tagtitle: yup.string().optional(),
    metadescricao: yup.string().optional(),
    nofollow: yup.boolean().required(),
    noindex: yup.boolean().required(),
    imagemcapa: yup.string().required('Imagem de capa é obrigatório'),
    imagem: yup.string().optional()
  })
  .required({});

type FormData = yup.InferType<typeof schema>;

function CriarCampanhas() {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors }
  } = useForm<FormData>({ resolver: yupResolver(schema) });
  const [imageHeader, setImageHeader] = useState<File>();
  const [imageThumbs, setImageThumbs] = useState<File>();

  const handleCreate = async (data: FormData) => {
    setLoading(true);
    const { codcampanha } = await MarketingService.criarCampanha({
      descricao: data.descricao,
      exibirdestaquetrends: data.exibirdestaquetrends,
      exibirhome: data.exibirhome,
      exibirtrends: data.exibirtrends,
      imagem: data.imagem,
      imagemcapa: data.imagemcapa,
      mostrarsidebar: data.mostrarsidebar,
      nome: data.nome,
      ordemexibicao: data.ordemexibicao,
      ordenacao: data.ordenacao === 'default' ? '' : data.ordenacao,
      seorobots: {
        noindex: data.noindex,
        nofollow: data.nofollow
      },
      status: 'A',
      templateheader: data.templateheader,
      metadescricao: data.metadescricao,
      tagtitle: data.tagtitle
    });
    setLoading(false);
    Swal.fire('Campanha criada!', '', 'success');
    navigate(`/painel-marketing/campanhas/editar/${codcampanha}/1`);
  };

  const urlImageHeader = useMemo(() => (imageHeader ? URL.createObjectURL(imageHeader) : ''), [imageHeader]);
  const urlImageThumbs = useMemo(() => (imageThumbs ? URL.createObjectURL(imageThumbs) : ''), [imageThumbs]);

  const uploadImage = async (type: ITypeUpload, image: File) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('uso', type);
    const { filename } = await MarketingService.uploadImage(formData);

    if (type === 'imagem') {
      setValue('imagem', filename, { shouldValidate: true });
    } else {
      setValue('imagemcapa', filename, { shouldValidate: true });
    }
  };

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>, type: ITypeUpload) => {
    const image = event.target.files?.[0];
    // eslint-disable-next-line no-useless-return
    if (!image) return;

    if (type === 'imagem') {
      setImageHeader(image);
    } else {
      setImageThumbs(image);
    }

    try {
      await uploadImage(type, image);
    } catch {
      if (type === 'imagem') {
        setImageHeader(undefined);
      } else {
        setImageThumbs(undefined);
      }
    }
  };

  return (
    <TemplateBase>
      <Box display="flex" flexDirection="column" mb={5} minHeight="100vh">
        <h1 style={{ marginBottom: 0 }}>Criar campanha</h1>
        <Box mt={2} gap={2} display="flex" flexDirection="column">
          <TextFieldHookForms
            {...register('nome')}
            error={!!errors.nome}
            helperText={errors.nome?.message}
            label="Título"
            placeholder="Título"
            variant="outlined"
            fullWidth
          />
          <TextFieldHookForms
            {...register('descricao')}
            variant="outlined"
            placeholder="Texto do header"
            label="Texto do header"
            fullWidth
            multiline
            rows={4}
          />
          <Box display="flex" flexDirection="column">
            <p>Imagem do header</p>
            {urlImageHeader && <img width={202} height={176} src={urlImageHeader} alt="Imagem do header" />}
            <Button
              style={{ width: 300 }}
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Selecione um arquivo
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={event => {
                  handleUploadImage(event, 'imagem');
                }}
              />
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" mb={1}>
            <p>Capa thumbs (1024 X 768)</p>
            {urlImageThumbs && <img width={202} height={176} src={urlImageThumbs} alt="Capa thumbs" />}
            <Button
              style={{ width: 300 }}
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Selecione um arquivo
              <VisuallyHiddenInput
                type="file"
                onChange={event => {
                  handleUploadImage(event, 'imagemcapa');
                }}
              />
            </Button>
            {errors.imagemcapa && <p style={{ color: '#d32f2f' }}>{errors.imagemcapa.message}</p>}
          </Box>
          <TextFieldHookForms
            {...register('templateheader')}
            error={!!errors.templateheader}
            helperText={errors.templateheader?.message}
            select
            label="Template do header"
          >
            {templateHeaderOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldHookForms>
          <Box>
            <p>Exibição sidebar de filtros?</p>
            <FormGroup row>
              <FormControlLabel {...register('mostrarsidebar')} control={<Checkbox />} label="Sim" />
            </FormGroup>
          </Box>
          <TextFieldHookForms
            {...register('ordenacao')}
            defaultValue={orderDefault[0].value}
            select
            label="Ordenação padrão"
          >
            {orderDefault.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldHookForms>

          <Box>
            <p>Exibição</p>
            <FormGroup row>
              <FormControlLabel {...register('exibirtrends')} control={<Checkbox />} label="Página de trends" />
              <FormControlLabel
                {...register('exibirdestaquetrends')}
                control={<Checkbox />}
                label="Destaque na página de trends"
              />
              <FormControlLabel {...register('exibirhome')} control={<Checkbox />} label="Carrousel da home" />
            </FormGroup>
          </Box>
          <TextFieldHookForms
            slotProps={{ htmlInput: { min: 1 } }}
            type="number"
            {...register('ordemexibicao')}
            label="Ordem de exibição carrousel"
          />

          <TextFieldHookForms
            {...register('tagtitle')}
            variant="outlined"
            placeholder="SEO: Page Title"
            label="SEO: Page Title"
            fullWidth
          />
          <TextFieldHookForms
            {...register('metadescricao')}
            variant="outlined"
            placeholder="SEO: Meta Descriptions"
            label="SEO: Meta Descriptions"
            fullWidth
            multiline
            rows={4}
          />
          <Box>
            <p>SEO: Meta Robots</p>
            <FormGroup row>
              <FormControlLabel {...register('nofollow')} control={<Checkbox />} label="nofollow" />
              <FormControlLabel {...register('noindex')} control={<Checkbox />} label="noindex" />
            </FormGroup>
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <Button
              style={{ height: 36.5 }}
              variant="outlined"
              onClick={() => {
                navigate('/painel-marketing/campanhas');
              }}
            >
              Voltar
            </Button>
            <Button style={{ height: 36.5 }} variant="contained" color="success" onClick={handleSubmit(handleCreate)}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Box>
    </TemplateBase>
  );
}

export default CriarCampanhas;
