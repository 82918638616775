import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { copyToClipboard } from 'helpers/copy-clipboard';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface GlobalNotificationData {
  CopyToClipBoard: (text: string) => void;
  handleClick: (message: string) => void;
}

const GlobalNotificationContext = createContext<GlobalNotificationData>({} as GlobalNotificationData);

export function GlobalNotificationProvider({ children }: { children: React.ReactElement }) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<string>('');

  const handleClick = useCallback((m: string) => {
    setOpen(true);
    setMessage(m);
  }, []);

  const handleClose = (event: Event | React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseButton = () => setOpen(false);

  const CopyToClipBoard = useCallback(
    (text: string) => [
      copyToClipboard({
        text,
        cbSuccess: () => {
          handleClick('Copiado para o clipboard!');
        },
        cbError: e => {
          handleClick(e.message);
        }
      })
    ],
    [handleClick]
  );

  const values = useMemo(() => ({ CopyToClipBoard, handleClick }), [CopyToClipBoard, handleClick]);
  return (
    <GlobalNotificationContext.Provider value={values}>
      {children}

      <Snackbar
        open={open}
        autoHideDuration={2000}
        sx={{
          zIndex: 99999
        }}
        onClose={handleClose}
        message={message}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseButton}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </GlobalNotificationContext.Provider>
  );
}

export function useGlobalNotification() {
  const context = useContext(GlobalNotificationContext);

  if (!context) {
    throw new Error('useLoading deve usado dentro de um LoadingProvider');
  }

  return context;
}
