import React, { useMemo, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CampoCategorias } from 'interfaces';
import AutoCompleteService from 'services/autocomplete/AutoCompleteService';
import { UseFormRegister } from 'react-hook-form';
import { TextFieldHookForms } from '../text-field-hook-forms';
import style from './style';

// type Atributos = Partial<Pick<QueroVenderItem, 'atributos'>>;
type AutocompleteCategoriasProps = {
  register?: UseFormRegister<any>; // eslint-disable-line
  getCategoria?: (categoria: CampoCategorias | null) => void;
  disabled?: boolean;
  categoria?: CampoCategorias;
  onChange?: () => void;
  categoriaPai?: boolean;
};

const AutocompleteCategorias = forwardRef(function AutocompleteCategorias(
  props: AutocompleteCategoriasProps,
  externalRef
) {
  const classes = style();
  const {
    categoria = undefined,
    register = () => ({
      ref: null
    }),
    categoriaPai = false,
    getCategoria = () => undefined,
    disabled = false,
    onChange = () => undefined
  } = props;

  const [campoCategoria, setCampoCategoria] = useState(false);
  const [categoriaOptions, setCategoriaOption] = useState<CampoCategorias[]>([]);
  const [campoCategoriaValue, setCampoCategoriaValue] = useState<CampoCategorias | null | undefined>(categoria);
  const [inputValue, setInputValue] = useState<string>('');

  /**
   * Permite que essas funcoes sejam acessiveis de fora do componente pelo atributo ref e o hook userRef
   */
  useImperativeHandle(externalRef, () => ({
    clearAutoComplete: () => {
      setCampoCategoriaValue(null);
      setCampoCategoria(false);
      setInputValue('');
      getCategoria(null);
    }
  }));

  /**
   * Autocomplete de categoria
   */
  const handleBuscaCategoria = async (event: React.ChangeEvent<unknown>, value: string) => {
    if (event && event.type === 'change') {
      setInputValue(value);
      AutoCompleteService.categorias(value, categoriaPai)?.then(({ data: { data } }) => {
        setCategoriaOption(data);
        setCampoCategoria(true);
      });
    }
  };

  const { ref, ...restProps } = useMemo(() => {
    if (campoCategoriaValue?.codcategoria) {
      return register?.('codcategoria', {
        setValueAs() {
          return campoCategoriaValue?.codcategoria;
        }
      });
    }

    return {
      ref: null
    };
  }, [campoCategoriaValue, register]);

  useEffect(() => {
    if (categoria) {
      setCampoCategoriaValue(categoria);
      setInputValue(categoria?.label_autocomplete ?? categoria?.nome ?? '');
    }
  }, [categoria]);

  return (
    <Autocomplete
      open={campoCategoria}
      onInputChange={handleBuscaCategoria}
      value={campoCategoriaValue}
      onChange={(e, newValue: CampoCategorias | null) => {
        onChange?.();
        setCampoCategoriaValue(newValue);
        getCategoria?.(newValue);
        setInputValue(newValue?.label_autocomplete ?? newValue?.nome ?? '');
      }}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option?.label_autocomplete === value?.label_autocomplete}
      getOptionLabel={option => option?.label_autocomplete ?? option?.nome ?? ''}
      onClose={() => {
        setCampoCategoria(false);
      }}
      options={categoriaOptions}
      autoComplete
      noOptionsText="No options"
      renderInput={params => (
        <TextFieldHookForms
          {...params}
          label="Categoria"
          className={classes.textInput}
          size="small"
          {...restProps}
          ref={ref}
          variant="outlined"
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              endAdornment: params.InputProps.endAdornment,
              value: inputValue
            }
          }}
        />
      )}
    />
  );
});

export default AutocompleteCategorias;
