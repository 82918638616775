import { Filter } from './Filter';

export class Criteria {
  filters: Filter[] = [];

  variables: string[] = [];

  add(variable: string, value: string) {
    if (this.variables.includes(variable)) {
      for (let i = 0; i < this.filters.length; i += 1) {
        if (this.filters[i].variable === variable) {
          this.filters[i] = new Filter(variable, value);
        }
      }
    } else {
      this.filters.push(new Filter(variable, value));
      this.variables.push(variable);
    }
  }

  remove(variable: string) {
    if (this.variables.includes(variable)) {
      this.filters = this.filters.filter(filter => filter.variable !== variable);
      this.variables = this.variables.filter(_variable => _variable !== variable);
    }

    return false;
  }

  getQuery() {
    return this.filters.map(element => element.query).join('&');
  }
}
