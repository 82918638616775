import React, { useCallback, useEffect, useState, useMemo } from 'react';
import TemplateBase from 'components/template-base/template';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import { BadgeVendedorVip } from 'components/custom-badge/badge-vendedor-vip';
import PrintIcon from '@mui/icons-material/Print';
import CheckingService from 'services/checking';
import { AccordionCollapseProvider } from 'context/accordion-collapse';
import { Pedido, Produto as ITProduto } from 'interfaces';
import Swal from 'sweetalert2';
import { BadgePortadorRetirada } from 'components/custom-badge/badge-portador-retirada';
import { formatTextPhone } from 'helpers/format-text-phone';
import ModalEditarEndereco from './modal-editar-endereco';

import Produto from './produto';

export function Details({ trailingPath }: { trailingPath: string }) {
  document.title = 'Expedição - Checking';

  const [pedido, setPedido] = useState<Pedido | null>(null);
  const [urlEtiqueta, setUrlEtiqueta] = useState<string | null>(null);
  const [urlDeclaracao, setUrlDeclaracao] = useState<string | null>(null);
  const [modalEditarEndereco, setModalEditarEndereco] = useState<boolean>(false);
  const { codigo_pedido } = useParams() as { codigo_pedido: string };

  const navigate = useNavigate();

  const path = `${trailingPath}`;

  const [pedidoFinalizado, setPedidosFinalizados] = useState<boolean>(false);

  const [semProdutosLocalizados, setSemProdutosLocalizados] = useState<boolean>(false);

  const initialCollapse = useMemo(() => {
    return pedidoFinalizado ? null : 0;
  }, [pedidoFinalizado]);

  // Chama serviço que retorna url da etiqueta para ser impressa
  const imprimirEtiqueta = useCallback(
    async (action?: 'both') => {
      return CheckingService.imprimir_etiqueta(codigo_pedido).then(response => {
        if (action === 'both') {
          window.open(response.data.browser);
        }

        setUrlEtiqueta(response.data.browser);
      });
    },
    [codigo_pedido]
  );

  const imprimirDeclaracao = useCallback(
    async (action?: 'both') => {
      return CheckingService.imprimir_declaracao(codigo_pedido).then(response => {
        if (action === 'both') {
          window.open(response.data.pdf);
        }

        setUrlDeclaracao(response.data.pdf);
      });
    },
    [codigo_pedido]
  );

  const handleRefresh = () => {
    CheckingService.pedido(codigo_pedido).then(response => {
      setPedido(response.data);

      fetchPedido(pedidoSemFinalizar => {
        if (!pedidoSemFinalizar) {
          setPedidosFinalizados(true);
          imprimirEtiqueta('both');
          imprimirDeclaracao('both');
        }
      });
    });
  };

  // Finalizar pedido quando todos os pedidos estiverem conferidos
  const handleFinalizarPedido = () => {
    CheckingService.finalizar(codigo_pedido).then(() => {
      Swal.fire('Pedido conferido', '', 'success').then(() => {
        navigate(path);
      });
    });
  };

  const hasProdutosLocalizados = (produtos: ITProduto[]) => {
    return produtos.some(produto => produto.localizado === 'LOCALIZADO');
  };

  const fetchPedido = useCallback(
    (cb: (param: boolean) => void) => {
      CheckingService.pedido(codigo_pedido).then(response => {
        setPedido(response.data);

        if (hasProdutosLocalizados(response.data.produtos)) {
          if (response?.data?.produtos) {
            const pedidoSemFinalizar = response.data.produtos
              .filter((produto: ITProduto) => produto.localizado === 'LOCALIZADO')
              .some((produto: ITProduto) => !produto.conferido && !produto.conferido_observacoes);

            cb?.(pedidoSemFinalizar);
          }
        } else {
          setSemProdutosLocalizados(true);
        }
      });
    },
    [codigo_pedido]
  );

  const handleOpenModalEditar = () => {
    setModalEditarEndereco(true);
  };

  const handleCloseModalEditar = () => {
    setModalEditarEndereco(false);
  };

  const handleRefreshEndereco = () => {
    if (pedido) {
      CheckingService.pedido(pedido.codigo).then(response => {
        setPedido(response.data);
      });
    }
  };

  const isVip = useMemo(() => {
    return pedido?.cliente?.prioridade === 1 && pedido?.devolucao;
  }, [pedido]);

  // Tenta iniciar o pedido e se der erro retorna para a listagem
  useEffect(() => {
    CheckingService.iniciar(codigo_pedido)
      .then(() => {
        fetchPedido(pedidoSemFinalizar => {
          if (!pedidoSemFinalizar) {
            setPedidosFinalizados(true);
            imprimirEtiqueta();
            imprimirDeclaracao();
          }
        });
      })
      .catch(() => {
        navigate(path);
      });
  }, [codigo_pedido, navigate, imprimirEtiqueta, imprimirDeclaracao, fetchPedido, path]);

  return (
    <TemplateBase>
      {pedido && (
        <>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Box display="flex" width="100%" justifyContent="space-between">
                <div>
                  <h1>Pedido #{codigo_pedido}</h1>
                  <h2>{format(new Date(pedido?.data), 'dd/MM/yyyy HH:mm:ss')}</h2>
                </div>

                <Box mt={2}>
                  <Box mr={1} display="inline-block">
                    <Button variant="contained" onClick={() => navigate(path)}>
                      Voltar
                    </Button>
                  </Box>
                  <Box display="inline-block">
                    {!semProdutosLocalizados && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleFinalizarPedido}
                        disabled={!pedidoFinalizado}
                        endIcon={<SendIcon />}
                      >
                        Finalizar Pedido
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box display="inline-flex" alignItems="center" mb={2}>
                <PersonIcon />
                <Box ml={1}>
                  <strong>Informações do cliente</strong>
                </Box>
              </Box>
              <div>
                <strong>Email: </strong>
                {pedido.cliente.nome}
              </div>
              <div>
                <strong>Telefone: </strong>
                {formatTextPhone(pedido.cliente.telcelular)}
              </div>

              <Box display="inline-flex">
                {pedido.data_agendamento_retirada && (
                  <BadgePortadorRetirada date={format(new Date(pedido.data_agendamento_retirada), 'dd/MM/yyyy')} />
                )}
                {!isVip && <BadgeVendedorVip />}
              </Box>
            </Grid>
          </Grid>
          <Box display="inline-flex" mt={2} alignItems="center" width="100%" justifyContent="space-between">
            <Box>
              <Button variant="contained" color="primary" onClick={handleOpenModalEditar}>
                Atualizar Endereço
              </Button>
            </Box>
            <Box display="inline-flex">
              {urlEtiqueta && (
                <Box>
                  <Button
                    component="a"
                    href={urlEtiqueta}
                    target="_blank"
                    endIcon={<PrintIcon />}
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Reemprimir etiqueta
                  </Button>
                </Box>
              )}
              {urlDeclaracao && (
                <Box ml={1}>
                  <Button
                    component="a"
                    href={urlDeclaracao}
                    target="_blank"
                    endIcon={<PrintIcon />}
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Reemprimir Declaração de Conteúdo
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          {semProdutosLocalizados && (
            <Box display="flex" justifyContent="center" p={10}>
              Não há produtos localizados neste pedido
            </Box>
          )}
          <AccordionCollapseProvider initialCollapse={initialCollapse}>
            {pedido.produtos.map((produto, index) => {
              return (
                produto.localizado !== 'NÃO LOCALIZADO' && (
                  <Produto
                    key={produto.codproduto}
                    index={index}
                    devolucao={pedido.devolucao}
                    {...produto}
                    codpedido={pedido.codpedido}
                    refresh={handleRefresh}
                  />
                )
              );
            })}
          </AccordionCollapseProvider>
        </>
      )}
      {modalEditarEndereco && (
        <ModalEditarEndereco pedido={pedido} onClose={handleCloseModalEditar} onRefresh={handleRefreshEndereco} />
      )}
    </TemplateBase>
  );
}
