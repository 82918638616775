import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import formatPrice from 'helpers/format-price';
import { Produto } from 'interfaces';
import { grey } from '@mui/material/colors';
import devolucaoStyles from '../styles';

type Props = {
  produto: Pick<Produto, 'custo_devolucao' | 'nome' | 'codigo' | 'codproduto' | 'imagens' | 'vendedora' | 'repasse'>;
  vip: boolean;
  handleRemoveProduto: (codproduto: number) => void;
  produtoIndex: (i: number, state: { cobrar_taxa: boolean }) => void;
  index: number;
};

export function ProdutoRow(props: Props) {
  const classes = devolucaoStyles();

  const { produto, vip, handleRemoveProduto, produtoIndex, index } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    produtoIndex(index, { cobrar_taxa: value });
  };

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center" py={2} textAlign="left">
          <Avatar alt="" src={produto?.imagens?.[0]?.thumbnail} className={classes.avatarImageSmall} />
          <Box pl={2}>
            <Box color={grey[600]}>{produto.codigo}</Box>

            <Box py={1} lineHeight={1.2} fontWeight={700}>
              {produto.nome}
            </Box>

            <Box color={grey[600]}>
              <span>Valor a receber: {formatPrice(produto.repasse.vendedor)}</span>{' '}
              <Box component="span" ml={2}>
                Custo dev: {formatPrice(produto.custo_devolucao)}
              </Box>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">08/01/2023</TableCell>
      <TableCell align="center">
        <Checkbox color="primary" defaultChecked={!vip} disabled={vip} onChange={handleChange} />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleRemoveProduto(produto.codproduto)} size="large">
          <DeleteIcon color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
