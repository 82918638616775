import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

import LoadingStyles from './styles';

interface Props {
  open: boolean;
}

function Loading(props: Props) {
  const style = LoadingStyles();

  const { open } = props;

  return (
    <Backdrop className={style.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loading;
