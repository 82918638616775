import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Paper, Button, Card, CardContent, Grid, TextField, Box, Chip } from '@mui/material';
import TemplateBase from 'components/template-base/template';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loading from 'components/loading';

import EtiquetasDevolucaoService from 'services/etiquetas-devolucao/EtiquetasDevolucaoService';

import { Acervo, Usuario as UsuarioInterface } from 'interfaces';
import EtiquetasDevolucaoStyle from './style';

// interfaces
interface Loja {
  codloja: number;
  codigo: string;
  nome: string;
  sobrenome: string;
}

interface DevolucaoGenerica {
  acervo?: Acervo;
}

interface ItemDevolucao {
  created_at: string;
  id: number;
  loja: Loja;
  quantidade_pecas: number;
  numero_devolucao: number;
  cancelada?: null | number;
  devolvida: boolean;
  devolvida_data?: null | string;
  devolvida_usuario?: UsuarioInterface;
  data_cancelamento?: null | string;
  motivo_cancelamento?: null | string;
  observacoes?: null | string;
  usuario: UsuarioInterface;
  produtos: DevolucaoGenerica[];
}

// component
function EtiquetasDevolucao() {
  document.title = 'Devolução';

  const classes = EtiquetasDevolucaoStyle();
  const [loja, setLoja] = useState<Loja>();
  const [buscaVendedor, setBuscaVendedor] = useState<string>('');
  const [quantidadeInput, setQuantidadeInput] = useState<number>();
  const [observacoesInput, setObservacoesInput] = useState<string>('');
  const [listaDevolucoes, setListaDevolucoes] = useState<ItemDevolucao[]>([]);
  const [loadingFull, setLoadingFull] = useState<boolean>(false);

  /**
   * Busca um único vendedor/loja no EtiquetasDevolucaoService
   */
  const handleSubmitBuscaVendedor = () => {
    setLoadingFull(true);
    EtiquetasDevolucaoService.getLojaByCodigo(buscaVendedor)
      .then(response => {
        let lojaEncontrada = false;
        if ('loja' in response.data) {
          const lojaAPI: Loja = response.data.loja;
          if (lojaAPI) {
            lojaEncontrada = true;
            setLoja(lojaAPI);
            listaProdutosVendedor(lojaAPI.codigo);
          }
        }

        if (!lojaEncontrada) {
          setLoadingFull(false);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `Vendedor/loja de código ${buscaVendedor} não encontrados.`
          });
        }
      })
      .catch(() => {
        setLoadingFull(false);
      });
  };

  /**
   *  Gera uma etiqueta devolucao baseado em um codloja
   *  Necessita um vendedor carregado no objeto 'loja'
   *  Lê os parametros definidos nos inputs do formulário
   */
  const handleSubmitCriarEtiquetaDevolucao = () => {
    if (loja && (quantidadeInput as unknown as number) > 0 && observacoesInput) {
      setLoadingFull(true);
      EtiquetasDevolucaoService.gerarEtiqueta(loja.codigo, quantidadeInput as unknown as number, observacoesInput).then(
        response => {
          if ('url' in response.data) {
            abreJanelaComEtiqueta(response.data.url);
          } else {
            setLoadingFull(false);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erro ao gerar Etiqueta'
            });
          }
          resetCriarEtiquetaForm();
          listaProdutosVendedor(loja.codigo);
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao gerar Etiqueta',
        text: 'Campos não preenchidos corretamente.'
      });
    }
  };

  /**
   * Reseta o form de vendedor
   */
  const resetCriarEtiquetaForm = () => {
    setObservacoesInput('');
    setQuantidadeInput(0);
  };

  /**
   * Abre uma janela com a url de gerar etiqueta fornecida
   *
   * @param url string - aceita uma url onde será gerado o SVG da etiqueta
   *
   */
  const abreJanelaComEtiqueta = (url: string) => {
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.setAttribute('href', url);
    a.click();
  };

  /**
   * Carrega a lista de devoluções para o vendedor
   * Esta função é invocada a todo evento de mudança na lista de devoluções para a loja carregada
   *
   * @param codigo string - código do vendedor/loja
   *
   */
  const listaProdutosVendedor = (codigo: string) => {
    setLoadingFull(true);
    EtiquetasDevolucaoService.getDevolucoes(codigo).then(response => {
      setLoadingFull(false);
      setListaDevolucoes(response.data.data.sort((a: ItemDevolucao, b: ItemDevolucao) => b.id - a.id));
    });
  };

  /**
   * Bate na API para resgatar uma etiqueta para uma devolução gerada anteriormente
   *
   * @param id number - id da devolução
   *
   */
  const handleResgatarEtiqueta = (id: number) => {
    EtiquetasDevolucaoService.resgatarEtiqueta(id).then(response => {
      abreJanelaComEtiqueta(response.data.url);
    });
  };

  /**
   * Bate na API para marcar tudo como devolvido
   */
  const handleMarcarDevolvido = (id: number) => {
    Swal.fire({
      title: 'Deseja marcar como devolvido?',
      showCancelButton: true,
      confirmButtonText: `Marcar como devolvido`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (!result.isConfirmed) {
        return false;
      }

      // eslint-disable-next-line
      setLoadingFull(true);

      EtiquetasDevolucaoService.devolverTodos(id)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Marcada como devolvida com sucesso.'
          });

          if (loja) {
            listaProdutosVendedor(loja.codigo);
          }
        })
        .catch(() => {
          setLoadingFull(false);
        });

      return true;
    });
  };

  /**
   * Bate na API para cancelar uma etiqueta
   *
   * @param id number - id da devolução
   *
   */
  const handleCancelarEtiqueta = (id: number) => {
    const modalPularHtml = () => {
      return `<div>
          <div>Motivo do cancelamento (obrigatório):</div>
          <div><textarea style="width: 100%; height: 200px;" id="motivo_cancelamento" placeholder="Digite o motivo do cancelamento"></textarea></div>
        </div>`;
    };
    Swal.fire({
      title: 'Deseja cancelar essa devolução?',
      html: modalPularHtml(),
      showCancelButton: true,
      confirmButtonText: `Cancelar devolução`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        // eslint-disable-next-line
        const motivo_cancelamento = document.getElementById('motivo_cancelamento') as HTMLInputElement;
        if (motivo_cancelamento) {
          if (motivo_cancelamento.value) {
            setLoadingFull(true);
            EtiquetasDevolucaoService.cancelarEtiqueta(id, motivo_cancelamento.value)
              .then(() => {
                Swal.fire({
                  icon: 'success',
                  title: 'Devolução cancelada com sucesso.'
                });
                if (loja) {
                  listaProdutosVendedor(loja.codigo);
                }
              })
              .catch(() => {
                setLoadingFull(false);
              });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'O campo motivo do cancelamento é obrigatório'
            });
          }
        }
      }
    });
  };

  const cardClass = (item: ItemDevolucao) => {
    if (item.cancelada) {
      return classes.cardCancelado;
    }
    if (item.devolvida) {
      return classes.cardDevolvido;
    }

    return '';
  };

  return (
    <TemplateBase>
      <h1>Etiquetas de Devolução</h1>

      <Paper elevation={1} className={classes.paperBusca}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h3>Localizar vendedor</h3>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="buscaVendedor"
                  label="Código do vendedor"
                  placeholder="Código do vendedor"
                  variant="outlined"
                  size="small"
                  onChange={e => {
                    setBuscaVendedor(e.target.value.replace(/[^0-9a-z]/gi, ''));
                  }}
                  value={buscaVendedor}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitBuscaVendedor}
                  className={classes.submitButton}
                >
                  Localizar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      {loja ? (
        <Paper elevation={1} className={classes.paperBusca}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item>
                  <h2 className={classes.vendedorTitle}>
                    Vendedor: {loja.nome} {loja.sobrenome} - {loja.codigo}
                  </h2>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={5}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        name="quantidade"
                        label="Quantidade de produtos"
                        placeholder="Quantidade de produtos"
                        variant="outlined"
                        size="small"
                        value={quantidadeInput}
                        type="number"
                        onChange={e => {
                          const n: number = parseInt(e.target.value, 10);
                          setQuantidadeInput(n > -1 ? n : 0);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="observacoes"
                        label="Observações"
                        placeholder="Observações"
                        variant="outlined"
                        size="small"
                        value={observacoesInput}
                        onChange={e => {
                          setObservacoesInput(e.target.value);
                        }}
                        multiline
                        rows={5}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container xs={12} justifyContent="flex-end" alignItems="flex-start">
                        <Button
                          type="button"
                          variant="contained"
                          onClick={handleSubmitCriarEtiquetaDevolucao}
                          className={classes.btnGerarDevolucao}
                          startIcon={<LocalOfferIcon />}
                        >
                          GERAR NOVO VOLUME DE DEVOLUÇÃO
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      ) : (
        ''
      )}

      {listaDevolucoes.length > 0 &&
        listaDevolucoes.map(item => (
          <Paper className={classes.paperListagem}>
            <Card className={cardClass(item)}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={9} md={9}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        {item.cancelada === 1 && (
                          <>
                            <Chip label="Cancelada" color="secondary" />
                            <br />
                          </>
                        )}
                        <b>Devolução:</b> #{item.numero_devolucao}
                        <br />
                        <b>Vendedor:</b> {item.loja.nome} {item.loja.sobrenome} - {item.loja.codigo}
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Box textAlign="right">
                          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')} <br />
                          <b>Criado por:</b> {item?.usuario?.nome}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <b>Quantidade de produtos:</b> {item.quantidade_pecas}
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.textoObservacoes}>
                            <b>Observações:</b>
                            <br />
                            {item.observacoes}
                          </Grid>
                        </Grid>
                      </Grid>
                      {item?.produtos[0]?.acervo ? (
                        <Grid item xs={12} sm={12}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <b>CD:</b> {item.produtos[0].acervo?.centro_distribuicao}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <b>Andar:</b> {item.produtos[0].acervo?.andar}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <b>Posição:</b> {item.produtos[0].acervo?.posicao}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <b>Altura:</b> {item.produtos[0].acervo?.altura}
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <b>Caixa:</b> {item.produtos[0].acervo?.caixa}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}

                      {item.cancelada === 1 ? (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12}>
                              <b>Data do cancelamento: </b>
                              {format(new Date(item.data_cancelamento as string), 'dd/MM/yyyy HH:mm')}
                              <br />
                              <b>Motivo do cancelamento:</b> {item.motivo_cancelamento}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {item.devolvida ? (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12}>
                              <b>Data da devolução: </b>
                              {item.devolvida_data
                                ? format(new Date(item.devolvida_data as string), 'dd/MM/yyyy HH:mm')
                                : ''}
                              <br />
                              <b>Devolvido por:</b>{' '}
                              {item.devolvida_usuario ? item.devolvida_usuario.nome : 'Usuário não indicado'}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    {!item.cancelada && !item.devolvida && (
                      <>
                        <Box textAlign="right" className={classes.resgatarEtiquetaBox}>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleResgatarEtiqueta(item.id);
                            }}
                            className={classes.submitButton}
                            startIcon={<PrintIcon />}
                          >
                            IMPRIMIR ETIQUETA
                          </Button>
                        </Box>
                        <Box textAlign="right">
                          <Button
                            color="secondary"
                            onClick={() => {
                              handleCancelarEtiqueta(item.id);
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            CANCELAR DEVOLUÇÃO
                          </Button>
                        </Box>
                        {!item.devolvida && (
                          <Box textAlign="right">
                            <Button
                              color="primary"
                              onClick={() => {
                                handleMarcarDevolvido(item.id);
                              }}
                              startIcon={<CheckCircleIcon />}
                            >
                              MARCAR COMO DEVOLVIDO
                            </Button>
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        ))}
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}
export default EtiquetasDevolucao;
