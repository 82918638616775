import api from '../api';

export type DadosVendedora = {
  codloja: number;
  nome: string;
  telefone1: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  uf: string;
};

const update = (data: DadosVendedora) => {
  return api.put(`/vendedora`, data);
};

const get_address = (cep: string) => {
  return api.get(`/endereco/${cep}`);
};

export default {
  update,
  get_address
};
