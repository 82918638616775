import React, { useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { UseFormRegister } from 'react-hook-form';
import Button from '@mui/material/Button';
import { AutocompleteModelosPayload, QueroVenderItem } from 'interfaces';
import AutoCompleteService from 'services/autocomplete/AutoCompleteService';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { TextFieldHookForms } from '../text-field-hook-forms';
import style from './style';

type Atributos = Partial<Pick<QueroVenderItem, 'atributos'>>;
type AutocompleteCategoriasProps = {
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  getModeloSelected?: (modelo: AutocompleteModelosPayload | null | string) => void;
  disabled?: boolean;
  codmarca: number | undefined;
  codcategoria: number | undefined;
  defaultValue: AutocompleteModelosPayload | string | undefined;
  options: AutocompleteModelosPayload[];
} & Atributos;

function AutocompleteModelos(props: AutocompleteCategoriasProps) {
  const classes = style();
  const { defaultValue, register, getModeloSelected = null, disabled = false, codmarca, codcategoria, options } = props;

  const [modeloOptions, setModeloOptions] = useState<AutocompleteModelosPayload[]>([]);
  const [campoModeloValue, setModeloValue] = useState<AutocompleteModelosPayload | null | string>(null);

  const navigate = useNavigate();
  const isFieldDisabled = useMemo(() => {
    return !(codcategoria && codmarca);
  }, [codcategoria, codmarca]);

  const inputDefautValue = useMemo(() => {
    if (campoModeloValue) {
      return campoModeloValue;
    }

    return defaultValue;
  }, [defaultValue, campoModeloValue]);

  useEffect(() => {
    setModeloOptions(options);
  }, [options]);

  const handleBuscaModelos = async (event: React.ChangeEvent<unknown>, value: string) => {
    setModeloValue(value as string);
    if (event && event.type === 'change') {
      AutoCompleteService.modelos({
        nome: value,
        codmarca: codmarca as number,
        codcategoria: codcategoria as number
      })?.then(({ data: { data } }) => {
        setModeloOptions(data);
      });
    }
  };

  const { ref, ...restProps } = useMemo(() => {
    return register?.('modelo_id', {
      setValueAs() {
        return typeof inputDefautValue === 'string' ? inputDefautValue : inputDefautValue?.id;
      }
    });

    return {
      ref: null
    };
  }, [inputDefautValue, register]);

  return (
    <Autocomplete
      onInputChange={handleBuscaModelos}
      value={inputDefautValue}
      onChange={(e, newValue: AutocompleteModelosPayload | null | string) => {
        if (newValue) {
          setModeloValue(newValue);
          getModeloSelected?.(newValue);
        }
      }}
      disabled={disabled || isFieldDisabled}
      getOptionLabel={option => (typeof option === 'string' ? option : option.nome)}
      options={modeloOptions}
      autoComplete
      noOptionsText={
        <Button size="small" onClick={() => navigate('/modelos/cadastrar')} startIcon={<AddIcon />} fullWidth>
          Adicionar modelo
        </Button>
      }
      defaultValue={defaultValue}
      renderInput={params => (
        <TextFieldHookForms
          {...params}
          label="Modelo"
          className={classes.textInput}
          {...restProps}
          ref={ref}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: params.InputProps.endAdornment
          }}
        />
      )}
    />
  );
}

export default AutocompleteModelos;
