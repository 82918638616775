import React, { useEffect, useState, useRef, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { CheckBoxIndividual, CustomCheckBoxType, CheckboxSelectAll } from 'components/form/custom-checkbox';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { useSelecaoProdutos } from '../selecao-produtos-provider';
import { useFetchDevolucoes } from '../fetch-devolucoes-provider';
import { useHandleSteps } from '../handle-steps-provider';
import { ProdutoSelect } from '../produto-select';
import devolucaoStyles from '../styles';

export function SelecionarProdutos() {
  const classes = devolucaoStyles();

  const refProduto = useRef<CustomCheckBoxType>([]);
  const refSelectAll = useRef<any>(); // eslint-disable-line

  const [page, setPage] = useState(1);

  const prevPage = useRef<number>(1);

  const { register, handleSubmit } = useForm();
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    setValue: setValueSearch
  } = useForm<{ query: string }>();
  const { produtosSelected, handleProductsSelection } = useSelecaoProdutos();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const { produtos, meta, fetchProdutosDevolucaoManual, querySearch } = useFetchDevolucoes();
  const [loadingPaginacao, setLoadingPaginacao] = useState<boolean>(false);
  const { step1, setStep1, setStep2 } = useHandleSteps();

  const handleOnStep1Exited = () => {
    setStep2(true);
  };

  /**
   * onSubmit
   * @description envia array com codigos dos produtos
   */

  const onSubmit = () => {
    setStep1(false);
  };

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handlePagination = useCallback(
    (currentPage: number) => {
      setLoadingPaginacao(true);

      fetchProdutosDevolucaoManual({ page: currentPage, query: querySearch }).finally(() => {
        setLoadingPaginacao(false);

        handleSelectAll();
      });
    },
    [fetchProdutosDevolucaoManual, setLoadingPaginacao, querySearch]
  );

  const handleSelectAll = () => {
    const el: HTMLInputElement[] = Array.from(document.querySelectorAll('[name="produtos"]'));

    if (el.some(item => item.checked === false)) {
      refSelectAll.current.setSelection(false);
    } else {
      refSelectAll.current.setSelection(true);
    }
  };

  /**
   * handleSearchForm
   * @description Busca produtos para iniciar devolução filtrando por parametro
   */

  const handleSearchForm = async (data: { query: string }) => {
    setLoadingSearch(true);

    fetchProdutosDevolucaoManual({ query: data.query, page: 1 })
      .then(result => {
        if (result.data.data.length === 0) {
          Swal.fire('Oops', 'Nenhum resultado para a busca foi encontrado', 'error').then(({ isConfirmed }) => {
            if (isConfirmed) {
              fetchProdutosDevolucaoManual({ query: '' });
              setValueSearch('query', '');
            }
          });
        } else {
          setPage(1);
          prevPage.current = 1;
        }
      })
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  /**
   * handleClearSearch
   * @description Limpa campo de texto de busca e retorna todos os resultados
   */

  const handleClearSearch = async () => {
    setLoadingSearch(true);
    setValueSearch('query', '');

    fetchProdutosDevolucaoManual({ query: '', page: 1 })
      .then(() => {
        setPage(1);
        prevPage.current = 1;
      })
      .finally(() => {
        setLoadingSearch(false);
      });
  };

  useEffect(() => {
    if (prevPage.current !== page) {
      handlePagination(page);
    }

    prevPage.current = page;
  }, [page, handlePagination, querySearch]);

  useEffect(() => {
    setTimeout(() => {
      handleSelectAll();
    }, 20);
  }, [produtosSelected, produtos, step1]);

  return (
    <Grow in={step1} unmountOnExit onExited={handleOnStep1Exited}>
      <Paper elevation={1}>
        <Box p={2}>
          <Typography variant="overline" color="textSecondary" className={classes.titles}>
            INFORMAÇÕES EXATAS SOBRE O PRODUTO:
          </Typography>

          <Box my={4} display="flex" justifyContent="space-between" alignItems="center">
            <CheckboxSelectAll
              ref={refSelectAll}
              ref_checkboxes={refProduto}
              disabled={loadingSearch || loadingPaginacao}
            />

            <form onSubmit={handleSubmitSearch(handleSearchForm)}>
              <Box display="inline-flex">
                <TextFieldHookForms
                  label="Pesquisar Produto"
                  variant="outlined"
                  size="small"
                  {...registerSearch('query')}
                  defaultValue={querySearch}
                  disabled={loadingPaginacao}
                  style={{ width: 250, marginRight: 5 }}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loadingPaginacao}
                  style={{ marginRight: 5 }}
                >
                  Buscar
                </Button>
                <Button disabled={loadingPaginacao} type="button" variant="contained" onClick={handleClearSearch}>
                  limpar
                </Button>
              </Box>
            </form>
          </Box>

          {!loadingSearch ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mt={1} height={400}>
                {!loadingPaginacao ? (
                  <Grid container alignItems="center">
                    {produtos.map((produto, index) => {
                      return (
                        <Grid item xs={6} key={produto.codproduto}>
                          <Box display="inline-flex" alignItems="center">
                            <CheckBoxIndividual
                              name="produtos"
                              value={produto.codproduto}
                              onChange={(e: React.ChangeEvent<HTMLInputElement> | null, value: boolean) => {
                                handleProductsSelection(produto, value);
                              }}
                              register={register}
                              defaultChecked={produtosSelected.some(item => item.codproduto === produto.codproduto)}
                              ref={el => {
                                if (el) {
                                  refProduto.current[index] = el;
                                }
                              }}
                            />
                            <Box onClick={() => refProduto?.current[index]?.handleClick()}>
                              <ProdutoSelect
                                imagens={produto.imagens}
                                nome={produto.nome}
                                custo_dev={produto.custo_devolucao}
                                codigo={produto.codigo}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <div>carregando...</div>
                )}
              </Box>

              <Box display="flex" justifyContent="center" mt={3}>
                <Pagination
                  color="primary"
                  count={meta?.last_page}
                  page={page}
                  disabled={loadingPaginacao}
                  onChange={handlePage}
                />
              </Box>

              <Box my={3} display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" disabled={loadingPaginacao} type="submit">
                  selecionar produtos
                </Button>
              </Box>
            </form>
          ) : (
            <div>carregando...</div>
          )}
        </Box>
      </Paper>
    </Grow>
  );
}
