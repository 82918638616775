import makeStyles from '@mui/styles/makeStyles';
import { grey, blue } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const AvaliacaoFisicaBatchStyle = makeStyles(() => ({
  space: {
    marginBottom: theme.spacing(6)
  },
  localizar: {
    marginRight: 20,
    boxSizing: 'border-box'
  },
  filterDate: {
    marginRight: 20,
    boxSizing: 'border-box'
  },
  filterFinalDate: {
    paddingTop: 56,
    marginRight: 20,
    boxSizing: 'border-box'
  },
  colDescricao: {
    width: '25%'
  },
  linkAvaliar: {
    color: theme.palette.primary.main
  },
  locked: {
    opacity: 0.5
  },
  sendButton: {
    marginLeft: theme.spacing(1)
  },
  dividerBoxVendedora: {
    marginTop: 50,
    marginBottom: 30
  },
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  mergeIcon: {
    marginRight: 5
  },
  listBatchsRelacionadas: {
    marginTop: 15
  },
  listItemBatchsRelacionadas: {
    padding: 0
  },
  iconMergeBatchs: {
    minWidth: 35
  },
  btnAdicionarProduto: {
    marginRight: 15
  },
  btnAdicionarRejeitados: {
    backgroundColor: grey[800],

    '&:hover': {
      backgroundColor: grey[900]
    }
  },
  btnFinalizarRemessa: {
    marginBottom: 15,
    marginLeft: 10
  },
  btEditar: {
    fontWeight: 700,
    color: blue[500],
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginLeft: 10
  }
}));

export default AvaliacaoFisicaBatchStyle;
