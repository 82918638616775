import makeStyles from '@mui/styles/makeStyles';

const PagamentosItemStyle = makeStyles(() => ({
  ButtonPago: {
    marginRight: 10
  },

  nomeProduto: {
    maxWidth: '200px'
  }
}));

export default PagamentosItemStyle;
