import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';

type Props = {
  onClose: () => void;
  id: number;
  numeroPedido: string;
  onExcluirSuccess: () => void;
};

function ModalExcluirPedido(props: Props) {
  const { id, onClose, numeroPedido, onExcluirSuccess } = props;
  const [motivo, setMotivo] = useState<string>('');

  const handleExcluirPedido = () => {
    const data = {
      motivo,
      id
    };
    Swal.fire({
      title: `Tem certeza que deseja excluir o pedido ${numeroPedido} ?`,
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        // eslint-disable-next-line
        console.log(data)
        onExcluirSuccess();
      }

      onClose();
    });
  };

  const handleMotivo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMotivo(event.target.value);
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>
        Apagar pedido: <br />
        #932493-OFDEED
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={2}
          name="motivo"
          variant="outlined"
          label="Motivo"
          value={motivo}
          onChange={handleMotivo}
          fullWidth
          inputProps={{
            'data-clickaway': true
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={handleExcluirPedido}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalExcluirPedido;
