import React, { useState } from 'react';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

import { FormAvaliacaoProvider } from 'context/form-avaliacao';
import CardContent from '@mui/material/CardContent';
import { QueroVenderItem } from 'interfaces';
import { ModalRejeitarItem } from 'components/modal-rejeitar-item';
import { ProdutoImagens } from 'components/avaliacao-fisica/produto-imagens';
import { ItensInclusos } from 'components/avaliacao-fisica/itens-inclusos';
import { Informacoes } from 'components/avaliacao-fisica/informacoes';
import { InformacoesProduto } from 'components/avaliacao-fisica/informacoes-produto';
import { InformacoesExatas } from 'components/avaliacao-fisica/informacoes-exatas';
import { Observacoes } from 'components/avaliacao-fisica/observacoes';
import { CalculoValores } from 'components/avaliacao-fisica/calculo-valores';
import { RodapeProdutoBatch } from 'components/avaliacao-fisica/rodape';
import { Status } from 'components/avaliacao-fisica/status';
import { ProdutoBonus } from 'components/avaliacao-fisica/produto-bonus';
import styles from 'components/avaliacao-fisica/styles';

interface Props {
  item?: QueroVenderItem;
  isExpanded?: boolean;
  doRefresh?(): void;
  codQueroVender: number | undefined;
  temComissionada: boolean;
  isProduto?: boolean;
  closeButton?: boolean;
  handleCardExpand?(value: boolean): void;
  onClickCloseButton?(): void;
  codloja?: number;
  batchComissionamentosDiferenciados?: boolean;
  batchProdutosSemBonus?: boolean;
}

function AvaliacaoFisica(props: Props) {
  const {
    item = {} as QueroVenderItem,
    temComissionada,
    codQueroVender,
    isExpanded = false,
    doRefresh = () => undefined,
    handleCardExpand = () => undefined,
    isProduto = false,
    closeButton = null,
    onClickCloseButton = () => undefined,
    codloja = undefined,
    batchComissionamentosDiferenciados = false,
    batchProdutosSemBonus = false
  } = props;
  const [modalRejeitarItem, setModalRejeitarItem] = useState<boolean>(false);

  const classes = styles();

  /**
   * Abre modal de Rejeitar Item
   */

  const openModalRejeitarItem = () => {
    setModalRejeitarItem(true);
  };

  /**
   * Fecha modal de Rejeitar Item
   */

  const closeModalRejeitarItem = () => {
    setModalRejeitarItem(false);
  };

  const FormAvaliacaoProviderProps = {
    item,
    isProduto,
    temComissionada,
    batchComissionamentosDiferenciados,
    codQueroVender,
    codloja,
    batchProdutosSemBonus,
    tipoAvaliacao: 'produto_batch'
  };

  return (
    <FormAvaliacaoProvider {...FormAvaliacaoProviderProps}>
      {item?.codquerovenderitem && modalRejeitarItem && (
        <ModalRejeitarItem
          doRefresh={doRefresh}
          codquerovenderitem={item.codquerovenderitem}
          onClose={closeModalRejeitarItem}
          callBack={() => {
            if (handleCardExpand) handleCardExpand(false);
          }}
        />
      )}

      <CardContent className={classes.cardContent}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.closeButton}>
            {closeButton && (
              <IconButton onClick={onClickCloseButton} size="large">
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <ProdutoBonus />
        <ProdutoImagens fotos={item.fotos} />
        <Informacoes />
        <CalculoValores />
        <ItensInclusos />
        <Status />
        <InformacoesProduto />
        <InformacoesExatas />
        <Observacoes />
      </CardContent>

      <CardActions className={classes.actions}>
        <RodapeProdutoBatch
          isExpanded={isExpanded}
          doRefresh={doRefresh}
          handleCardExpand={handleCardExpand}
          openModalRejeitarItem={openModalRejeitarItem}
        />
      </CardActions>
    </FormAvaliacaoProvider>
  );
}

export default AvaliacaoFisica;
