import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const TabPanelPickingStyles = makeStyles(() => ({
  wrapper: {
    // paddingBottom: theme.spacing(8),
    marginTop: theme.spacing(3)
  }
}));

export default TabPanelPickingStyles;
