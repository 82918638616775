import React, { useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AutocompleteModelosPayload } from 'interfaces';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ModelosService from 'services/modelos/ModelosService';
import Swal from 'sweetalert2';
import FormModelo from '../components/form-modelo';

type Props = {
  modelo: AutocompleteModelosPayload;
  doRefresh: () => void;
};

export function ListRow(props: Props) {
  const { modelo, doRefresh } = props;
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    Swal.fire({
      title: `Tem certeza que excluir o modelo "${modelo.nome}"?`,
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        ModelosService.deletar(modelo.id).then(() => {
          Swal.fire('Modelo excluido com sucesso', '', 'success').then(() => {
            doRefresh();
          });
        });
      }
    });
  };

  return (
    <>
      <TableRow key={modelo.id}>
        <TableCell>{modelo.nome}</TableCell>
        <TableCell>{modelo?.categoria?.nome}</TableCell>
        <TableCell>{modelo?.marca?.nome}</TableCell>
        <TableCell>
          <IconButton color="primary" onClick={handleOpenModal} size="large">
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton color="primary" onClick={handleDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog open={openModal}>
        <DialogContent>
          <FormModelo
            title="Atualizar Cadastro"
            modelo={modelo}
            action="atualizar"
            onCancel={handleCloseModal}
            doRefresh={doRefresh}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
