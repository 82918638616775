import React, { memo, useMemo } from 'react';
import { useFormAvaliacao } from 'context/form-avaliacao';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useFiltros } from 'context/filtros';
import { useFormContext } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { FiltroCheckbox } from '../filtros-checkbox';
import styles from '../styles';

export const ItensInclusos = memo(() => {
  const { register } = useFormContext();
  const { item, isProduto, disableForm, campoCategoria } = useFormAvaliacao();
  const { filtroState } = useFiltros();
  const classes = styles();

  const selectItensNode = useMemo(() => {
    return isProduto ? item?.produto?.filtros['itens-inclusos']?.variacoes : item?.atributos?.itens_inclusos;
  }, [item, isProduto]);

  return (
    <Box py={1}>
      <Typography variant="overline" color="textSecondary" className={classes.titles}>
        Itens inclusos:
      </Typography>

      <FiltroCheckbox
        name="itens_inclusos"
        disabled={disableForm}
        categoria={campoCategoria}
        register={register}
        apiFiltroVariacoes={filtroState?.itensInclusos?.variacoes}
        produtoFiltroVariacoes={selectItensNode}
      />

      {!selectItensNode?.length && (
        <TextFieldHookForms
          inputRef={register}
          multiline
          rows={1}
          disabled={disableForm}
          className={classes.textArea}
          placeholder="Adicionar mais itens inclusos"
          variant="outlined"
          fullWidth
          {...register('itens_inclusos_extra')}
        />
      )}
    </Box>
  );
});
