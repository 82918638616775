import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { GreenSwitch } from '../../custom-switch';

export function PublicacaoModo() {
  const { register } = useFormContext();
  const [modo, setModo] = useState<boolean>(false);

  return (
    <Box my={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <GreenSwitch
              {...register('publicacao_modo')}
              checked={modo}
              onChange={() => setModo(!modo)}
              color="secondary"
              value="WHATSAPP_PENDENTE"
            />
          }
          label="Separar para WhatsApp"
        />
      </Grid>
    </Box>
  );
}
