import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import TemplateBase from 'components/template-base/template';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch } from 'interfaces';
import HeaderDetails from '../components/HeaderDetails';
import Item from '../components/Item';

import style from './style';

function AvaliacaoNegociacaoDetails() {
  document.title = 'Negociação de Pré-avaliação de Produtos';

  const classes = style();

  const { id } = useParams() as { id: string };

  const [batch, setBatch] = useState<QueroVenderBatch>();
  const [loading, setloading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    QueroVenderService.get(id).then(response => {
      setBatch(response.data);
      setloading(false);
    });
  }, [id, refresh]);

  useEffect(() => {
    QueroVenderService.lock(id);

    return function cleanup() {
      QueroVenderService.unlock(id);
    };
  }, [id]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      {loading ? (
        <div>Carregando...</div>
      ) : (
        <>
          <div className={classes.space}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={7}>
                <HeaderDetails
                  id={id}
                  title={`${batch?.cliente?.nome} - ${batch?.cliente?.codcliente}`}
                  email={batch?.cliente?.email}
                  telcelular={batch?.cliente?.telcelular}
                />
              </Grid>
            </Grid>
          </div>

          {batch?.itens.map((item, index) => (
            <Item
              doRefresh={() => setRefresh(refresh + 1)}
              key={item.codquerovenderitem}
              item={item}
              index={index + 1}
              status={item.negociacao_atual?.status === 20 ? 'Nova sugestão de valor recebida' : 'Aguardando aprovação'}
              step="preAvaliacaoNegociacao"
            />
          ))}
        </>
      )}
    </TemplateBase>
  );
}

export default AvaliacaoNegociacaoDetails;
