import api from '../api';

const upload = (codproduto: string, data: FormData) => {
  return api.post(`produtos/${codproduto}/midias-internas`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const list = (codproduto: string, params?: string) => {
  return api.get(`produtos/${codproduto}/midias-internas?${params}`);
};

const iniciar = async (codeProduct: string) => {
  await api.post(`produtos/${codeProduct}/midias-internas/iniciar`);
};

const concluir = async (productId: number, data: object) => {
  await api.post(`produtos/${productId}/midias-internas/concluir`, data);
};

export default { upload, list, iniciar, concluir };
