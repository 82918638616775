import React, { useMemo, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import IconButton from '@mui/material/IconButton';
import { Pedido } from 'interfaces';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';

import VipIcon from 'components/vip-icon';
import Pluralize from 'components/utils/pluralize';
import { useCheckingProvider } from '../CheckingProvider';
import CheckingStyle from './style';

type Props = {
  pedido: Pedido;
  devolucao: boolean | undefined;
};

export function TablePedidosRow(props: Props) {
  const { pedido, devolucao } = props;
  const [open, setOpen] = useState(false);
  const { path, detailUrl } = useCheckingProvider();

  const classes = CheckingStyle();

  const produtosLocalizados = useMemo(() => {
    return pedido.produtos.filter(produto => produto.localizado === 'LOCALIZADO');
  }, [pedido]);

  const openProdutos = () => {
    setOpen(!open);
  };

  const getCliente = useMemo(() => {
    if (pedido.cliente) {
      return `${pedido?.cliente?.nome} - ${pedido?.cliente?.codcliente}`;
    }

    return '-';
  }, [pedido?.cliente]);

  const linkVer = () => {
    return (
      <Link to={`${detailUrl}${path}/${pedido.codigo}`} className={classes.linkAvaliar}>
        Abrir
      </Link>
    );
  };

  return (
    <>
      <TableRow>
        {devolucao && (
          <TableCell>
            <VipIcon isVip={pedido.cliente.prioridade === 1} />
          </TableCell>
        )}
        <TableCell>
          {pedido?.data_aprovacao ? format(new Date(pedido?.data_aprovacao), 'dd/MM/yyyy HH:mm:ss') : '-'}
        </TableCell>
        <TableCell>
          {produtosLocalizados.length}{' '}
          <Pluralize quantidade={produtosLocalizados.length} singular="produto" plural="produtos" />
          <IconButton aria-label="expand row" size="small" onClick={openProdutos}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{pedido.codigo}</TableCell>
        <TableCell>{getCliente}</TableCell>
        <TableCell>{linkVer()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <List dense>
                {produtosLocalizados.map(produto => (
                  <ListItem key={produto?.codpedidoproduto} className={classes.listItem}>
                    <ListItemAvatar>
                      <Avatar src={produto?.imagens[0]?.thumbnail} alt="" />
                    </ListItemAvatar>
                    <ListItemText primary={produto?.nome} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
