import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import grey from '@mui/material/colors/grey';
import { ModeracaoEtapa } from 'interfaces';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ModeracaoProdutoStyles from './styles';

type Props = {
  label: string;
  children: React.ReactNode;
  id: string;
  onSetEtapa?: (etapa: ModeracaoEtapa) => void;
  sugerir_preco?: boolean;
  disabled?: boolean;
  motivoDefault?: string;
  situacaoDefault: string;
};

export function Etapas(props: Props) {
  const { label, children, id, onSetEtapa, sugerir_preco, disabled, motivoDefault = '', situacaoDefault = '' } = props;
  const [observacoes, setObservacoes] = useState(motivoDefault);
  const [showObservacoes, setShowObservacoes] = useState(false);
  const [situacao, setSituacao] = useState('');
  const [disableAprovacao, setDisableAprovacao] = useState(false);
  const styles = ModeracaoProdutoStyles();

  const handleChangeSituacao = (event: React.ChangeEvent<HTMLInputElement>) => setSituacao(event.target.value);

  const handleChangeObservacoes = (event: React.ChangeEvent<HTMLInputElement>) => setObservacoes(event.target.value);

  const handleShowObservacoes = useCallback(
    (value: string) => {
      let motivo = '';

      if (value === 'ALTERACAO_NECESSARIA') {
        setShowObservacoes(true);
        motivo = observacoes;
      } else {
        setShowObservacoes(false);
      }

      onSetEtapa?.({ id, situacao: value, motivo });
    },
    [observacoes, setShowObservacoes] // eslint-disable-line
  );

  useEffect(() => {
    if (situacao !== '') {
      handleShowObservacoes(situacao);
    }
  }, [observacoes, situacao, handleShowObservacoes]);

  useEffect(() => {
    if (situacaoDefault) {
      handleShowObservacoes(situacaoDefault);
    }
  }, [situacaoDefault]); // eslint-disable-line

  useEffect(() => {
    if (sugerir_preco) {
      setDisableAprovacao(true);
      setSituacao('ALTERACAO_NECESSARIA');
      setShowObservacoes(true);
      setObservacoes('Por favor, verifique a nossa sugestão de valor.');
    }
  }, [sugerir_preco]);

  return (
    <Box paddingTop={4}>
      <Typography variant="overline" color="textSecondary" className={styles.etapasTitle}>
        {label} <CheckCircleOutlineIcon />
      </Typography>
      <div>{children}</div>
      <Paper elevation={2}>
        <Box p={2} bgcolor={grey[100]}>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">
              <Typography variant="overline" color="textSecondary">
                validação de {label}:
              </Typography>
            </FormLabel>
            <RadioGroup row aria-label="situacao" value={situacao} onChange={handleChangeSituacao} name="situacao">
              <FormControlLabel
                value="APROVADO"
                disabled={disabled || disableAprovacao}
                control={<Radio color="primary" />}
                label="Aprovado"
              />
              <FormControlLabel
                value="ALTERACAO_NECESSARIA"
                control={<Radio color="primary" />}
                label="Revisão do Cliente"
                disabled={disabled}
              />
            </RadioGroup>
          </FormControl>
          {showObservacoes && (
            <Box pt={1}>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                label="Observações"
                rows={2}
                value={observacoes}
                onChange={handleChangeObservacoes}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

Etapas.defaultProps = {
  onSetEtapa: null,
  sugerir_preco: false,
  disabled: false,
  motivoDefault: ''
};
