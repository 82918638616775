import React from 'react';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { CheckingProvider } from '../components/checking/CheckingProvider';

function CheckingDevolucao() {
  const filterPedidos = new Criteria();
  const filterLocalizarBip = new Criteria();

  filterPedidos.add('tipo_pedido', '1');
  filterPedidos.add('codstatuspedido', '16');
  filterPedidos.add('perpage', '10');
  filterPedidos.add('page', '1');

  filterLocalizarBip.add('tipo_pedido', '1');
  filterLocalizarBip.add('codstatuspedido[]', '16');

  return (
    <CheckingProvider
      title="Devolução"
      subtitle="Checking"
      path="checking-devolucao"
      filterLocalizarBip={filterLocalizarBip}
      filterPedidos={filterPedidos}
      detailUrl="/devolucao/"
      devolucao
    >
      <CheckingProvider.CheckingLayout />
    </CheckingProvider>
  );
}

export default CheckingDevolucao;
