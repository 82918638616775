import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import ModalExcluirPedido from './modal-excluir-pedido';

type Props = {
  onExcluirSuccess: () => void;
};

function Produto(props: Props) {
  const { onExcluirSuccess } = props;
  const [modalExcluirPedido, setModalExcluirPedido] = useState<boolean>(false);

  const handleRemoveProduct = () => {
    setModalExcluirPedido(true);
  };

  const handleCloseModalExcluirPedido = () => {
    setModalExcluirPedido(false);
    setTimeout(() => document.getElementById('field-cod-barras')?.focus(), 300);
  };

  const handleOnExcluirSuccess = () => {
    // eslint-disable-next-line
    console.log('das');
    onExcluirSuccess();
  };

  return (
    <>
      {modalExcluirPedido && (
        <ModalExcluirPedido
          id={1234}
          onClose={handleCloseModalExcluirPedido}
          numeroPedido="#932493-OFDEED"
          onExcluirSuccess={handleOnExcluirSuccess}
        />
      )}

      <Box my={2}>
        <Card>
          <CardContent>
            <Box display="inline-flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box fontSize={14} fontWeight={700} color={grey[600]}>
                pedido #932493-OFDEED
              </Box>
              <Box>
                <IconButton color="secondary" onClick={handleRemoveProduct} size="large">
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <strong>Destinatário:</strong> Lorem ipsum
              </Grid>
              <Grid item xs={12}>
                <strong>CEP:</strong> 00000-000
              </Grid>
              <Grid item xs={12}>
                <strong>Endereço:</strong> Rua Lorem ipsum, 000
              </Grid>
              <Grid item xs={6}>
                <strong>Compl.:</strong>
              </Grid>
              <Grid item xs={6}>
                <strong>Bairro:</strong> Lorem Ipsum
              </Grid>
              <Grid item xs={6}>
                <strong>Cidade:</strong> São Paulo
              </Grid>
              <Grid item xs={6}>
                <strong>UF:</strong> SP
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Produto;
