import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const FilterSelectPickingStyle = makeStyles(() => ({
  filtroSelectForm: {
    marginBottom: theme.spacing(4)
  }
}));

export default FilterSelectPickingStyle;
