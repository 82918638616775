import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import alertValidationMessage from 'helpers/alert-validation-message';
import PagamentoVendedoras, { PayloadModalPago } from 'services/ferramentas/PagamentosVendedoras';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import { RadioHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';
import { useLoading } from 'context/loading';
import PagamentosVendedorasStyle from '../style';

type ModalPagoProps = {
  openModal: boolean;
  setModalPago: (status: boolean) => void;
  codPedidoProduto: number;
  fetchPagamentos: () => void;
};

const schema = Yup.object().shape({
  data_pagamento: Yup.string().required('Data é obrigatório'),
  motivo: Yup.string().required('Motivo é obrigatório')
});

function ModalPago(props: ModalPagoProps) {
  const classes = PagamentosVendedorasStyle();
  const { openModal, setModalPago, codPedidoProduto, fetchPagamentos } = props;

  const { setLoading } = useLoading();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<PayloadModalPago>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  const onSubmit = ({ role, ...data }: PayloadModalPago) => {
    const formData = { ...data };
    formData.data_pagamento = format(new Date(formData.data_pagamento), 'yyyy-MM-dd');

    formData.captadora = role === 'captadora';
    formData.vendedora = role === 'vendedora';

    setModalPago(false);
    setLoading(true);

    PagamentoVendedoras.marcarPago(codPedidoProduto, formData)
      .then(response => {
        if (response) {
          Swal.fire('Marcado como pago', '', 'success').then(() => {
            fetchPagamentos();
          });
        }
      })
      .catch(() => {
        // Erro tratado no interceptor
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openModal} onClose={() => setModalPago(false)}>
      <DialogTitle id="form-dialog-title">Marcar como pago</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <FormControl variant="standard" fullWidth>
            <RadioGroup row defaultValue="vendedora">
              <FormControlLabel
                value="vendedora"
                control={<RadioHookForms color="primary" {...register('role')} />}
                label="Vendedora"
              />
              <FormControlLabel
                value="captadora"
                control={<RadioHookForms color="primary" {...register('role')} />}
                label="Captadora"
              />
            </RadioGroup>
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <DatePickerHookForm label="Data" name="data_pagamento" control={control} />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <TextFieldHookForms variant="standard" label="Motivo" {...register('motivo')} multiline maxRows={6} />
          </FormControl>

          <div className={classes.wrapModalButtons}>
            <Button variant="contained" className={classes.buttonModalCancelar} onClick={() => setModalPago(false)}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              OK
            </Button>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}

export default ModalPago;
