import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { FiltroStateBody } from 'context/filtros';
import { CampoCategorias, FiltrosVariacao } from 'interfaces';
import { UseFormRegister } from 'react-hook-form';
import { CheckboxHookForms } from 'components/form/text-field-hook-forms';
import styles from '../styles';

type Props = {
  apiFiltroVariacoes: FiltroStateBody['variacoes'];
  produtoFiltroVariacoes?: FiltrosVariacao[];
  name: string | undefined;
  categoria: CampoCategorias;
  disabled?: boolean;
  register: UseFormRegister<any>; // eslint-disable-line
} & Partial<FormControlLabelProps>;

export function FiltroCheckbox(props: Props) {
  const classes = styles();

  const { disableForm } = useFormAvaliacao();
  const { apiFiltroVariacoes, produtoFiltroVariacoes = [], name, register, categoria, ...rest } = props;

  function checkedFilters(codfiltrovariacao: number) {
    if (!produtoFiltroVariacoes) return false;

    return produtoFiltroVariacoes.some(variacao => variacao.codfiltrovariacao === codfiltrovariacao);
  }

  if (apiFiltroVariacoes && apiFiltroVariacoes.length > 0 && name) {
    return (
      <FormGroup row>
        {apiFiltroVariacoes.map(variacao => (
          <FormControlLabel
            {...rest}
            key={`${variacao.slug}_from_${categoria?.url}`}
            className={classes.checkBox}
            control={
              <CheckboxHookForms
                disabled={disableForm}
                {...register(name as string)}
                value={variacao.codfiltrovariacao}
                color="primary"
                defaultChecked={checkedFilters(variacao.codfiltrovariacao)}
              />
            }
            label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
          />
        ))}
      </FormGroup>
    );
  }

  if (produtoFiltroVariacoes?.length === 0) return <div>-</div>;

  return name ? (
    <FormGroup row>
      {produtoFiltroVariacoes?.map(variacao => (
        <FormControlLabel
          {...rest}
          key={`${variacao.slug}_from_${categoria?.url}`}
          className={classes.checkBox}
          control={
            <CheckboxHookForms
              disabled={disableForm}
              {...register(name as string)}
              value={variacao.codfiltrovariacao}
              color="primary"
              checked
            />
          }
          label={variacao.nome.charAt(0).toUpperCase() + variacao.nome.slice(1)}
        />
      ))}
    </FormGroup>
  ) : null;
}
