import { TransferenciasData } from 'interfaces';
import api from '../api';

const criar = ({ forcar, ...rest }: TransferenciasData) => {
  return api.post(`transferencias${forcar ? '?forcar=1' : ''}`, { ...rest });
};

const listar = ({ query, perpage = 10, page }: { query: string; perpage?: number; page: number }) => {
  return api.get(`transferencias?${query}&page=${page}&perpage=${perpage}`);
};

const remover = (codtransferencia: number, motivo: string) => {
  return api.delete(`transferencias/${codtransferencia}`, {
    data: {
      motivo
    }
  });
};

const cancelar = (codtransferencia: number, motivo: string) => {
  return api.post(`transferencias/${codtransferencia}/cancelar`, {
    motivo
  });
};

const limparTentativas = (codtransferencia: number) => {
  return api.post(`transferencias/${codtransferencia}/limpar-tentativas`);
};

export default {
  criar,
  listar,
  remover,
  limparTentativas,
  cancelar
};
