import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormAvaliacao } from 'context/form-avaliacao';
import { useFormContext } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import styles from '../styles';

export const Observacoes = memo(() => {
  const { register } = useFormContext();
  const classes = styles();

  const { item } = useFormAvaliacao();

  const renderObservacoes = () => {
    if (!item?.observacoes) return '—';
    if (item?.observacoes.trim() === '') return '—';

    return item?.observacoes;
  };

  return (
    <>
      <Box py={1}>
        <Typography variant="overline" color="textSecondary" className={classes.titles}>
          Observações
        </Typography>
        <Grid container direction="column">
          <TextFieldHookForms
            className={classes.textArea}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            defaultValue={renderObservacoes()}
            {...register('observacoes')}
          />
        </Grid>
      </Box>
      <Box py={1}>
        <Typography variant="overline" color="textSecondary" className={classes.titles}>
          Observações Internas
        </Typography>
        <Grid container direction="column">
          <TextFieldHookForms
            className={classes.textArea}
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            {...register('observacoes_internas')}
          />
        </Grid>
      </Box>
    </>
  );
});
