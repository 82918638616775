import React, { useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { Barcode } from 'components/icons';
import AcervoService from 'services/acervo/AcervoService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
  title: string;
  codproduto: number;
};

function ModalBipPedido(props: Props) {
  const { onClose, title, codproduto } = props;
  const navigate = useNavigate();
  const inputBip = useRef<HTMLInputElement | null>(null);

  const handleLocalizarPorCodigoBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const el = document.getElementById('codigoAcervo') as HTMLInputElement;

      if (el) {
        AcervoService.bip({
          codigo: el.value
        }).then(({ data }) => {
          if (data.tipo === 'ACERVO') {
            AcervoService.bip({
              codigo: codproduto?.toString(),
              acervo_id: data.data.id
            }).then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Produto localizado!',
                confirmButtonText: `Ok`
              }).then(() => {
                navigate('/avaliacao/avaliacao-fisica-produto');
                onClose();
              });
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputBip && inputBip?.current) {
        inputBip?.current.focus();
      }
    }, 200);
  }, [inputBip]);

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <Box pb={1}>Bipe a localização deste item</Box>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            onKeyUp={handleLocalizarPorCodigoBip}
            style={{ width: 300 }}
            inputRef={inputBip}
            InputProps={{
              id: 'codigoAcervo',
              endAdornment: (
                <InputAdornment position="end">
                  <Barcode />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ModalBipPedido;
