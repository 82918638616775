import api from '../api';

export type PayloadModalPago = {
  vendedora?: boolean;
  captadora?: boolean;
  motivo: string;
  data_pagamento: string;
  role?: string;
};

export type PayloadModalTentarNovamente = {
  vendedora?: boolean;
  captadora?: boolean;
  role?: string;
  motivo: string;
};

const listar = (page: number, queryFilter: string, valueFilter: string) => {
  return api.get('pedido-produto/pagamentos', {
    params: {
      page,
      [queryFilter]: valueFilter
    }
  });
};

const marcarPago = (codPedidoProduto: number, payload: PayloadModalPago) => {
  return api.post(`pedido-produto/${codPedidoProduto}/pagamentos/marcar-pago`, payload);
};

const tentarNovamente = (codPedidoProduto: number, payload: PayloadModalTentarNovamente) => {
  return api.post(`pedido-produto/${codPedidoProduto}/pagamentos/limpar-tentativas`, payload);
};

export default {
  listar,
  marcarPago,
  tentarNovamente
};
