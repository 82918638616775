import api from '../api';

const list = (queryMeta?: string) => {
  const query = queryMeta !== undefined ? `&${queryMeta}` : '';

  return api.get(`lifecycle?${query}`);
};

const pay = (codpedidoproduto: number) => {
  return api.post(`lifecycle/${codpedidoproduto}`);
};

export default {
  list,
  pay
};
