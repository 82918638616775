import React, { useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import VendasPorVendedora from 'services/relatorios/VendasPorVendedora';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import formGerarRelatorioStyle from './styles';

interface FormGerarRelatorioProps {
  onClose: () => void;
  params: {
    codloja: string;
  };
}

function FormGerarRelatorio(props: FormGerarRelatorioProps) {
  const {
    onClose,
    params: { codloja }
  } = props;
  const style = formGerarRelatorioStyle();

  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const { handleSubmit, control } = useForm<{ data_inicio: string; data_termino: string }>();

  const onSubmit = (data: { data_inicio: string; data_termino: string }) => {
    const formData = { ...data, codloja };

    formData.data_inicio = format(new Date(formData.data_inicio), 'yyyy-MM-dd');

    formData.data_termino = format(new Date(formData.data_termino), 'yyyy-MM-dd');

    setLoading(true);

    VendasPorVendedora.fetch(formData)
      .then(response => {
        if (response.data.byteLength > 0) {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          setFileUrl(fileURL);
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Nenhum relatório foi encontrado nesse período'
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Download do relatório em PDF
   */

  const handleDownloadReport = () => {
    if (typeof fileUrl === 'string') {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = `Relatório${new Date()}.pdf`;
      link.click();
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Selecionar período</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={style.modalContent}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <DatePickerHookForm label="Data de Início" control={control} name="data_inicio" />
            </Grid>
            <Grid item>
              <DatePickerHookForm label="Data de Término" control={control} name="data_termino" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={style.modalActions}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              <Button
                type="submit"
                onClick={handleDownloadReport}
                disabled={!fileUrl}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Grid>
            <Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <Button onClick={onClose}>Cancelar</Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary" endIcon={loading ?? <CircularProgress />}>
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FormGerarRelatorio;
