import React from 'react';
import TemplateBase from 'components/template-base/template';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { SelecaoProdutosProvider } from '../selecao-produtos-provider';
import { FetchDevolucoesProvider } from '../fetch-devolucoes-provider';
import { GerarDevolucao } from '../gerar-devolucao';
import { SelecionarProdutos } from '../selecionar-produtos';
import { DadosVendedora } from '../dados-vendedora';
import { HandleStepsProvider } from '../handle-steps-provider';

function IncluirDevolucao() {
  document.title = 'Avaliação | Incluir Devolução';

  const { codigo } = useParams() as { codigo: string };

  return (
    <TemplateBase>
      <FetchDevolucoesProvider codigo={codigo}>
        <SelecaoProdutosProvider>
          <HandleStepsProvider>
            <DadosVendedora />

            <Box mt={3}>
              <SelecionarProdutos />
              <GerarDevolucao />
            </Box>
          </HandleStepsProvider>
        </SelecaoProdutosProvider>
      </FetchDevolucoesProvider>
    </TemplateBase>
  );
}

export default IncluirDevolucao;
