import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TextFieldHookForms, CheckboxHookForms, SwitchHookForms } from 'components/form/text-field-hook-forms';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import VendedoraDevolucaoService, { FormData } from 'services/devolucao/VendedoraDevolucaoService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useLoading } from 'context/loading';
import devolucaoStyles from '../styles';
import { ProdutoRow } from './ProdutoRow';
import { useSelecaoProdutos } from '../selecao-produtos-provider';
import { useFetchDevolucoes } from '../fetch-devolucoes-provider';
import { useHandleSteps } from '../handle-steps-provider';

export function GerarDevolucao() {
  document.title = 'Avaliação | Gerar Devolução';

  const classes = devolucaoStyles();

  const navigate = useNavigate();

  const { produtosSelected, setProdutosSelected } = useSelecaoProdutos();
  const { vendedora } = useFetchDevolucoes();
  const { step2, setStep1, setStep2 } = useHandleSteps();

  const vip = useMemo(() => {
    return !!vendedora.cliente.prioridade;
  }, [vendedora]);

  const codigo = useMemo(() => {
    return vendedora.codigo;
  }, [vendedora]);

  const { register, handleSubmit } = useForm<FormData>();

  const [cobrarFrete, setCobrarFrete] = useState<boolean>(!vip);

  const { setLoading } = useLoading();
  const [produtosDev, setProdutosDev] = useState<
    {
      codproduto: number;
      cobrar_taxa: boolean;
    }[]
  >([]);

  /**
   * handleCombrarFrete
   * @description controla componente switch
   */
  const handleCombrarFrete = () => setCobrarFrete(!cobrarFrete);

  /**
   * handleRemoveProduto
   * @description remover produto da lista
   */
  const handleRemoveProduto = (codproduto: number) => {
    if (produtosSelected.length === 1) {
      navigate('/devolucao');
    } else {
      setProdutosSelected(prevValue => prevValue.filter(produto => produto.codproduto !== codproduto));
    }
  };

  /**
   * onSubmit
   * @description
   */
  const onSubmit = (data: FormData) => {
    const result: FormData = {} as FormData;

    result.cobrar_frete = data.cobrar_frete;
    result.codigo_loja = codigo;
    result.produtos = produtosDev;
    result.motivo = data?.fieldMotivo.join(', ');

    if (data.outros_motivos && result.motivo.length === 0) {
      result.motivo = `${data.outros_motivos}`;
    } else if (data.outros_motivos && result.motivo.length > 0) {
      result.motivo = `${result.motivo}, ${data.outros_motivos}`;
    }

    if (data?.outros_motivos?.length === 0 && data.fieldMotivo.length === 0) {
      Swal.fire('Oops', 'É preciso inserir algum motivo', 'warning');

      return false;
    }

    setLoading(true);

    VendedoraDevolucaoService.criar_pedido(result)
      .then(() => {
        Swal.fire('Pedido de devolução criado com sucesso', '', 'success').then(() => {
          navigate('/devolucao');
        });
      })
      .finally(() => {
        setLoading(false);
      });

    return null;
  };

  const handleProductDev = (index: number, state: { cobrar_taxa: boolean }) => {
    setProdutosDev(prevValue => {
      const result = [...prevValue];
      result[index].cobrar_taxa = state.cobrar_taxa;

      return result;
    });
  };

  const handleOnVoltar = () => {
    setStep2(false);
  };

  /**
   * handleOnStep2Exit
   * @description chamada quando o step2 (selecao de produtos) termina a animação e desmonta
   */

  const handleOnStep2Exit = () => {
    setStep1(true);
    // clearSearchForm();
  };

  useEffect(() => {
    setProdutosDev(
      produtosSelected.map(produto => ({
        codproduto: produto.codproduto,
        cobrar_taxa: !vip
      }))
    );
  }, [produtosSelected, vip]);

  const { ref: refMotivo, ...propsMotivo } = register('fieldMotivo', { value: [] });

  return (
    <Grow in={step2} unmountOnExit onExited={handleOnStep2Exit}>
      <Paper elevation={1}>
        <Box p={2} display="flex" justifyContent="space-between">
          <Typography variant="overline" color="textSecondary" className={classes.titles}>
            CONFIRMAR DEVOLUÇÃO
          </Typography>
          <Button color="secondary" variant="contained" onClick={handleOnVoltar}>
            Voltar para seleção
          </Button>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Itens selecionados</TableCell>
                  <TableCell align="center">Data aprovação</TableCell>
                  <TableCell align="center">Cobrar taxa?</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {produtosSelected.length > 0 &&
                  produtosSelected.map((produto, index) => (
                    <ProdutoRow
                      produto={produto}
                      vip={vip}
                      key={`${produto.codproduto}_produto_row`}
                      index={index}
                      handleRemoveProduto={handleRemoveProduto}
                      produtoIndex={handleProductDev}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>

          <Box p={2}>
            <Typography variant="overline" color="textSecondary" className={classes.titles}>
              Motivo da devolução
            </Typography>

            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CheckboxHookForms ref={refMotivo} {...propsMotivo} value="Não quis justificar" color="primary" />
                  }
                  label="Não quis justificar"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CheckboxHookForms ref={refMotivo} {...propsMotivo} value="Vendeu no particular" color="primary" />
                  }
                  label="Vendeu no particular"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CheckboxHookForms
                      color="primary"
                      ref={refMotivo}
                      {...propsMotivo}
                      value="Alegou estar a muito tempo disponível para venda"
                    />
                  }
                  label="Alegou estar a muito tempo disponível para venda"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <CheckboxHookForms
                      ref={refMotivo}
                      {...propsMotivo}
                      value="Atraso no prazo pra subida no site"
                      color="primary"
                    />
                  }
                  label="Atraso no prazo pra subida no site"
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <TextFieldHookForms
                style={{ width: 300 }}
                variant="outlined"
                size="small"
                placeholder="Outros motivos"
                multiline
                rows={2}
                {...register('outros_motivos')}
              />
            </Box>
          </Box>
          <Box p={2} display="flex" justifyContent="space-between">
            <FormControlLabel
              control={
                <SwitchHookForms
                  color="primary"
                  disabled={vip}
                  {...register('cobrar_frete', {
                    value: cobrarFrete
                  })}
                  onChange={handleCombrarFrete}
                  checked={cobrarFrete}
                />
              }
              label={`Cobrar frete? ${cobrarFrete ? 'Sim' : 'Não'}`}
            />

            <Button type="submit" color="primary" variant="contained">
              gerar devolução
            </Button>
          </Box>
        </form>
      </Paper>
    </Grow>
  );
}
