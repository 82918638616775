import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles(() => ({
  paper: {
    width: 120,
    height: 120,
    overflow: 'hidden'
  },
  buttonImage: {
    border: 0,
    background: 'transparent',
    padding: 0,
    cursor: 'pointer'
  }
}));
