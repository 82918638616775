import React, { useState } from 'react';
import { MidiasInternas } from 'interfaces';
import Lightbox from 'react-image-lightbox';
import Box from '@mui/material/Box';
import 'react-image-lightbox/style.css';

type Props = {
  data: MidiasInternas[];
};

export function GaleriaMidiasInternas(props: Props) {
  const { data } = props;

  const [imagesModal, setImagesModal] = useState<{
    url: string;
    index: number;
    show: boolean;
  }>({
    url: '',
    index: 0,
    show: false
  });

  const customModalStyles = {
    overlay: {
      zIndex: 9999
    }
  };

  const handleImageClick = ({ url, index, show }: { url: string; index: number; show: boolean }) => {
    setImagesModal({
      url,
      index,
      show
    });
  };

  return (
    <>
      {imagesModal.show && (
        <Lightbox
          mainSrc={data[imagesModal.index].url}
          onCloseRequest={() =>
            setImagesModal({
              url: '',
              index: 0,
              show: false
            })
          }
          reactModalStyle={customModalStyles}
          nextSrc={data[(imagesModal.index + 1) % data.length].url}
          prevSrc={data[(imagesModal.index + data.length - 1) % data.length].url}
          onMoveNextRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + 1) % data.length
            })
          }
          onMovePrevRequest={() =>
            setImagesModal({
              ...imagesModal,
              index: (imagesModal.index + data.length - 1) % data.length
            })
          }
        />
      )}

      {data.map((imagem, index) => (
        <Box
          key={imagem.id}
          mr={1}
          component="span"
          display="inline-block"
          width={135}
          style={{ verticalAlign: 'top' }}
          mb={2}
        >
          <button
            type="button"
            aria-label="View image"
            onClick={() => handleImageClick({ index, url: imagem.url, show: true })}
            style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', padding: 0 }}
          >
            <img width={135} src={imagem.url} alt="" loading="lazy" />
          </button>
          <Box style={{ whiteSpace: 'break-spaces' }}>{imagem.fotografia_template_item?.label}</Box>
        </Box>
      ))}
    </>
  );
}
