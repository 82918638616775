import makeStyles from '@mui/styles/makeStyles';

const ExibirCarrinhoStyles = makeStyles(() => ({
  productImage: {
    width: 90,
    height: 90
  }
}));

export default ExibirCarrinhoStyles;
