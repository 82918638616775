import React from 'react';
import TemplateBase from 'components/template-base/template';
import { InserirEmail } from './inserir-email';
import { DadosAcesso } from './dados-acesso';

function GerarAcessoTemporario() {
  document.title = 'Gerar Acesso Temporario';

  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  return (
    <TemplateBase>
      <h1>Gerar Acesso Temporario</h1>

      {step === 1 && <InserirEmail setStep={setStep} setEmail={setEmail} setPassword={setPassword} />}
      {step === 2 && <DadosAcesso setStep={setStep} email={email} password={password} />}
    </TemplateBase>
  );
}

export default GerarAcessoTemporario;
