import React, { useState, useCallback, useEffect } from 'react';
import { QueroVenderBatch } from 'interfaces';
import { useStatusInterface } from '../../hooks/status-interface';

export function BatchIconsStatus(props: { itens: QueroVenderBatch['itens'] }) {
  const { itens } = props;
  const [batchStatus, setBatchStatus] = useState<number[]>([]);

  const Status10 = useStatusInterface(10);
  const Status20 = useStatusInterface(20);
  const Status30 = useStatusInterface(30);
  const Status50 = useStatusInterface(50);

  /**
   * retrieveAllBatchStatus
   */
  const retrieveAllBatchStatus = useCallback(() => {
    const batch_status: number[] = [];

    for (let index = 0; index < itens.length; index += 1) {
      const itemStatus = itens[index]?.negociacao_atual?.status;

      if (itemStatus) {
        if (batch_status.indexOf(itemStatus) < 0) {
          batch_status.push(itemStatus);
        }
      }
    }

    setBatchStatus(batch_status);
  }, [itens]);

  useEffect(() => {
    retrieveAllBatchStatus();
  }, [retrieveAllBatchStatus]);

  return (
    <>
      <Status10.Icon tooltip={Status10.statusText} disabled={batchStatus.indexOf(10) < 0} />
      <Status20.Icon tooltip={Status20.statusText} disabled={batchStatus.indexOf(20) < 0} />
      <Status30.Icon tooltip={Status30.statusText} disabled={batchStatus.indexOf(30) < 0} />
      <Status50.Icon tooltip={Status50.statusText} disabled={batchStatus.indexOf(50) < 0} />
    </>
  );
}
