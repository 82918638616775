import React, { createContext, useMemo } from 'react';
import Pusher, { Channel } from 'pusher-js';

type PusherContextType = {
  channel: Channel;
};

const PusherContext = createContext<PusherContextType>({} as PusherContextType);

function PusherProvider({ children }: { children: React.ReactElement }) {
  const appKey: string = process.env.REACT_APP_PUSHER_KEY || '';
  const pusher = new Pusher(appKey, { cluster: 'us2' });
  const channel = pusher.subscribe('backoffice-querovender');

  const values = useMemo(() => ({ channel }), [channel]);

  return <PusherContext.Provider value={values}>{children}</PusherContext.Provider>;
}

function usePusher() {
  const context = React.useContext(PusherContext);

  if (!context) {
    throw new Error('usePusher deve usado dentro de um PusherProvider');
  }

  return context;
}

export { PusherProvider, usePusher };
