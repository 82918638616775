import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { PatternFormat, NumericFormatProps } from 'react-number-format';
import { useFormContext, Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import formFieldsGrid from './styles';

type MaskNumberFormatProps = {
  inputRef: (instance: NumericFormatProps | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  hideInfoVendedor: boolean;
  format: 'currency' | 'number';
};

function MaskNumberFormat(props: MaskNumberFormatProps) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name,
            value: values.value
          }
        });
      }}
    />
  );
}

const inputsDefaultConfig: TextFieldProps = {
  variant: 'outlined',
  size: 'small',
  fullWidth: true
};

function TextFieldDefaultPlaceholder(props: TextFieldProps) {
  const { placeholder, name } = props;
  const { control } = useFormContext();

  const inputDefaultprops: TextFieldProps = {
    ...inputsDefaultConfig,
    label: placeholder,
    ...props
  };

  return (
    <Controller
      name={name as string}
      control={control}
      render={({ field: { value, ref, ...renderProps } }) => {
        return (
          <TextField
            inputRef={ref}
            variant="standard"
            {...renderProps}
            {...inputDefaultprops}
            defaultValue={value}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
}

export const withMaskFormat = (format: string) => (BaseComponent: React.ElementType) =>
  function WithMaskFormat(props: TextFieldProps) {
    const { InputProps } = props;

    return (
      <BaseComponent
        {...props}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputComponent: MaskNumberFormat as React.FC,
          inputProps: {
            format
          },
          ...InputProps
        }}
      />
    );
  };

const withDefaultGrid = (BaseComponent: React.FC) =>
  function WithDefaultGrid(props: TextFieldProps) {
    return (
      <Grid item sm={4} xs={12}>
        <BaseComponent {...props} />
      </Grid>
    );
  };

const withReadOnly = (BaseComponent: React.FC<TextFieldProps>) =>
  function WithReadOnly(props: TextFieldProps) {
    const styles = formFieldsGrid();

    return (
      <BaseComponent
        {...props}
        className={styles.inputsReadOnly}
        InputProps={{
          readOnly: true
        }}
      />
    );
  };

export const TextFieldCep = withDefaultGrid(withMaskFormat('#####-###')(TextFieldDefaultPlaceholder));
export const TextFieldCPF = withDefaultGrid(
  withReadOnly(withMaskFormat('###.###.###-##')(TextFieldDefaultPlaceholder))
);
export const TextFieldCelular = withDefaultGrid(withMaskFormat('(##) #####-####')(TextFieldDefaultPlaceholder));
export const TextFieldDefault = withDefaultGrid(TextFieldDefaultPlaceholder);
export const TextFieldReadOnlyDefault = withReadOnly(TextFieldDefault);
