import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { format, addDays, isValid } from 'date-fns';
import Paper from '@mui/material/Paper';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArchiveIcon from '@mui/icons-material/Archive';
import Pagination from '@mui/material/Pagination';
import { Barcode } from 'components/icons';
import TemplateBase from 'components/template-base/template';
import ModalTableLogs from 'components/modal-table-logs';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import { ApiListMeta, QueroVenderItem, LockedBatch, QueroVenderLogs } from 'interfaces';
import { usePusher } from 'context/pusher';
import useDebounce from 'hooks/debounce';
import Swal from 'sweetalert2';
import { ClientClusters } from 'components/client-clusters';
import { useNavigate } from 'react-router-dom';

import AvaliacaoFisicaProdutoStyle from './style';

type ModalLogsProps = {
  logs: QueroVenderLogs[];
  codigo: string | undefined;
  open: boolean;
};

function AvaliacaoFisicaProduto() {
  document.title = `Avaliação | Produtos aguardando avaliação física`;

  const style = AvaliacaoFisicaProdutoStyle();

  const [items, setItems] = useState<QueroVenderItem[]>([]);

  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buscaNome, setBuscaNome] = useState('');
  const [isPagination, setIsPagination] = useState(false);
  const [openLogs, setOpenLogs] = useState<ModalLogsProps>({
    logs: [],
    codigo: undefined,
    open: false
  });
  const [codQuerovenderLogs, setCodQuerovenderLogs] = useState(0); // eslint-disable-line
  const [lockedBatch, setLockedBatch] = useState<LockedBatch[]>([]); // eslint-disable-line
  const [dataEntradaInicio, setDataEntradaInicio] = useState<Date | null>(null); //eslint-disable-line
  const [dataEntradaFinal, setDataEntradaFinal] = useState<Date | null>(null); //eslint-disable-line

  const { channel } = usePusher();// eslint-disable-line
  const nomeCliente = useDebounce(buscaNome, 500);
  // const discardBatch = useDiscardBatch(); // eslint-disable-line

  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  const navigate = useNavigate();

  /**
   * Abre modal de logs
   */

  const openModalLogs = ({ logs, codigo, open }: ModalLogsProps) => setOpenLogs({ logs, codigo, open });

  /**
   * Fecha a modal de logs
   */

  const handleCloseModalOpenLogs = () => {
    setOpenLogs({
      logs: [],
      codigo: undefined,
      open: false
    });
  };

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Pega os dados das Batchs da API
   */
  const getItems = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const dataDe = formatDate(dataEntradaInicio) ? `&data_de=${formatDate(dataEntradaInicio)}` : '';
    const dataAte = formatDate(dataEntradaFinal) ? `&data_ate=${formatDate(dataEntradaFinal)}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}${dataDe}${dataAte}`;

    if (!isPagination) setPage(1);

    QueroVenderItemService.listar(queryMeta, 20).then(response => {
      if (response) {
        setItems(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        // setBatch([]);
      }
      setLoading(false);
    });
  }, [nomeCliente, page, dataEntradaInicio, dataEntradaFinal]); //eslint-disable-line

  const formatDate = (date: Date | string | null) => {
    if (isValid(date)) {
      return format(date as Date, 'yyyy-MM-dd');
    }

    return null;
  };

  /**
   * Controla disparo do enter para localizar produto por bip
   */

  const handleLocalizarPorCodigoBip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const el = document.getElementById('codigoProduto') as HTMLInputElement;

      if (el) {
        QueroVenderItemService.bip(el.value, 20)
          .then(response => {
            setItems([response.data]);
            setMeta(null);
          })
          .catch(e => {
            if (e.response.status === 404) {
              Swal.fire('Oops', 'Código inválido', 'error').then(() => {
                el.value = '';
              });
            }
            if (e.response.status === 400) {
              Swal.fire('Oops', 'Produto não encontrado', 'error').then(() => {
                el.value = '';
              });
            }
          });
      }
    }
  };

  /**
   * Controla campo de data de entrada inicio
   */

  const handleDataEntradaInicioChange = (date: Date | null) => {
    setDataEntradaInicio(date);
  };

  /**
   * Controla campo de data de entrada final
   */

  const handleDataEntradaFinaloChange = (date: Date | null) => {
    setDataEntradaFinal(date);
  };

  /**
   * Controla botão "limpar" form de busca
   */

  const handleLimpar = () => {
    setDataEntradaInicio(null);
    setDataEntradaFinal(null);
    setBuscaNome('');
  };

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getItems();
  }, [getItems]);

  /**
   * Websocket- Pusher
   */
  // useEffect(() => {
  //   channel.bind(
  //     'lock-change',
  //     (params: { codquerovender: number; locked: boolean; lockInfo: { nome: string; email: string } }) => {
  //       setLockedBatch(prevValue => {
  //         if (params.locked) {
  //           return [
  //             ...prevValue,
  //             { codquerovender: params.codquerovender, nome: params.lockInfo.nome, email: params.lockInfo.email }
  //           ];
  //         }
  //         return prevValue.filter(value => value.codquerovender !== params.codquerovender);
  //       });
  //     }
  //   );

  //   return () => {
  //     channel.unbind('lock-change');
  //   };
  // }, [channel, getItems, items]);

  /**
   * Arquiva Remessa
   */
  const handleArquivarRemessa = (codquerovenderitem: number) => {
    Swal.fire({
      title: 'Tem certeza que deseja arquivar o produto?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        QueroVenderItemService.arquivar(codquerovenderitem).then(() => {
          Swal.fire('Produto arquivado com sucesso', '', 'success').then(() => {
            getItems();
          });
        });
      }
    });
  };

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  // const handleLogs = (codquerovender: number) => {
  //   setOpenLogs(true);
  //   setCodQuerovenderLogs(codquerovender);
  // };

  // const isBatchLocked = (codquerovender: number) => {
  //   return lockedBatch?.filter(value => value.codquerovender === codquerovender);
  // };

  // useEffect(() => {
  //   const lockedBatches = batches
  //     .filter(batch => batch.locked)
  //     .map(batch => ({
  //       nome: batch.lock_info?.nome,
  //       email: batch.lock_info?.email,
  //       codquerovender: batch.codquerovender
  //     }));

  //   setLockedBatch(lockedBatches);
  // }, [batches]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      <h1>{`Produtos aguardando avaliação física${renderCount}`}</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <h3>Identificar produto</h3>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  onKeyUp={handleLocalizarPorCodigoBip}
                  InputProps={{
                    id: 'codigoProduto',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Barcode />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="nomeremessa"
                  label="Buscar Vendedor"
                  placeholder="Buscar Vendedor"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={buscaNome}
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>

            <h3>Buscar por data de entrada</h3>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="De:"
                    value={dataEntradaInicio}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        size: 'small',
                        fullWidth: true,
                        name: 'agendamento'
                      }
                    }}
                    onChange={handleDataEntradaInicioChange}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Até:"
                    minDate={dataEntradaInicio ? addDays(dataEntradaInicio, 1) : undefined}
                    disabled={!dataEntradaInicio}
                    format="dd/MM/yyyy"
                    value={dataEntradaFinal}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        size: 'small',
                        fullWidth: true,
                        name: 'agendamento'
                      }
                    }}
                    onChange={handleDataEntradaFinaloChange}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Button color="primary" variant="contained" onClick={handleLimpar}>
                Limpar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Paper>
      {loading && <Box>Carregando ...</Box>}
      {!loading && items.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Vip?</TableCell>
                  <TableCell>Vendedor</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Cadastrado em</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(item => (
                  <TableRow key={item.codquerovenderitem}>
                    <TableCell>
                      <ClientClusters cliente={item?.produto?.vendedora?.cliente} />
                    </TableCell>
                    <TableCell>{item?.produto?.vendedora?.nome ?? '–'}</TableCell>
                    <TableCell>{item?.produto?.vendedora?.codigo ?? '–'}</TableCell>
                    <TableCell>
                      {item?.produto?.datacadastro ? format(new Date(item?.produto?.datacadastro), 'dd/MM/yyyy') : '–'}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar alt="" src={item?.produto?.imagens[0]?.thumbnail} />
                        <Box ml={1}>{item?.produto?.nome}</Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          navigate(`/avaliacao/avaliacao-fisica-produto/${item.codquerovenderitem}`);
                        }}
                      >
                        Avaliar
                      </Button>
                    </TableCell>
                    <TableCell>
                      {item?.produto?.logs && (
                        <Button
                          color="primary"
                          onClick={() => {
                            openModalLogs({
                              logs: item?.produto?.logs || [],
                              codigo: item?.produto?.codigo,
                              open: true
                            });
                          }}
                        >
                          Logs
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => handleArquivarRemessa(item.codquerovenderitem)}
                        size="large"
                      >
                        <ArchiveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>

          <ModalTableLogs
            logs={openLogs.logs}
            title={`Log do produto #${openLogs.codigo}`}
            openLogs={openLogs.open}
            handleCloseModal={handleCloseModalOpenLogs}
          />
        </>
      )}
    </TemplateBase>
  );
}

export default AvaliacaoFisicaProduto;
