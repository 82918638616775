import React from 'react';
import Button from '@mui/material/Button';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
};

export function BotaoValorAceito(props: Props) {
  const { codquerovenderitem, doRefresh, disabled = false } = props;

  const { setLoading } = useLoading();

  const styles = avaliacaoFisicaNegociacaoStyle();

  const handleOperation = () => {
    setLoading(true);
    if (codquerovenderitem) {
      QueroVenderItemService.aprovacao_fisica(codquerovenderitem as number, {
        acao: 'aceitar'
      })
        .then(() => {
          Swal.fire('Operação realizada com sucesso', 'A proposta foi aceita', 'success');
          doRefresh();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('codquerovenderitem does not exist');
    }
  };

  return (
    <Button color="primary" variant="contained" disabled={disabled} className={styles.button} onClick={handleOperation}>
      Valor Aceito
    </Button>
  );
}
