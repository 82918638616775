import makeStyles from '@mui/styles/makeStyles';

const EntradaAcervoStyles = makeStyles(() => ({
  mainPicture: {
    width: '100%',
    position: 'relative'
  },
  mainTitle: {
    fontSize: 25,
    padding: 10
  },
  mainWrapper: {
    maxWidth: 340
  },
  acervoTitle: {},
  mainbutton: {
    position: 'absolute',
    bottom: 26,
    right: 17
  },
  acervoTitlePlaceholder: {
    color: '#ccc'
  },
  paper: {
    padding: 15
  },
  picture: {
    width: 80,
    height: 80,
    margin: 5,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  entradasWrapper: {
    paddingLeft: 10
  },
  pictureTitle: {
    fontSize: 15,
    marginTop: 5,
    marginBottom: 10
  },
  pictureWrapper: {
    marginLeft: 2,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  pictureTitleWrapper: {
    marginLeft: 10,
    marginTop: 5
  }
}));

export default EntradaAcervoStyles;
