import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';
import QueroVenderRemessaService from 'services/quero-vender/QueroVenderRemessaService';
import TemplateBase from 'components/template-base/template';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { EntradaProdutosProvider, useEntradaProdutos } from 'pages/avaliacao/context/entrada-produtos';
import { useForm } from 'react-hook-form';
import { QueroVenderBatch, ApiListMeta } from 'interfaces';
import { usePusher } from 'context/pusher';
import { DatePickerHookForm } from 'components/datepicker-hook-form';
import EditarDataAgendamento from './editar-data-agendamento';
import EditarCodRemessa from './editar-cod-remessa';
import LocalizarRemessa from './localizar-remessa';
import FormEntradaRemessa from './form-entrada-remessa';
import useDiscardBatch from '../hooks/discard-batch';
import BatchRow from './batchRow';
import EntradaProdutosStyle from './style';

/**
 * COMPONENTE
 */
function EntradaProdutos() {
  const style = EntradaProdutosStyle();

  const {
    loading,
    setLoading,
    batches,
    setBatch,
    localizarRemessa,
    nomeCliente,
    debouncedFormaEntrada,
    debouncedDataAgendamento
  } = useEntradaProdutos();
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);
  const [openModalImprimirEtiquetas, setModalImprimirEtiquetas] = useState(false);
  const [isPagination, setIsPagination] = useState(false);
  const [modalCodRemessa, setModalEditCodRemessa] = useState<{
    open: boolean;
    cod_remessa: string;
  }>({
    open: false,
    cod_remessa: ''
  });
  const [modalDataAgendamento, setModalDataAgendamento] = useState<{
    open: boolean;
    data_agendamento: Date | string;
  }>({
    open: false,
    data_agendamento: ''
  });
  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  const { channel } = usePusher();
  const discardBatch = useDiscardBatch();

  document.title = `Pré-Avaliação`;

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Pega os dados das Batchs da API
   */

  const getBatchs = useCallback(() => {
    const perPage = 30;
    const nome =
      nomeCliente || debouncedFormaEntrada || debouncedDataAgendamento
        ? `&search=${nomeCliente}&formarecebimento=${debouncedFormaEntrada}&data_agendamento=${debouncedDataAgendamento}&remessa_codigo=`
        : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${meta?.per_page || perPage}${nome}`;

    if (!isPagination) setPage(1);

    setLoading(true);
    QueroVenderService.list('4', queryMeta).then(response => {
      if (response) {
        setBatch(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setBatch([]);
      }
      setLoading(false);
    });
  }, [page, nomeCliente, debouncedFormaEntrada, debouncedDataAgendamento]); // eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getBatchs();
  }, [getBatchs]);

  /**
   * Websocket- Pusher
   */
  useEffect(() => {
    channel.bind('lock-change', () => {
      getBatchs();
    });

    return () => {
      channel.unbind('lock-change');
    };
  }, [channel, getBatchs]);

  /**
   * Arquiva Remessa
   */
  const handleDiscardRemessa = (codquerovender: number) => {
    const dataDiscard = {
      codquerovender,
      cbSuccess: () => {
        getBatchs();
      }
    };

    discardBatch(dataDiscard);
  };

  /**
   * Localiza Remessa pelo "codRemessa" e abre modal para dar entrada na remessa
   * de produtos
   */
  const handleEntradaRemessa = (remessa: string) => {
    const codremessa = { codremessa: remessa };

    localizarRemessa(codremessa);
  };

  /**
   * Resgata dsdos do form e dispara mensagem
   */
  const { handleSubmit, control } = useForm<{ data_remessa: string }>();

  const onSubmit = (data: { data_remessa: string }) => {
    const formData = { ...data };

    formData.data_remessa = format(new Date(formData.data_remessa), 'yyyy-MM-dd');

    QueroVenderRemessaService.imprimirEtiqutas(formData.data_remessa).then(response => {
      if (response?.data?.remessa_print) {
        window.location.href = response?.data?.remessa_print;
      }
      setModalImprimirEtiquetas(false);
    });
  };

  /**
   * Fecha modal seleção de data
   */
  const handleCloseModal = () => {
    setModalImprimirEtiquetas(false);
  };

  /**
   * Template
   */
  return (
    <>
      <Dialog open={openModalImprimirEtiquetas} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Selecionar data da remessa</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={style.modalContent}>
            <DatePickerHookForm label="Data da remessa" control={control} name="data_remessa" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancelar</Button>
            <Button type="submit" variant="contained" color="primary">
              Imprimir
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {modalCodRemessa.open && (
        <EditarCodRemessa
          cod_remessa={modalCodRemessa.cod_remessa}
          onClose={() =>
            setModalEditCodRemessa({
              open: false,
              cod_remessa: ''
            })
          }
        />
      )}

      {modalDataAgendamento.open && (
        <EditarDataAgendamento
          data_agendamento={modalDataAgendamento.data_agendamento}
          onClose={() =>
            setModalDataAgendamento({
              open: false,
              data_agendamento: ''
            })
          }
        />
      )}

      <TemplateBase>
        <h2>{`Entrada de Produtos ${renderCount}`}</h2>

        <LocalizarRemessa />

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h2>{`Aguardando Chegada ${renderCount}`}</h2>
          </Grid>
          <Grid item>
            <Button onClick={() => setModalImprimirEtiquetas(true)} type="submit" variant="contained" color="primary">
              Imprimir etiqueta de remessa
            </Button>
          </Grid>
        </Grid>
        {loading && <Box>Carregando ...</Box>}
        {!loading && batches.length > 0 && (
          <>
            <Paper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Cliente</TableCell>
                    <TableCell>Produtos</TableCell>
                    <TableCell>Cadastrado em</TableCell>
                    <TableCell align="center">Forma</TableCell>
                    <TableCell align="center">Agendamento</TableCell>
                    <TableCell align="center">Cód Remessa</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches &&
                    batches.map((batch: QueroVenderBatch) => (
                      <BatchRow
                        key={batch.codquerovender}
                        batch={batch}
                        setModalDataAgendamento={setModalDataAgendamento}
                        setModalEditCodRemessa={setModalEditCodRemessa}
                        handleDiscardRemessa={handleDiscardRemessa}
                        handleEntradaRemessa={handleEntradaRemessa}
                      />
                    ))}
                </TableBody>
              </Table>
            </Paper>

            <Box display="flex" justifyContent="center" mt={3}>
              <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
            </Box>
          </>
        )}

        {!loading && batches.length === 0 && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
            <WarningIcon fontSize="large" color="disabled" />
            <Box component="span" ml={2}>
              Nenhuma Remessa encontrada
            </Box>
          </Box>
        )}
        <FormEntradaRemessa />
      </TemplateBase>
    </>
  );
}

function EntradaProdutosPage() {
  return (
    <EntradaProdutosProvider>
      <EntradaProdutos />
    </EntradaProdutosProvider>
  );
}

export default EntradaProdutosPage;
