import React, { useEffect, useState, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { FiltrosVariacao } from 'interfaces';
import { UseFormRegister } from 'react-hook-form';
import AutocompleteFiltrosStyle from './styles';
import { TextFieldHookForms } from '../text-field-hook-forms';

interface Props {
  register?: UseFormRegister<any>; // eslint-disable-line
  filtro: (FiltrosVariacao | string)[];
  disabled?: boolean;
  name: string | undefined;
  label: string;
  defaultValue?: FiltrosVariacao[];
  freeSolo?: boolean;
  getFiltro?: ((filtro: (FiltrosVariacao | string)[]) => void) | null;
}

function AutocompleteFiltros(props: Props) {
  const {
    register = () => ({
      ref: null
    }),
    filtro,
    disabled = false,
    name,
    label,
    defaultValue = undefined,
    getFiltro = null,
    freeSolo = false
  } = props;
  const classes = AutocompleteFiltrosStyle();

  const [autoCompleteValue, setAutoCompleteValue] = useState<(FiltrosVariacao | string)[]>([]);

  useEffect(() => {
    if (defaultValue) {
      setAutoCompleteValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    getFiltro?.(autoCompleteValue);
  }, [autoCompleteValue, getFiltro]);

  const inputDefautValue = useMemo(() => {
    if (autoCompleteValue) {
      return autoCompleteValue;
    }

    return defaultValue;
  }, [defaultValue, autoCompleteValue]);

  const { ref, ...restProgps } = useMemo(() => {
    if (inputDefautValue) {
      return register?.(`${name as string}`, {
        value: inputDefautValue.map(checkIfValueIsStringOrVariacao),
        setValueAs() {
          return inputDefautValue.map(checkIfValueIsStringOrVariacao);
        }
      });
    }
    return {
      ref: null
    };
  }, [inputDefautValue, register, name]);

  function checkIfValueIsStringOrVariacao(item: FiltrosVariacao | string) {
    if (typeof item === 'string') {
      return item;
    }
    return item.codfiltrovariacao;
  }

  return (
    <Autocomplete
      freeSolo={freeSolo}
      multiple
      filterSelectedOptions
      options={filtro}
      getOptionLabel={option => (typeof option === 'string' ? option : option.nome)}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === 'string' || typeof value === 'string') {
          return option === value;
        }
        if (option.slug && value.slug) {
          return option.slug === value.slug;
        }

        return false;
      }}
      size="small"
      onChange={(e, values) => {
        setAutoCompleteValue(values);
        getFiltro?.(values);
      }}
      disabled={disabled}
      value={inputDefautValue}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          return (
            <Chip label={typeof option === 'string' ? option : option.nome} size="small" {...getTagProps({ index })} />
          );
        });
      }}
      renderInput={params => {
        return (
          <TextFieldHookForms
            ref={ref}
            {...restProgps}
            className={classes.textInput}
            {...params}
            label={label}
            variant="outlined"
          />
        );
      }}
    />
  );
}

export default AutocompleteFiltros;
