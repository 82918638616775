import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import AllInboxIcon from '@mui/icons-material/AllInbox';

import SettingsIcon from '@mui/icons-material/Settings';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InputIcon from '@mui/icons-material/Input';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import MenuItem from 'components/template-base/menu/menu-item';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import HandshakeIcon from '@mui/icons-material/Handshake';

import AddReactionIcon from '@mui/icons-material/AddReaction';

import RestringeMenu from 'helpers/restringe-menu';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { SubmenuItem } from './submenu-item';
import MenuStyles from './styles';

export function MenuPrincipal() {
  const style = MenuStyles();

  const location = useLocation();
  const [activePage, setactivePage] = useState('');

  useEffect(() => {
    setactivePage(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <MenuItem name="Dashboard" link="/home" icon={<DashboardIcon />} activePage={activePage} />

      <MenuItem name="Ferramentas" icon={<BuildIcon />} activePage={activePage} pathBase="ferramentas" submenu>
        <List component="div" disablePadding>
          <RestringeMenu>
            <>
              <ListItemButton
                component={Link}
                to="/ferramentas/comissionamento"
                selected={activePage === '/ferramentas/comissionamento'}
                className={style.nested}
              >
                <ListItemText primary="Comissões EU" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/ferramentas/comissionadas"
                selected={activePage === '/ferramentas/comissionadas'}
                className={style.nested}
              >
                <ListItemText primary="Comissionadas" />
              </ListItemButton>
            </>
          </RestringeMenu>
          <RestringeMenu
            extra={[
              'clara@etiquetaunica.com.br',
              'barbara@etiquetaunica.com.br',
              'emily@etiquetaunica.com.br',
              'thaynara@etiquetaunica.com.br',
              'b2c@etiquetaunica.com.br'
            ]}
          >
            <ListItemButton
              component={Link}
              to="/ferramentas/associar-quiosque"
              selected={activePage === '/ferramentas/associar-quiosque'}
              className={style.nested}
            >
              <ListItemText primary="Associar ao Quiosque" />
            </ListItemButton>
          </RestringeMenu>

          <ListItemButton
            component={Link}
            to="/ferramentas/frete-gratis"
            selected={activePage === '/ferramentas/frete-gratis'}
            className={style.nested}
          >
            <ListItemText primary="Frete Grátis" />
          </ListItemButton>
          <RestringeMenu
            extra={[
              'mariana@etiquetaunica.com.br',
              'b2c@etiquetaunica.com.br',
              'thiago@etiquetaunica.com.br',
              'andregumieri@gmail.com',
              'ariana@etiquetaunica.com.br',
              'nataliabraga@etiquetaunica.com.br'
            ]}
          >
            <ListItemButton
              component={Link}
              to="/ferramentas/excluir-peca"
              selected={activePage === '/ferramentas/excluir-peca'}
              className={style.nested}
            >
              <ListItemText primary="Excluir Peça" />
            </ListItemButton>
          </RestringeMenu>

          <ListItemButton
            component={Link}
            to="/ferramentas/url-redefinir-senha"
            selected={activePage === '/ferramentas/url-redefinir-senha'}
            className={style.nested}
          >
            <ListItemText primary="URL Redefinir Senha" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/ferramentas/gerar-carrinho"
            selected={activePage === '/ferramentas/gerar-carrinho'}
            className={style.nested}
          >
            <ListItemText primary="Gerar Carrinho" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/ferramentas/associar-vendedor-interno-canal"
            selected={activePage === '/ferramentas/associar-vendedor-interno-canal'}
            className={style.nested}
          >
            <ListItemText primary="Associar Vendedor Interno | Canal" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/ferramentas/gerar-acesso-temporario"
            selected={activePage === '/ferramentas/gerar-acesso-temporario'}
            className={style.nested}
          >
            <ListItemText primary="Gerar Acesso Temporário" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/ferramentas/modelos/listar"
            selected={activePage === '/ferramentas/modelos/listar'}
            className={style.nested}
          >
            <ListItemText primary=" Modelos" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem
        name="Pré-Avaliação"
        icon={<ImageSearchIcon />}
        activePage={activePage}
        pathBase="pre-avaliacao"
        submenu
      >
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/pre-avaliacao"
            selected={activePage === '/pre-avaliacao'}
            className={style.nested}
          >
            <ListItemText primary="Pré avaliação" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/pre-avaliacao/pre-avaliacao-negociacao"
            selected={activePage === '/pre-avaliacao/pre-avaliacao-negociacao'}
            className={style.nested}
          >
            <ListItemText primary="Negociação de pré avaliação" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/pre-avaliacao/agendamento"
            selected={activePage === '/pre-avaliacao/agendamento'}
            className={style.nested}
          >
            <ListItemText primary="Agendamento/Postagem" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/pre-avaliacao/entrada"
            selected={activePage === '/pre-avaliacao/entrada'}
            className={style.nested}
          >
            <ListItemText primary="Aguardando Chegada" />
          </ListItemButton>
        </List>
      </MenuItem>
      <MenuItem name="Parceiros" icon={<HandshakeIcon />} activePage={activePage} pathBase="parceiros" submenu>
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/parceiros/moderacao-produtos"
            selected={activePage === '/parceiros/moderacao-produtos'}
            className={style.nested}
          >
            <ListItemText primary="Moderação de produtos" />
          </ListItemButton>
        </List>
      </MenuItem>
      <MenuItem name="Entrada Manual" link="/entrada-manual" icon={<InputIcon />} activePage={activePage} />
      <MenuItem
        name="Painel Marketing"
        icon={<AddReactionIcon />}
        activePage={activePage}
        pathBase="painel-marketing"
        submenu
      >
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/painel-marketing/campanhas"
            selected={activePage === '/painel-marketing/campanhas'}
            className={style.nested}
          >
            <ListItemText primary="Campanhas" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/painel-marketing/gerenciar-faixas"
            selected={activePage === '/painel-marketing/gerenciar-faixas'}
            className={style.nested}
          >
            <ListItemText primary="Gerenciar Faixa" />
          </ListItemButton>
        </List>
      </MenuItem>
      <MenuItem name="Avaliação" icon={<ZoomInIcon />} activePage={activePage} pathBase="avaliacao" submenu>
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/avaliacao/avaliacao-fisica-produto"
            selected={activePage === '/avaliacao/avaliacao-fisica-produto'}
            className={style.nested}
          >
            <ListItemText primary="Avaliação Física Por Produto" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/avaliacao/avaliacao-fisica-batch"
            selected={activePage === '/avaliacao/avaliacao-fisica-batch'}
            className={style.nested}
          >
            <ListItemText primary="Avaliação Física Por Batch" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/avaliacao/avaliacao-fisica-negociacao"
            selected={activePage === '/avaliacao/avaliacao-fisica-negociacao'}
            className={style.nested}
          >
            <ListItemText primary="Negociação Avaliação Física" />
          </ListItemButton>
        </List>
      </MenuItem>
      <MenuItem name="Fotografia" icon={<PhotoCameraIcon />} activePage={activePage} pathBase="fotografia" submenu>
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/fotografia/picking-fotos"
            selected={activePage === '/fotografia/picking-fotos'}
            className={style.nested}
          >
            <ListItemText primary="Picking de Fotos" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/fotografia/upload-imagens"
            selected={activePage === '/fotografia/upload-imagens'}
            className={style.nested}
          >
            <ListItemText primary="Upload de Fotos" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/fotografia/adicionar-fotos-parceiro"
            selected={activePage === '/fotografia/adicionar-fotos-parceiro'}
            className={style.nested}
          >
            <ListItemText primary="Adicionar fotos parceiro" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem
        name="Devolução de vendedor"
        icon={<AssignmentReturnedIcon />}
        activePage={activePage}
        pathBase="devolucao"
        submenu
      >
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/devolucao"
            selected={activePage === '/devolucao'}
            className={style.nested}
          >
            <ListItemText primary="Devolução" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/devolucao/picking-devolucao"
            selected={activePage === '/devolucao/picking-devolucao'}
            className={style.nested}
          >
            <ListItemText primary="Picking Devolução" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/devolucao/checking-devolucao"
            selected={activePage === '/devolucao/checking-devolucao'}
            className={style.nested}
          >
            <ListItemText primary="Checking Devolução" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/devolucao/packing-devolucao"
            selected={activePage === '/devolucao/packing-devolucao'}
            className={style.nested}
          >
            <ListItemText primary="Packing Devolucao" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/devolucao/picking-doacao"
            selected={activePage === '/devolucao/picking-doacao'}
            className={style.nested}
          >
            <ListItemText primary="Picking Doação" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/devolucao/etiquetas-devolucao"
            selected={activePage === '/devolucao/etiquetas-devolucao'}
            className={style.nested}
          >
            <ListItemText primary="Etiquetas de Devolução" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem
        name="Expedição de Pedidos"
        icon={<ShoppingCartIcon />}
        activePage={activePage}
        pathBase="expedicao"
        submenu
      >
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/expedicao/picking"
            selected={activePage === '/expedicao/picking'}
            className={style.nested}
          >
            <ListItemText primary="Picking" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/expedicao/checking"
            selected={activePage === '/expedicao/checking'}
            className={style.nested}
          >
            <ListItemText primary="Checking" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/expedicao/packing"
            selected={activePage === '/expedicao/packing'}
            className={style.nested}
          >
            <ListItemText primary="Packing" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/expedicao/nao-encontrados"
            selected={activePage === '/expedicao/nao-encontrados'}
            className={style.nested}
          >
            <ListItemText primary="Produtos não encontrados" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/expedicao/marcar-localizado"
            selected={activePage === '/expedicao/marcar-localizado'}
            className={style.nested}
          >
            <ListItemText primary="Marcar como localizado" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem name="Acervo" icon={<AllInboxIcon />} activePage={activePage} pathBase="acervo" submenu>
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/acervo/entrada-acervo"
            selected={activePage === '/acervo/entrada-acervo'}
            className={style.nested}
          >
            <ListItemText primary="Entrada no acervo" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/acervo/localizar"
            selected={activePage === '/acervo/localizar'}
            className={style.nested}
          >
            <ListItemText primary="Localizar produto" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/acervo/separacao-produtos-por-preco"
            selected={activePage === '/acervo/separacao-produtos-por-preco'}
            className={style.nested}
          >
            <ListItemText primary="Separação Por Preço" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/acervo/reetiquetagem"
            selected={activePage === '/acervo/reetiquetagem'}
            className={style.nested}
          >
            <ListItemText primary="Reetiquetagem" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem
        name="Ferramentas Administrativas"
        icon={<SettingsIcon />}
        activePage={activePage}
        pathBase="administrativo"
        submenu
      >
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/administrativo/forcar-captura"
            selected={activePage === '/administrativo/forcar-captura'}
            className={style.nested}
          >
            <ListItemText primary="Forçar captura" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/administrativo/cancelar-pedido"
            selected={activePage === '/administrativo/cancelar-pedido'}
            className={style.nested}
          >
            <ListItemText primary="Cancelar pedido" />
          </ListItemButton>

          <SubmenuItem title="Transferências" pathBase="administrativo/transferencias" activePage={activePage}>
            <List component="div">
              <ListItemButton
                component={Link}
                to="/administrativo/transferencias/criar-agendar"
                selected={activePage === '/administrativo/transferencias/criar-agendar'}
                className={style.doubleNested}
              >
                <ListItemText primary="Criar/Agendar" />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/administrativo/transferencias/listar"
                selected={activePage === '/administrativo/transferencias/listar'}
                className={style.doubleNested}
              >
                <ListItemText primary="Lista de trasferências" />
              </ListItemButton>
            </List>
          </SubmenuItem>

          <ListItemButton
            component={Link}
            to="/administrativo/lifecycle-voucher"
            selected={activePage === '/administrativo/lifecycle-voucher'}
            className={style.nested}
          >
            <ListItemText primary="Lifecycle Voucher" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/administrativo/pagamentos-vendedoras"
            selected={activePage === '/administrativo/pagamentos-vendedoras'}
            className={style.nested}
          >
            <ListItemText primary="Pagamentos Vendedoras" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to="/administrativo/unificar-conta-cliente"
            selected={activePage === '/administrativo/unificar-conta-cliente'}
            className={style.nested}
          >
            <ListItemText primary="Unificar Contas" />
          </ListItemButton>
        </List>
      </MenuItem>

      <MenuItem name="Relatórios" icon={<InsertDriveFileIcon />} activePage={activePage} pathBase="relatorios" submenu>
        <List component="div" disablePadding>
          <ListItemButton
            component={Link}
            to="/relatorios/vendas-vendedora"
            selected={activePage === '/relatorios/vendas-vendedora'}
            className={style.nested}
          >
            <ListItemText primary="Vendas por vendedora" />
          </ListItemButton>
        </List>
      </MenuItem>
    </div>
  );
}
