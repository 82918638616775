import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { ApiListMeta, Pedido } from 'interfaces';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { useLoading } from 'context/loading';
import CheckingService from 'services/checking';
import { CheckingLayout } from './checking-layout';

type CheckingProviderData = {
  setMeta: React.Dispatch<React.SetStateAction<ApiListMeta | undefined>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPedidos: React.Dispatch<React.SetStateAction<Pedido[]>>;
  pedidos: Pedido[];
  meta: ApiListMeta | undefined;
  filterPedidos: Criteria;
  filterLocalizarBip: Criteria;
  fetchPedidos: () => void;
  fetchPedidoPorCodigo: (codigo: string) => void;
  hasPagination: boolean | undefined;
  path: string;
  page: number;
  title: string;
  subtitle: string;
  devolucao?: boolean;
  detailUrl: string;
};

const CheckingContext = createContext<CheckingProviderData>({} as CheckingProviderData);

type CheckingProviderProps = {
  filterPedidos: Criteria;
  filterLocalizarBip: Criteria;
  path: string;
  title: string;
  subtitle: string;
  devolucao?: boolean;
  detailUrl: string;
};

type CheckingProviderType = {
  children: React.ReactElement;
} & CheckingProviderProps;

function CheckingProvider({
  children,
  filterPedidos,
  filterLocalizarBip,
  path,
  title,
  subtitle,
  devolucao = false,
  detailUrl
}: CheckingProviderType) {
  const [meta, setMeta] = useState<ApiListMeta>();
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [page, setPage] = useState<number>(1);
  const { setLoading } = useLoading();

  const hasPagination = useMemo(() => {
    return meta && meta.last_page > 1;
  }, [meta]);

  const fetchPedidos = useCallback(() => {
    setLoading(true);
    filterPedidos.add('page', `${page}`);

    CheckingService.pedidos(filterPedidos.getQuery())
      .then(response => {
        setPedidos(response.data.data);
        setMeta(response.data.meta);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterPedidos, page, setLoading]);

  const fetchPedidoPorCodigo = useCallback(
    (codigo: string) => {
      setLoading(true);

      filterPedidos.add('codigo', `${codigo}`);
      filterPedidos.add('page', `1`);

      CheckingService.pedidos(filterPedidos.getQuery())
        .then(response => {
          setPedidos(response.data.data);
          setMeta(response.data.meta);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filterPedidos, setLoading]
  );

  const values = useMemo(
    () => ({
      setMeta,
      setPage,
      setPedidos,
      pedidos,
      meta,
      filterPedidos,
      filterLocalizarBip,
      fetchPedidos,
      hasPagination,
      path,
      fetchPedidoPorCodigo,
      page,
      title,
      subtitle,
      devolucao,
      detailUrl
    }),
    [
      setMeta,
      setPage,
      setPedidos,
      pedidos,
      meta,
      filterPedidos,
      filterLocalizarBip,
      fetchPedidos,
      hasPagination,
      path,
      fetchPedidoPorCodigo,
      page,
      title,
      subtitle,
      devolucao,
      detailUrl
    ]
  );

  return <CheckingContext.Provider value={values}>{children}</CheckingContext.Provider>;
}

function useCheckingProvider(): CheckingProviderData {
  const context = useContext(CheckingContext);

  if (!context) {
    throw new Error('useCheckingProvider deve usado dentro de um CheckingProvider');
  }

  return context;
}

CheckingProvider.CheckingLayout = CheckingLayout;

export { CheckingProvider, useCheckingProvider };
