import api from '../api';

const forceCapture = (codPedido: string) => {
  return api.post(`pedidos/${codPedido}/force-capture`);
};

const canceOrder = (codPedido: string, notify: number) => {
  return api.post(`pedidos/${codPedido}/cancel?notify=${notify}`);
};

const buscar_pedido = (codigo: string) => {
  return api.get(`/pedidos/${codigo}`);
};

export default {
  forceCapture,
  canceOrder,
  buscar_pedido
};
