import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CustomCheckBoxType } from '.';

type Props = {
  ref_checkboxes: React.RefObject<CustomCheckBoxType>;
  disabled?: boolean;
};

type RefMethods = {
  clearSelection: () => void;
  isChecked: () => boolean;
  setSelection: (value: boolean) => void;
};

export const CheckboxSelectAll = forwardRef<RefMethods, Props>((props: Props, ref) => {
  const { ref_checkboxes, disabled = false } = props;
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    if (ref_checkboxes && ref_checkboxes.current) {
      for (let i = 0; i < ref_checkboxes.current.length; i += 1) {
        ref_checkboxes.current[i].handleChange(e, value);
        setChecked(value);
      }
    }
  };

  const isChecked = () => checked;

  const setSelection = (value: boolean) => {
    setChecked(value);
  };

  const clearSelection = () => {
    setChecked(false);
  };

  useImperativeHandle(ref, () => ({
    clearSelection,
    isChecked,
    setSelection
  }));

  return (
    <FormControlLabel
      style={{
        marginLeft: 0
      }}
      control={<Checkbox checked={checked} color="primary" onChange={handleChange} disabled={disabled} />}
      label="Selecionar Todos"
    />
  );
});
