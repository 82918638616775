import React, { createContext, useCallback, useState, useMemo } from 'react';

interface EditingModeData {
  id: number;
  editing: boolean;
}

interface AvaliacaoContextData {
  listEditingMode: EditingModeData[];
  setEditingMode(editing: EditingModeData): void;
  clearEditing(): void;
}

const AvaliacaoContext = createContext<AvaliacaoContextData>({} as AvaliacaoContextData);

function AvaliacaoProvider({ children }: { children: React.ReactElement }) {
  const [listEditingMode, setListEditingMode] = useState<EditingModeData[]>([]);

  const setEditingMode = useCallback(
    (editing: EditingModeData) => {
      const codItem = editing.id;
      const hasItemInList = listEditingMode.some(val => val.id === codItem);

      // Verifica de existe algum item na lista de produtos editados, caso não exista adiciona
      if (!hasItemInList) {
        const item: EditingModeData[] = [editing];
        setListEditingMode([...listEditingMode, ...item]);

        return;
      }

      // Verifica o item que está sendo editado e muda seu "status"
      const listItens = [...listEditingMode];
      listItens.forEach((val, key) => {
        if (val.id === codItem) {
          listItens[key].editing = editing.editing;
        }
      });

      setListEditingMode(listItens);
    },
    [listEditingMode]
  );

  // Limpa estado de editar
  const clearEditing = useCallback(() => {
    setListEditingMode([]);
  }, []);

  const values = useMemo(
    () => ({ listEditingMode, setEditingMode, clearEditing }),
    [listEditingMode, setEditingMode, clearEditing]
  );

  return <AvaliacaoContext.Provider value={values}>{children}</AvaliacaoContext.Provider>;
}

function useAvaliacao() {
  const context = React.useContext(AvaliacaoContext);

  if (!context) {
    throw new Error('useAvaliacao deve usado dentro de um AvaliacaoProvider');
  }

  return context;
}

export { AvaliacaoProvider, useAvaliacao };
