import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';
import CreateIcon from '@mui/icons-material/Create';

import TemplateBase from 'components/template-base/template';

import { LojaComissionada } from 'interfaces/comissionada';
import { ApiListMeta } from 'interfaces';
import ComissionadaService from 'services/comissionada/ComissionadaService';
import useDebounce from 'hooks/debounce';
import ComissionadaStyle from './style';

function ComissionadasLista() {
  const style = ComissionadaStyle();
  document.title = 'Ferramentas';

  const [lojas, setLojas] = useState<LojaComissionada[]>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buscaNome, setBuscaNome] = useState('');
  const [isPagination, setIsPagination] = useState(false);

  const nomeLoja = useDebounce(buscaNome, 500);

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Pega os dados das lojas via API
   */
  const getLojas = useCallback(() => {
    const perPage = 20;
    const nome = nomeLoja ? `&search=${nomeLoja}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}`;

    if (!isPagination) setPage(1);

    ComissionadaService.getListaLojas(queryMeta).then(response => {
      if (response) {
        if (response?.data?.meta) {
          const newMeta = {
            current_page: response?.data?.meta.page,
            // TODO: Corrigir erro de Lint
            from: (response?.data?.meta.page - 1) * response?.data?.meta.per_page + 1, // eslint-disable-line
            last_page: response?.data?.meta.pages,
            path: `${process.env.REACT_APP_API_URL}lojas`,
            per_page: response?.data?.meta.per_page,
            // TODO: Corrigir erro de Lint
            to: response?.data?.meta.page * response?.data?.meta.per_page, //eslint-disable-line
            total: response?.data?.meta.total
          };
          setMeta(newMeta);
        }

        setLojas(response?.data.data);
        // setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setLojas([]);
      }
      setLoading(false);
    });
  }, [nomeLoja, page]); //eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getLojas();
  }, [getLojas]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      <h1>Comissionadas</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <h3>Buscar lojas</h3>
                <TextField
                  name="nomecodigoloja"
                  label="Nome ou código da loja"
                  placeholder="Nome ou código da loja"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {loading && <div>Carregando...</div>}
      {!loading && lojas.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Código Loja</TableCell>
                  <TableCell>Vendedor/Loja</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {lojas &&
                  lojas.map(item => (
                    <TableRow key={item.codloja}>
                      <TableCell>{item.codloja}</TableCell>
                      <TableCell>
                        {item.nome} {item.sobrenome}
                      </TableCell>
                      <TableCell>{item.codigo}</TableCell>
                      <TableCell align="right">
                        <Link to={`comissionadas/${item.codloja}`}>
                          <CreateIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
      {!loading && lojas.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma loja comissionada encontrada
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default ComissionadasLista;
