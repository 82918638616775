import Swal from 'sweetalert2';

import QueroVenderService from 'services/quero-vender/QueroVenderService';

interface MergeBatchsData {
  codquerovender: number;
  codBatchs: number[];
  cbSuccess(): void;
  cbFinally(): void;
}

export default function useMergeBatchs() {
  const setMergeBatchs = (props: MergeBatchsData) => {
    const { codquerovender, codBatchs, cbSuccess, cbFinally } = props;

    Swal.fire({
      title: 'Tem certeza que deseja unir as remessas?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    })
      .then(result => {
        if (!result.isConfirmed) return;

        const dataMerge = { batches: codBatchs };

        QueroVenderService.merge(codquerovender, dataMerge)
          .then(response => {
            if (response) {
              Swal.fire('Remessas mescladas', '', 'success').then(() => {
                cbSuccess();
              });
            }
          })
          .finally(() => {
            cbFinally();
          });
      })
      .finally(() => {
        cbFinally();
      });
  };

  return setMergeBatchs;
}
