import React, { useRef, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import { Box, TextField, InputAdornment, ClickAwayListener, Card, Avatar, Typography } from '@mui/material';
import Loading from 'components/loading';

import { Barcode } from 'components/icons';

import BipLocalizacaoService from 'services/bip-localizacao/BipLocalizacaoV2Service';

import SeparacaoStyle from './style';

interface Produto {
  codproduto: number;
  codprodutovariacao: number;
  codigo: string;
  nome: string;
  imagens: {
    codprodutoimagem: number;
    original: string;
    large: string;
    medium: string;
    small: string;
    thumbnail: string;
  }[];
  precificacao: {
    venda_a_vista: number;
  };
}

function SeparacaoPorPreco() {
  document.title = 'Separação de produtos por preço';

  const [produtoBipado, setProdutoBipado] = useState<Produto>();
  const [loadingFull, setLoadingFull] = useState<boolean>(false);
  const [barCode, setBarCode] = useState<string>('');
  const classes = SeparacaoStyle();

  const codBarras = useRef<HTMLInputElement>(null);

  const barCodeFocus = () => {
    codBarras?.current?.focus();
  };

  // eslint-disable-next-line
  const handleClickAway = (e: any) => {
    if (e.target.nodeName !== 'SELECT' && e.target?.parentNode?.dataset?.name !== 'bt-pular') {
      barCodeFocus();
    }
  };

  const limparBarCodeInput = () => {
    setBarCode('');
    barCodeFocus();
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (codBarras?.current) {
        setProdutoBipado(undefined);
        setLoadingFull(true);

        BipLocalizacaoService.localizar(codBarras?.current?.value)
          .then(response => {
            // poderá ser substituído por uma função
            // que classifique produtos com diferentes critérios
            setProdutoBipado(response.data);
            limparBarCodeInput();
            setLoadingFull(false);
            barCodeFocus();
          })
          .catch(() => {
            limparBarCodeInput();
            setLoadingFull(false);
            barCodeFocus();
          });
      }
    }
  };

  const handleOnChangeBarCode = (barCodeString: string) => {
    setBarCode(barCodeString);
  };

  const destinoProduto = (produto: Produto) => {
    return produto.precificacao.venda_a_vista < 2500 ? 'saude' : 'itaim';
  };

  return (
    <TemplateBase>
      <h1>Separação de produtos por preço</h1>

      {produtoBipado && (
        <>
          <Card className={classes.card}>
            <div className={classes.cardContent}>
              <Box display="inline-flex" alignItems="flex-start" width="100%">
                {produtoBipado.imagens.length > 0 && (
                  <Avatar variant="square" className={classes.avatar} src={produtoBipado.imagens[0].medium} />
                )}

                <div>
                  <div className={classes.wrapperInfos}>
                    <Box mb={1} display="inline-flex" alignItems="center">
                      <Typography className={classes.textInfos}>
                        <div>COD: {produtoBipado.codigo}</div>
                      </Typography>
                    </Box>
                    <Typography className={classes.textInfosTitle}>
                      <strong>{produtoBipado.nome}</strong>
                    </Typography>
                    <Typography className={classes.textInfosTitle}>
                      <strong>Preço:</strong> R$ {produtoBipado.precificacao.venda_a_vista.toFixed(2).replace('.', ',')}
                    </Typography>
                  </div>
                </div>
              </Box>
            </div>
          </Card>

          {destinoProduto(produtoBipado) === 'saude' && (
            <Card className={classes.saudePanel}>
              <Box textAlign="center">
                <Typography className={classes.textDestino}>SAÚDE</Typography>
              </Box>
            </Card>
          )}

          {destinoProduto(produtoBipado) === 'itaim' && (
            <Card className={classes.itaimPanel}>
              <Box textAlign="center">
                <Typography className={classes.textDestino}>ITAIM</Typography>
              </Box>
            </Card>
          )}
        </>
      )}

      <div className={classes.campoCodBarras}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            name="codbarra"
            size="small"
            id="field-cod-barras"
            variant="outlined"
            autoFocus
            onKeyUp={handleInputKeyPress}
            value={barCode}
            onChange={e => {
              handleOnChangeBarCode(e.target.value);
            }}
            inputRef={codBarras}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Barcode />
                </InputAdornment>
              )
            }}
          />
        </ClickAwayListener>
      </div>
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default SeparacaoPorPreco;
