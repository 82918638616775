import api from '../api';

/**
 *
 * @param codigo
 * @returns
 */
const buscarLoja = (codigo: string) => {
  return api.get(`lojas?search=${codigo}`);
};

/**
 *  busca apenas uma loja/vendedor por 'codigo'
 *
 * @param codigo
 * @returns
 *
 */
const getLojaByCodigo = (codigo: string) => {
  return api.get(`loja/${codigo}`);
};

const gerarEtiqueta = (codigo: string, quantidade: number, observacoes: string) => {
  const data = {
    codigo_loja: codigo,
    quantidade_pecas: quantidade,
    observacoes
  };
  return api.post('devolucoes/generica', data);
};

const getDevolucoes = (codigo: string) => {
  const queryCodLoja = codigo ? `?codigo_loja=${codigo}` : '';
  return api.get(`devolucoes${queryCodLoja}`);
};

const resgatarEtiqueta = (id: number) => {
  return api.get(`devolucoes/resgatar-etiqueta/${id}`);
};

const cancelarEtiqueta = (id: number, motivo_cancelamento: string) => {
  return api.patch(`devolucoes/cancelar/${id}`, { motivo_cancelamento });
};

const devolverTodos = (id: number) => {
  return api.post(`devolucoes/${id}/devolver-tudo`);
};

export default {
  buscarLoja,
  getLojaByCodigo,
  gerarEtiqueta,
  resgatarEtiqueta,
  getDevolucoes,
  cancelarEtiqueta,
  devolverTodos
};
