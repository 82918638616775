import React, { memo } from 'react';
import Fotos from 'pages/avaliacao/components/Fotos';
import { QueroVenderItemFoto } from 'interfaces';
import VideoPlaceholder from '../../../assets/images/video-file.png';
import styles from './styles';

interface Props {
  fotos: QueroVenderItemFoto[];
}

export const ProdutoImagens = memo((props: Props) => {
  const { fotos } = props;
  const classes = styles();

  return !fotos?.length ? (
    <div>Nenhuma foto enviada</div>
  ) : (
    <div className={classes.wrapFotos}>
      {fotos?.map(value => {
        return (
          <Fotos
            codquerovenderfoto={value.codquerovenderfoto}
            imagem={value.tipo_midia === 'video' ? VideoPlaceholder : value.imagem}
            url={value.imagem}
            key={value.imagem.split('quero-vender-fotos/')[1] + Math.random()}
          />
        );
      })}
    </div>
  );
});
