import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import createTheme from '@mui/material/styles/createTheme';
import InputCurrency from 'components/form/input-currency';
import ComissionamentoService from 'services/comissionamento/ComissionamentoService';

import TemplateBase from 'components/template-base/template';
import { CategoriaComissionamento, FaixaComissionamento, ComissionamentoPayload } from 'interfaces/comissionamento';
import ComissionamentoDetailsStyle from './style';
import {
  decimalToMoney,
  moneyToDecimal,
  decimalToPercentage,
  percentageToDecimal,
  tempId
} from '../ComissionamentoHelpers/ComissionamentoHelpers';

import api from '../../../services/api';

// referente aos paineis
interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tab: {
    backgroundColor: theme.palette.background.paper
  }
}));
// end referente aos paineis

function ComissionamentoDetailsPage() {
  document.title = 'Ferramentas';

  const id = parseInt(useParams<{ id: string }>().id as string, 10);
  const [loading, setLoading] = useState<boolean>(true);
  const [tabelaComissionamentoAPIOriginal, setTabelaComissionamentoAPIOriginal] = useState<FaixaComissionamento[]>([]);
  const [tabelaComissionamentoAPI, setTabelaComissionamentoAPI] = useState<FaixaComissionamento[]>([]);
  const [tabelaComissionamentoNovosRegistros, setTabelaComissionamentoNovosRegistros] = useState<
    FaixaComissionamento[]
  >([]);
  const [tabelaComissionamentoRender, setTabelaComissionamentoRender] = useState<FaixaComissionamento[]>([]);
  const [tabelaHistorico, setTabelaHistorico] = useState<FaixaComissionamento[]>([]);
  const [categoria, setCategoria] = useState<CategoriaComissionamento>({
    codcategoria: 0,
    codcategoriapai: 0,
    nome: ''
  });
  const [faixasInvalidas, setFaixasInvalidas] = useState<Array<string | number>>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const style = ComissionamentoDetailsStyle();
  const classes = useStyles();

  // funções da tela

  // troca de tabs ativas
  // eslint-disable-next-line
  const mudaTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setTabValue(newValue);
  };

  /**
   *
   * @param itemId
   * @returns boolean
   * retorna true se a faixa a ser enviada tiver campos invalidos ou conflitantes
   */
  const faixaEstaInvalida = (itemId: string | number) => {
    /* const temp: string[] | number[] = faixasInvalidas.indexOf(itemId) > -1;
    // eslint-disable-next-line
    return temp; */
    return faixasInvalidas.some(e => {
      return e === itemId;
    });
  };

  /* alteraProp
  continuidade do evento onChange dos inputs da tela de detalhe */
  /* modifica propriedades da faixa de comissão */
  /* idItem: int | string: id do banco ou id temporario (string hash) para novos registros
      tipo: string ('registro'|'temp')  (registros do banco | registros a serem adicionados, respectivamente)
      prop: string: a chave da propriedade a ser alterada
      valor: any: o valor da propriedade a ser alterada
  */
  const alteraProp = (idItem: string | number, tipo: string, prop: string, valor: string | number | boolean) => {
    // novos registros
    if (tipo === 'temp') {
      const index = tabelaComissionamentoNovosRegistros.map(e => e.id).indexOf(idItem);
      if (tabelaComissionamentoNovosRegistros[index][prop] !== valor) {
        const tempTabela = [...tabelaComissionamentoNovosRegistros];
        tempTabela[index][prop] = valor;
        tempTabela[index].dirty = true;
        setTabelaComissionamentoNovosRegistros(tempTabela);
      }
    } else if (tipo === 'registro') {
      const index = tabelaComissionamentoAPI.map(e => e.id).indexOf(idItem);
      if (tabelaComissionamentoAPI[index][prop] !== valor) {
        const tempTabela = [...tabelaComissionamentoAPI];
        tempTabela[index][prop] = valor;
        tempTabela[index].dirty = true;
        setTabelaComissionamentoAPI(tempTabela);
      }
    }
  };

  // adiciona novo registro vazio a tabela de novos registros
  const adicionarNovoRegistro = () => {
    const tempTabelaComissionamentoNovosRegistros = [...tabelaComissionamentoNovosRegistros];
    tempTabelaComissionamentoNovosRegistros.push({
      codcategoria: id,
      codigo: '',
      piso: 0.0,
      comissao: 0,
      isNew: 1,
      dirty: false,
      created_at: '',
      created_by: 0,
      deleted_at: '',
      deleted_by: 0,
      criado_por: '',
      deletado_por: '',
      id: tempId()
    });
    setTabelaComissionamentoNovosRegistros(tempTabelaComissionamentoNovosRegistros);
  };

  const removeNovoRegistro = (idNovoItem: number | string) => {
    const tempTabelaComissionamentoNovosRegistros = [...tabelaComissionamentoNovosRegistros];
    const index = tempTabelaComissionamentoNovosRegistros.map(e => e.id).indexOf(idNovoItem);
    tempTabelaComissionamentoNovosRegistros.splice(index, 1);
    setTabelaComissionamentoNovosRegistros(tempTabelaComissionamentoNovosRegistros);
  };

  const validacao = () => {
    const camposObrigatorios = ['codigo', 'piso', 'comissao'];

    /* array apenas com faixas que tiveram modificações */
    const dirtyOnly = tabelaComissionamentoRender.filter(e => e.dirty === true);

    /* validação de campos obrigatorios */
    /* retorna apenas o id da faixa de comissao invalida */
    /* ao final deste loop, 'faixasIncompletas' conterá os ids das faixas incompletas */
    const faixasIncompletas: (string | number)[] = [];
    dirtyOnly.forEach(e => {
      camposObrigatorios.forEach(f => {
        if (!(f in e) && faixasIncompletas.indexOf(e.id) === -1) {
          faixasIncompletas.push(e.id);
        }
      });
    });

    /* valida existencia/preenchimento do campo 'codigo' */
    /* ao final deste loop, 'faixasSemCampoCodigo' conterá os ids das faixas sem o campo código */
    const faixasSemCampoCodigo: (string | number)[] = [];
    tabelaComissionamentoRender.forEach(e => {
      if (e?.codigo === '') {
        faixasSemCampoCodigo.push(e.id);
      }
    });

    /* validação de conflitos em 'codigo' e 'piso' */
    const conflitosCodigos: Array<string | number> = [];
    const conflitosPisos: Array<string | number> = [];
    const uniqueCodigos: string[] = [];
    const uniquePisos: number[] = [];
    tabelaComissionamentoRender.forEach(e => {
      if (uniqueCodigos.indexOf(e.codigo) > -1) {
        conflitosCodigos.push(e.id);
      } else {
        uniqueCodigos.push(e.codigo);
      }

      if (uniquePisos.indexOf(e.piso) > -1) {
        conflitosPisos.push(e.id);
      } else {
        uniquePisos.push(e.piso);
      }
    });

    /* tempFaixasInvalidas é um array contendo os ids de todas as faixas que apresentaram algum erro */
    const tempFaixasInvalidas: Array<string | number> = [
      ...faixasIncompletas,
      ...faixasSemCampoCodigo,
      ...conflitosCodigos,
      ...conflitosPisos
    ].filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    /* adiciona faixas invalidas, retorna true */
    if (tempFaixasInvalidas.length > 0) {
      setFaixasInvalidas(tempFaixasInvalidas);
      return false;
    }
    /* reseta faixas invalidas, retorna false */
    setFaixasInvalidas([]);
    return true;
  };

  /**
   * verifica se os registros já existentes foram modificados em relação a chamada original da API
   * seta a propriedade 'dirty' para false, caso ambos registros estejam iguais
   */
  const verificaMudancas = () => {
    const campos: string[] = ['codigo', 'piso', 'comissao', 'inativo'];
    const tempTable: FaixaComissionamento[] = [];

    tabelaComissionamentoAPI.forEach(e => {
      if (e.dirty === true) {
        const faixaOriginalAPI = tabelaComissionamentoAPIOriginal.filter(item => item.id === e.id)[0];
        // compara campos
        let hasChanged = false;
        campos.forEach(campo => {
          if (faixaOriginalAPI[campo] !== e[campo]) {
            hasChanged = true;
          }
        });
        e.dirty = hasChanged;
      }
      tempTable.push(e);
    });
    setTabelaComissionamentoAPI(tempTable);
  };

  const handleSalvar = async () => {
    verificaMudancas();

    /* retorna true se dados estiverem validos - false, caso contrário */
    const resultValidacao = validacao();

    if (resultValidacao === false) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        html: 'Há campos inválidos na tabela de comissionamento.'
      });
    } else {
      const payload = gerarPayload();

      if (payload?.faixas.length > 0) {
        // eslint-disable-next-line
        console.log(payload);
        await ComissionamentoService.salvar(id, payload).then(() => {
          getTabelaComissionamento(id);
          Swal.fire('Tabela de comissionamento atualizada com sucesso.', '', 'success');
        });
      } else {
        // eslint-disable-next-line
        console.log('Payload vazio');
      }
    }
  };

  const gerarPayload = () => {
    const dirtyOnly = tabelaComissionamentoRender.filter(e => e.dirty === true);
    const payload: ComissionamentoPayload = { faixas: dirtyOnly };
    return payload;
  };

  // getTabelaComissionamento
  // puxa as faixas ativas por categoria
  const getTabelaComissionamento = (codcategoria: number) => {
    ComissionamentoService.getFaixasAtivasPorCategoria(codcategoria).then(response => {
      if (response.data.length > 0) {
        const tabelaOriginal = response.data;
        const tempTabela = response.data.map((item: FaixaComissionamento) => ({ ...item, dirty: false, valid: true }));
        setTabelaComissionamentoAPIOriginal(tabelaOriginal);
        setTabelaComissionamentoAPI(tempTabela);
        setTabelaComissionamentoNovosRegistros([]);
      }
      setLoading(false);
    });
    ComissionamentoService.getFaixasInativasPorCategoria(codcategoria).then(response => {
      setTabelaHistorico(response.data);
    });
  };

  useEffect(() => {
    const novaTabela = [...tabelaComissionamentoAPI, ...tabelaComissionamentoNovosRegistros];
    setTabelaComissionamentoRender(novaTabela);
  }, [tabelaComissionamentoAPI, tabelaComissionamentoNovosRegistros]);

  /* seta categoria e a tabela de comissionamento */
  useEffect(() => {
    // puxa informação da categoria
    // TODO throw error caso a resposta esteja vazia
    api.get(`categorias?codcategoria=${id}`).then(response => {
      setCategoria(response.data.data[0]);
    });
    // puxa as faixas ativas por categoria
    getTabelaComissionamento(id);
  }, [id]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      {loading === false ? (
        <>
          {categoria?.nome ? <h1>{categoria.nome} - Tabela de comissionamento</h1> : ''}

          <Paper>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={mudaTab}
                aria-label="Tabela comissionamento"
                className={classes.tab}
              >
                <Tab label="Comissões" {...a11yProps(0)} />
                <Tab label="Histórico de modificações" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={tabValue} index={0}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Piso</TableCell>
                    <TableCell>% Comissão EÚ</TableCell>
                    <TableCell>Criado por/data</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabelaComissionamentoRender &&
                    tabelaComissionamentoRender.map((comissionamentoItem: FaixaComissionamento) => (
                      <TableRow
                        key={comissionamentoItem.id}
                        className={faixaEstaInvalida(comissionamentoItem.id) ? style.faixaInvalida : ''}
                      >
                        <TableCell>
                          <TextField
                            name="codigo"
                            label="Código"
                            placeholder="Código (ex: BA01, BA02)"
                            variant="outlined"
                            size="small"
                            value={comissionamentoItem.codigo}
                            onChange={e =>
                              alteraProp(
                                comissionamentoItem.id,
                                comissionamentoItem?.isNew ? 'temp' : 'registro',
                                'codigo',
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="piso"
                            label="Piso"
                            placeholder="Piso (ex: R$ 1000)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={comissionamentoItem.piso}
                            InputProps={{
                              inputComponent: InputCurrency as React.FC
                            }}
                            onChange={e => {
                              alteraProp(
                                comissionamentoItem.id,
                                comissionamentoItem?.isNew ? 'temp' : 'registro',
                                'piso',
                                moneyToDecimal(e.target.value)
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="Comissão EU"
                            label="Comissão EU"
                            placeholder="Comissão EU (ex: 10%)"
                            variant="outlined"
                            size="small"
                            value={decimalToPercentage(comissionamentoItem.comissao)}
                            onChange={e =>
                              alteraProp(
                                comissionamentoItem.id,
                                comissionamentoItem?.isNew ? 'temp' : 'registro',
                                'comissao',
                                percentageToDecimal(e.target.value)
                              )
                            }
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          {comissionamentoItem?.isNew === 1 ? (
                            '-'
                          ) : (
                            <>
                              {comissionamentoItem.criado_por}
                              <br />
                              {format(new Date(comissionamentoItem.created_at), 'dd/MM/yyyy H:m')}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          {comissionamentoItem?.isNew === 1 ? (
                            <IconButton
                              onClick={() => removeNovoRegistro(comissionamentoItem.id)}
                              aria-label="delete"
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : (
                            <Switch
                              checked={!comissionamentoItem.inativo}
                              onChange={e => {
                                /* converte status */
                                const status = e.target.checked ? 1 : 0;

                                /* manda status invertido */
                                alteraProp(comissionamentoItem.id, 'registro', 'inativo', status === 0 ? 1 : 0);
                              }}
                              color="primary"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={adicionarNovoRegistro}
                        endIcon={<AddIcon />}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleSalvar}
                        endIcon={<SaveIcon />}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Piso</TableCell>
                    <TableCell>% Comissão EÚ</TableCell>
                    <TableCell>Criado em/por</TableCell>
                    <TableCell>Desativado em/por</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabelaHistorico &&
                    tabelaHistorico.map(historicoItem => (
                      <TableRow key={historicoItem.id}>
                        <TableCell>{historicoItem.codigo}</TableCell>
                        <TableCell>{decimalToMoney(historicoItem.piso)}</TableCell>
                        <TableCell>{decimalToPercentage(historicoItem.comissao)}</TableCell>
                        <TableCell>
                          {historicoItem.criado_por}
                          <br />
                          {format(new Date(historicoItem.created_at), 'dd/MM/yyyy H:m')}
                        </TableCell>
                        <TableCell>
                          {historicoItem.deletado_por}
                          <br />
                          {format(new Date(historicoItem.deleted_at), 'dd/MM/yyyy H:m')}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TabPanel>
          </Paper>
        </>
      ) : (
        <Box>Carregando...</Box>
      )}
    </TemplateBase>
  );
}

export default ComissionamentoDetailsPage;
