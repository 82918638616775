import React, { useState } from 'react';
import TemplateBase from 'components/template-base/template';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { CarrinhoProduto } from 'interfaces';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GeracaoCarrinhoService from 'services/geracao-carrinho/GeracaoCarrinhoService';
import PedidosService from 'services/pedidos/pedidosService';
import { useLoading } from 'context/loading';

function GerarCarrinho() {
  document.title = 'Ferramenta Geradora de Carrinho';

  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [codcliente, setCodCliente] = useState<string>('');
  const [codpedido, setCodPedido] = useState<string>('');

  const handleCodPedido = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodPedido(event.target.value);
  };

  const handleCodCliente = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodCliente(event.target.value);
  };

  const handleReprocessarCarrinho = () => {
    setLoading(true);
    PedidosService.buscar_pedido(codpedido)
      .then(response => {
        GeracaoCarrinhoService.gerar_carrinho({
          codcliente: response.data.cliente.codcliente,
          produtos: response.data.produtos.map((produto: CarrinhoProduto) => ({ codproduto: produto.codproduto }))
        })
          .then(result => {
            const { codcarrinho } = result.data;

            navigate(`/ferramentas/carrinho/${codcarrinho}`, { state: { created: true } });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleGerarCarrinhoCliente = () => {
    setLoading(true);
    GeracaoCarrinhoService.gerar_carrinho({
      codcliente
    })
      .then(result => {
        const { codcarrinho } = result.data;

        navigate(`/ferramentas/carrinho/${codcarrinho}`, { state: { created: true } });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGerarCarrinho = () => {
    setLoading(true);
    GeracaoCarrinhoService.gerar_carrinho()
      .then(result => {
        const { codcarrinho } = result.data;

        navigate(`/ferramentas/carrinho/${codcarrinho}`, { state: { created: true } });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TemplateBase>
      <Paper elevation={1}>
        <Box p={1}>
          <h3>Reprocessar carrinho: </h3>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={6}>
              <TextField
                size="small"
                onChange={handleCodPedido}
                value={codpedido}
                variant="outlined"
                style={{ marginBottom: 8 }}
                fullWidth
                name="modelo"
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleReprocessarCarrinho}>
            Reprocessar Carrinho
          </Button>
        </Box>

        <Box p={1}>
          <h3>Gerar carrinho para cliente: </h3>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={6}>
              <TextField
                size="small"
                variant="outlined"
                style={{ marginBottom: 8 }}
                fullWidth
                onChange={handleCodCliente}
                value={codcliente}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={handleGerarCarrinhoCliente}>
            Gerar Carrinho
          </Button>
        </Box>

        <Box p={1}>
          <h3>Gerar carrinho: </h3>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md={6}>
              <Button variant="contained" color="primary" onClick={handleGerarCarrinho}>
                Gerar Carrinho
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </TemplateBase>
  );
}

export default GerarCarrinho;
