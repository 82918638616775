import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { Barcode } from 'components/icons';

import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';

import TemplateBase from 'components/template-base/template';

import QueroVenderService from 'services/quero-vender/QueroVenderService';

import { ApiListMeta, QueroVenderBatch, LockedBatch } from 'interfaces';
import { usePusher } from 'context/pusher';
import useDebounce from 'hooks/debounce';
import { BatchRow } from './batchRow';

import AvaliacaoFisicaBatchStyle from './styles';

function AvaliacaoFisicaBatch() {
  const style = AvaliacaoFisicaBatchStyle();

  const [batches, setBatch] = useState<Array<QueroVenderBatch>>([]);
  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buscaNome, setBuscaNome] = useState('');
  const [isPagination, setIsPagination] = useState(false);
  const [lockedBatch, setLockedBatch] = useState<LockedBatch[]>([]);
  const [barCode, setBarCode] = useState<string>('');

  const { channel } = usePusher();
  const nomeCliente = useDebounce(buscaNome, 500);

  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  document.title = `Avaliação | Batches aguardando avaliação física`;

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  /**
   * Pega os dados das Batchs da API
   */
  const getBatchs = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${perPage}${nome}`;

    if (!isPagination) setPage(1);

    QueroVenderService.list('40', queryMeta).then(response => {
      if (response) {
        setBatch(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setBatch([]);
      }
      setLoading(false);
    });
  }, [nomeCliente, page]); //eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getBatchs();
  }, [getBatchs]);

  /**
   * Websocket- Pusher
   */
  useEffect(() => {
    channel.bind(
      'lock-change',
      (params: { codquerovender: number; locked: boolean; lockInfo: { nome: string; email: string } }) => {
        setLockedBatch(prevValue => {
          if (params.locked) {
            return [
              ...prevValue,
              { codquerovender: params.codquerovender, nome: params.lockInfo.nome, email: params.lockInfo.email }
            ];
          }
          return prevValue.filter(value => value.codquerovender !== params.codquerovender);
        });
      }
    );

    return () => {
      channel.unbind('lock-change');
    };
  }, [channel, getBatchs, batches]);

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  const isBatchLocked = (codquerovender: number) => {
    return lockedBatch?.filter(value => value.codquerovender === codquerovender);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setLoading(true);
      QueroVenderService.bip(barCode).then(response => {
        QueroVenderService.get(response?.data.codquerovender)
          .then(({ data }) => {
            setBatch([data]);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };

  const handleOnChangeBarCode = (barCodeString: string) => {
    setBarCode(barCodeString);
  };

  const handleBotaoLimpar = () => {
    setPage(1);
    setBuscaNome('');
    setBarCode('');

    if (nomeCliente === '') {
      getBatchs();
    }
  };

  useEffect(() => {
    const lockedBatches = batches
      .filter(batch => batch.locked)
      .map(batch => ({
        nome: batch.lock_info?.nome,
        email: batch.lock_info?.email,
        codquerovender: batch.codquerovender
      }));

    setLockedBatch(lockedBatches);
  }, [batches]);

  /**
   * Template
   */
  return (
    <TemplateBase>
      <h1>{`Batches aguardando avaliação física${renderCount}`}</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <h3>Localizar remessa</h3>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoFocus
                  value={barCode}
                  onChange={e => {
                    handleOnChangeBarCode(e.target.value);
                  }}
                  onKeyUp={handleInputKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Barcode />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="nomeremessa"
                  label="Nome ou código do cliente"
                  placeholder="Nome ou código do cliente"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={buscaNome}
                  onChange={handleBuscaNome}
                />
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" color="primary" onClick={handleBotaoLimpar}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      {loading && <Box>Carregando ...</Box>}
      {!loading && batches.length > 0 && (
        <>
          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Remessa</TableCell>
                  <TableCell>Sacola</TableCell>
                  <TableCell className={style.colDescricao}>Descrição</TableCell>
                  <TableCell>Cadastrado em</TableCell>
                  <TableCell>Produtos</TableCell>
                  <TableCell align="center">Logs</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {batches &&
                  batches.map((batch: QueroVenderBatch) => {
                    const locked = isBatchLocked(batch.codquerovender);

                    const isLocked = locked.length > 0;

                    return <BatchRow batch={batch} isLocked={isLocked} getBatchs={getBatchs} locked={locked} />;
                  })}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
      {!loading && batches.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma Remessa encontrada
          </Box>
        </Box>
      )}
    </TemplateBase>
  );
}

export default AvaliacaoFisicaBatch;
