import Swal from 'sweetalert2';

import QueroVenderService from 'services/quero-vender/QueroVenderService';

interface propsDiscardBatch {
  codquerovender: number;
  cbSuccess(): void;
}

export default function useDiscardBatch() {
  const discardBatch = (props: propsDiscardBatch) => {
    const { codquerovender, cbSuccess } = props;

    Swal.fire({
      title: 'Tem certeza que deseja arquivar a proposta?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Cancelar`
    }).then(result => {
      if (result.isConfirmed) {
        QueroVenderService.discard(codquerovender.toString()).then(() => {
          Swal.fire('Proposta arquivada', '', 'success').then(() => {
            cbSuccess();
          });
        });
      }
    });
  };

  return discardBatch;
}
