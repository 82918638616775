import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function VipStar(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.33301 11L6.99967 8.96668L9.66634 11L8.59967 7.71668L11.2663 5.83334H8.01634L6.99967 2.45001L5.96634 5.83334H2.71634L5.38301 7.71668L4.33301 11ZM6.99967 13.6667C6.08856 13.6667 5.22745 13.4917 4.41634 13.1417C3.60523 12.7917 2.8969 12.3139 2.29134 11.7083C1.68579 11.1028 1.20801 10.3945 0.858008 9.58334C0.508008 8.77223 0.333008 7.91112 0.333008 7.00001C0.333008 6.07779 0.508008 5.21112 0.858008 4.40001C1.20801 3.5889 1.68579 2.88334 2.29134 2.28334C2.8969 1.68334 3.60523 1.20834 4.41634 0.858343C5.22745 0.508343 6.08856 0.333344 6.99967 0.333344C7.9219 0.333344 8.78856 0.508343 9.59967 0.858343C10.4108 1.20834 11.1163 1.68334 11.7163 2.28334C12.3163 2.88334 12.7913 3.5889 13.1413 4.40001C13.4913 5.21112 13.6663 6.07779 13.6663 7.00001C13.6663 7.91112 13.4913 8.77223 13.1413 9.58334C12.7913 10.3945 12.3163 11.1028 11.7163 11.7083C11.1163 12.3139 10.4108 12.7917 9.59967 13.1417C8.78856 13.4917 7.9219 13.6667 6.99967 13.6667Z" />
    </SvgIcon>
  );
}
