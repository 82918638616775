import React, { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArchiveIcon from '@mui/icons-material/Archive';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import Pluralize from 'components/utils/pluralize';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { QueroVenderBatch, QueroVenderItem, LockedBatch } from 'interfaces';
import useDiscardBatch from '../../hooks/discard-batch';

import preAvaliacaoStyle from '../style';

interface PropsBatchRow {
  batch: QueroVenderBatch;
  retrieve(): void;
  locked: LockedBatch[];
}

function BatchRow(props: PropsBatchRow) {
  const { batch, retrieve, locked } = props;

  const [open, setOpen] = useState(false);
  const [produtos, setProdutos] = useState<Array<QueroVenderItem>>();

  const classes = preAvaliacaoStyle();
  const discardBatch = useDiscardBatch();

  const isLocked = locked.length > 0;

  /**
   * Manipula classe CSS das linhas das tabelas de batchs
   */
  const classRow = useCallback(() => {
    const lockedClass = isLocked ? `${classes.locked}` : '';
    const disableClass = batch.disabled ? `${classes.disabled}` : '';

    return `${lockedClass} ${disableClass}`;
  }, [batch, classes, isLocked]);

  const linkVer = () => {
    return (
      <Link to={`/pre-avaliacao/pre-avaliacao-negociacao/${batch.codquerovender}`} className={classes.linkAvaliar}>
        Avaliar
      </Link>
    );
  };

  /**
   * Abrir produtos do batch
   */
  const openProdutos = useCallback(() => {
    if (!open) {
      const idBatch = batch.codquerovender.toString();

      QueroVenderService.get(idBatch).then(response => {
        setProdutos(response.data.itens);
        setOpen(!open);
      });
    } else {
      setOpen(!open);
    }
  }, [batch, open]);

  /**
   * Arquiva Remessa
   */
  const handleDiscardRemessa = (codquerovender: number) => {
    const dataDiscard = {
      codquerovender,
      cbSuccess: () => {
        retrieve();
      }
    };

    discardBatch(dataDiscard);
  };

  return (
    <>
      <TableRow key={batch.codquerovender} className={classRow()}>
        <TableCell>
          {batch.cliente !== null && batch.cliente.prioridade !== null && batch.cliente.prioridade > 0 ? '[VIP] ' : ''}
          {`${batch?.cliente?.nome} - ${batch.cliente?.codcliente}`}
        </TableCell>

        <TableCell>
          {`${batch.count.total} `}
          <Pluralize quantidade={batch.count.total} singular="produto" plural="produtos" />
          {batch.count.total > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={openProdutos} disabled={batch.locked}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{format(new Date(batch.created_at), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
        <TableCell>
          {isLocked ? (
            <Tooltip placement="top" title={`${locked[0]?.email || batch.lock_info?.email}`}>
              <span>Em uso por {locked[0]?.nome || batch.lock_info?.nome}</span>
            </Tooltip>
          ) : (
            linkVer()
          )}
        </TableCell>
        <TableCell>
          <Tooltip placement="top" title="Arquivar remessa">
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleDiscardRemessa(batch.codquerovender)}
              disabled={isLocked}
              size="large"
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {batch.negociacao === 'SUGESTAO_VENDEDORA' && (
            <Tooltip placement="top" title="Nova sugestão de valor recebida.">
              <Link to={`/pre-avaliacao/pre-avaliacao-negociacao/${batch.codquerovender}`}>
                <IconButton aria-label="Nova sugestão" className={classes.iconSugestao} size="large">
                  <QuestionAnswer />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {produtos &&
                produtos.map(item => (
                  <List key={item.codquerovenderitem} dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar src={item.fotos[0].imagem} alt="" />
                      </ListItemAvatar>
                      <ListItemText primary={item.titulo} />
                    </ListItem>
                  </List>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BatchRow;
