import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const headerDetailsStyle = makeStyles(() => ({
  title: {
    display: 'inline-block',
    marginRight: '0.8rem'
  },

  fluxoAntigo: {
    position: 'relative',
    top: '-0.3rem',
    padding: '0.5rem',
    fontSize: '0.8rem',
    color: '#fff',
    borderRadius: '3px',
    backgroundColor: theme.palette.secondary.dark
  },

  iconFluxoAntigoAlerta: {
    position: 'relative',
    top: '0.2rem',
    marginRight: '0.3rem',
    fontSize: '1rem'
  }
}));

export default headerDetailsStyle;
