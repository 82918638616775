import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const PackingStyle = makeStyles(() => ({
  paperBusca: {
    marginBottom: theme.spacing(5)
  },
  productContainer: {
    marginTop: theme.spacing(2)
  },

  pedido: {
    color: grey[700],
    fontSize: theme.spacing(2),
    fontWeight: 700
  },

  titleProduct: {
    color: grey[600],
    textTransform: 'uppercase'
  },

  productPhoto: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}));

export default PackingStyle;
