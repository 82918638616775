import React, { ReactNode } from 'react';
import clsx from 'clsx';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  Divider,
  Container,
  Typography,
  Tooltip,
  Box,
  CssBaseline
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppTwoTone from '@mui/icons-material/ExitToAppTwoTone';

import { useAuth } from 'context/auth';
import TplBaseStyles from './styles';
import { MenuPrincipal } from '../menu';

type Props = {
  children: ReactNode;
};

function TemplateBase(props: Props) {
  const { user, logout } = useAuth();
  const { children } = props;
  const style = TplBaseStyles();
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <div className={style.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(style.appBar, open && style.appBarShift)}>
        <Toolbar className={style.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(style.menuButton, open && style.menuButtonHidden)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={style.title}>
            {document.title}
          </Typography>
          <Box component="span" mr={2} fontSize={16}>
            {`Olá, ${user.nome}`}
          </Box>
          <Tooltip title="Sair">
            <IconButton color="inherit" onClick={handleLogout} size="large">
              <ExitToAppTwoTone />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <nav className={style.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: style.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <div className={style.toolbarIcon}>
              <IconButton onClick={handleDrawerClose} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <MenuPrincipal />
            </List>
          </Drawer>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Drawer
            classes={{
              paper: style.drawerPaper
            }}
            variant="permanent"
            open
          >
            <div className={style.toolbarIcon} />
            <Divider />
            <List>
              <MenuPrincipal />
            </List>
          </Drawer>
        </Box>
      </nav>
      <main className={style.content}>
        <div className={style.appBarSpacer} />
        <Container maxWidth="lg" className={style.container}>
          <div>{children}</div>
        </Container>
      </main>
    </div>
  );
}

export default TemplateBase;
