import React from 'react';

import { AuthProvider } from './auth';
import { PusherProvider } from './pusher';

function AppProvider({ children }: { children: React.ReactElement }) {
  return (
    <PusherProvider>
      <AuthProvider>{children}</AuthProvider>
    </PusherProvider>
  );
}

export default AppProvider;
