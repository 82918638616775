import React from 'react';
import CalculadoraValores from 'components/calculadora-de-valores';
import { useFormContext } from 'react-hook-form';
import { TextFieldHookForms } from 'components/form/text-field-hook-forms';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  defaultValuesNegociacao: {
    preco: number;
    precoaprazo: number;
    precoavista: number;
    precocomissao: number;
    precocomissionada: number;
    status: number;
    observacoes: string;
    revisado: boolean;
  };
  codloja: number | undefined;
  handleIsCalculating: (value: boolean) => void;
  codquerovenderitem: number | undefined;
};

export function FormCalculosMotivos(props: Props) {
  const { register } = useFormContext();
  const { defaultValuesNegociacao, codloja, handleIsCalculating, codquerovenderitem } = props;

  const styles = avaliacaoFisicaNegociacaoStyle();

  return (
    <>
      <CalculadoraValores
        negociacaoAtual={defaultValuesNegociacao}
        categoria={null}
        captadora
        codloja={codloja}
        comissionamentoDiferenciado={false}
        register={register}
        codquerovenderitem={codquerovenderitem}
        isCalculating={handleIsCalculating}
      />
      <TextFieldHookForms
        multiline
        rows={3}
        label="Observações"
        variant="outlined"
        margin="normal"
        fullWidth
        size="small"
        {...register('motivo')}
        className={styles.textInput}
      />
    </>
  );
}
