import {
  QueroVenderRetirada,
  QueroVenderPostagem,
  QueroVenderEntradaRemessa,
  QueroVenderEntradaManual
} from 'interfaces';

import api from '../api';

const retirada = (id: number, data: QueroVenderRetirada) => {
  return api.post(`quero-vender/${id}/volume/request`, data);
};

const postagem = (id: number, data: QueroVenderPostagem) => {
  return api.post(`quero-vender/${id}/volume/request`, data);
};

const entrada = (id: number, data: QueroVenderEntradaRemessa) => {
  return api.post(`quero-vender/${id}/volume/arrival`, data);
};

const entradaManual = (data: QueroVenderEntradaManual) => {
  return api.post(`quero-vender/volume/arrival`, data);
};

const localizar = (id: string) => {
  return api.get(`quero-vender/volume/${id}`);
};

const imprimirEtiqutas = (date: string) => {
  return api.get(`quero-vender/volume/labels?date=${date}`);
};

export default {
  retirada,
  postagem,
  entrada,
  entradaManual,
  localizar,
  imprimirEtiqutas
};
