import React from 'react';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';

type Props = {
  text: string;
};

export function EmptyResults(props: Props) {
  const { text } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
      <WarningIcon fontSize="large" color="disabled" />
      <Box component="span" ml={2}>
        {text}
      </Box>
    </Box>
  );
}
