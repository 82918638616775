import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

export default makeStyles(() =>
  createStyles({
    form: {
      padding: theme.spacing(2)
    },
    titles: {
      fontSize: '0.65rem'
    },
    checkBox: {
      marginLeft: 0,
      '& .MuiFormControlLabel-root': {
        marginLeft: 0
      },
      '& .MuiCheckbox-root, & .MuiRadio-root': {
        padding: 2
      },
      '& .MuiFormControlLabel-label': {
        fontSize: 12
      }
    },
    closeButton: {
      textAlign: 'right'
    },
    cardContent: {
      paddingBottom: 0
    },
    btnSalvar: {
      color: green[500]
    },
    btnsLeft: {
      textAlign: 'left'
    },
    actions: {
      textAlign: 'right',
      backgroundColor: '#eee',
      justifyContent: 'flex-end'
    },

    contentWrap: {
      marginTop: 3
    },
    textArea: {
      marginTop: 3,
      '& .MuiOutlinedInput-inputMultiline': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      }
    },
    textInput: {
      '& .MuiOutlinedInput-root': {
        fontSize: 13,
        '&::placeholder': {
          fontSize: 13
        }
      },

      '& .MuiInputLabel-root': {
        fontSize: 13
      }
    },
    btnOperation: {
      padding: '2px 4px',
      fontSize: 12,
      marginRight: -10
    }
  })
);
