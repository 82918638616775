import React, { useState, useEffect } from 'react';
import { Box, Paper, Button, Grid, Card, CardContent, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import alertValidationMessage from 'helpers/alert-validation-message';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TemplateBase from 'components/template-base/template';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import PedidosService from 'services/pedidos/pedidosService';
import { CheckboxHookForms, TextFieldHookForms } from 'components/form/text-field-hook-forms';

interface FormaData {
  codPedido: string;
  notify?: boolean;
}

/**
 * Regras das validações do form
 */
const schema = Yup.object().shape({
  codPedido: Yup.string().required('O código do pedido é obrigatório'),
  notify: Yup.bool()
});

function CancelarPedido() {
  document.title = 'Ferramentas | Cancelar pedido';

  const [loading, setLoading] = useState(false);
  /**
   * Resgata dados do form e dispara mensagem
   */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      alertValidationMessage(errors);
    }
  }, [errors]);

  /**
   * Submit Form
   */
  const onSubmit = (data: FormaData) => {
    setLoading(true);

    PedidosService.canceOrder(data.codPedido, Number(data.notify))
      .then(response => {
        if (response.status === 200) {
          Swal.fire('Pedido cancelado com sucesso', '', 'success');
          reset(); // Limpa o form
        }
      })
      .catch(e => {
        if (e.response.status === 404) {
          Swal.fire('Oops', 'Recurso não encontrado', 'error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <TemplateBase>
      <h1>Cancelar pedido</h1>

      <Paper elevation={1}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xl={4} lg={5}>
                <h3>Código do Pedido</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextFieldHookForms
                        {...register('codPedido')}
                        variant="outlined"
                        size="small"
                        placeholder="Ex.: A1S2D3"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="div" mt={2}>
                        <FormControlLabel
                          control={<CheckboxHookForms color="primary" {...register('notify')} />}
                          label="Notificar cliente"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box component="div" mt={2}>
                        <Button type="submit" variant="contained" color="primary">
                          Cancelar Pedido
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>

      <Loading open={loading} />
    </TemplateBase>
  );
}

export default CancelarPedido;
