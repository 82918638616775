import React, { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import QueroVenderItemService from 'services/quero-vender/QueroVenderItemService';
import Swal from 'sweetalert2';
import { useLoading } from 'context/loading';
import avaliacaoFisicaNegociacaoStyle from './style';

type Props = {
  codquerovenderitem: number | undefined;
  doRefresh: () => void;
  disabled?: boolean;
  observacoes?: string;
};

export function BotaoEnviarDevolucao(props: Props) {
  const { codquerovenderitem, doRefresh, disabled = false, observacoes = '' } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [motivo, setMotivo] = useState<string>(observacoes || '');
  const [solicitacao, setSolicitacao] = useState<string>('');

  const styles = avaliacaoFisicaNegociacaoStyle();

  const enableContinuar = useMemo(() => solicitacao !== '', [solicitacao]);

  const handleOperation = () => {
    setLoading(true);
    if (codquerovenderitem) {
      const data: { acao: string; motivo: string; avaliador?: boolean } = {
        acao: 'recusar',
        motivo
      };

      if (solicitacao === 'avaliador') {
        data.avaliador = true;
      }

      QueroVenderItemService.aprovacao_fisica(codquerovenderitem as number, data)
        .then(() => {
          handleClose();

          Swal.fire('Operação realizada com sucesso', 'Proposta recusada. Produto enviado para devolução', 'success');
          doRefresh();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('codquerovenderitem does not exist');
    }
  };

  const handleMotivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMotivo(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSolicitacao = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSolicitacao(event.target.value);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogContent>
          <Typography>Escreva um motivo para a devolução</Typography>

          <TextField
            margin="normal"
            value={motivo}
            defaultValue={observacoes}
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            onChange={handleMotivo}
          />
          <FormControl variant="standard" component="div">
            <RadioGroup name="avaliador" value={solicitacao} onChange={handleSolicitacao} row>
              <FormControlLabel value="vendedor" control={<Radio color="primary" />} label="Vendedor" />
              <FormControlLabel value="avaliador" control={<Radio color="primary" />} label="Avaliador" />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancelar
          </Button>
          <Button color="primary" disabled={!enableContinuar} variant="contained" onClick={handleOperation}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>

      <Button color="secondary" variant="contained" disabled={disabled} className={styles.button} onClick={handleOpen}>
        Enviar para devolução
      </Button>
    </>
  );
}
