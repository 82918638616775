import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import WarningIcon from '@mui/icons-material/Warning';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import TemplateBase from 'components/template-base/template';
import QueroVenderService from 'services/quero-vender/QueroVenderService';
import { RetiradaPostagemProvider, useRetiradaPostagem } from 'pages/avaliacao/context/agendamento';
import { QueroVenderBatch, ApiListMeta } from 'interfaces';
import useDebounce from 'hooks/debounce';
import Loading from 'components/loading';
import useMergeBatchs from '../hooks/merge-batchs';
import BatchRow from './batchRow';
import FormRetirada from './form-retirada';
import FormPostagem from './form-postagem';

import agendamentoStyle from './style';

function Agendamento() {
  const style = agendamentoStyle();

  const [meta, setMeta] = useState<ApiListMeta | null>(null);
  const [page, setPage] = useState(1);
  const [buscaNome, setBuscaNome] = useState('');
  const [loadingFull, setLoadingFull] = useState(false);
  const [listBatchMerge, setListBatchMerge] = useState<Array<number>>([]);
  const [mergeCodCliente, setMergeCodCliente] = useState<number | undefined>(undefined);
  const [isPagination, setIsPagination] = useState(false);

  const { batches, setBatch, loading, setLoading } = useRetiradaPostagem();
  const nomeCliente = useDebounce(buscaNome, 500);
  const setMergeBatchs = useMergeBatchs();

  const renderCount = useMemo(() => (meta ? ` (${meta.total})` : ''), [meta]);

  document.title = `Pré-Avaliação`;

  /**
   * Handler paginação - Modifica página ativa
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setIsPagination(true);
  };

  const getBatchs = useCallback(() => {
    const perPage = 30;
    const nome = nomeCliente ? `&search=${nomeCliente}` : '';
    const activePage = nome !== '' && !isPagination ? 1 : page;
    const queryMeta = `page=${activePage}&per_page=${meta?.per_page || perPage}${nome}`;

    if (!isPagination) setPage(1);

    setLoading(true);
    QueroVenderService.list('3', queryMeta).then(response => {
      if (response) {
        setBatch(response?.data.data);
        setMeta(response?.data.meta);
        setIsPagination(false);
      } else {
        setBatch([]);
      }
      setLoading(false);
    });
  }, [nomeCliente, page]); // eslint-disable-line

  /**
   * Monitora estado de paginacão, caso seja modificado, busca novos dados na API
   */
  useEffect(() => {
    getBatchs();
  }, [getBatchs]);

  /**
   * Busca por nome
   */
  const handleBuscaNome = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuscaNome(e.target.value);
  };

  /**
   * Faz o merge dos batchs
   */
  const handleMergeBatchs = () => {
    const dataListBatchs = [...listBatchMerge];
    const batchMerge = dataListBatchs[0];
    dataListBatchs.shift();

    const dataBatchs = {
      codquerovender: batchMerge,
      codBatchs: dataListBatchs,
      cbSuccess: () => {
        setListBatchMerge([]);
        getBatchs();
      },
      cbFinally: () => {
        setLoadingFull(false);
      }
    };

    setLoadingFull(true);
    setMergeBatchs(dataBatchs);
  };

  /**
   * Monitora se o código do cliente foi modificado e add ao estado da batch um valor para saber
   * se desabilita a batch para edição
   */
  useEffect(() => {
    const listBatchs = [...batches];

    listBatchs.forEach((item, key) => {
      if (item.cliente?.codcliente === mergeCodCliente || listBatchMerge.length <= 0) {
        listBatchs[key].disabled = false;
      } else {
        listBatchs[key].disabled = true;
      }
    });

    setBatch(listBatchs);
  }, [listBatchMerge, mergeCodCliente]); // eslint-disable-line

  return (
    <TemplateBase>
      <h1>{`Aguardando agendamento/postagem${renderCount}`}</h1>

      <Paper elevation={1} className={style.paperBusca}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <h3>Localizar remessa</h3>
                <TextField
                  name="nomeremessa"
                  label="Nome ou código do cliente"
                  placeholder="Nome ou código do cliente"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleBuscaNome}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {loading && <Box>Carregando ...</Box>}
      {!loading && batches.length > 0 && (
        <>
          <Box display="flex" justifyContent="flex-end" mb={3}>
            <Button
              variant="contained"
              color="primary"
              classes={{ root: style.mergeBtn }}
              disabled={listBatchMerge.length <= 1}
              startIcon={<MergeTypeIcon />}
              onClick={handleMergeBatchs}
            >
              Unir Remessas
            </Button>
          </Box>

          <Paper>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={style.colCheckbox} />
                  <TableCell>Cliente</TableCell>
                  <TableCell>Produtos</TableCell>
                  <TableCell>Cadastrado em</TableCell>
                  <TableCell align="right" />
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {batches &&
                  batches.map((batch: QueroVenderBatch) => (
                    <BatchRow
                      key={batch.codquerovender}
                      batch={batch}
                      listBatchMerge={listBatchMerge}
                      setListBatchMerge={setListBatchMerge}
                      setMergeCodCliente={setMergeCodCliente}
                      getBatchs={getBatchs}
                    />
                  ))}
              </TableBody>
            </Table>
          </Paper>

          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination color="primary" count={meta?.last_page} page={page} onChange={handlePage} />
          </Box>
        </>
      )}
      {!loading && batches.length === 0 && (
        <Box display="flex" alignItems="center" justifyContent="center" mt={7}>
          <WarningIcon fontSize="large" color="disabled" />
          <Box component="span" ml={2}>
            Nenhuma Remessa encontrada
          </Box>
        </Box>
      )}

      <FormRetirada />
      <FormPostagem />

      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

function AgendamentoPage() {
  return (
    <RetiradaPostagemProvider>
      <Agendamento />
    </RetiradaPostagemProvider>
  );
}

export default AgendamentoPage;
