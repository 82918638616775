import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { pink } from '@mui/material/colors';
import { CustomBadge } from '.';

type Props = {
  nospace?: boolean;
  date?: string;
};

export function BadgePortadorRetirada(props: Props) {
  const { nospace = false, date = undefined } = props;

  return (
    <CustomBadge
      color={pink[500]}
      bgcolor={pink[100]}
      nospace={nospace}
      icon={<LocalShippingIcon />}
      text={`Retirada/Portador${date ? `: ${date}` : ''}`}
    />
  );
}
