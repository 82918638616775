import React from 'react';

interface PropsPluralize {
  quantidade: number;
  singular: string;
  plural: string;
}

function Pluralize(props: PropsPluralize) {
  const { quantidade, singular, plural } = props;

  // Pluralize label de produtos
  const text = quantidade > 1 ? plural : singular;

  return <span>{text}</span>;
}

export default Pluralize;
