import React, { createContext, useContext, useMemo } from 'react';
import { Criteria } from 'helpers/filter-criteria/Criteria';
import { PackingLayout } from './packing-layout';

type PackginProviderData = {
  filter: Criteria;
  title: string;
};

const PackingContext = createContext<PackginProviderData>({} as PackginProviderData);

type PackingProviderProps = {
  filter: Criteria;
  title: string;
};

type PackginProviderType = {
  children: React.ReactElement;
} & PackingProviderProps;

function PackingProvider({ children, filter, title }: PackginProviderType) {
  const value = useMemo(
    () => ({
      filter,
      title
    }),
    [filter, title]
  );
  return <PackingContext.Provider value={value}>{children}</PackingContext.Provider>;
}

function usePackingProvider() {
  const context = useContext(PackingContext);

  if (!context) {
    throw new Error('usePackingProvider deve usado dentro de um PackingProvider');
  }

  return context;
}

PackingProvider.PackingLayout = PackingLayout;

export { PackingProvider, usePackingProvider };
