import React, { useMemo } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputCurrency from 'components/form/input-currency';
import FormatCurrency from 'components/utils/format-currency';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { UseFormRegister } from 'react-hook-form';

type Props = {
  register: UseFormRegister<any>; // eslint-disable-line
  name: string;
  value?: string;
  readonly?: boolean;
  fontBig?: boolean;
} & TextFieldProps;

function CustomInputCurrency(props: Props) {
  const {
    register,
    name,
    value,
    InputProps,
    onChange,
    readonly = false,
    disabled,
    placeholder,
    fontBig = false,
    ...rest
  } = props;

  // defaultValue é o valor float recebido da API ou que será enviado para ela
  const defaultValue = useMemo(() => `${value}`, [value]);

  // currencyValue é o valor float formatado para ser exibido no campo InputCurrency
  const currencyValue = useMemo(() => `${Math.round(Number(value) * 100)}`, [value]);

  const {
    ref,
    onChange: OnChangeRef,
    ...fieldProps
  } = register(name, {
    value: defaultValue
  });

  return (
    <>
      <Box sx={{ display: readonly ? 'inline-block' : 'none' }} color={grey[600]} fontSize={13} lineHeight="1.4em">
        <span>{placeholder}:</span>
        <br />
        {!fontBig ? (
          <strong>
            <FormatCurrency value={Number(defaultValue)} />
          </strong>
        ) : (
          <Box fontSize={28} lineHeight="1.3em">
            <strong>
              <FormatCurrency value={Number(defaultValue)} />
            </strong>
          </Box>
        )}
      </Box>
      <TextField
        sx={{ display: !readonly ? 'inline-block' : 'none' }}
        variant="standard"
        {...rest}
        defaultValue={`${Number(value) * 100}`}
        disabled={disabled}
        {...fieldProps}
        inputRef={ref}
        onChange={e => {
          const changeParam = {
            ...e,
            target: {
              ...e.target,
              name: e.target.name,
              value: !Number.isNaN(parseFloat(e.target.value)) ? `${parseFloat(e.target.value) / 100}` : ''
            }
          };

          onChange?.(changeParam);
          OnChangeRef(changeParam);
        }}
        InputProps={{
          inputComponent: InputCurrency as React.FC,
          value: currencyValue,
          ...InputProps
        }}
      />
    </>
  );
}

export default CustomInputCurrency;
