import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import TemplateBase from 'components/template-base/template';
import { Produto, Acervo } from 'interfaces';
import { Button, Grid, TextField, Paper, Box } from '@mui/material';
import AcervoService from 'services/acervo/AcervoService';
import Card from '@mui/material/Card';
import Swal from 'sweetalert2';
import Loading from 'components/loading';
import ApiValidationsHandler from '../../helpers/api-validations-handler';
import AudioError from '../../assets/audio/error-audio.mp3';
import AudioError2 from '../../assets/audio/error-audio-2.mp3';

import EntradaAcervoStyles from './style';

function EntradaAcervo() {
  document.title = 'Entrada Acervo';
  const classes = EntradaAcervoStyles();
  const [acervo, setAcervo] = useState<Acervo>({} as Acervo);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [value, setValue] = useState<string>('');
  const [loadingFull, setLoadingFull] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const audioElement = document.querySelector('audio#audio-error') as HTMLAudioElement;
  const audio2Element = document.querySelector('audio#audio-error-2') as HTMLAudioElement;

  const handleKeyBoardPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.key === 'Enter') {
      setLoadingFull(true);

      AcervoService.bip({
        codigo: value,
        acervo_id: acervo?.id
      })
        .then(({ data }) => {
          if (data.tipo === 'ACERVO') {
            if (acervo.id && data.data.id !== acervo.id) {
              inputRemoveFocus();

              audioElement?.play();

              Swal.fire({
                title: 'Nova entrada de Acervo',
                text: 'Você está lendo um código de uma outra localização no acervo. Deseja finalizar as entradas no acervo anterior?',
                showCancelButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: `Cancelar`
              }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                  setProdutos([]);
                  setAcervo(data.data);
                }

                inputInsertFocus();

                return null;
              });
            } else {
              setAcervo(data.data);
            }
          } else {
            setProdutos((state: Produto[]) => {
              return state.concat(data.data).reverse();
            });
          }
        })
        .catch(async e => {
          inputRemoveFocus();

          if (!e.response) {
            audio2Element?.play();

            Swal.fire({
              icon: 'error',
              title: 'Houve um erro de conexão.',
              text: 'Verifique se a internet está conectada e tente novamente'
            }).then(() => {
              inputInsertFocus();
            });
          } else {
            audioElement?.play();

            let swalResponse;

            if (e?.response?.data?.error?.details?.produto) {
              swalResponse = await Swal.fire({
                icon: 'error',
                title: `${e?.response?.data?.error?.details?.produto.nome} - ${e?.response?.data?.error?.details?.produto.codigo} `,
                text: e?.response?.data?.error?.message ?? 'Ocorreu um erro inesperado. Por favor, tente novamente!'
              });
            } else {
              swalResponse = await ApiValidationsHandler(e);
            }

            if (swalResponse?.isConfirmed || swalResponse?.isDismissed) {
              inputInsertFocus();
            }
          }
        })
        .finally(() => {
          setLoadingFull(false);
          setValue('');
        });
    }
  };

  const finalizarEntrada = () => {
    setAcervo({} as Acervo);
    setProdutos([]);
    setValue('');
  };

  const inputRemoveFocus = () => {
    if (inputRef?.current) {
      inputRef.current.disabled = true;
    }
  };

  const inputInsertFocus = () => {
    if (inputRef?.current) {
      inputRef.current.disabled = false;
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    if (!inputRef.current?.disabled) {
      inputInsertFocus();
    }
  };

  const handleDesfazer = (codproduto: number) => {
    setLoadingFull(true);

    inputRef?.current?.focus();

    AcervoService.reverterBip(codproduto)
      .then(() => {
        setProdutos((state: Produto[]) => {
          return state.filter((produto: Produto) => produto.codproduto !== codproduto);
        });
      })
      .finally(() => {
        setLoadingFull(false);
      });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <TemplateBase>
      <h1>Entrada de Acervo: </h1>

      {/* eslint-disable jsx-a11y/media-has-caption */}
      <audio id="audio-error" src={AudioError} crossOrigin="anonymous" />
      <audio id="audio-error-2" src={AudioError2} crossOrigin="anonymous" />

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button color="primary" onClick={finalizarEntrada} variant="contained" type="button" disabled={!acervo?.label}>
          Finalizar
        </Button>
      </Box>
      <Paper elevation={1} className={classes.paper}>
        {acervo?.label ? (
          <h2 className={classes.acervoTitle}>{acervo?.label}</h2>
        ) : (
          <h2 className={classes.acervoTitlePlaceholder}>Leia o código do acervo para iniciar</h2>
        )}

        <Box py={2}>
          <TextField
            inputRef={inputRef}
            onKeyPress={handleKeyBoardPress}
            label="Aguardando leitura de código"
            autoFocus
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>

        <Grid container>
          <Grid xs={12} sm={4}>
            {produtos.length > 0 && (
              <>
                <h3>Entrada mais recente</h3>

                {produtos?.[0] && (
                  <Card variant="outlined" className={classes.mainWrapper}>
                    <div className={classes.mainPicture}>
                      <img src={produtos[0]?.imagens[0]?.medium} width="100%" alt="" />

                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.mainbutton}
                        onClick={() => {
                          handleDesfazer(produtos[0].codproduto);
                        }}
                      >
                        Desfazer
                      </Button>
                    </div>
                    <div className={classes.mainTitle}>
                      <strong>{produtos[0].nome}</strong>
                    </div>
                  </Card>
                )}
              </>
            )}
          </Grid>
          <Grid xs={12} sm={8} className={classes.entradasWrapper}>
            {produtos.length > 1 && (
              <>
                <h3>Todas as entradas ({produtos?.length})</h3>

                {produtos?.map((produto: Produto, index: number) => {
                  if (index === 0) return null;

                  return (
                    <Card variant="outlined" className={classes.pictureWrapper}>
                      {produto?.imagens ? (
                        <div
                          className={classes.picture}
                          style={{ backgroundImage: `url(${produto.imagens[0]?.small})` }}
                        />
                      ) : (
                        ''
                      )}
                      <div className={classes.pictureTitleWrapper}>
                        <div className={classes.pictureTitle}>
                          <strong>{produto.nome}</strong>
                        </div>
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleDesfazer(produto.codproduto);
                          }}
                        >
                          Desfazer
                        </Button>
                      </div>
                    </Card>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Loading open={loadingFull} />
    </TemplateBase>
  );
}

export default EntradaAcervo;
