import React from 'react';
import StarsIcon from '@mui/icons-material/Stars';

import vipIconStyles from './styles';

function VipIcon({ isVip }: { isVip: boolean }) {
  const styles = vipIconStyles();

  const vipIconColor = isVip ? styles.isVip : styles.isNotVip;

  return <StarsIcon className={vipIconColor} />;
}

export default VipIcon;
