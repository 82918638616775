import makeStyles from '@mui/styles/makeStyles';
import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme();

const formEntradaRemessaStyles = makeStyles(() => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalContent: {
    minWidth: '500px'
  },
  labelNome: {
    fontWeight: 700
  },
  descricao: {
    marginTop: 15
  }
}));

export default formEntradaRemessaStyles;
